<FormGroup class="mb1" valid={input.orgStaffRoles?.length > 0} validationMessage="You must assign at least 1 role">
  <div data-test="role-list">
    {#if showTitle}
      <h3 class="flex-row flex-align-center g05 mb1">
        Organization roles
        <RequiredMarker />
        <!--
            Probably find a better way to explain more clearly that each role is OR'd together and that each role's selections are AND'd together.
            So if ANY of their roles give them permission to view/edit something, they'll be able to.
          -->
        <Help>
          <p>Staff members can be configured to be shown <em>only</em> what they’ll want to see within their organization.</p>
          <p class="mb0">
            For instance, maybe a coordinator only handles medical students and doesn’t need to see nursing student rotations, onboarding steps, etc.
            And maybe a preceptor should always be automatically added to rotations for a particular service, but not other services.
          </p>
        </Help>
      </h3>
    {/if}
    {#if showServiceOrCapacityAccessibility && !isCapacityStaff && (rolesWithAccessGranted || rolesWithoutAccessGranted)}
      <div class="p1 mb1" style="background: #eee; border-radius: 4px">
        <div class="flex-row g1">
          <Icon lg name="eye" class="text-success" />
          <span
            ><strong>{rolesWithAccessGranted}</strong>
            {pluralCount('role', rolesWithAccessGranted, 'omitNumber')} <strong>can</strong> access the {accessType}
            <Icon name={accessTypeIcon} class="color-text-purple" />
            <strong>{accessTypeName}</strong>.</span
          >
        </div>
        <div class="flex-row g1 mt05">
          <Icon lg name="hide" class="text-danger" />
          <span
            ><strong>{rolesWithoutAccessGranted}</strong>
            {pluralCount('role', rolesWithoutAccessGranted, 'omitNumber')} <strong>cannot</strong> access the {accessType}
            <Icon name={accessTypeIcon} class="color-text-purple" />
            <strong>{accessTypeName}</strong>.</span
          >
        </div>
      </div>
    {/if}
    {#each input.orgStaffRoles as osr, index (osr.key)}
      <FormGroup valid={osr.staffRole != null} validationMessage="Please select a role.">
        <StaffFormRole
          bind:osr
          bind:input
          {index}
          {hasHealthFeature}
          {showServiceOrCapacityAccessibility}
          {canRestrictByLocation}
          {canRestrictByTeam}
          {canRestrictByServiceOrCapacity}
          {canRestrictByDiscipline}
          {canRestrictByCustomTag}
          {canRestrictByStudent}
          {orgs}
          {descendantOrgOptions}
          {teams}
          {removeOrgStaffRole}
          {canManageEntireStaffRecord}
          roleGrantsAccess={roleGrantsAccessToEntity(osr)}
          {accessType}
          {accessTypeIcon}
          {accessTypeName}
          orgHasChanged={input.orgId !== inputInitial.orgId}
        />
      </FormGroup>
    {/each}
  </div>

  <div class="m1">
    <IconTextLink text="Add role" onClick={addOrgStaffRole} dataTest="add-role-btn" />
  </div>
</FormGroup>

<script>
  import { FeatureType } from 'config/enums'
  import { pluralCount } from 'services/string-utils'
  import Help from 'components/Help.svelte'
  import Icon from 'components/Icon.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import orgsService from 'services/orgs-service'
  import personaService from 'services/persona-service'
  import RequiredMarker from './fields/RequiredMarker.svelte'
  import StaffFormRole from 'components/StaffForm.Role.svelte'
  import FormGroup from './bootstrap/FormGroup.svelte'
  import roleService from 'services/role-service'

  export let input
  export let orgs
  export let service
  export let capacity
  export let teams
  export let accessType
  export let accessTypeIcon
  export let accessTypeName
  export let canManageEntireStaffRecord
  export let showTitle = true
  export let inputInitial = {}
  export let isCapacityStaff = false

  const hasHealthFeature = personaService.canUseAnyFeatureType(FeatureType.HealthInstitution)
  const hasSchoolFeature = personaService.canUseAnyFeatureType(FeatureType.TeachingInstitution)
  const hasTeamFeature = personaService.canUseAnyFeatureType(FeatureType.TeamManagement)
  const hasCustomTagFeature = personaService.canUseAnyFeatureType(FeatureType.CustomTags)

  let rolesWithAccessGranted
  let rolesWithoutAccessGranted
  let accessTypeDisciplines = null
  export let nextRoleKey = -1

  $: orgsById = orgs != null ? new Map(orgs.map(o => [o.orgId, o])) : new Map()
  $: descendantOrgOptions =
    orgs != null && input?.orgId != null
      ? orgsService.getFlatChildren(
          orgs.find(o => o.orgId === input.orgId),
          orgs,
          true
        )
      : []
  $: canRestrictByLocation = descendantOrgOptions?.length > 1
  $: canRestrictByTeam = hasTeamFeature && !!teams?.length
  $: canRestrictByServiceOrCapacity = hasHealthFeature
  $: canRestrictByDiscipline = hasHealthFeature || hasSchoolFeature
  $: canRestrictByCustomTag = hasCustomTagFeature
  $: canRestrictByStudent = hasSchoolFeature
  $: showServiceOrCapacityAccessibility = !!service || !!capacity
  $: input?.orgStaffRoles, service, capacity, teams, updateAccessCounts()
  $: service, capacity, teams, (accessTypeDisciplines = getAccessTypeDisciplines())

  function getAccessTypeDisciplines() {
    if (capacity?.disciplines?.length) return capacity.disciplines
    return service?.teamId ? teams.find(t => t.teamId === service.teamId)?.disciplines : null
  }

  function updateAccessCounts() {
    if (!showServiceOrCapacityAccessibility || !input?.orgStaffRoles) return
    rolesWithAccessGranted = 0
    rolesWithoutAccessGranted = 0
    for (const osr of input.orgStaffRoles) {
      if (osr.staffRole == null) continue
      if (roleGrantsAccessToEntity(osr)) rolesWithAccessGranted++
      else rolesWithoutAccessGranted++
    }
  }
  function roleGrantsAccessToEntity(osr) {
    if (isCapacityStaff) {
      const roleRestrictions = roleService.getRestrictionsPreventingAccessToOpportunity(capacity, osr)
      return !roleRestrictions.restrictions.length
    } else {
      const serviceId = service?.serviceId ?? capacity?.serviceId ?? null
      const capacityId = capacity?.capacityId ?? null
      const orgId = service?.orgId ?? capacity?.orgId ?? null
      const teamId = service?.teamId ?? capacity?.teamId ?? null

      if (osr.serviceIds.includes(serviceId)) return true
      if (osr.capacityIds.includes(capacityId)) return true
      if (osr.serviceIds.length || osr.capacityIds.length) return false

      const roleOrgIds = new Set(osr.orgIds?.length ? osr.orgIds : [input.orgId])
      let org = orgsById.get(orgId)
      let roleHasAccessToServiceOrg = false
      while (org) {
        if (roleOrgIds.has(org.orgId)) {
          roleHasAccessToServiceOrg = true
          break
        }
        org = orgsById.get(org.parentOrgId)
      }
      if (!roleHasAccessToServiceOrg) return false
      if (teamId && osr.teamIds.length && !osr.teamIds.includes(teamId)) return false
      if (accessTypeDisciplines == null || !accessTypeDisciplines.length || !osr.disciplines?.length) return true
      return accessTypeDisciplines.some(d => osr.disciplines.includes(d))
    }
  }
  function addOrgStaffRole() {
    input.orgStaffRoles = [...input.orgStaffRoles, createOrgStaffRole()]
  }

  function removeOrgStaffRole(index) {
    input.orgStaffRoles.splice(index, 1)
    input.orgStaffRoles = input.orgStaffRoles
  }
  function createOrgStaffRole() {
    nextRoleKey--
    return {
      key: nextRoleKey,
      staffRoleId: nextRoleKey,
      staffRole: null,
      isAutoAssigned: true,
      orgIds: [],
      teamIds: [],
      serviceIds: [],
      capacityIds: [],
      disciplines: [],
      customTagIds: [],
      studentUserIds: [],
    }
  }
</script>
