<span class="input-group">
  <span class="input-group-addon">
    {#if loading}
      <Icon name="spinner" spin fw />
    {:else}
      <Icon name="search" dataTest="search-icon" fw />
    {/if}
  </span>

  <input
    id={inputId}
    class="form-control"
    autocomplete="off"
    data-test="search-input"
    {placeholder}
    bind:value={search}
    bind:this={inputElem}
    on:focus={onFocus}
    on:keydown={onKeyDown}
  />

  <div class="select-dropdown autocomplete" class:hide out:blur|local use:popper={{ reference: inputElem, strategy: 'absolute' }}>
    <InfiniteScroll
      currentCount={options.length}
      {distanceToLoadPage}
      {totalCount}
      loadPage={offset => loadPage(offset, search)}
      class="select-dropdown-items-container"
    >
      <div class="select-dropdown-items-container-wrapper">
        {#each options as option, i}
          <div on:click={() => (value = valueSelector(option))} class="item" data-test="item" class:viewing={currentIndex === i}>
            <slot {option}>
              <SafeHtml value={option.label} />
            </slot>
          </div>
        {/each}
      </div>
    </InfiniteScroll>

    {#if showNoResults}
      <slot name="no-results" />
    {/if}
  </div>
</span>

<script>
  import { blur } from 'svelte/transition'
  import Icon from 'components/Icon.svelte'
  import InfiniteScroll from 'components/InfiniteScroll.svelte'
  import Key from 'config/key.js'
  import validator from 'services/validator.js'
  import SafeHtml from 'components/SafeHtml.svelte'
  import popper from 'decorators/popper.js'

  export let value
  export let distanceToLoadPage = null
  export let loadPage // (offset, search) => { /* Something that updates options + totalCount */ }; callers should debounce on their own
  export let loading = false
  export let placeholder = ''
  export let options = []
  export let totalCount = null
  export let inputId = null
  export let showNoResults = false
  export let valueSelector = _.identity

  let search = ''
  let currentIndex = -1
  let hide = false

  $: if (!validator.empty(search)) loadPage(0, search)
  else options = []

  const min = -1
  $: max = options.length - 1
  $: {
    if (currentIndex < min) currentIndex = min
    if (currentIndex > max) currentIndex = max
  }

  function onKeyDown(e) {
    const key = e.which || e.keyCode

    if (hide && ![Key.Escape, Key.Left, Key.Right].includes(key)) {
      hide = false
      return
    }

    switch (key) {
      // up/down traverses autocomplete
      case Key.Down:
        if (currentIndex < options.length - 1) currentIndex++
        e.preventDefault()
        return

      case Key.Up:
        if (currentIndex > -1) currentIndex--
        e.preventDefault()
        return
      case Key.Enter:
        if (currentIndex > -1) {
          value = options[currentIndex]
          search = null
        }
        e.preventDefault()
        return

      case Key.Escape:
        hide = true
        e.preventDefault()
        return
    }
  }

  function onFocus() {
    hide = false
  }

  let inputElem
  export function focus() {
    inputElem?.focus?.()
  }
</script>
