// if this logic changes, `MatchControllerService.GenerateChangeDeltaAndCommentForApiUser` may also need to be updated

import { MatchRole } from 'config/enums.js'
import { toFriendlyList } from 'services/string-utils.js'
import validator from 'services/validator.js'

export function mergeMatch(initial, local, remote) {
  // server should always send basic info
  setMatchBasicInfo(local, remote)

  // only set step-related info, if server sends it
  const remoteContainsStepInfo = remote.applicationSteps || remote.onboardingSteps || remote.offboardingSteps || remote.evaluationSteps
  if (remoteContainsStepInfo) setMatchStepInfo(local, remote)

  // handle fields that can be unsaved client-side and might have conflicts
  const conflicts = {}
  const setOrAddConflict = (key, equalsFunc) => {
    // if (validator.empty(remote[key]))
    //   return // remote didn't send it or it's empty. if it's empty, no need to show conflict, since there's no risk of data-loss--just potential unwanted data gain

    if (equalsFunc == null) equalsFunc = validator.equals

    // if no local changes, but remote changed, we're safe to update it
    const localChanged = !equalsFunc(local[key], initial[key])
    if (!localChanged) {
      local[key] = remote[key]
      return
    }

    // local changed, but if it changed to same value as remote, nothing needs to be done
    const localEqualsRemote = equalsFunc(local[key], remote[key])
    if (localEqualsRemote) return

    // remote is same as initial, so no need to show conflicts
    const remoteDidNotChange = equalsFunc(remote[key], initial[key])
    if (remoteDidNotChange) return

    // both remote and local changed this prop and they aren't equal, so conflict
    conflicts[key] = {
      key,
      keyUIName: keyUINames[key],
      description: `Your changes to ${keyUINames[key]} are different from the latest changes:`,
    }
  }
  setOrAddConflict('studentComments')
  setOrAddConflict('studentCommentsHtml')
  setOrAddConflict('syllabus')
  setOrAddConflict('syllabusHtml')
  setOrAddConflict('startDate')
  setOrAddConflict('endDate')
  setOrAddConflict('alternateDates', alternateDatesEquals)
  setOrAddConflict('matchDays', matchDaysEquals)
  setOrAddConflict('matchDayConfigs', matchDayConfigsEquals)
  setOrAddConflict('matchUsers', matchUsersEquals)

  return {
    hasConflicts: Object.keys(conflicts).length > 0,
    conflicts,
    mergeResult: { ...local }, // this is safe to set directly--no conflicting changes will be set
  }
}

export const keyUINames = {
  studentComments: 'additional comments',
  syllabus: 'learning objectives',
  placeholderStudentCount: 'placeholder students',
  startDate: 'start date',
  endDate: 'end date',
  onboardingDeadline: 'onboarding deadline',
  alternateDates: 'alternate dates',
  matchDays: 'schedule',
  matchUsers: 'staff, faculty, and/or students', // TODO: split these out by role (potentially also server-side to avoid overwriting changes...), make those separate keys on the object when comparing/merging/diffing. that way, someone can add a preceptor and not conflict with someone else who'e in the middle of adding a student.
  customTags: 'custom tags',
  courseId: 'course',
}

export function setMatchBasicInfo(local, remote) {
  // server will always send basic match status and closed record info, so we always set that. user can't change it explicitly, so no conflicts
  local.status = remote.status
  local.statusName = remote.statusName
  local.closed = remote.closed
  local.closedReason = remote.closedReason
  local.closedReasonName = remote.closedReasonName
  local.otherClosedReason = remote.otherClosedReason
  local.closedSource = remote.closedSource
  local.closedSourceName = remote.closedSourceName
  local.onboardingDeadline = remote.onboardingDeadline
  local.paused = remote.paused
  local.pausedReason = remote.pausedReason

  // if server sends notifiable users, update them (when matchUsers change)
  local.notifiableUsers = remote.notifiableUsers || local.notifiableUsers

  // same for proposed changes
  local.proposedChanges = remote.proposedChanges || local.proposedChanges
}

export function setMatchStepInfo(local, remote) {
  // these won't cause conflicts, since all step actions immediately persist to server
  local.applicationSteps = remote.applicationSteps
  local.onboardingSteps = remote.onboardingSteps
  local.offboardingSteps = remote.offboardingSteps
  local.evaluationSteps = remote.evaluationSteps

  // if server sends matchUsers, update only the status of matchusers--local might have added/removed matchusers, so we don't want to blow those changes away
  if (remote.matchUsers != null) {
    local.matchUsers = local.matchUsers.map(localMu => {
      const remoteMu = remote.matchUsers.find(ru => ru.userId === localMu.userId && ru.matchRole === localMu.matchRole)
      return remoteMu == null ? localMu : { ...localMu, status: remoteMu.status, statusName: remoteMu.statusName }
    })
  }
}

function matchDaysEquals(left, right) {
  const basic = mds =>
    mds
      ? _.orderBy(
          mds.map(md => ({ userId: md.userId, shiftId: md.shiftId, date: md.date })),
          ['userId', 'shiftId', 'date']
        )
      : null
  return validator.equals(basic(left), basic(right))
}

function matchDayConfigsEquals(left, right) {
  const basic = mdcs =>
    mdcs
      ? _.orderBy(
          mdcs.map(mdc => ({
            shiftId: mdc.shiftId,
            date: mdc.date,
            startTime: mdc.startTime,
            endTime: mdc.endTime,
            placeholderStudentCount: mdc.placeholderStudentCount,
          })),
          ['shiftId', 'date', 'startTime', 'endTime', 'placeholderStudentCount']
        )
      : null
  return validator.equals(basic(left), basic(right))
}

function alternateDatesEquals(left, right) {
  const order = ad => (ad == null ? null : _.orderBy(ad, 'orderNumber'))
  return validator.equals(order(left), order(right))
}

function matchUsersEquals(left, right) {
  // only care about userId, matchRole
  const order = mus =>
    mus
      ? _.orderBy(
          mus.map(mu => ({ userId: mu.userId, matchRole: mu.matchRole })),
          ['userId', 'matchRole']
        )
      : null
  return validator.equals(order(left), order(right))
}

function customTagsEquals(left, right) {
  const order = customTags => customTags?.map(ct => ct.customTagId).sort((a, b) => a - b) ?? []
  return validator.equals(order(left), order(right))
}

export function getChangesViewModel(matchInitial, match) {
  const changes = getChanges(matchInitial, match)
  const anyChanges = Object.values(changes).some(v => v != null)
  if (!anyChanges) return {} // if they saved their changes and _then_ submitted...
  changes.previousState = getPreviousStateToSave(matchInitial, changes)
  return {
    changes,
  }
}

function matchUserChangesWouldAffectOtherSide(changes, match) {
  const rolesNotAffectingOtherSide = []
  if (match.isCoordinator || match.isPreceptor) {
    rolesNotAffectingOtherSide.push(MatchRole.ClinicCoordinator, MatchRole.Preceptor, MatchRole.HostAdmin, MatchRole.HostViewer)
  }
  if (match.isSchoolCoordinator || match.isFaculty) {
    rolesNotAffectingOtherSide.push(MatchRole.SchoolCoordinator, MatchRole.SchoolFaculty, MatchRole.GuestAdmin, MatchRole.GuestViewer)
  }
  const getMatchUsersAffectingOtherSide = mu => !rolesNotAffectingOtherSide.includes(mu.matchRole)
  const before = changes.matchUsers.filter(getMatchUsersAffectingOtherSide)
  const after = changes.previousState.matchUsers.filter(getMatchUsersAffectingOtherSide)
  return !matchUsersEquals(before, after)
}

// This function is negative because if we add new things that can be changed,
// I'd rather the propose changes button light up than to assume it won't affect
// the other side. Then, if we think it's stupid, we can handle it here.
export function changesWouldNotAffectOtherSide(changes, match) {
  if (changes == null) return true
  for (const key in changes) {
    if (changes[key] == null) continue
    switch (key) {
      case 'previousState':
        continue
      case 'customTags':
        continue
      case 'matchUsers':
        if (matchUserChangesWouldAffectOtherSide(changes, match)) return false
        continue
      default:
        return false
    }
  }
  return true
}

function getChanges(initial, local) {
  // leave non-changed props null when sending to server (it only saves what's set)
  const getVal = (a, b, equalsFunc) => {
    equalsFunc ??= validator.equals
    return equalsFunc(a, b) ? undefined : b
  }
  return {
    syllabus: getVal(initial.syllabus, local.syllabus),
    studentComments: getVal(initial.studentComments, local.studentComments),
    startDate: getVal(initial.startDate, local.startDate),
    endDate: getVal(initial.endDate, local.endDate),
    onboardingDeadline: getVal(initial.onboardingDeadline, local.onboardingDeadline),
    alternateDates: getVal(initial.alternateDates, local.alternateDates, alternateDatesEquals),
    matchDays: getVal(initial.matchDays, local.matchDays, matchDaysEquals),
    matchDayConfigs: getVal(initial.matchDayConfigs, local.matchDayConfigs, matchDayConfigsEquals),
    matchUsers: getVal(initial.matchUsers, local.matchUsers, matchUsersEquals),
    placeholderStudentCount: getVal(initial.placeholderStudentCount, local.placeholderStudentCount),
    customTags: getVal(initial.customTags, local.customTags, customTagsEquals),
    courseId: getVal(initial.courseId, local.courseId),
  }
}

function getPreviousStateToSave(matchInitial, changes) {
  if (changes == null) return null
  const previousState = {}
  Object.keys(changes).forEach(k => {
    if (changes[k] !== undefined) previousState[k] = matchInitial[k]
  })

  // if modding match days, store some other info that could change during the match's lifetime, so we can construct an accurate "previous schedule"
  if (previousState.matchDays) {
    previousState.status = matchInitial.status
    previousState.startDate = matchInitial.startDate
    previousState.endDate = matchInitial.endDate
    previousState.shifts = matchInitial.shifts
    previousState.capacity = matchInitial.capacity // generally capacity doesn't change for the match, but it _could_, so persist it too
    previousState.otherMatchSchedules = matchInitial.otherMatchSchedules // this can make it clear why a day might have been changed if the student was busy at the time
    previousState.matchDayConfigs = matchInitial.matchDayConfigs
  }
  return previousState
}

export function changeFromPrev(currMatch, previousState) {
  // matchId won't change during the life of the match, so no need to store it on previousState
  previousState.matchId = currMatch.matchId

  // fallback to using current match's values for any values that aren't stored on the previousState
  // note some of these values are now stored on previousState, but weren't in the past
  if (previousState.matchDays != null) {
    if (previousState.status == null) previousState.status = currMatch.status
    if (previousState.startDate == null) previousState.startDate = currMatch.startDate
    if (previousState.endDate == null) previousState.endDate = currMatch.endDate
    if (previousState.shifts == null) previousState.shifts = currMatch.shifts
    if (previousState.capacity == null) previousState.capacity = currMatch.capacity
    if (previousState.matchUsers == null) previousState.matchUsers = currMatch.matchUsers
    if (previousState.otherMatchSchedules == null) previousState.otherMatchSchedules = currMatch.otherMatchSchedules
    if (previousState.courseId == null) previousState.courseId = currMatch.courseId
  }

  return previousState
}

// if this changes, probably update `MatchControllerService.cs.GenerateChangeDeltaAndCommentForApiUser`. Also, consider just calling to that when they click "propose change"
export function getDefaultChangeComment(delta, currMatch) {
  const changes = []
  if (delta == null) return ''

  if (delta.placeholderStudentCount != null) changes.push('placeholder students')

  // staff changes
  if (delta.matchUsers != null) {
    // since we store all matchusers at time of change we need to look at which role of match user(s) will be affected by the change
    const matchUsersChanged = (role, roleName) => {
      if (
        !matchUsersEquals(
          currMatch.matchUsers.filter(mu => mu.matchRole === role),
          delta.matchUsers.filter(mu => mu.matchRole === role)
        )
      )
        changes.push(roleName)
    }
    matchUsersChanged(MatchRole.Student, 'students')
    matchUsersChanged(MatchRole.SchoolFaculty, 'school faculty')
    matchUsersChanged(MatchRole.Preceptor, 'preceptors')
  }
  // schedule changes
  if (delta.matchDays != null) changes.push('schedule')
  else {
    if (delta.startDate != null) changes.push('start date')
    if (delta.endDate != null) changes.push('end date')
  }
  if (delta.matchDayConfigs != null) changes.push('schedule configuration')

  // onboarding deadline
  if (delta.onboardingDeadline != null) changes.push('onboarding deadline')

  // course
  if (delta.courseId != null) changes.push('course')

  // alternate date changes
  if (delta.alternateDates != null) changes.push('alternate dates')

  // learning objective changes
  if (delta.syllabus != null) changes.push('learning objectives')

  // additional comment changes
  if (delta.studentComments != null) changes.push('additional comments')

  if (delta.customTags != null) changes.push('custom tags')

  return toFriendlyList(changes) + ' changed'
}
