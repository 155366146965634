{#if bucket && timeBucket}
  <Router>
    <Modal on:close={closeDetails} xl>
      <h4 slot="title">
        {bucket.host ?? bucket.guest ?? ''}

        {#if bucket.service}
          {#if $persona.personaType === PersonaType.ProviderStaff}
            <div>
              <IconTextLink icon="shapes" text={bucket.service} href="/services/{bucket.serviceId}" />
            </div>
          {:else}
            <div class="flex-row flex-align-center g05">
              <Icon name="shapes" class="color-text-purple" />
              <span>{bucket.service}</span>
            </div>
          {/if}
        {/if}

        {#if bucket.agreementId}
          <div>
            <IconTextLink icon="agreement" text={bucket.agreement} href={`/agreements/{bucket.agreementId}`} />
          </div>
        {/if}

        {#if bucket.capacity}
          <div class="flex-row flex-align-center g05">
            <Icon name="list" class="color-text-purple" />
            <span>{bucket.capacity}</span>
          </div>
        {/if}

        <div>
          {#if bucket.shift}<span class="pill color-bg-{bucket.color}">{bucket.shift}</span>{/if}
          {timeBucket.keyFriendly}
          <Badge count={timeBucket.totalDays} unit="day" min={0} />
          {#if bucket.timeRange}<span class="help-block-inline">{bucket.timeRange}</span>{/if}
        </div>
      </h4>

      <div class="modal-body">
        {#if matchesViolatingCapacitySchedule?.length}
          <div class="mb1">
            <span class="text-warning" data-test="match-violation-warning">
              <Icon name="calendar" />
              {pluralCount('rotation', matchesViolatingCapacitySchedule.length)}
              violate{matchesViolatingCapacitySchedule.length === 1 ? 's' : ''}
              {matchesViolatingCapacitySchedule.length > 1 ? 'their' : 'its'}
              opportunity’s schedule.
            </span>
          </div>
        {/if}
        <div class="mb1">
          {#if bucket.finite && bucket.finite.overfilledPercent > 0}
            <Collapsible name="overfill">
              <span slot="label" class="text-danger" data-test="overfill-maybe-label">
                <Icon name="alert-triangle" />
                {bucket.finite.shiftsOverfilledCount}
                availability window{bucket.finite.shiftsOverfilledCount > 1 ? 's are' : ' is'}
                overfilled
              </span>
              <ul>
                {#each Object.keys(bucket.finite.shiftsOverfilled) as shiftId}
                  <li>
                    <CapacityCalendarBucketDetailsServiceCapacityShift shift={shifts[shiftId]} />
                    <span class="help-block-inline" data-test="shifts-overfilled-help-block">
                      (short by {pluralCount('availability window opening', bucket.finite.shiftsOverfilled[shiftId])})
                    </span>
                    <!--nice-to-have : if user has permission to modify the capacity, provide simple button to update guaranteed rotations, adding the needed number of rotations-->
                  </li>
                {/each}
              </ul>
            </Collapsible>
          {/if}
          {#if bucket.finite && bucket.finite.overfilledMaybePercent > 0}
            <Collapsible name="overfillMaybe">
              <span slot="label" class="text-warning">
                <Icon name="alert-triangle" />
                {pluralCount('availability window', bucket.finite.shiftsOverfilledMaybeCount)}
                might overfill if all rotations using
                {bucket.finite.shiftsOverfilledMaybeCount > 1 ? 'them' : 'it'}
                get to onboarding status
              </span>
              <ul>
                {#each Object.keys(bucket.finite.shiftsOverfilledMaybe) as shiftId}
                  <li>
                    <CapacityCalendarBucketDetailsServiceCapacityShift shift={shifts[shiftId]} />
                    <span class="help-block-inline">
                      (potentially short by
                      {pluralCount(
                        'availability window opening',
                        (bucket.finite.shiftsOverfilledMaybe[shiftId] || 0) + (bucket.finite.shiftsOverfilled[shiftId] || 0)
                      )})
                    </span>
                    <!--nice-to-have maybe: if user has permission to modify the capacity, provide simple button to update guaranteed rotations, adding the needed number of rotations-->
                  </li>
                {/each}
              </ul>
            </Collapsible>
          {/if}
          {#if bucket.infinite && bucket.infinite.shiftsInfiniteCount !== 0}
            <Collapsible name="infinite">
              <span slot="label" class="text-info">
                <Icon name="infinity" />
                {pluralCount('availability window', bucket.infinite.shiftsInfiniteCount)}
                {bucket.infinite.shiftsInfiniteCount > 1 ? "don't" : "doesn't"}
                specify a per day limit.
                <Help>If you specify a per day limit on your shifts, we can identify overfills.</Help>
              </span>
              <ul>
                {#each Object.keys(bucket.infinite.shiftsInfinite) as shiftId}
                  <li>
                    <CapacityCalendarBucketDetailsServiceCapacityShift shift={shifts[shiftId]} />
                    <span class="help-block-inline">
                      (would need {pluralCount('availability window', bucket.infinite.shiftsInfinite[shiftId])})
                    </span>
                    <!--nice-to-have: if user has permission to modify capacity, provide button to set shift per day limit explicitly to bucket.filled (or bucket.filled+pending+waitlisted)-->
                  </li>
                {/each}
              </ul>
            </Collapsible>
          {/if}
        </div>
        <h4 class="flex-row flex-align-center g05">
          Per day limit usage
          <Help>This takes into account <em>all</em> rotations in the system under this {groupByName.toLowerCase()} and within this time frame.</Help>
        </h4>

        <MatchUsageBar lg bucket={bucket.finite} showTooltip />

        {#if bucket.infinite && bucket.infinite.shiftsInfiniteCount !== 0}
          <MatchUsageBar lg bucket={bucket.infinite} showTooltip infinite />
        {/if}

        <h4 class="flex-row flex-align-center g05 mt3">
          Rotations
          <Help>
            <p class="mb1">The rotations shown below are not necessarily all the rotations that contribute to “guaranteed rotations usage” above.</p>
            <p class="m0">Only rotations you have access to and that meet any filters you’ve applied are shown here.</p>
          </Help>
        </h4>

        {#if matches == null}
          <Spinner />
        {:else}
          <Tabs bind:active={tab} class="tab-rows">
            <Tab href="/">
              All rotations
              <Badge color="gray" name="all" count={matches} min={0} />
            </Tab>

            <Tab href="filled" name="filled" color="success" linkClass="text-success">
              Filled
              <Badge color="success" name="filled" count={filledMatches} min={0} />
            </Tab>

            <Tab href="pending" name="pending" color="purple" linkClass="text-purple">
              Pending
              <Badge color="purple" name="pending" count={pendingMatches} min={0} />
            </Tab>

            <Tab href="waitlisted" name="waitlisted" color="sky" linkClass="text-sky">
              Waitlisted
              <Badge color="sky" name="waitlisted" count={waitlistedMatches} min={0} />

              {#if bucket.roomForWaitlisted > 0}
                <Help>
                  There may be room to move
                  {bucket.roomForWaitlisted === bucket.waitlisted ? 'all waitlisted' : bucket.roomForWaitlisted}
                  out of the waitlist for this shift.
                </Help>
              {/if}
            </Tab>

            <Tab href="overfilled" name="overfilled" color="danger" linkClass="text-danger">
              Overfilled
              <Badge color="danger" name="overfilled" count={matchesOverfilled} min={0} />

              <Help>
                <p class="mb1">
                  The rotations shown here make use of shift(s) that are overfilled (more rotations in the same shift on the same day than the shift
                  is configured to allow).
                </p>
                <p class="m0">If this is a problem, consider rescheduling or waitlisting some of them.</p>
              </Help>
            </Tab>

            <Tab href="overfilledmaybe" name="overfilledmaybe" color="warning" linkClass="text-warning">
              Might overfill
              <Badge color="warning" name="overfilledmaybe" count={matchesOverfilledMaybe} min={0} />

              <Help>The rotations shown here make use of shift(s) that may overfill if pending and waitlisted rotations are accepted.</Help>
            </Tab>

            {#if matchesViolatingCapacitySchedule.length > 0}
              <Tab href="capacityDateViolators" name="capacityDateViolators" color="warning" linkClass="text-warning">
                Opportunity schedule violated
                <Badge color="warning" name="capacityDateViolators" count={matchesViolatingCapacitySchedule} min={0} />
              </Tab>
            {/if}
          </Tabs>
        {/if}

        <div class="scrollable-lg p2">
          <MatchList matches={matchesOrdered} {baseUrl} {onMatchModalClosed} emptyMsg="None" />
          <MatchList path="waitlisted" matches={waitlistedMatches} {baseUrl} emptyMsg="None waitlisted" {onMatchModalClosed} />
          <MatchList path="pending" matches={pendingMatches} {baseUrl} emptyMsg="None pending" {onMatchModalClosed} />
          <MatchList path="filled" matches={filledMatches} {baseUrl} emptyMsg="None filled" {onMatchModalClosed} />
          <MatchList path="overfilled" matches={matchesOverfilled} {baseUrl} emptyMsg="None using overfilled shift(s)" {onMatchModalClosed} />
          <MatchList
            path="overfilledmaybe"
            matches={matchesOverfilledMaybe}
            {baseUrl}
            emptyMsg="None using that might overfill"
            {onMatchModalClosed}
          />
          <MatchList
            path="capacityDateViolators"
            matches={matchesViolatingCapacitySchedule}
            {baseUrl}
            emptyMsg="No opportunity schedule violations"
            {onMatchModalClosed}
          />
        </div>
      </div>
    </Modal>
  </Router>
{/if}

<script>
  import { GroupBy, FilterType, MatchStatus, PersonaType, ToManyComparison } from 'config/enums.js'
  import { onDestroy, createEventDispatcher } from 'svelte'
  import { pluralCount } from 'services/string-utils.js'
  import { Router, navigate } from 'svelte-routing'
  import api from 'services/api.js'
  import Badge from 'components/Badge.svelte'
  import Collapsible from 'components/Collapsible.svelte'
  import getMatchController from 'services/match-controller.js'
  import Help from 'components/Help.svelte'
  import Icon from 'components/Icon.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import MatchList from './CapacityCalendar.MatchList.svelte'
  import Modal from 'components/Modal.svelte'
  import persona from 'stores/persona.js'
  import MatchUsageBar from 'components/MatchUsageBar.svelte'
  import Spinner from 'components/Spinner.svelte'
  import Tab from 'components/Tab.svelte'
  import Tabs from 'components/Tabs.svelte'
  import CapacityCalendarBucketDetailsServiceCapacityShift from './CapacityCalendar.BucketDetails.ServiceCapacityShift.svelte'

  export let key
  export let groupedById
  export let capacityDataAll
  export let shifts
  export let groupByName
  export let criteria
  export let baseHref

  const dispatch = createEventDispatcher()

  let timeBucket
  let bucket
  let matches
  let bucketParams

  let tab = ''
  let currentXHR = null

  $: baseUrl = `${baseHref}/${encodeURIComponent(key)}/${groupedById}${tab ? `/${tab}` : ''}`
  $: if (key && capacityDataAll) showDetails()
  // order so that the ones they'd probably want to favor rescheduling are on top (least far along status-wise, and furthest out date-wise)
  // need to parse as date, so that "3/3/2020" shows _below_ "3/20/2020" for instance
  $: matchesOrdered =
    matches == null
      ? null
      : _.orderBy(
          matches.map(m => ({ ...m, startParsed: new Date(m.startDate) })),
          ['status', 'startParsed'],
          ['asc', 'desc']
        )
  $: waitlistedMatches = matchesWhere(matchesOrdered, m => m.status === MatchStatus.Waitlisted)
  $: pendingMatches = matchesWhere(
    matchesOrdered,
    m => m.status !== MatchStatus.Waitlisted && m.status <= MatchStatus.PendingClinicalSite && m.status > MatchStatus.Unsubmitted
  )
  $: filledMatches = matchesWhere(matchesOrdered, m => m.status >= MatchStatus.Onboarding && m.status < MatchStatus.Closed)
  $: matchesOverfilled = bucket
    ? bucket.finite && bucket.finite.shiftsOverfilled.length === 0
      ? []
      : matchesWhere(matchesOrdered, m => bucket.finite && bucket.finite.matchesOverFilled.some(mo => mo === m.matchId))
    : []
  $: matchesOverfilledMaybe = bucket
    ? bucket.finite && bucket.finite.shiftsOverfilledMaybe.length === 0
      ? []
      : matchesWhere(matchesOrdered, m => bucket.finite && bucket.finite.matchesOverFilledMaybe.some(mo => mo === m.matchId))
    : []
  $: matchesViolatingCapacitySchedule = matchesWhere(
    matchesOrdered,
    m =>
      (bucket && bucket.finite && bucket.finite.matchesViolatingCapacitySchedule.some(mv => mv === m.matchId)) ||
      (bucket && bucket.infinite && bucket.infinite.matchesViolatingCapacitySchedule.some(mv => mv === m.matchId))
  )

  onDestroy(() => {
    if (currentXHR) currentXHR.abort()
  })

  function showDetails() {
    const decodedKey = decodeURIComponent(key)
    timeBucket = capacityDataAll[decodedKey]
    if (timeBucket == null) return // if url was set to now-deleted key
    bucket = capacityDataAll[decodedKey].buckets.find(b => b.groupedById === groupedById)
    const filters = [...criteria.filters]
    const bucketFilter = getBucketFilter()
    if (bucketFilter) filters.push(bucketFilter)
    bucketParams = {
      ...{ ...criteria, filters },
      startDate: timeBucket.start,
      endDate: timeBucket.end,
    }
    loadBucketDetails()
  }

  async function loadBucketDetails() {
    // using same endpoint as rotation calendar uses, just only getting data for a single day and the item clicked (shift, capacity, service, org, school)
    if (currentXHR != null) currentXHR.abort()
    currentXHR = getMatchController().getCalendar(bucketParams, api.noMonitor)
    try {
      const data = await currentXHR
      currentXHR = null
      // comes as a dictionary...could probably make new endpoint that skips sending back shifts and sends an array of matches instead of dictionary
      matches = Object.values(data.matches)
    } catch {
      currentXHR = null
    }
  }

  function onMatchModalClosed(e) {
    navigate(baseUrl)
    dispatch('matchModalClosed', e.detail)
  }

  function matchesWhere(matchesOrdered, where) {
    return matchesOrdered?.filter(where) ?? []
  }

  function closeDetails() {
    if (currentXHR != null) currentXHR.abort()
    dispatch('close')
  }

  function getBucketFilter() {
    const id = isNaN(groupedById) ? groupedById : parseInt(groupedById)
    const noneSpecified = id === -1 || id === 'None' // discipline is "None" since no concept of "DisciplineID"
    const groupBy = criteria.groupBy
    const toManyAnyOf = { comparison: ToManyComparison.AnyOf }
    switch (groupBy) {
      case GroupBy.ClinicalSite:
        return buildFilter(id, FilterType.HostOrgs, 'orgIds')
      case GroupBy.ClinicalTeam:
        return noneSpecified ? null : buildFilter(id, FilterType.Teams, 'teamIds')
      case GroupBy.School:
        return buildFilter(id, FilterType.GuestOrgs, 'schoolOrgIds')
      case GroupBy.GuestTeam:
        return noneSpecified ? null : buildFilter(id, FilterType.MatchGuestTeams, 'teamIds') // NOTE: this filter isn't implemented yet--will implement when we make school teams actually used on matches...
      case GroupBy.Service:
        return buildFilter(id, FilterType.Services, 'serviceIds')
      case GroupBy.Agreement:
        return noneSpecified ? null : buildFilter(id, FilterType.Agreements, 'agreementIds')
      case GroupBy.Opportunity:
        return buildFilter(id, FilterType.MatchCapacity, 'capacityIds')
      case GroupBy.RequestType:
        return buildFilter(id, FilterType.MatchRequestType, 'matchRequestTypes')
      case GroupBy.Shift:
        return buildFilter(id, FilterType.MatchShift, 'shiftIds')
      case GroupBy.Student:
        return buildFilter(id, FilterType.Students, 'userIds', toManyAnyOf)
      case GroupBy.Coordinator:
        return buildFilter(id, FilterType.MatchHostOrgCoordinators, 'userIds', toManyAnyOf)
      case GroupBy.SchoolCoordinator:
        return buildFilter(id, FilterType.GuestOrgCoordinators, 'userIds', toManyAnyOf)
      case GroupBy.Preceptor:
        return buildFilter(id, FilterType.MatchPreceptors, 'userIds', toManyAnyOf)
      case GroupBy.SchoolFaculty:
        return buildFilter(id, FilterType.SchoolFaculty, 'userIds', toManyAnyOf)
      case GroupBy.Discipline:
        return noneSpecified ? null : buildFilter(id, FilterType.Disciplines, 'disciplines')
    }
  }

  function buildFilter(id, type, configKey, configOverrides = {}) {
    return {
      type,
      config: { [configKey]: [id], ...configOverrides },
    }
  }
</script>

<style>
  .pill {
    padding: 5px;
    border-radius: 5px;
    color: #eee;
  }
  .rotationsOpen {
    color: #777;
    background-color: #ededed;
  }
</style>
