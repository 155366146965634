<FilterTypeListFilter
  bind:filter
  {editing}
  {appliedMeta}
  {filterOptions}
  usingCustomPicker
  labelSelector={o => o.name}
  selectedItemsContainerClass={null}
  let:item
>
  <div slot="picker" class="mt1 mb1">
    <CoursePicker bind:value={filter[appliedMeta.filterProp]} multiple or={customPickerShouldUseOr(appliedMeta, filter)} />
  </div>

  <svelte:fragment slot="selectedItem"
    >{#if item?.courseId}{item.name}{:else if !appliedMeta.toMany}<em>unspecified</em>{/if}</svelte:fragment
  >
</FilterTypeListFilter>

<script context="module">
  import { FeatureType, FilterType, PersonaType } from 'config/enums.js'
  import api from 'services/api.js'
  import CoursePicker from 'components/CoursePicker.svelte'
  import FilterTypeListFilter, { meta as baseMeta, customPickerShouldUseOr } from 'components/filter-types/FilterTypeListFilter.svelte'
  import personaService from 'services/persona-service'

  export const meta = {
    ...baseMeta,
    type: FilterType.Course,
    idKey: 'courseId',
    label: 'Course',
    labelApplied: 'course',
    icon: 'course',
    iconClass: 'color-text-steel',
    allowEmpty: true,
    excludable: true,
    hasDoesntHave: true,
    filterProp: 'courseIds',
    getSelected: async selectedCourseIds =>
      (await api.course.list({ selectedCourseIds, includePageOfData: false, pageSize: null, excludeTotalCount: true }, api.noMonitor))
        .selectedCourses,
    // we show the course to the health side, so we allow them to filter by it too. ideally we'd only show it if any of their matches were attached to schools that have the feature, but meh.
    shouldShow: $persona =>
      $persona.personaType === PersonaType.ProviderStaff || personaService.canUseAnyFeatureType(FeatureType.BulkChangeCourse, FeatureType.Courses),
  }
</script>

<script>
  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
</script>
