{#if showDateSuggestion}
  {#if isProviderStaff && !isCapacityStartDateAfterDateWithLeadTime}
    <em class="small text-gray"
      >This opportunity’s {isCapacityStartDateAfterDateWithLeadTime ? 'start date' : 'lead time'} suggests a start date no earlier than
      <strong class="strongish">{minStartDateWithLeadTimeOrCapacityStartDate}</strong>
    </em>
  {:else}
    <em class="small text-gray"
      >The soonest your experience could start is <strong class="strongish">{minStartDateWithLeadTimeOrCapacityStartDate}</strong>, based on
      opportunity’s {isCapacityStartDateAfterDateWithLeadTime ? 'start date' : 'lead time'}.
    </em>
  {/if}
{/if}

<FormGroup valid={isValid}>
  <DatePicker
    name="match-dates"
    range
    autoSetOther={false}
    bind:value={startDate}
    bind:endValue={endDate}
    min={minCalendarStartDate}
    max={maxEndDate}
    on:changed={refreshStepsDebounced}
    disabled={disableEditing}
    showError={!areDatesFormattedCorrectly}
    setValueIfNotInBounds
    iconClass="color-text-dark-green"
  >
    <svelte:fragment slot="rangeEndLabel">
      <span>to</span>
      <Icon name="calendar" class="color-text-light-red" />
    </svelte:fragment>
  </DatePicker>
</FormGroup>

{#if isStartDateBeforeLeadTimeOrCapacityStartDate && isProviderStaff}
  <Alert type="warning" class="mt05" dataTest={'invalid-date-alert'}>
    {isExisting && !isCalendarChanged ? 'The selected start date is' : 'You’ve selected a date'} earlier than the suggested start date of
    <strong class="strongish">{minStartDateWithLeadTimeOrCapacityStartDate}</strong>, based on opportunity’s {isCapacityStartDateAfterDateWithLeadTime
      ? 'start date'
      : 'lead time'}.
  </Alert>
{/if}
{#if showAlert}
  <Alert type={submitted ? 'danger' : 'warning'} class="mt05 validation-message" dataTest={'invalid-date-alert'}
    >Your chosen start date conflicts with our opportunity’s {isCapacityStartDateAfterDateWithLeadTime ? 'start date' : 'lead time policy'}. To
    proceed, please modify the {canUserScheduleMatchDays ? 'start date or the first shift' : 'start date'} to be on or after
    <strong class="strongish">{minStartDateWithLeadTimeOrCapacityStartDate}</strong>.</Alert
  >
{/if}

<script>
  import { PersonaType, MatchStatus } from 'config/enums.js'
  import Alert from 'components/bootstrap/Alert.svelte'
  import DatePicker from 'components/fields/DatePicker.svelte'
  import dateService from 'services/date-service'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Icon from 'components/Icon.svelte'
  import persona from 'stores/persona.js'
  import validator from 'services/validator'

  export let isCreating = false
  export let isExisting = false
  export let capacity = {}
  export let match = {}
  export let matchDays = []
  export let startDate = null
  export let endDate = null
  export let submitted = false
  export let matchInitial = {}
  export let refreshStepsDebounced

  const today = dayjs().utc().add(-7, 'hours').startOf('day').format('M/D/YYYY') // server uses pst
  const initialStartDate = isExisting ? matchInitial?.startDate : null
  const initialEndDate = isExisting ? matchInitial?.endDate : null

  let isValid

  $: if (match.status === MatchStatus.Unsubmitted) isCreating = true

  $: canUserScheduleMatchDays = $persona.personaType === PersonaType.Student && match?.capacity?.allowStudentScheduling

  $: leadTimeDays = capacity.matchLeadTimeDays
  $: dateWithLeadTime = getDateWithLeadTime(leadTimeDays)

  $: isProviderStaff = $persona.personaType === PersonaType.ProviderStaff
  $: disableEditing = isExisting && (!match.isEditable || !match.allowChanges)

  $: isCalendarChanged = dateService.datechanged(startDate, initialStartDate) || dateService.datechanged(endDate, initialEndDate)

  $: derivedStartDate = dayjs(startDate)
  $: minCalendarStartDate = getCalendarMinStartDate(
    isProviderStaff,
    isCapacityStartDateAfterDateWithLeadTime,
    dateWithLeadTime,
    leadTimeDays,
    canUserScheduleMatchDays
  )
  $: earliestMatchDate = getEarliestMatchDate(matchDays)?.date
  $: maxEndDate = getMaxEndDate(isProviderStaff)

  $: areDatesFormattedCorrectly = endDate
    ? dayjs(startDate, 'M/D/YYYY').isValid() && dayjs(endDate, 'M/D/YYYY').isValid()
    : dayjs(startDate, 'M/D/YYYY').isValid()

  $: isEarliestMatchDateValid =
    earliestMatchDate &&
    (dayjs(minStartDateWithLeadTimeOrCapacityStartDate).isBefore(earliestMatchDate) ||
      minStartDateWithLeadTimeOrCapacityStartDate === earliestMatchDate)

  $: startDate, endDate, submitted, earliestMatchDate, (isValid = checkIfValid(isProviderStaff, submitted, earliestMatchDate))
  $: isCapacityStartDateAfterDateWithLeadTime = dayjs(capacity?.startDate).diff(today, 'day') > leadTimeDays

  $: minStartDateWithLeadTimeOrCapacityStartDate = isCapacityStartDateAfterDateWithLeadTime
    ? dateService.dateformat(capacity?.startDate)
    : dateService.dateformat(dateWithLeadTime)

  $: isStartDateBeforeLeadTimeOrCapacityStartDate = derivedStartDate.isBefore(dateWithLeadTime) || derivedStartDate.isBefore(capacity?.startDate)
  $: isTodayAfterDateWithLeadTime = dayjs(today).isAfter(minStartDateWithLeadTimeOrCapacityStartDate)
  $: showAlert =
    !isProviderStaff &&
    (derivedStartDate.isBefore(dateWithLeadTime) || derivedStartDate.isBefore(capacity.startDate)) &&
    (!matchDays.length || !isEarliestMatchDateValid) &&
    isCalendarChanged

  $: showDateSuggestion = !isTodayAfterDateWithLeadTime && !dayjs(minStartDateWithLeadTimeOrCapacityStartDate).isSame(today)

  function checkIfValid(isProviderStaff, submitted, earliestMatchDate) {
    if (!submitted) return areDatesFormattedCorrectly
    if (isProviderStaff) return areProviderDatesValid()
    return areSchoolOrStudentDatesValid(earliestMatchDate)
  }

  function areProviderDatesValid() {
    if (isCreating)
      return capacity.startDate != null && validator.inFuture(capacity.startDate)
        ? capacity.startDate <= startDate
        : dayjs(today).isSameOrBefore(startDate)
    return true
  }

  function areSchoolOrStudentDatesValid(earliestMatchDate) {
    if (!isCalendarChanged) return true
    if (isCreating) {
      if (canUserScheduleMatchDays) {
        if (
          (matchDays.length > 0 && dayjs(earliestMatchDate).isAfter(minStartDateWithLeadTimeOrCapacityStartDate)) ||
          (!!earliestMatchDate && !dateService.datechanged(earliestMatchDate, minStartDateWithLeadTimeOrCapacityStartDate))
        )
          return true
        if (isCapacityStartDateAfterDateWithLeadTime ? derivedStartDate >= dayjs(capacity.startDate) : derivedStartDate >= dateWithLeadTime)
          return true
      } else {
        if (
          capacity.startDate != null && validator.inFuture(capacity.startDate)
            ? dayjs(startDate).isSameOrAfter(capacity.startDate)
            : dayjs(startDate).isSameOrAfter(dateWithLeadTime)
        ) {
          return true
        }
      }
    }
    if (isExisting) {
      if (matchDays.length && isEarliestMatchDateValid) return true
      if (isCapacityStartDateAfterDateWithLeadTime ? startDate >= capacity.startDate : startDate >= dateWithLeadTime) return true
    }
    return false
  }

  function getDateWithLeadTime(leadTimeDays) {
    return isCreating || match.status === MatchStatus.Unsubmitted
      ? addLeadTimeToDate(today, leadTimeDays)
      : addLeadTimeToDate(match.dateSubmitted, leadTimeDays)
  }

  function getEarliestMatchDate(matchDays) {
    if (!matchDays?.length) return null
    return matchDays.reduce((earliest, matchDay) =>
      dateService.dateformat(matchDay.date, 'MM/DD/YYYY') < (dateService.dateformat(earliest?.date, 'MM/DD/YYYY') || Infinity) ? matchDay : earliest
    )
  }

  function getCalendarMinStartDate(
    isProviderStaff,
    isCapacityStartDateAfterDateWithLeadTime,
    dateWithLeadTime,
    leadTimeDays,
    canUserScheduleMatchDays
  ) {
    if (isCreating || match.status === MatchStatus.Unsubmitted) {
      if (isProviderStaff) return capacity.startDate != null && validator.inFuture(capacity.startDate) ? capacity.startDate : today
      if (isCapacityStartDateAfterDateWithLeadTime) return dayjs(capacity?.startDate)
      return dateWithLeadTime
    }
    if (isExisting) {
      if (isProviderStaff) return null
      if (canUserScheduleMatchDays) return today
      if (dayjs(initialStartDate).isBefore(capacity.startDate) || dayjs(initialStartDate).isBefore(dateWithLeadTime)) return initialStartDate
      const potentialMinDate = isCapacityStartDateAfterDateWithLeadTime
        ? dayjs(capacity?.startDate)
        : dayjs(match.dateSubmitted).add(leadTimeDays, 'day')
      return potentialMinDate
    }
  }

  function getMaxEndDate(isProviderStaff) {
    if (capacity?.endDate == null) return null
    if (isCreating) return dayjs(capacity?.endDate)
    else if (isExisting) {
      if (isProviderStaff) return null
      if (initialEndDate > capacity?.endDate) return initialEndDate
      return dayjs(capacity?.endDate)
    }
    return null
  }

  function addLeadTimeToDate(baseDate, leadTimeDays) {
    return dayjs(baseDate).clone().add(leadTimeDays, 'day').format('MM/D/YYYY')
  }
</script>
