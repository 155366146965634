import { Api, ApiCache } from 'services/apigenerated.js'
import { get as getCookie } from 'services/cookie-util.js'
import { handleRequestError } from './handle-request-error.js'
import { isErrorBenign } from 'services/errors.js'
import environment from './environment.js'
import globalLoading from 'stores/loading.js'
import initial from 'stores/initial.js'
import personaFiltersStore from 'stores/persona-filters.js'
import personaStore from 'stores/persona.js'

const miniProfilerHeader = 'x-miniprofiler-ids'

let $persona
personaStore.subscribe(p => {
  $persona = p
  ApiCache.resetPersonaSensitiveCaches()
})

let $personaFilters
personaFiltersStore.subscribe(pf => ($personaFilters = pf))

let $initial = {}
initial.subscribe(v => ($initial = v))

function fetcher(url, options) {
  const controller = new AbortController()
  options.signal = controller.signal
  const fetchPromise = fetcherPromise(url, options)
  // kinda hacky to put the method on an instance of Promise, but it works just fine
  fetchPromise.abort = () => controller.abort()
  return fetchPromise
}

async function fetcherPromise(url, options) {
  const prefetchKey = url.replace(environment.linkApi, '').replace('/api/', '')
  // check if the data has been prefetched (profile, connections, etc can be set to window by mvc when api calls are anticipated by server)
  if (options.method.toLowerCase() === 'get' && $initial.prefetch?.[prefetchKey]) {
    // if so, return a completed promise instead of ajax call
    // then clear it from $initial.prefetch so it doesn't get stale data on subsequent calls
    const response = $initial.prefetch[prefetchKey]
    delete $initial.prefetch[prefetchKey]
    $initial.prefetch[prefetchKey] = null
    return new Promise(resolve => resolve(response))
  }
  options.headers = api._prepareHeaders(options.headers)
  let monitor = true
  if (options.hasOwnProperty('monitor')) {
    monitor = options.monitor
    delete options.monitor
  }
  if (monitor) api._incrementActiveRequests()
  let canHandleRequestError = () => false
  if (options.hasOwnProperty('canHandleRequestError')) {
    canHandleRequestError = options.canHandleRequestError
    delete options.canHandleRequestError
  }
  const response = await fetch(url, options)
  if (monitor) api._decrementActiveRequests()
  if (window.MiniProfiler != null) processMiniProfiler(response.headers.get(miniProfilerHeader))
  const contentType = response.headers.get('content-type')
  const isBinary = options.isBinary || contentType === 'application/octet-stream'
  const responseContentPromise = isBinary ? null : contentType?.includes('application/json') ? response.json() : response.text()
  let responseContent = null
  let responseContentParseError = null
  let wasCaught = false
  try {
    // if we get a failure status, the body _itself_ should contain the error message, so read the body content regardless of response status
    responseContent = await responseContentPromise
  } catch (e) {
    wasCaught = true
    responseContent = response.ok ? 'Failed to read response body.' : 'Failed to read the error response.'
    responseContentParseError = e
  }
  if (response.ok && !wasCaught) return isBinary ? response : responseContent // binary responses need to have access to headers too
  throwError(responseContentParseError, options, url, responseContent, response, canHandleRequestError)
}

// instantiate singleton of generated api
// wrap all responses in checks for ok / deal with our activerequest count etc
const api = new Api(environment.linkApi, fetcher)

// number of outstanding requests - this is incremented when a request starts and decremented when it finishes
// used to determine if we should show/hide loading
api._activeRequests = 0

function throwError(responseContentParseError, options, url, responseContent, response, canHandleRequestError) {
  // Check if benign _before_ setting `response` on the error.
  // We don't log an azure insights error for 400 errors, but we _do_ want to display them to the user.
  // And similarly, we _do_ want to reject the fetch promise for benign errors so the calling code can `.catch` and not have an un-resolved promise.
  // Note that client side error will almost certainly be due to an aborted request, but still catch and check just in case it's something else we haven't seen before.
  const isBenign = responseContentParseError != null && isErrorBenign(responseContentParseError)
  const err = responseContentParseError ?? new Error(`Error fetching ${options.method ?? 'GET'} ${url}: ${responseContent}`)
  err.response = response
  err.content = responseContent
  if (!isBenign && !canHandleRequestError(response, responseContent)) handleRequestError(response.status, responseContent) // don't prompt about benign
  throw err
}

api._prepareHeaders = function (headers) {
  if ($persona != null) {
    // beware firewalls/proxies can remove header fields. If that becomes a problem, we could instead just put into cookie
    if ($persona.personaType != null) headers['personaType'] = $persona.personaType
    if ($personaFilters.orgId != null) headers['orgId'] = $personaFilters.orgId
    if ($personaFilters.teamId != null) headers['teamId'] = $personaFilters.teamId
  }

  // currently this is only used for export jobs, but might be useful to be more precise with webhooks for other things too.
  // note it might not be set before the first api call is sent.
  if (window.cn_signalR_connectionId) headers['signalr-connection-id'] = window.cn_signalR_connectionId

  // we pull the csrf value from a cookie and use it on POST / PUT / DELETE API calls so we can use the [ValidateAntiForgeryToken] attribute on API actions
  headers['RequestVerificationToken'] = getCookie('csrf-token')

  // don't cache api responses...ie11
  headers['pragma'] = 'no-cache'
  headers['cache-control'] = 'no-cache'

  return headers
}

api._incrementActiveRequests = function () {
  this._activeRequests++
  globalLoading.set(true)
}

api._decrementActiveRequests = function () {
  this._activeRequests--
  if (this._activeRequests === 0) {
    globalLoading.set(false)
  }
}

function processMiniProfiler(ids) {
  if (ids?.length) {
    window.MiniProfiler.fetchResults(JSON.parse(ids))
  }
}

export default api
