{#if links}
  {#each links as l (l.url ?? l.slug)}
    <SubNavItem
      href={l.url ? l.url : `/${l.slug}`}
      icon={l.icon}
      title={getLinkTooltip(l.label, l.slug, $orgPendingConfigCounts, $leftNavExpanded, l.hasFeature)}
      badgeColor="info"
      badgeCount={getLinkPendingCount(l.slug, $orgPendingConfigCounts)}
      badgeName="{l.slug}-pending-count"
    >
      {l.label}
      {#if !l.hasFeature}
        <Icon name="subscription" sm />
      {/if}
    </SubNavItem>
  {/each}
{/if}

<script>
  import leftNavExpanded from 'stores/left-nav-expanded.js'
  import orgPendingConfigCounts from 'stores/org-pending-config-counts.js'
  import SubNavItem from 'components/SubNavItem.svelte'
  import Icon from './Icon.svelte'

  export let links

  const slugToCountKey = {
    students: 'pendingStudentsCount',
  }

  function getLinkPendingCount(slug, counts) {
    const countKey = slugToCountKey[slug]
    return countKey == null ? null : counts?.[countKey]
  }

  function getLinkTooltip(label, slug, counts, leftNavExpanded) {
    const pendingCount = getLinkPendingCount(slug, counts)
    if (pendingCount) return `${label} (${pendingCount} pending)`
    return leftNavExpanded ? null : label
  }
</script>
