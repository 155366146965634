import api from './api.js'
import { downloadBinary } from './file-service.js'
import { writable } from 'svelte/store'

let $exportJobsById = {}
const exportJobsByIdStore = writable($exportJobsById)
exportJobsByIdStore.subscribe(v => ($exportJobsById = v))

export async function loadExportJobs() {
  const exportJobs = await api.exportJob.getActiveExportJobsByUserId()
  const byId = exportJobs.reduce((byId, job) => {
    byId[job.exportJobId] = job
    return byId
  }, {})
  exportJobsByIdStore.set(byId)
}

export async function handleExportJobUpdate(exportJob) {
  const exportJobId = exportJob.exportJobId
  $exportJobsById[exportJobId] = { ...$exportJobsById[exportJobId], ...exportJob }
  exportJobsByIdStore.set($exportJobsById)
  if (exportJob.autoDownload) {
    try {
      await downloadExportJob(exportJobId)
    } catch (e) {
      exportJob.error = e.message
    }
  } else if (exportJob.cancelDateTime) {
    removeExportJob(exportJobId) // they clicked the button, so remove immediately.
  }
}

function removeExportJob(exportJobId) {
  delete $exportJobsById[exportJobId]
  exportJobsByIdStore.set($exportJobsById)
}

export async function downloadExportJob(exportJobId) {
  try {
    const res = await api.exportJob.downloadExportedReport({ exportJobId }, api.noMonitor)
    downloadBinary(res)
  } finally {
    removeAfterABit(exportJobId)
  }
}

function removeAfterABit(exportJobId) {
  setTimeout(() => removeExportJob(exportJobId), 500)
}

export default exportJobsByIdStore
