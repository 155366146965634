// add anything that isn't covered by `mathjs` package, window.Math, window.Number

export const isNumeric = val => !isNaN(parseFloat(val))

export function percent(num, total) {
  if (total == null || num == null || total === 0) throw new Error(`calculating percent with invalid parameters: ${num}/${total}`)
  return (num / total) * 100
}

export function tryPercent(value, total) {
  return total > 0 ? percent(value, total) : 0
}
