{#if primaryEmail && !primaryEmail.isConfirmed}
  <div data-test="confirmation-warning" class="alert alert-warning text-center my1">
    Please <a href={null} on:click={openModal}>confirm</a> your email
  </div>
{/if}

<ConfirmUserEmailModal bind:email />

<script>
  import ConfirmUserEmailModal from 'components/ConfirmUserEmailModal.svelte'
  import user from 'stores/user.js'

  $: primaryEmail = $user != null ? $user.emailAddresses.find(e => e.isPrimary) : null

  let email = null
  function openModal() {
    email = primaryEmail.email
  }
</script>
