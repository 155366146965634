<div
  class="datepicker flex-row flex-align-center"
  class:is-range={range}
  bind:this={container}
  class:clearable
  class:has-error={error && showError}
  data-test="{name}-container"
  data-test-viewdate={viewDate.format(format)}
  data-test-value={value}
  data-test-endvalue={endValue}
  on:keydown={keyListener}
>
  <div class="input-group" class:input-group-sm={sm}>
    {#if !showAsDropdown}
      <label for="{name}-label" class="input-group-addon text-left" style={labelStyle}>
        <Icon name="calendar" class={iconClass} style={iconStyle} />
        <slot />
      </label>
    {/if}
    {#if allowNextPrev}
      <a class="input-group-addon" href={null} data-test="prev-range" on:click={() => shiftDates(-1)}>
        <Icon name="chevron-left" />
      </a>
      <a class="input-group-addon" href={null} data-test="next-range" on:click={() => shiftDates(1)}>
        <Icon name="chevron-right" />
      </a>
    {/if}
    {#if showAsDropdown}
      <QuickDropdown
        anyClickCloses
        label={value ? dayjs(value, format).format(format) : ''}
        class="flex-grow-1 text-left text-nowrap text-truncate"
        on:open={() => {
          if (!disabled) open(PickMode.Start)
        }}
      />
    {:else}
      <input
        autocomplete="off"
        type="text"
        class="form-control"
        name
        id={name}
        data-test="{name}{range ? '-start' : ''}"
        bind:value={_value}
        {tabindex}
        {disabled}
        on:click={() => {
          if (!disabled) open(PickMode.Start)
        }}
        on:click
        on:input
        on:focus={e => {
          if (disabled || e.target.hasAttribute(focusTrapInitialFocusAttribute) || !e.relatedTarget) return
          open(PickMode.Start)
        }}
        placeholder={_placeholder}
        bind:this={startField}
      />
    {/if}
    {#if range}
      <label for="{name}-label" class="input-group-addon">
        <span class="flex-row flex-align-center g05"><slot name="rangeEndLabel">to</slot></span>
      </label>
      <input
        autocomplete="off"
        type="text"
        class="form-control"
        name="{name}-end"
        id="{name}-end"
        data-test="{name}-end"
        bind:value={_endValue}
        {tabindex}
        {disabled}
        on:click={() => {
          if (!disabled) open(PickMode.End)
        }}
        on:click
        on:input
        on:focus={() => {
          if (!disabled) open(PickMode.End)
        }}
        placeholder={_endPlaceholder}
        bind:this={endField}
      />
    {/if}
    {#if clearable}
      <span class="input-group-addon clear-btn" on:click={clear} tabindex="-1" use:tip={'Clear'}>
        <Icon name="close" />
      </span>
    {/if}
    {#if showTodayBtn}<a class="input-group-addon" href={null} data-test="today" on:click={goToToday}>Today</a>{/if}
  </div>
  <!-- TODO: DatePicker shouldn't be responsible for its own validation messaging. -->
  {#if error && showError}
    <div class="validation-message">{error}</div>
  {/if}
  {#if pickMode != null && !disabled}
    <div
      bind:this={dropdownContainerElem}
      class="datepicker-dropdown-container"
      class:pick-end={pickMode == PickMode.End}
      class:pop-right={popRight}
      class:pop-center={popCenter}
    >
      <div
        class="datepicker-dropdown flex-row"
        class:chevron-animate={popCenter && pickMode == PickMode.End}
        class:has-preset-ranges={usePresetRanges && range && rangesInBounds.length > 0}
      >
        <div class="datepicker-calendar">
          <div class="zoom-picker">
            <a class="prev" on:click={() => pageView(-1)} href={null}>
              <Icon name="caret-left" />
            </a>
            <a class="zoom-option" data-test="zoom-year-btn" class:active={zoom == Zoom.Year} on:click={() => setZoom(Zoom.Year)} href={null}>
              Year
            </a>
            {#if minZoom <= Zoom.Month}
              <a class="zoom-option" data-test="zoom-month-btn" class:active={zoom == Zoom.Month} on:click={() => setZoom(Zoom.Month)} href={null}>
                Month
              </a>
            {/if}
            {#if minZoom <= Zoom.Day}
              <a class="zoom-option" data-test="zoom-day-btn" class:active={zoom == Zoom.Day} on:click={() => setZoom(Zoom.Day)} href={null}> Day </a>
            {/if}
            <a class="next" on:click={() => pageView(1)} href={null}>
              <Icon name="caret-right" />
            </a>
          </div>
          <table class="table table-sm">
            {#if zoom == Zoom.Day}
              <thead>
                <tr>
                  {#each weekDays as day}
                    <th class="dow">{day}</th>
                  {/each}
                </tr>
              </thead>
              <tbody>
                {#each viewDates as w}
                  <tr>
                    {#each w as d}
                      <td
                        class="day"
                        data-test={d.dataTest}
                        class:active={d.isStart || d.isEnd}
                        class:in-range={d.inRange}
                        class:disabled={d.disabled}
                        class:is-view-date={d.isViewDate}
                        on:mouseover={() => range && showDayCountTooltip && setEndDateTooltip(d)}
                        on:focus={() => range && showDayCountTooltip && setEndDateTooltip(d)}
                        on:click={() => !disabled && select(d.date)}
                        use:tip={range && showDayCountTooltip ? (d.isViewDate ? stickyTooltip : endDateTooltip) : null}
                      >
                        {d.formatted}
                        {#if d.showMonthLabel}<span class="month-label">{d.date.format('MMM')}</span>{/if}
                        <TodayIcon show={d.today} />
                      </td>
                    {/each}
                  </tr>
                {/each}
              </tbody>
            {:else if zoom == Zoom.Month}
              <tbody>
                {#each viewDates as d}
                  <tr>
                    <td
                      data-test={d.dataTest}
                      class:active={d.isStart || d.isEnd}
                      class:in-range={d.inRange}
                      class:disabled={d.disabled}
                      class:is-view-date={d.isViewDate}
                      on:click={() => !disabled && select(d.date)}
                    >
                      {d.formatted}
                    </td>
                  </tr>
                {/each}
              </tbody>
            {:else if zoom == Zoom.Year}
              <tbody>
                {#each viewDates as group}
                  <tr>
                    {#each group as d}
                      <td
                        data-test={d.dataTest}
                        class:active={d.isStart || d.isEnd}
                        class:in-range={d.inRange}
                        class:disabled={d.disabled}
                        class:is-view-date={d.isViewDate}
                        on:click={() => !disabled && select(d.date)}
                      >
                        {d.formatted}
                      </td>
                    {/each}
                  </tr>
                {/each}
              </tbody>
            {/if}
          </table>
        </div>

        {#if usePresetRanges && range && rangesInBounds.length > 0}
          <div class="list-group range-options">
            {#each rangesInBounds as r}
              <a
                href={null}
                data-test="range-{r.name}"
                class="list-group-item"
                class:active={selectedPresetRange === r.key}
                tabindex="-1"
                on:click={() => selectPresetRange(r, r.key)}
              >
                {r.key}
              </a>
            {/each}
          </div>
        {/if}
      </div>
    </div>
  {/if}
</div>

<script>
  import TodayIcon from 'components/TodayIcon.svelte'
  import { createEventDispatcher, getContext } from 'svelte'
  import Key from 'config/key.js'
  import Icon from 'components/Icon.svelte'
  import QuickDropdown from 'components/QuickDropdown.svelte'
  import { focusTrapInitialFocusAttribute } from 'decorators/focus-trap/index'
  import tip from 'decorators/tip'

  export let name = 'date'
  export let format = 'M/D/YYYY'
  // for value and endValue, you can pass a formatted date string, or a dayjs object
  // this component will convert them to either a formatted date string or null
  export let value = null
  export let endValue = null

  // min and max can be passed as either formatted date string or dayjs object
  // this component will convert them to dayjs objects internally and use them as such
  export let min = null
  export let max = null

  // whether to let users pick 2 dates as a range, or just a single date
  export let range = false
  export let clearable = false
  export let disabled = false

  export let allowNextPrev = false
  export let showTodayBtn = false
  export let usePresetRanges = false
  export let ranges = {
    'Last 7 days': ['last-7-days', dayjs().subtract(6, 'days'), dayjs()],
    'Last 14 days': ['last-14-days', dayjs().subtract(13, 'days'), dayjs()],
    'Last 30 days': ['last-30-days', dayjs().subtract(29, 'days'), dayjs()],
    'Last week': ['last-week', dayjs().startOf('week').subtract(7, 'days'), dayjs().subtract(7, 'days').endOf('week')],
    'This week': ['this-week', dayjs().startOf('week'), dayjs().endOf('week')],
    'Next week': ['next-week', dayjs().add(7, 'd').startOf('week'), dayjs().add(7, 'd').endOf('week')],
    'Last month': ['last-month', dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'This month': ['this-month', dayjs().startOf('month'), dayjs().endOf('month')],
    'Next month': ['next-month', dayjs().add(1, 'month').startOf('month'), dayjs().add(1, 'month').endOf('month')],
    'Last quarter': ['last-quarter', dayjs().subtract(1, 'quarter').startOf('quarter'), dayjs().subtract(1, 'quarter').endOf('quarter')],
    'This quarter': ['this-quarter', dayjs().startOf('quarter'), dayjs().endOf('quarter')],
    'Next quarter': ['next-quarter', dayjs().add(1, 'quarter').startOf('quarter'), dayjs().add(1, 'quarter').endOf('quarter')],
    'Last year': ['last-year', dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')],
    'This year': ['this-year', dayjs().startOf('year'), dayjs().endOf('year')],
    'Next year': ['next-year', dayjs().add(1, 'year').startOf('year'), dayjs().add(1, 'year').endOf('year')],
  }
  export let tabindex = 0
  export let placeholder = null
  export let endPlaceholder = null
  export let autoSetOther = true
  export let autofocus = false
  export let sm = false
  export let popRight = false
  export let autoOpenEndDatePicker = false
  export let popCenter = false
  export let showDayCountTooltip = false

  // Pass this down as true to set the value even if it's not in bounds, and validate in parent component.
  // With this false if the value is outside of the range it will be set to null.
  export let setValueIfNotInBounds = false
  // Only toggled by <DynamicFormField> at the moment; should make it so <DatePicker>
  // never handles its own error messaging so it's more consistent with other controls.
  export let showError = true
  export let iconClass = null
  export let iconStyle = null
  export let showAsDropdown = false
  export let labelStyle = null

  const markDirty = getContext('markDirty')
  const fieldValidationMessage = getContext('fieldValidationMessage')
  const addFormGroupValidation = getContext('addFormGroupValidation')
  const yearGroupSize = 40
  const dispatch = createEventDispatcher()
  const weekDays = [0, 1, 2, 3, 4, 5, 6].map(d => dayjs().weekday(d).format('dd'))
  const Zoom = {
    Day: 0,
    Month: 1,
    Year: 2,
  }
  const PickMode = {
    Start: 1,
    End: 2,
  }

  let dropdownContainerElem
  let selectedPresetRange = null
  let container = null
  let startField = null
  let endField = null
  let viewDate = null
  let pickMode = null
  let viewDates = null
  let zoom = null
  let minZoom = null
  let error = null
  let _value = value instanceof dayjs ? value.format(format) : value
  let _endValue = endValue instanceof dayjs ? endValue.format(format) : endValue

  $: if (autofocus && startField) startField.focus()

  $: _placeholder = placeholder ?? ''
  $: _endPlaceholder = endPlaceholder ?? ''
  $: format, (minZoom = computeMinZoom())
  $: _min = min != null ? dayjs(min, format) : null
  $: _max = max != null ? dayjs(max, format) : null
  $: rangesInBounds =
    ranges != null
      ? Object.keys(ranges)
          .map(key => ({
            key,
            name: ranges[key][0],
            start: ranges[key][1],
            end: ranges[key][2],
          }))
          .filter(r => fitsBounds(r.start) || fitsBounds(r.end))
      : []

  $: value, range, format, setIfValid(false, false)
  $: _value, range, format, setIfValid(true, false)
  $: endValue, range, format, setIfValid(false, true)
  $: _endValue, range, format, setIfValid(true, true)

  $: dropdownContainerElem?.scrollIntoView({ behavior: 'smooth' })

  // when _either_ start or end changes, fire a single event
  let start
  let end
  $: if (start !== value || end !== endValue) {
    start = value
    end = endValue
    dispatch('changed')
  }

  let previousHoveredDay
  let endDateTooltip = null
  let stickyTooltip = null
  function setEndDateTooltip(currentDay) {
    if (pickMode == PickMode.Start) {
      stickyTooltip = null
      endDateTooltip = null
      return
    }
    if (currentDay.date.format(format) === value) {
      stickyTooltip = { content: '1 day', options: { placement: 'right', show: true } }
      endDateTooltip = null
      return
    }
    if (!previousHoveredDay) {
      const startDate = dayjs(value, format)
      const daysDifference = currentDay.date.diff(startDate, 'd') + 1
      const placement = daysDifference > 2 ? 'top' : 'right'
      endDateTooltip = { content: daysDifference + ' days', options: { placement } }
      previousHoveredDay = currentDay
      stickyTooltip = null
      return
    }

    let tooltip = null

    const daysDiff = currentDay.date.diff(dayjs(value, format), 'd') + 1
    if (daysDiff > 0) {
      const hoveredDaysDiff = currentDay.date.diff(previousHoveredDay.date, 'd')
      let placement = 'top'
      const content = daysDiff == 1 ? daysDiff + ' day' : daysDiff + ' days'
      if (hoveredDaysDiff == 1) placement = 'left'
      else if (hoveredDaysDiff == -1) placement = 'right'
      else if (hoveredDaysDiff > 1) placement = 'top'
      else if (hoveredDaysDiff < 1) placement = 'bottom'
      tooltip = { content, options: { placement } }
    }

    previousHoveredDay = currentDay
    endDateTooltip = tooltip
    stickyTooltip = null
  }

  function setError(err) {
    error = err
    if (fieldValidationMessage != null) {
      $fieldValidationMessage = error
    }
  }

  function setIfValid(internalChanged, endChanged) {
    // initial values should be set to exactly what the calling code passes in. no validating until after rendered to DOM initially
    if (startField == null && !internalChanged) {
      return
    }

    // if changing programmatically from calling code, sync internal values
    if (!internalChanged) {
      _value = cleanValue(value)
      _endValue = cleanValue(endValue)
    } else if (_value != null && markDirty != null) markDirty()

    const valueName = range ? 'Start date' : 'Date'
    const start = internalChanged ? _value : value
    const end = internalChanged ? _endValue : endValue
    const setErrorAndClear = err => {
      setError(err)
      if (endChanged) endValue = null
      else value = null
    }
    if (isInvalid(start) && setValueIfNotInBounds) addFormGroupValidation(`Please enter a valid ${valueName.toLowerCase()} in ${format} format.`)
    else if (isInvalid(start)) setErrorAndClear(`Please enter a valid ${valueName.toLowerCase()} in ${format} format.`)
    else if (range && isInvalid(end) && setValueIfNotInBounds) addFormGroupValidation(`Please enter a valid end date in ${format} format.`)
    else if (range && isInvalid(end)) setErrorAndClear(`Please enter a valid end date in ${format} format.`)
    else if (!fitsBounds(dayjs(start, format)) && !setValueIfNotInBounds) setErrorAndClear(`${valueName} ${mustFitBoundsMsg()}`)
    else if (range && !fitsBounds(dayjs(end, format)) && !setValueIfNotInBounds) setErrorAndClear(`End date ${mustFitBoundsMsg()}`)
    else if (range && start != null && end != null && dayjs(start, format).isAfter(dayjs(end, format)))
      setErrorAndClear('Start date must be before end date')
    else {
      // don't set public values until valid
      setError(null)
      setValue(start)
      setEndValue(end)
      if (setValueIfNotInBounds) addFormGroupValidation(null)
    }
  }

  $: {
    // make sure viewDate is always in bounds
    if (viewDate == null) {
      viewDate = fitBounds(value != null ? dayjs(value, format) : endValue != null ? dayjs(endValue, format) : dayjs())
    } else {
      viewDate = fitBounds(viewDate)
    }
  }

  $: viewDate, min, max, zoom, value, endValue, format, (viewDates = computeViewDates())

  function mustFitBoundsMsg() {
    if (_min != null && _max != null) return `must be between ${dayjs(_min, format).format(format)} and ${dayjs(_max, format).format(format)}`
    if (_min != null) return `must be after ${dayjs(_min, format).format(format)}`
    if (_max != null) return `must be before ${dayjs(_max, format).format(format)}`
    return null
  }

  function computeViewDates() {
    switch (zoom) {
      case Zoom.Day:
        return computeViewWeeks()
      case Zoom.Month:
        return computeViewMonths()
      case Zoom.Year:
        return computeViewYears()
    }
  }

  function computeViewWeeks() {
    const start = viewDate.clone().startOf('month').startOf('week')

    const end = viewDate.clone().endOf('month').endOf('week')

    const diff = end.diff(start, 'week')
    if (diff == 4) end.add(1, 'week')

    const weeks = []
    const days = []
    const dValue = dayjs(value, format)
    const dEndValue = dayjs(endValue, format)
    const today = dayjs()
    let d = start.clone().startOf('day')
    while (d.diff(end) < 0) {
      days.push({
        date: d.clone(),
        dataTest: d.format('M/D/YYYY'),
        formatted: d.format('D'),
        showMonthLabel: d.isSame(start) || d.get('date') == 1,
        isStart: dValue != null && isSame(dValue, d),
        isEnd: range && dEndValue != null && isSame(dEndValue, d),
        inRange: range && dValue != null && dEndValue != null && isBetweenInclusive(d, dValue, dEndValue),
        isViewDate: isSame(viewDate, d),
        today: isSame(d, today),
        disabled: !fitsBounds(d),
      })
      d = d.add(1, 'day')
    }

    for (let i = 0, len = days.length; i < len; i += 7) {
      weeks.push(days.slice(i, i + 7))
    }

    return weeks
  }

  function computeViewMonths() {
    const start = viewDate.clone().startOf('year')
    const months = []
    const dValue = dayjs(value, format)
    const dEndValue = dayjs(endValue, format)
    const today = dayjs()
    for (let i = 0; i < 12; i++) {
      const d = start.clone().add(i, 'month')
      months.push({
        date: d.clone(),
        dataTest: d.format('MMMM YYYY'),
        formatted: d.format('MMMM YYYY'),
        isStart: isSame(dValue, d, format),
        isEnd: isSame(dEndValue, d, format),
        inRange: range && dValue != null && dEndValue != null && isBetweenInclusive(d, dValue, dEndValue),
        isViewDate: isSame(viewDate, d, 'M/YYYY'),
        today: isSame(today, d, 'M/YYYY'),
        disabled: !fitsBounds(d),
      })
    }
    return months
  }

  function computeViewYears() {
    const startYear = Math.floor(viewDate.get('year') / yearGroupSize) * yearGroupSize
    const start = viewDate.clone().set('year', startYear)
    const end = start.clone().add(yearGroupSize, 'year')
    const dValue = dayjs(value, format)
    const dEndValue = dayjs(endValue, format)
    const numYears = end.get('year') - start.get('year')
    const years = []
    const groups = []
    const today = dayjs()
    for (let i = 0; i < numYears; i++) {
      const d = start.add(i, 'year')
      years.push({
        date: d.clone(),
        dataTest: d.format('YYYY'),
        formatted: d.format('YYYY'),
        isStart: isSame(dValue, d, format),
        isEnd: isSame(dEndValue, d, format),
        inRange: range && dValue != null && dEndValue != null && isBetweenInclusive(d, dValue, dEndValue),
        isViewDate: isSame(viewDate, d, format),
        today: isSame(today, d, format),
        disabled: !fitsBounds(d),
      })
    }

    // return in groups of 5
    for (let i = 0, len = years.length; i < len; i += 5) {
      groups.push(years.slice(i, i + 5))
    }

    return groups
  }

  function fitBounds(date) {
    if (_min != null && date.isBefore(_min, 'd')) date = _min.clone()
    else if (_max != null && date.isAfter(_max, 'd')) date = _max.clone()
    return date
  }

  function fitsBounds(date) {
    if (_min != null && date.isBefore(_min, 'd')) return false
    else if (_max != null && date.isAfter(_max, 'd')) return false
    return true
  }

  function isSame(d1, d2, format = 'M/D/YYYY') {
    return d1 != null && d2 != null && d1.format(format) == d2.format(format)
  }

  function isOpen() {
    return pickMode != null && !disabled
  }

  function open(mode) {
    const isOpenAlready = isOpen() // tests fail if we skip this function when already open. didn't look close as to why--just needed to not set viewDate if already open (and added test to assert why in DatePicker.spec.js)
    zoom = computeStartZoom()
    pickMode = mode
    if (!isOpenAlready) setViewDate()
    document.addEventListener('mousedown', clickListener)
    document.addEventListener('touchstart', clickListener)
    dispatch('open')
  }

  function setViewDate() {
    if (pickMode == PickMode.Start) viewDate = value != null ? dayjs(value, format) : dayjs()
    else viewDate = endValue != null ? dayjs(endValue, format) : value != null ? dayjs(value, format) : dayjs()
    viewDate = fitBounds(viewDate)
  }

  function close() {
    viewDate = null // so when reopen sets to today
    pickMode = null
    document.removeEventListener('mousedown', clickListener)
    document.removeEventListener('touchstart', clickListener)
  }

  function setValue(v) {
    value = cleanValue(v)
    _value = value
    setViewDate()
    dispatch('input')
  }

  function setEndValue(v) {
    endValue = cleanValue(v)
    _endValue = endValue
    setViewDate()
    dispatch('input')
  }

  function cleanValue(v) {
    return v instanceof dayjs ? v.format(format) : isEmpty(v) ? null : dayjs(v, format).isValid() ? dayjs(v, format).format(format) : null
  }

  function autoSetOtherIfDesired() {
    if (!range || !autoSetOther) return

    // autoset endValue if we just set start value
    if (value != null && endValue == null) setEndValue(value)

    // autoset value if we just set end value
    if (endValue != null && value == null) setValue(endValue)
  }

  function selectPresetRange(r, key) {
    selectedPresetRange = key
    setValue(r.start)
    setEndValue(r.end)
    close()
  }

  function isBetweenInclusive(date, start, end) {
    return date.isSame(start, 'd') || date.isSame(end, 'd') || date.isBetween(start, end) || date.isBetween(end, start)
  }

  function select(date) {
    const v = date.clone()

    // if single, just set it
    if (!range) {
      // if we aren't at the lowest zoom mode, zoom down one, otherwise close
      if (zoom > minZoom) {
        viewDate = v
        zoom--
      } else {
        setValue(v)
        close()
      }

      return
    }

    // if pickMode start, set it, then focus end field
    if (pickMode == PickMode.Start) {
      // if we aren't at the lowest zoom mode, zoom down one, otherwise set value and focus end field
      if (zoom > minZoom) {
        viewDate = v
        zoom--
      } else {
        // if we're setting start to something after endValue, set them both
        if (endValue != null && dayjs(v, format).isAfter(dayjs(endValue, format))) setEndValue(v)

        setValue(v)

        // focus end field if present
        if (endField) endField.focus()

        // if we're auto setting not autosetting other, close the date picker (end date might be optional, so they might not want/need to pick it.)
        if (!autoSetOther) close()

        if (range && autoOpenEndDatePicker) {
          open(PickMode.End)
          if (showDayCountTooltip) stickyTooltip = { content: '1 day', options: { placement: 'right', show: true } }
        }
      }
    } else {
      // if we aren't at the lowest zoom mode, zoom down one, otherwise close
      if (zoom > minZoom) {
        viewDate = v
        zoom--
      } else {
        // if we're setting endValue to something before value, set them both
        if (value != null && dayjs(v, format).isBefore(dayjs(value, format))) setValue(v)

        setEndValue(v)
        close()
      }
    }

    autoSetOtherIfDesired()
  }

  function clear() {
    setValue(null)
    setEndValue(null)
    startField?.focus?.()
  }

  function shiftViewDate(delta, part) {
    viewDate = viewDate.add(delta, part)
  }

  function keyListener(e) {
    if (!isOpen()) return

    const key = e.which || e.keyCode

    // if tab key, close and let them out
    if (key === Key.Tab) {
      close()
      return
    }

    switch (key) {
      case Key.Escape:
        close()
        e.preventDefault()
        e.stopImmediatePropagation()
        break
      case Key.Enter:
      case Key.Space:
        select(viewDate)
        e.preventDefault() // so they don't submit parent form
        break
      case Key.Left:
        shiftViewHorizontal(-1)
        break
      case Key.Right:
        shiftViewHorizontal(1)
        break
      case Key.Up:
        shiftViewVertical(-1)
        break
      case Key.Down:
        shiftViewVertical(1)
        break
    }
  }

  function shiftViewHorizontal(n) {
    switch (zoom) {
      case Zoom.Day:
        shiftViewDate(n, 'day')
        break
      case Zoom.Month:
        shiftViewDate(n, 'year')
        break
      case Zoom.Year:
        shiftViewDate(n, 'year')
        break
    }
  }

  function shiftViewVertical(n) {
    switch (zoom) {
      case Zoom.Day:
        shiftViewDate(n, 'week')
        break
      case Zoom.Month:
        shiftViewDate(n, 'month')
        break
      case Zoom.Year:
        shiftViewDate(n * 5, 'year')
        break
    }
  }

  function pageView(n) {
    switch (zoom) {
      case Zoom.Day:
        return shiftViewDate(n, 'month')
      case Zoom.Month:
        return shiftViewDate(n, 'year')
      case Zoom.Year:
        return shiftViewDate(n * yearGroupSize, 'year')
    }
  }

  // close if they click outside the datepicker
  function clickListener(e) {
    if (e.target.closest == null || e.target.closest('.datepicker') !== container) close()
  }

  function computeStartZoom() {
    if (format == null || format.indexOf('D') > -1) return Zoom.Day
    else if (format.indexOf('M') > -1) return Zoom.Month
    return Zoom.Year
  }

  function computeMinZoom() {
    if (format == null || format.indexOf('D') > -1) return Zoom.Day
    else if (format.indexOf('M') > -1) return Zoom.Month
    return Zoom.Year
  }

  // function zoomOut() {
  //   if (zoom < Zoom.Year) zoom++
  // }

  function setZoom(z) {
    zoom = z
  }

  function shiftDates(direction) {
    if (!range) {
      // if just a single date, just increment/decrement days
      setValue(dayjs(value).add(direction, 'days').format(format))
    } else {
      //if custom range, infer how far to navigate back/forward from difference between the dates
      //if they clicked "past month," then clicked "next" only show # of days up to today (max date) not full month with empty data at end or null enddate statuses incrementing future days
      let start = value
      let end = endValue

      start = dayjs(start, format)
      end = dayjs(end, format)

      switch (selectedPresetRange) {
        case 'This month':
        case 'Last month':
        case 'Next month':
          start = start.add(direction, 'month').startOf('month')
          end = end.add(direction, 'month').endOf('month')
          break

        case 'This year':
        case 'Last year':
        case 'Next year':
          start = start.add(direction, 'year').startOf('month')
          end = end.add(direction, 'year').endOf('month')
          break

        // all other range labels just figure out diff of start/end in days and shift over
        default:
          const shiftDays = (end.diff(start, 'days') + 1) * direction
          start = start.add(shiftDays, 'days')
          end = end.add(shiftDays, 'days')
          break
      }

      // limit to max date selection
      if (max != null) {
        max = dayjs(max, format)
        if (end.isAfter(max)) {
          if (start.isBefore(max)) end = max
          else return
        }
      }

      setValue(start.format(format))
      setEndValue(end.format(format))
    }
  }

  function isEmpty(val) {
    return val == null || (val.trim && val.trim() === '')
  }

  function isInvalid(date) {
    return !isEmpty(date) && !dayjs(date, format).isValid()
  }

  function goToToday() {
    let newValue = dayjs()
    let newEndValue = dayjs()
    switch (selectedPresetRange) {
      case 'This month':
      case 'Last month':
      case 'Next month':
        newValue = newValue.startOf('month')
        newEndValue = newValue.endOf('month')
        break
      case 'This year':
      case 'Last year':
      case 'Next year':
        newValue = newValue.startOf('year')
        newEndValue = newValue.endOf('year')
        break

      // all other range labels just figure out diff of start/end in days and start from today
      default:
        if (_endValue != null) {
          const days = dayjs(_endValue).diff(start, 'days')
          newEndValue = newValue.add(days, 'days')
        }
        break
    }
    _value = newValue.format(format)
    _endValue = newEndValue.format(format)
  }
</script>

<style lang="scss">
  @import '../../../css/helpers';

  $active: $primary;
  $gray-200: #e9ecef;
  $gray-300: #dee2e6;
  $gray-400: #ced4da;
  $gray-700: #495057;
  $gray-900: #212529;
  $box-shadow-sm: 0 0.125rem 0.25rem 0 rgba($gray-900, 0.2) !default;
  $box-shadow: 0 0.15rem 1.75rem 0 rgba($gray-900, 0.15) !default;
  $box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175) !default;
  $box-shadow: 0 0 1.5rem 0 rgba(68, 94, 167, 0.1);

  $hover: #eee;
  $in-range: #ebf4f8;

  .datepicker {
    position: relative;
    max-width: 500px;

    & input[type='text'] {
      min-width: 100px;
      flex-grow: 1;
    }

    &.clearable {
      .datepicker-dropdown-container.pick-end {
        left: calc(50% - 1.8rem);
      }
      .clear-btn {
        cursor: pointer;

        &:hover {
          color: $primary;
        }
      }
    }

    .datepicker-dropdown-container {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 11;

      &.pick-end {
        left: 300px;
      }

      &.pop-center {
        left: 20%;
        top: 44px;

        .chevron-animate:before {
          left: 26.8rem !important;
          transition: left 1s;
        }
        .chevron-animate:after {
          left: 26.9rem !important;
          transition: left 1s;
        }
        .datepicker-dropdown:before {
          left: 2.8rem;
          top: -0.7rem;
        }
        .datepicker-dropdown:after {
          left: 2.9rem;
          top: -0.6rem;
        }
      }

      &.pop-right {
        left: 100%;
        top: 0;

        .datepicker-dropdown:before {
          top: 0.8rem;
          left: -0.7rem;

          border-left: none;
          border-right: 0.7rem solid rgba(0, 0, 0, 0.2);
          border-bottom: 0.7rem solid transparent;
          border-top: 0.7rem solid transparent;
        }

        .datepicker-dropdown:after {
          left: -0.6rem;
          top: 0.9rem;

          border-left: none;
          border-right: 0.6rem solid #fff;
          border-bottom: 0.6rem solid transparent;
          border-top: 0.6rem solid transparent;
        }
      }

      .datepicker-dropdown {
        box-shadow: $box-shadow-sm, $box-shadow;
        position: absolute;
        top: 0.3rem;
        background: #fff;
        padding: 0.4rem;
        font-size: 1.4rem;

        // display: flex;
        // flex-direction: row;
        // justify-items: stretch;
        align-items: flex-start;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        &:before {
          content: '';
          display: inline-block;
          border-left: 0.7rem solid transparent;
          border-right: 0.7rem solid transparent;
          border-bottom: 0.7rem solid rgba(0, 0, 0, 0.2);
          border-top: 0;
          position: absolute;
          left: 0.8rem;
          top: -0.7rem;
        }

        &:after {
          content: '';
          display: inline-block;
          border-left: 0.6rem solid transparent;
          border-right: 0.6rem solid transparent;
          border-bottom: 0.6rem solid #fff;
          border-top: 0;
          position: absolute;
          left: 0.9rem;
          top: -0.6rem;
        }

        .range-options {
          margin-left: 5px;
          width: 15rem;
          overflow: auto;
          max-height: 100%;
          margin-bottom: 0;

          > .list-group-item {
            margin: 0;
            width: 15rem;
            padding: 5px 10px;
          }
        }

        .datepicker-calendar {
          table {
            margin-bottom: 0;
            width: 31.5rem;

            tr {
              th,
              td {
                text-align: center;
                width: 4.5rem;
                height: 4rem;
                border: none;
                background-color: transparent;
                vertical-align: middle;
              }

              th {
                color: #333; // Respecify so invalid color doesn't bleed through
              }

              td {
                color: $gray-700;
                position: relative;

                &.in-range {
                  background: $in-range;
                }

                &.is-view-date {
                  background: $gray-300;
                }

                &.active,
                &.active:hover {
                  background: $active;
                  color: #fff;
                }

                &.disabled,
                &.disabled:hover {
                  color: $gray-400;
                  background-color: $gray-200;
                  background-image: repeating-linear-gradient(
                    0deg,
                    transparent 0%,
                    transparent 48%,
                    $gray-400 50%,
                    transparent 52%,
                    transparent 100%
                  );
                  cursor: var(--disabled-cursor, not-allowed);
                }

                &:hover {
                  background: $hover;
                  cursor: pointer;
                }

                .month-label {
                  position: absolute;
                  top: 0.1rem;
                  left: 0.2rem;
                  font-size: 0.9rem;
                }
              }
            }
          }

          .zoom-picker {
            display: flex;
            width: 100%;
            margin-bottom: 0.4rem;

            a {
              cursor: pointer;
              padding: 8px 15px;
              text-decoration: none;
              text-align: center;
              &:hover,
              &:active,
              &:focus {
                color: $primary;
              }
            }

            .prev,
            .next {
              width: 50px;
              &:hover {
                background: #eee;
              }
            }

            .zoom-option {
              flex-grow: 1;

              &:hover,
              &.active {
                border-bottom: 2px solid $primary;
              }
            }
          }
        }
      }
    }
  }
</style>
