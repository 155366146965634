<!-- TODO(phase2-nursing-josh): Review verbiage suggestions made by Melissa in DM between her and Josh in Slack, update this component accordingly -->
<div class="flex-row flex-align-center g05{extraClass ? ` ${extraClass}` : ''}">
  {#if value === AutoReleaseUnscheduledMatchesWhen.Never}
    <div class={labelClass}>
      {#if concise}
        Never release rotations
      {:else}
        Never, these rotations are for this school only.
      {/if}
    </div>
    <Help>
      Regardless of how many guaranteed rotations are used by this school, or if they confirm how many rotations they’d like to use in advance, no
      rotations will be released to other schools.
    </Help>
  {:else if value === AutoReleaseUnscheduledMatchesWhen.Confirmed}
    <div class={labelClass}>
      {#if concise}
        Allow manual release
      {:else}
        Release rotations when school confirms how many they’ll use.
      {/if}
    </div>

    <Help>
      Rotations will be released when the school confirms how many rotations they’ll use. If they merely set an expected amount but never
      <em>confirm</em> that amount, their unscheduled rotations won’t be released.
      <br />
      <br />
      {releasedRotationsHelp}
    </Help>
  {:else if value === AutoReleaseUnscheduledMatchesWhen.PastConfirmDesiredMatchCountByDate}
    <div class={labelClass}>
      {#if concise}
        Auto-release after confirmation deadline
      {:else}
        Automatically release unscheduled rotations after the <Icon name="calendar" class="color-text-blue" />
        <strong class="strongish">confirmation deadline</strong>.
      {/if}
    </div>
    <Help>
      Rotations will be released when the school confirms how many rotations they’ll use.
      <br />
      <br />
      Additionally, if they don’t confirm how many they’ll use by the <strong class="strongish">confirmation deadline</strong>, their unscheduled
      rotations will be released. If they’ve set an expected amount but never <em>confirm</em> that amount, the difference between what you’ve
      guaranteed them and what they’ve last set as their expected amount will be released. If they never set an expected amount, all of their
      guaranteed rotations will be released.
      <br />
      <br />
      {releasedRotationsHelp}
    </Help>
  {:else if value === AutoReleaseUnscheduledMatchesWhen.PastSchedulingEndDate}
    <div class={labelClass}>
      {#if concise}
        Auto-release after request end date
      {:else}
        Automatically release unscheduled rotations after the <Icon name="calendar" class="color-text-light-red" />
        <strong class="strongish">request end date</strong>.
      {/if}
    </div>

    <Help>
      Rotations will be released when the school confirms how many rotations they’ll use.
      <br />
      <br />
      Additionally, if they don’t confirm how many they’ll use by the <strong class="strongish">request end date</strong>, their unscheduled rotations
      will be released. If they’ve set an expected amount but never <em>confirm</em> that amount, the difference between what you’ve guaranteed them
      and what they’ve last set as their expected amount will be released. If they never set an expected amount, all of their guaranteed rotations
      will be released.
      <br />
      <br />
      {releasedRotationsHelp}
    </Help>
  {/if}
</div>

<script>
  import { AutoReleaseUnscheduledMatchesWhen } from 'config/enums.js'
  import Help from 'components/Help.svelte'
  import Icon from 'components/Icon.svelte'

  export let value
  export let extraClass
  export let labelClass
  export let concise = false

  const releasedRotationsHelp = `
Released rotations will be available to other schools who have used all guaranteed rotations available to them but who have not yet used
their maximum rotations. For example, if another school was guaranteed 20 rotations with a maximum of 30, confirmed they only want 15, then scheduled 15 rotations,
they could still schedule an additional 15 rotations from those released by other schools.
`
</script>
