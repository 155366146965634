import { auto } from 'browser-unhandled-rejection'

// applies unhandledrejection polyfill if necessary to global Promise (whether it's native promise or not)
auto()

// eslint-disable-next-line no-extra-semi
;(function (ElementProto) {
  if (typeof ElementProto.matches !== 'function') {
    ElementProto.matches =
      ElementProto.msMatchesSelector ||
      ElementProto.mozMatchesSelector ||
      ElementProto.webkitMatchesSelector ||
      function matches(selector) {
        const element = this
        const elements = (element.document || element.ownerDocument).querySelectorAll(selector)
        let index = 0

        while (elements[index] && elements[index] !== element) {
          ++index
        }

        return Boolean(elements[index])
      }
  }

  if (typeof ElementProto.closest !== 'function') {
    ElementProto.closest = function closest(selector) {
      let element = this

      while (element && element.nodeType === 1) {
        if (element.matches(selector)) {
          return element
        }

        element = element.parentNode
      }

      return null
    }
  }
})(window.Element.prototype)

//Edge needs toBlob: https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value: function (callback, type, quality) {
      const canvas = this
      setTimeout(function () {
        const binStr = atob(canvas.toDataURL(type, quality).split(',')[1]),
          len = binStr.length,
          arr = new Uint8Array(len)

        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i)
        }

        callback(new Blob([arr], { type: type || 'image/png' }))
      })
    },
  })
}

//safari needs forEach on NodeList
// https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window
    for (let i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this)
    }
  }
}

// forEach on HTMLCollection
if (window.HTMLCollection && !HTMLCollection.prototype.forEach) {
  HTMLCollection.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window
    for (let i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this)
    }
  }
}

// // assert we have all the polyfills we need via core-js or a manual
// [
//   { name: 'Array.prototype.find', condition: Array.prototype.find == null },
//   { name: 'Element.prototype.matches', condition: Element.prototype.matches == null },
//   { name: 'Element.prototype.closest', condition: Element.prototype.closest == null },
//   { name: 'HTMLCanvasElement.prototype.toBlob', condition: HTMLCanvasElement.prototype.toBlob == null },
//   { name: 'NodeList.prototype.forEach', condition: NodeList.prototype.forEach == null },
//   { name: 'HTMLCollection.prototype.forEach', condition: window.HTMLCollection == null || window.HTMLCollection.prototype.forEach == null },
//   { name: 'Element.prototype.remove', condition: Element.prototype.remove == null },
//   { name: 'Promise', condition: Promise == null },
//   { name: 'Promise.prototype.finally', condition: Promise != null && Promise.prototype.finally == null }
// ].forEach(f => {
//   if (f.condition)
//     console.error(`Missing polyfill for ${f.name}`)
// })
