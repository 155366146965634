{#if show}
  <Icon name="spinner" spin {x2} {x3} {sm} {fw} class={className} />
{/if}

<script>
  import Icon from 'components/Icon.svelte'

  let className = ''
  export { className as class }
  export let loading = true
  export let sm = false
  export let x2 = false
  export let x3 = false
  export let fw = false
  export let noFlicker = false

  let show = loading
  let timeoutId = null

  $: loading, noFlicker, updateShow()

  function updateShow() {
    if (!noFlicker) {
      show = loading
    } else if (loading) {
      show = true
      clearTimeout(timeoutId)
    } else if (timeoutId == null) {
      timeoutId = setTimeout(() => {
        show = false
        timeoutId = null
      }, 200)
    }
  }
</script>
