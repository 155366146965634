<!-- Displays a relative time stamp (eg, "3 seconds ago") and updates client-side as time goes on -->
{#if stamp}<span
    class="from-now{className ? ` ${className}` : ''}"
    class:has-tooltip={showTooltip}
    use:tip={showTooltip ? (useRichTooltip && tooltipElem ? { content: tooltipElem, options: tooltipOptions ?? {} } : formattedDate) : null}
    >{stamp}</span
  >{/if}{#if showTooltip && useRichTooltip}<span class="hide">
    <div bind:this={tooltipElem} class="flex-row flex-align-center g05">
      {#if tooltipIcon || tooltipIconClass}
        <Icon name={tooltipIcon ?? 'calendar'} class={tooltipIconClass} />
      {/if}
      <span>{formattedDate}</span>
    </div>
  </span>
{/if}

<script>
  import { onDestroy } from 'svelte'
  import dateService from 'services/date-service.js'
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'

  export let date = null
  export let format = 'MMMM D, YYYY @ h:mm:ss A'
  export let localize = false
  export let precision = true
  let className = null
  export { className as class }
  export let showTooltip = false
  export let tooltipIcon = null
  export let tooltipIconClass = null
  export let tooltipOptions = null

  let stamp = null
  let interval
  let tooltipElem = null

  $: useRichTooltip = tooltipIcon || tooltipIconClass || tooltipOptions
  $: formattedDate = dateService.dateformat(date, format, localize)

  onDestroy(() => {
    if (interval != null) clearInterval(interval)
  })

  if (precision) {
    // every 44s, recalc fromNow, so "a few seconds ago" turns into "2 minutes ago" and so on
    // 44 because that's the smallest interval of seconds that will change moment's displayed value...
    interval = setInterval(() => updateStamp(), 44000)
  }

  $: if (date) updateStamp()

  function updateStamp() {
    if (date == null) return
    stamp = precision ? dateService.fromnow(date, localize) : dateService.fromnowLessPrecise(date, localize)
  }
</script>
