import { RepeatUnit } from 'config/enums.js'
import dateService from 'services/date-service.js'
import validator from 'services/validator.js'

const oneTo = n => Array.from({ length: n }, (_, i) => i + 1)
const allDaysOfTheWeek = oneTo(7)
const allDaysOfTheMonth = oneTo(31)

export function buildRepeatSchedule(capacity, shift, shiftDay) {
  if (!capacity) return
  const repeatSchedule = {
    startDate: capacity.startDate,
    endDate: capacity.endDate,
    repeatUnit: RepeatUnit.Daily,
    repeatEvery: 1,
    repeatDaysOfWeek: allDaysOfTheWeek,
    repeatDaysOfMonth: allDaysOfTheMonth,
    startTime: shift?.startTime ?? null,
    endTime: shift?.endTime ?? null,
  }

  if (shiftDay != null) {
    repeatSchedule.repeatUnit = shiftDay.repeatUnit
    repeatSchedule.repeatEvery = shiftDay.repeatEvery
    repeatSchedule.repeatDaysOfWeek = shiftDay.repeatDaysOfWeek
    repeatSchedule.repeatDaysOfMonth = shiftDay.repeatDaysOfMonth
    if (shiftDay.date != null || shiftDay.repeatEndDate != null) {
      repeatSchedule.startDate = shiftDay.date
      repeatSchedule.endDate = shiftDay.repeatEndDate
    }
    if (shiftDay.startTime != null || shiftDay.endTime != null) {
      repeatSchedule.startTime = shiftDay.startTime
      repeatSchedule.endTime = shiftDay.endTime
    }
  }

  repeatSchedule.startDate = repeatSchedule.startDate ? dayjs(repeatSchedule.startDate) : null
  repeatSchedule.endDate = repeatSchedule.endDate ? dayjs(repeatSchedule.endDate) : null

  return repeatSchedule
}

export function repeatScheduleOccursOnDate(repeatSchedule, date) {
  if (repeatSchedule == null || date == null) return false
  if (!dayjs.isDayjs(date)) {
    const isString = typeof date === 'string'
    if (!isString) throw new Error(`Invalid repeatScheduleOccursOnDate: ${JSON.stringify(date)}`)
    date = dayjs(date)
  }
  if (repeatSchedule.startDate != null && repeatSchedule.startDate.isAfter(date)) return false
  if (repeatSchedule.endDate != null && repeatSchedule.endDate.isBefore(date)) return false
  switch (repeatSchedule.repeatUnit) {
    case RepeatUnit.Daily:
      const diffDays = date.diff(repeatSchedule.startDate, 'days')
      return diffDays % repeatSchedule.repeatEvery === 0
    case RepeatUnit.Weekly:
      const dayOfWeek = date.day()
      const rightDayOfWeek = repeatSchedule.repeatDaysOfWeek?.some(d => d === dayOfWeek + 1)
      if (!rightDayOfWeek) return false
      const sameDay = repeatSchedule.startDate?.startOf('week').add(dayOfWeek, 'days')
      const diffWeeks = date.diff(sameDay, 'week')
      return diffWeeks % repeatSchedule.repeatEvery === 0
    case RepeatUnit.Monthly:
      const dayOfMonth = date.date()
      const daysInMonth = date.daysInMonth() // 32 means Last day of month
      const rightDayOfMonth =
        repeatSchedule.repeatDaysOfMonth.some(d => d === dayOfMonth) ||
        (dayOfMonth === daysInMonth && repeatSchedule.repeatDaysOfMonth.some(d => d === 32))
      if (!rightDayOfMonth) return false
      const startMonth = repeatSchedule.startDate.startOf('month')
      const thisMonth = date.startOf('month')
      const diffMonths = thisMonth.diff(startMonth, 'month')
      return diffMonths % repeatSchedule.repeatEvery === 0
    default:
      throw new Error(`Invalid RepeatUnit ${repeatSchedule.repeatUnit}`)
  }
}

export function buildShiftRepeatSchedules(capacity, shift) {
  return shift.shiftDays?.length
    ? /**/ shift.shiftDays.map(sd => buildRepeatSchedule(capacity, shift, sd))
    : /**/ [buildRepeatSchedule(capacity, shift, null)]
}

export function shiftOccursOnDate(capacity, shift, date) {
  const repeatSchedules = buildShiftRepeatSchedules(capacity, shift)
  return repeatSchedules.some(rs => repeatScheduleOccursOnDate(rs, date))
}

export function datesToShiftDays(dates, minShiftDayId) {
  if (!(dates?.length > 0)) return []
  return dates.map(d => ({
    date: d,
    repeatUnit: RepeatUnit.Daily,
    repeatEvery: 1,
    repeatDaysOfWeek: [],
    repeatDaysOfMonth: [],
    repeatEndDate: d,
    startTime: null,
    endTime: null,
    shiftDayId: minShiftDayId || -1,
  }))
}

export function removeSpecificDayAndSplitShifts(capacity, shifts, daysToRemoveArray) {
  daysToRemoveArray.sort((a, b) => {
    const dateA = new Date(a)
    const dateB = new Date(b)
    return dateB - dateA
  })

  let currentShifts = shifts

  for (const dayToRemove of daysToRemoveArray) {
    const dayToRemoveDate = dayjs(dayToRemove)
    let shiftDayId = Math.min(0, ...currentShifts.map(shift => shift.shiftDayId)) - 1

    currentShifts = currentShifts.flatMap(shift => {
      const startDate = shift.date ? dayjs(shift.date) : dayjs(capacity.startDate)
      const endDate = shift.repeatEndDate ? dayjs(shift.repeatEndDate) : capacity.endDate ? dayjs(capacity.endDate) : null

      // If the day to be removed does not affect this shift, return it unchanged
      if (dayToRemoveDate.isBefore(startDate, 'day') || (endDate && dayToRemoveDate.isAfter(endDate, 'day'))) {
        return [shift]
      }

      const shiftsToReturn = []

      // If there are days before the day to be removed, create a shift for this interval
      if (dayToRemoveDate.isAfter(startDate, 'day')) {
        shiftsToReturn.push({
          ...shift,
          shiftDayId: shiftDayId--,
          date: startDate.format('M/D/YYYY'),
          repeatEndDate: dayToRemoveDate.subtract(1, 'day').format('M/D/YYYY'),
        })
      }

      // If the shift continues after the day to be removed, create a shift for the rest of the interval
      if (!endDate || dayToRemoveDate.isBefore(endDate, 'day')) {
        shiftsToReturn.push({
          ...shift,
          shiftDayId: shiftDayId--,
          date: dayToRemoveDate.add(1, 'day').format('M/D/YYYY'),
          repeatEndDate: endDate ? endDate.format('M/D/YYYY') : null,
        })
      }

      return shiftsToReturn
    })
  }

  return currentShifts
}

// Resolve intersections between rules
// Assume that later rules can override earlier rules.
//   Concat new after initial so new overrides initial.
//   Assume that rules in the same list have later items override earlier items.
export function mergeShiftDays(shiftDaysInitial, shiftDaysNew) {
  const all = sortShiftDays(shiftDaysInitial.concat(shiftDaysNew))
  if (all.length === 0) return []

  // first get rid of any intersections of schedules, favoring new (or later) items
  let deIntersecting = [all[0]]
  for (let ib = 1; ib < all.length; ib++) {
    const b = all[ib]
    let bAdded = false
    const toDelete = []
    const toAdd = []
    for (let ia = deIntersecting.length - 1; ia >= 0; ia--) {
      const a = deIntersecting[ia]

      const timesMatch =
        (dateService.parseTime(a.startTime) === dateService.parseTime(b.startTime) &&
          dateService.parseTime(a.endTime) === dateService.parseTime(b.endTime)) ||
        (a.startTime == null && a.endTime == null && b.startTime == null && b.endTime == null)

      const repeatEndDateB = b?.repeatEndDate || null
      let repeatEndDateA = a?.repeatEndDate || null

      // if b engulfs a, remove a and add b
      const bEngulfsa =
        (b.date === a.date || dayjs(b.date).isSameOrBefore(a.date)) &&
        (repeatEndDateB == null /*a never ends*/ || repeatEndDateB === repeatEndDateA || dayjs(repeatEndDateB).isSameOrAfter(repeatEndDateA))

      if (bEngulfsa && timesMatch) {
        // can only delete if doesnt need to stick around to overlay with other rules
        const canDeleteOld =
          a.repeatUnit === b.repeatUnit && a.repeatEvery === b.repeatEvery && validator.equals(a.repeatDaysOfWeek, a.repeatDaysOfMonth)
        if (canDeleteOld) toDelete.push(ia)

        if (!bAdded) {
          toAdd.push(b)
          bAdded = true
        }

        // continue, since there may be others on the merge list that this rule intersects with
        continue
      }

      // if a engulfs b, modify a to splice in b
      const aEngulfsB =
        (a.date === b.date || dayjs(a.date).isSameOrBefore(b.date)) &&
        (repeatEndDateA == null /*a never ends*/ || repeatEndDateA === repeatEndDateB || dayjs(repeatEndDateA).isSameOrAfter(repeatEndDateB))
      if (aEngulfsB && timesMatch) {
        toDelete.push(ia)

        // left part of a
        const bDateMinus1Day = dayjs(b.date).subtract(1, 'day')
        if (bDateMinus1Day.isSameOrAfter(dayjs(a.date))) toAdd.push({ ...a, repeatEndDate: bDateMinus1Day.format('M/D/YYYY') })

        // b in between
        if (!bAdded) {
          toAdd.push(b)
          bAdded = true
        }

        // right part of a
        const bEndDateAdd1Day = dayjs(repeatEndDateB).add(1, 'day')
        if (repeatEndDateB != null && (bEndDateAdd1Day.isSameOrBefore(dayjs(repeatEndDateA)) || repeatEndDateA == null)) {
          toAdd.push({ ...a, date: bEndDateAdd1Day.format('M/D/YYYY') })
        }

        break // no more merging needed for this rule since it's engulfed by a rule that's already there, so it won't intersect any others that are on the merged list
      }

      // if b intersects a at end of a
      if (
        (b.date === repeatEndDateA || dayjs(b.date).isSameOrBefore(repeatEndDateA)) &&
        (repeatEndDateB == null || repeatEndDateB == repeatEndDateA || dayjs(repeatEndDateB).isSameOrAfter(repeatEndDateA)) &&
        timesMatch
      ) {
        // modify a end date to get pushed back to 1 day before b starts.
        // but if this would make a end before it starts, delete it
        const bDateMinus1Day = dayjs(b.date).subtract(1, 'day')
        if (bDateMinus1Day.isSameOrAfter(dayjs(a.date))) repeatEndDateA = bDateMinus1Day.format('M/D/YYYY')
        else {
          // can only delete if doesnt need to stick around to overlay with other rules
          const canDeleteOld =
            a.repeatUnit === b.repeatUnit && a.repeatEvery === b.repeatEvery && validator.equals(a.repeatDaysOfWeek, a.repeatDaysOfMonth)
          if (canDeleteOld) toDelete.push(ia)
        }

        if (!bAdded) {
          toAdd.push(b)
          bAdded = true
        }
        continue // no more merging needed for this rule since it only intersects with the end and the merged list is always sorted
      }

      // if b intersects a at start of a
      if (
        (repeatEndDateB === a.date || dayjs(repeatEndDateB).isSameOrAfter(a.date)) &&
        (b.date == a.date || dayjs(b.date).isSameOrBefore(a.date)) &&
        timesMatch
      ) {
        // modify a date to get pushed to 1 day after b ends
        // but if this would make a end before it starts, delete it
        const bEndDateAdd1Day = dayjs(repeatEndDateB).add(1, 'day')
        if (bEndDateAdd1Day.isSameOrBefore(dayjs(repeatEndDateA)) || repeatEndDateA == null) a.date = bEndDateAdd1Day.format('M/D/YYYY')
        else {
          // can only delete if doesnt need to stick around to overlay with other rules
          const canDeleteOld =
            a.repeatUnit === b.repeatUnit && a.repeatEvery === b.repeatEvery && validator.equals(a.repeatDaysOfWeek, a.repeatDaysOfMonth)
          if (canDeleteOld) toDelete.push(ia)
        }

        if (!bAdded) {
          toAdd.push(b)
          bAdded = true
        }
        continue // may be others in the merge list that intersect with this rule earlier on, so keep going backward
      }
    }

    // no intersection was found, simply add
    if (!bAdded) {
      toAdd.push(b)
    }

    deIntersecting = deIntersecting
      .filter((_, mi) => !toDelete.includes(mi))
      .concat(toAdd)
      .sort((a, b) => dayjs(a.date).diff(dayjs(b.date)))
  }

  // now try to combine where possible the now-not-intersecting and sorted rules
  for (let j = deIntersecting.length - 1; j >= 1; j--) {
    const a = deIntersecting[j - 1]
    const b = deIntersecting[j]

    const timesMatch =
      (dateService.parseTime(a.startTime) == dateService.parseTime(b.startTime) &&
        dateService.parseTime(a.endTime) == dateService.parseTime(b.endTime)) ||
      (a.startTime == null && a.endTime == null && b.startTime == null && b.endTime == null)

    // combine into one rule, if possible
    // note we only handle if their repeat units are the same. could maybe combine them if they're different, but would be tricky and probably not a big deal.
    if (a.repeatUnit === b.repeatUnit && a.repeatEvery === b.repeatEvery && timesMatch) {
      // if a ends one day before b, combine them into 1 rule and drop a
      const aEnds1DayBeforeBStarts = dayjs(a.repeatEndDate).add(1, 'day').isSame(b.date)
      if (aEnds1DayBeforeBStarts) {
        a.repeatEndDate = b.repeatEndDate
        // distinct combined days of week and month
        a.repeatDaysOfWeek = _.uniq((a?.repeatDaysOfWeek || []).concat(b?.repeatDaysOfWeek || [])).sort()
        a.repeatDaysOfMonth = _.uniq((a?.repeatDaysOfMonth || []).concat(b?.repeatDaysOfMonth || [])).sort()

        deIntersecting.splice(j, 1)
        continue
      }

      if (a.date === b.date && a.repeatEndDate == b.repeatEndDate) {
        // distinct combined days of week and month
        a.repeatDaysOfWeek = _.uniq((a?.repeatDaysOfWeek || []).concat(b?.repeatDaysOfWeek || [])).sort()
        a.repeatDaysOfMonth = _.uniq((a?.repeatDaysOfMonth || []).concat(b?.repeatDaysOfMonth || [])).sort()
        deIntersecting.splice(j, 1)
        continue
      }
    }
  }

  return deIntersecting
}

function sortShiftDays(shiftDays) {
  return shiftDays.sort((a, b) => {
    const aTimeNull = a.startTime == null && a.endTime == null
    const bTimeNull = b.startTime == null && b.endTime == null
    if (aTimeNull && !bTimeNull) return -1
    return 1
  })
}
