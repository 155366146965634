<h3 class="mt1 mb0">
  <Icon name={icon} class={iconClass} />
  <Badge count={staff} min={0} />
  {header}
</h3>

{#if canDeleteOrAdd(null)}
  <AddMatchUser role={matchRole} {match} bind:matchUsers={match.matchUsers} />
{/if}

{#if staff.length}
  <FormGroup class="flex-column g1 m0">
    {#each staff as s}
      <UserCard
        user={s}
        school={isSchoolStaff ? match.school : null}
        showEmail
        showPhone
        roles={s.roleNames}
        matchStatus={s.status}
        showOnboardingWarning={s.status < MatchStatus.Ready && match.status >= MatchStatus.Active}
        class="m0"
        onDelete={canDeleteOrAdd(s) ? deleteMatchUser(matchRole) : null}
        deleteMessage="Remove {userNameFriendly(s)} from the rotation as {facultyOrPreceptor}. {removeFacultyExtra(s, matchRole)}"
        deleteMessageConfirm={{
          title: `Remove ${isSchoolStaff ? 'faculty' : 'staff'}`,
          message: `Are you sure you’d like to remove <strong>${userNameFriendly(s)}</strong> from the rotation? ${removeFacultyExtra(s, matchRole)}`,
          confirmLabel: 'Remove',
          confirmClass: 'btn-danger',
        }}
      />
    {/each}
  </FormGroup>
{/if}

<script>
  import { MatchStatus, MatchRole } from 'config/enums.js'
  import AddMatchUser from 'components/AddMatchUser.svelte'
  import Badge from 'components/Badge.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Icon from 'components/Icon.svelte'
  import UserCard from 'components/UserCard.svelte'

  export let match
  export let staff = []
  export let removeFacultyExtra
  export let deleteMatchUser
  export let userNameFriendly
  export let isSchoolStaff = false

  const icon = isSchoolStaff ? 'school' : 'hospital'
  const iconClass = isSchoolStaff ? 'color-text-blue' : 'color-text-orange'
  const header = isSchoolStaff ? 'School staff' : 'Clinic staff'

  $: matchRole = isSchoolStaff ? MatchRole.SchoolFaculty : MatchRole.Preceptor
  $: facultyOrPreceptor = isSchoolStaff ? 'faculty' : 'preceptor'

  function canDeleteOrAdd(s) {
    let canDelete = false
    if (match.isEditable && (match.isCoordinator || match.isSchoolCoordinator)) {
      // allow coordinators to change matches even after they're completed for reporting purposes
      canDelete = s == null || (isSchoolStaff ? s.matchRole === matchRole : s.roleValues?.includes(matchRole))
    }
    return canDelete
  }
</script>
