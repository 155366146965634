import { CovidStatus, FeatureType } from 'config/enums.js'
import colorService from 'services/color-service.js'
import filter from 'services/filter.js'
import validator from 'services/validator.js'

export function getOrgFeatureColor(featureType) {
  switch (featureType) {
    case FeatureType.Consortium:
      return 'purple'
    case FeatureType.HealthInstitution:
      return 'info'
    case FeatureType.TeachingInstitution:
      return 'danger'
    default:
      return 'warning'
  }
}

export function getRoots(flatOrgs) {
  if (flatOrgs == null) return []
  //get orgs whose parent org is not in the list
  //todo:should probably just always show orgs and never provide a partial list of orgs that can result in a partial org hierarchy
  //     better to just show grayed out / disabled accordingly rather than wonky list where a grandchild is shown at same level as
  //     grandparent, if the in between parent isn't available
  return flatOrgs.filter(o => !flatOrgs.some(oo => oo.orgId === o.parentOrgId))
}

function getOrgTree(flatOrgs) {
  if (flatOrgs == null || flatOrgs.length === 0) return null
  const recursivelySetChildren = org => {
    org.children = flatOrgs.filter(o => o.parentOrgId === org.orgId).map(recursivelySetChildren)
    org.childCount = org.children.length
    return org
  }
  return getRoots(flatOrgs).map(recursivelySetChildren)
}

export function getFlatChildren(org, orgsFlat, includeSelf) {
  if (org == null) return []
  const recursivelyGetChildren = org => {
    const directChildren = orgsFlat.filter(o => o.parentOrgId === org.orgId)
    return [directChildren, ...directChildren.map(o => recursivelyGetChildren(o))].reduce((a, b) => a.concat(b)) // basically selectMany
  }
  const allchildren = recursivelyGetChildren(org, [])
  return includeSelf ? [org, ...allchildren] : allchildren
}

export function getFlatAncestors(org, orgsFlat, includeOrg = true) {
  if (org == null) return []
  const recursivelyGetParents = (org, parents) => {
    if (org.parentOrgId != null) {
      const parent = orgsFlat.find(o => o.orgId === org.parentOrgId)
      // could be null if doesn't match search criteria
      if (parent != null) return recursivelyGetParents(parent, [parent, ...parents])
    }
    return parents
  }
  return recursivelyGetParents(org, includeOrg ? [org] : [])
}

//simple client-side search, but will probably go server-side in the future for more advanced keyword/fulltext/soft searching maybe.
export function filterOrgs(orgsFlat, search, sortBy) {
  if (orgsFlat == null) {
    return {
      orgsTree: null,
      orgsFlat: [],
      expanded: {},
      isNotMatch: {},
    }
  }

  //make a deep copy to begin with so the calling code doesn't have to worry about unexpected mutations
  const orgsFlatDeep = sortBy ? _.orderBy(_.cloneDeep(orgsFlat), sortBy) : _.cloneDeep(orgsFlat)
  let orgsDeep = getOrgTree(orgsFlatDeep)
  const expanded = {}
  const isNotMatch = {}

  //set expanded
  const expandAll = !validator.empty(search)
  orgsFlatDeep.forEach(o => (expanded[o.orgId] = expandAll))

  if (validator.empty(search) || orgsFlatDeep.length === 0) {
    return {
      orgsTree: orgsDeep,
      orgsFlat: orgsFlatDeep,
      expanded,
      isNotMatch,
    }
  }

  //determine which orgs match the search
  const orgsFlatFiltered = filter(orgsFlatDeep, search, ['children'])
  if (orgsFlatFiltered.length === 0) {
    return {
      orgsTree: null,
      orgsFlat: [],
      expanded,
      isNotMatch,
    }
  }

  //don't expand if many results
  if (orgsFlatFiltered.length > 25) {
    orgsFlatDeep.forEach(o => (expanded[o.orgId] = false))
  }

  //deflatten results, so the UI has the necessary info to indicate where the org is in its heirarchy
  const isMatch = o => orgsFlatFiltered.some(r => r.orgId === o.orgId)
  const isOrHasMatch = org => {
    if (isMatch(org)) return true
    return orgsFlatDeep.filter(o => o.parentOrgId === org.orgId).some(isOrHasMatch) //recursively check children
  }
  const recursivelyFilterChildren = org => {
    org.children = _.orderBy(orgsFlatDeep.filter(o => o.parentOrgId === org.orgId && isOrHasMatch(o)).map(recursivelyFilterChildren), o => o.name)
    isNotMatch[org.orgId] = !isMatch(org) //mark matches, so we can distinguish between actual matches and proud parents who don't match but have children who do
    return org
  }
  orgsDeep = orgsDeep.map(recursivelyFilterChildren).filter(o => o.children.length > 0 || !isNotMatch[o.orgId]) //filter top level
  return {
    orgsTree: orgsDeep,
    orgsFlat: orgsFlatFiltered,
    expanded,
    isNotMatch,
  }
}

export function buildOrgProfileUrl(org) {
  return org.relativePath ? `/orgs/${org.orgId}/${org.relativePath}` : null
}

export function getMarkerColor(org) {
  switch (org.covidStatus) {
    case CovidStatus.Open:
      return colorService.getThemeColor('success')
    case CovidStatus.Closed:
      return colorService.getThemeColor('danger')
    default:
      return colorService.getThemeColor('gray')
  }
}

const char7 = String.fromCharCode(7)
export function getParentNamesArray(relativeName) {
  return relativeName?.split(char7).slice(0, -1) // remove lowest org, we only want parents
}

export function getParentNames(relativeName, delimiter = ' / ') {
  const parentNames = getParentNamesArray(relativeName)
  return parentNames?.join(delimiter)
}

export function formatRelativeName(relativeName, delimiter = ' / ') {
  return relativeName?.split(char7).join(delimiter)
}

export function getOrgIconColor(featureTypes) {
  const isHealth = featureTypes?.includes(FeatureType.HealthInstitution) ?? false
  const isSchool = featureTypes?.includes(FeatureType.TeachingInstitution) ?? false
  if (isHealth && !isSchool) return 'color-text-orange'
  if (isSchool && !isHealth) return 'color-text-blue'
  return 'color-text-steel'
}

export default {
  getOrgFeatureColor: getOrgFeatureColor,
  getRoots,
  getFlatChildren,
  getFlatAncestors,
  filterOrgs,
  getOrgTree,
}
