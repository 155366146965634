/*
  <div>
    <button use:tip="I'm a tip">Default</button>
    <button use:tip={ { content: 'I\'m on the left', options: { placement: 'left' } } }>Left</button>
    <button use:tip={ { content: 'I\'m on the right', options: { placement: 'right' } } }>Right</button>
    <button use:tip={ { content: 'I\'m on the top', options: { placement: 'top' } } }>Top</button>
    <button use:tip={ { content: 'I\'m on the bottom', options: { placement: 'bottom' } } }>Bottom</button>
  </div>
 */

import tippy, { followCursor, sticky } from 'tippy.js'

export default function tip(node, opts) {
  let tip
  setTip(opts)

  function setTip(optionsOrContent) {
    const isString = typeof optionsOrContent === 'string'
    const options = isString ? {} : optionsOrContent == null ? null : optionsOrContent.options ?? {}
    const content = isString ? optionsOrContent : optionsOrContent == null ? null : optionsOrContent.content

    destroyTip()
    if (content == null || content == '') return

    const tipOptions = {
      appendTo: document.body,
      maxWidth: 500,
      arrow: true,
      allowHTML: true,
      animation: 'fade',
      delay: 0,
      touch: !!options.interactive,
      content,
      zIndex: 2147483001,
      onShow: options.shared
        ? instance => {
            // Tippy.js has change detection, so set to null first to force it to update
            instance.setContent(null)
            instance.setContent(content)
          }
        : _.noop,
      ...options,
    }
    const show = options.show ?? false
    const hide = options.hide ?? false
    delete tipOptions.show
    const plugins = []
    if (options.sticky) plugins.push(sticky)
    if (options.followCursor) plugins.push(followCursor)
    if (plugins.length) tipOptions.plugins = plugins
    tip = tippy(node, tipOptions)
    if (show) tip.show()
    if (hide) tip.hide()
  }

  function destroyTip() {
    if (tip != null) {
      tip.destroy()
      tip.popper.style.display = 'none' // ie11 will otherwise display the unattached element in the top left in some scenarios
      node.setAttribute('title', '') // otherwise, default browser behavior still shows title
      tip = null
    }
  }

  return {
    update(opts) {
      setTip(opts)
    },

    destroy() {
      destroyTip()
    },
  }
}
