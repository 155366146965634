<svelte:options accessors />

{#if showSearchBox}
  <div class="flex-row flex-align-center g1 mb1" style={fullWidth ? 'min-width: 500px' : 'width: 500px'}>
    <Filter
      bind:text={keywordSearch}
      placeholder={keywordSearchPlaceholder}
      on:change={onKeywordSearchChanged}
      class="flex-grow"
      id={keywordSearchId}
    />
  </div>
{/if}

<div style="grid-area: filters" class="flex-row flex-align-center flex-wrap g1">
  <slot />

  <div
    on:keydown={handleKeydown}
    bind:this={elem}
    style={elemStyle}
    class="flex-row flex-align-center flex-wrap g05{className ? ` ${className}` : ''}"
  >
    <QuickDropdown
      bind:this={addFilterDropdown}
      bind:isOpen={addFilterOpen}
      on:open={() => (availableFilterTypesFilterText = null)}
      dataTest={name}
      autoSized
      {dropdownStyle}
    >
      <span slot="label">
        <Icon name="plus" />
        {label ?? 'Add filter'}
      </span>

      {#if availableFilterTypes.length > 3}
        <Filter bind:text={availableFilterTypesFilterText} autofocus class="m1" placeholder="Search filter types" />
      {/if}

      <div class="list-group scrollable-50" style="min-width: 200px;">
        {#if filteredAvailableFilterTypes.length === 0}
          <div class="p2">No filter types found matching "{availableFilterTypesFilterText}".</div>
        {:else}
          {#each filteredAvailableFilterTypes as ft}
            <Btn
              clearBtnStyling
              class="list-group-item py05 {ft.disabled ? 'disabled' : ''}"
              on:click={() => addFilterType(ft)}
              dataTest="filter-{ft.type}"
            >
              <div class="flex-row flex-align-center g05">
                <Icon name={ft.icon} class={ft.iconClass} style="font-size: 1.2em;{ft.iconStyle ? ` ${ft.iconStyle}` : ''}" {...ft.iconProps ?? {}} />
                <SafeHtml value={ft.label} />
              </div>
            </Btn>
          {/each}
        {/if}
      </div>
    </QuickDropdown>

    <!--
      Show the button when there's a simulation so when they're simulating adding their
      first filter, the simulated filter is placed where it'll actually show up when added.
    -->
    {#if hasRemovableFilters || simulation}
      <Btn icon="close" class="btn btn-sm btn-outline-warning" on:click={clearFilters} dataTest="clear-all-filters">Clear filters</Btn>
    {/if}

    {#each filtersWrapped as wrappedFilter, i (`${wrappedFilter.type}-${i}`)}
      {#if i}
        <em class="small text-gray">and</em>
      {/if}

      <FiltersWrappedFilter
        {wrappedFilter}
        {filterBeingEdited}
        {filterClones}
        filterOptions={filterOptionsByFilterType[wrappedFilter.type]}
        interceptor={_interceptors[wrappedFilter.type]}
        {simulation}
        {open}
        {apply}
        {remove}
        {cancel}
        {closeDropdown}
      />
    {/each}

    {#if simulatedWrappedFilter}
      {#if filtersWrapped.length}
        <em class="small text-gray">and</em>
      {/if}

      <FiltersWrappedFilter
        wrappedFilter={simulatedWrappedFilter}
        {filterClones}
        filterOptions={[simulation.filterOption]}
        interceptor={_interceptors[simulation.filterType]}
        {simulation}
        isPlaceholder
      />
    {/if}
  </div>
</div>

<script context="module">
  import { allFilterTypes } from 'config/all-filter-types.js'
  import SafeHtml from 'components/SafeHtml.svelte'
  import validator from 'services/validator.js'
  import persona from 'stores/persona.js'

  // must manually subscribe to store value in module scope.
  let personaValue
  persona.subscribe(value => (personaValue = value))

  export function buildFilterTypesArray(metaMapFuncs, includedFilterTypes = [], excludedFilterTypes = []) {
    return allFilterTypes
      .filter(
        meta =>
          includedFilterTypes.includes(meta.type) &&
          !excludedFilterTypes.includes(meta.type) &&
          (meta.shouldShow == null || meta.shouldShow(personaValue))
      )
      .map(meta => {
        const configureArgs = personaValue != null ? { persona: personaValue } : {}
        meta = metaMapFuncs[meta.type] ? metaMapFuncs[meta.type](_.clone(meta), configureArgs) : meta

        const mappedMeta = {
          ...meta,
          configurable: meta.create != null && meta.configurable !== false, // some filters are not configurable, but they still have a create function to create their filter config
        }
        mappedMeta.configure?.(configureArgs)
        return mappedMeta
      })
  }

  export function buildIgnoredFilterTypesArray(includedFilterTypes = [], excludedFilterTypes = []) {
    return allFilterTypes
      .filter(
        meta =>
          includedFilterTypes.includes(meta.type) &&
          !excludedFilterTypes.includes(meta.type) &&
          meta.shouldShow != null &&
          !meta.shouldShow(personaValue)
      )
      .map(meta => meta.type)
  }

  export function filtersAreEffectivelySame(a, b) {
    a = [...(a ?? [])]
    b = [...(b ?? [])]
    if (a.length !== b.length) return false
    const sortByType = (f1, f2) => f2.type - f1.type
    a.sort(sortByType)
    b.sort(sortByType)
    // Might need to make this more robust in the future.
    // For example, if a filter's config has IDs selected in a different order than the equivalent filter,
    // they're effectively the same.
    for (let i = 0; i < a.length; i++) {
      if (a[i].type !== b[i].type) return false
      if (!validator.equals(a[i].config, b[i].config)) return false
    }
    return true
  }
</script>

<script>
  import { FilterType, PersonaType } from 'config/enums.js'
  import { tick, onDestroy } from 'svelte'
  import { validationSummaryItems } from 'stores/dashboard-filters.js'
  import api from 'services/api.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Filter from 'components/Filter.svelte'
  import FiltersWrappedFilter from 'components/Filters.WrappedFilter.svelte'
  import Icon from 'components/Icon.svelte'
  import Key from 'config/key.js'
  import personaFilters from 'stores/persona-filters.js'
  import QuickDropdown from 'components/QuickDropdown.svelte'

  export let filters
  export let interceptors = {}
  export let onChanged = _.noop
  export let onCleared = _.noop
  // You shouldn't be using <Filters> directly; instead, you should use <MatchFilters>, <CapacityFilters>, etc.
  // All of those components should pass the includedFilterTypes that make sense for their entity (match, capacity, etc.).
  // However, all of them should expose/forward an excludedFilterTypes array to remove filters that don't make
  // sense in the context they're used in.
  export let includedFilterTypes = []
  export let excludedFilterTypes = []
  export let label = null
  export let metaMapFuncs = {}
  let className = 'mb1'
  export { className as class }
  export let dropdownStyle = null
  export let filterOptionsController
  export const filterTypes = {}
  export let name = 'add-filter-btn'
  export let fullWidth = false
  export let showSearchBox = false
  export let keywordSearchPlaceholder = 'Search'
  export let keywordSearchId = 'keyword-search'

  let filterOptionsByFilterType = {}
  let filterBeingEdited = null
  let filterClones = new Map()
  let addFilterDropdown = null
  let addFilterOpen = false
  let availableFilterTypesFilterText = ''

  let focusSimulation = null
  let hoverSimulation = null
  let simulation = null

  let elem = null
  let elemMinHeight = 0
  let elemMinWidth = 0
  let elemStyleBasedOnWindowWidth = null
  let observer = null
  let keywordSearch = filters?.find(f => f.type === FilterType.KeywordSearch)?.config.keyword ?? ''

  onDestroy(() => observer?.disconnect())

  $: _interceptors = {
    [FilterType.KeywordSearch]: {
      canRemove: !showSearchBox,
      isAvailable: !showSearchBox,
    },
    ...interceptors,
  }
  $: availableFilterTypes = buildFilterTypesArray(
    metaMapFuncs,
    includedFilterTypes.filter(type => _interceptors[type]?.isAvailable !== false),
    excludedFilterTypes
  )
  $: availableFilterTypes.forEach(meta => (filterTypes[meta.type] = meta))
  $: personaFiltersOrgId = $personaFilters.orgId
  $: personaFiltersTeamId = $personaFilters.teamId
  $: personaFiltersOrgId, personaFiltersTeamId, clearAndLoadAppliedFilterOptions()
  $: filteredAvailableFilterTypes = validator.empty(availableFilterTypesFilterText)
    ? availableFilterTypes
    : availableFilterTypes.filter(ft => ft.label.toLowerCase().includes(availableFilterTypesFilterText.toLowerCase().trim()))
  $: elem, initResizeObserver()
  $: elemMinHeight = elemMinHeight ? `min-height: ${elemMinHeight}px;` : ''
  $: elemMinWidth = elemMinWidth ? `min-width: ${elemMinWidth}px;` : ''
  $: elemStyle = elemMinHeight + elemMinWidth
  $: filtersByType = _.groupBy(filters, 'type')
  $: filtersWrapped = filters
    .filter(f => filterTypes[f.type] != null)
    .map(f => {
      const meta = filterTypes[f.type]
      const filterItselfValid = meta.validate?.(f.config, filters, meta) ?? true
      const globallyValid = meta.canHaveMultiple || filtersByType[f.type].length < 2
      const valid = filterItselfValid && globallyValid
      const clone = filterClones.get(f)
      const hasUnappliedChanges = !!clone && !validator.equals(f.config, clone.config)
      return {
        type: f.type,
        meta,
        valid,
        hasUnappliedChanges,
        filter: f,
      }
    })
  $: hasRemovableFilters = filters.some(f => !excludedFilterTypes.includes(f.type) && _interceptors[f.type]?.canRemove !== false) // ignore excluded when determining if we have filters--calling code should handle customly-implemented filtertypes
  $: $validationSummaryItems = buildValidationSummaryItems(filtersWrapped)

  $: simulatedFilterConfigBase =
    simulation?.action === 'add' || simulation?.action === 'set'
      ? {
          ...(filterTypes[simulation.filterType].create?.(filterTypes[simulation.filterType]) ?? {}),
          simulatedValue: simulation.configValue,
        }
      : null

  $: simulatedFilterConfig = simulatedFilterConfigBase
    ? simulation.filterConfig
      ? {
          ...simulatedFilterConfigBase,
          ...simulation.filterConfig,
        }
      : {
          ...simulatedFilterConfigBase,
          [simulation.configKey]: [simulation.configValue],
        }
    : null

  $: simulatedWrappedFilter =
    simulatedFilterConfig != null
      ? {
          type: simulation.filterType,
          meta: filterTypes[simulation.filterType],
          valid: true,
          hasUnappliedChanges: false,
          filter: {
            config: simulatedFilterConfig,
          },
        }
      : null

  function onKeywordSearchChanged() {
    filters = filters.filter(f => f.type !== FilterType.KeywordSearch)
    if (!validator.empty(keywordSearch)) filters.push({ type: FilterType.KeywordSearch, config: { keyword: keywordSearch } })
    onChanged(filters)
  }

  function resetElem() {
    elemMinHeight = 0
    elemMinWidth = 0
    elemStyleBasedOnWindowWidth = null
  }

  function initResizeObserver() {
    if (elem == null || observer != null) return
    observer = new ResizeObserver(() => {
      if (elem == null) return resetElem()
      if (!simulation) return

      if (elemStyleBasedOnWindowWidth == null || window.innerWidth != elemStyleBasedOnWindowWidth) {
        elemMinHeight = 0
        elemMinWidth = 0
        elemStyleBasedOnWindowWidth = window.innerWidth
      }

      elemMinHeight = Math.max(elemMinHeight, elem.offsetHeight)
      elemMinWidth = Math.max(elemMinWidth, elem.offsetWidth)
    })
    observer.observe(elem)
  }

  function buildValidationSummaryItems(wrapped) {
    return wrapped
      .filter(w => !w.valid || w.hasUnappliedChanges)
      .map(w => ({
        type: w.valid ? 'warning' : 'error',
        message: `${theOrA(w.type)} <strong>${w.meta.label}</strong> filter ${w.valid ? 'has unapplied changes' : 'is invalid'}`,
        html: true,
        actions: [
          {
            icon: 'edit',
            text: 'Edit filter',
            dataTest: `edit-filter-${w.type}`, // Not guaranteed unique
            click: () => open(w.filter),
          },
        ],
      }))
  }

  function theOrA(type) {
    return filtersByType[type].length === 1 ? 'The' : 'A'
  }

  async function addFilterType(meta) {
    if (meta.disabled) return

    addFilterOpen = false

    const { type } = meta
    if (!meta.canHaveMultiple && filtersByType[type]) {
      // if already have a filter of this type and the filter type doesn't allow multiple, open the existing one
      open(filtersByType[type][0])
    } else {
      // otherwise add a new filter of this type with default value
      const defaultFilter = meta.configurable ? { type, config: meta.create(meta), isNew: true } : { type, config: meta.create?.(meta) ?? {} }
      filters = [...filters, defaultFilter]
      if (meta.configurable) {
        // some filters require configuration prior to being applied
        await tick()
        open(defaultFilter)
      } else {
        // other filters don't have require any configuration
        fireChangedIfValid()
      }
    }
  }

  export function applyQuickFilter(filterType, filterConfig, configKey, configValue, action, existingFilter) {
    // This doesn't handle filters where their value isn't an array.
    if (action === 'set') clearFilters(false)
    if (action === 'set' || action === 'add') {
      const meta = filterTypes[filterType]
      const filter = { type: filterType, config: meta.configurable ? meta.create(meta) : {} }
      if (filterConfig) {
        filter.config = filterConfig
      } else {
        addValueToFilterConfig(filter.config[configKey], configValue)
        // filter.config = configValues
      }
      filters.push(filter)
      loadOptionsByFilter(filter, false)
    } else if (filterConfig && existingFilter) {
      // Does this make sense to even happen? Should we remove the dropdown option from the UI?
      existingFilter.config = filterConfig
    } else if (existingFilter) {
      addValueToFilterConfig(existingFilter.config[configKey], configValue)
    }

    fireChangedIfValid()
  }

  function addValueToFilterConfig(filter, configValue) {
    if (!filter) return
    filter.push(configValue)
  }

  export function updateSimulation(action, event, filterType, filterConfig, configKey, configValue, simulationFilterOption, filter = null) {
    switch (event) {
      case 'focus':
        simulation = focusSimulation = { action, filter, filterType, filterConfig, configKey, configValue, filterOption: simulationFilterOption }
        break
      case 'blur':
        focusSimulation = null
        simulation = hoverSimulation
        break
      case 'mouseenter':
        simulation = hoverSimulation = { action, filter, filterType, filterConfig, configKey, configValue, filterOption: simulationFilterOption }
        break
      case 'mouseleave':
        hoverSimulation = null
        simulation = focusSimulation
        break
    }
  }

  export function resetQuickFilterSimulation() {
    focusSimulation = null
    hoverSimulation = null
    simulation = null
    resetElem()
  }

  function open(filter) {
    const meta = filterTypes[filter.type]
    if (!meta.configurable) {
      filterBeingEdited = filter
      return
    }
    if (!filterClones.has(filter)) {
      const clone = _.cloneDeep(filter)
      filterClones.set(filter, clone)
      filterClones = filterClones // Reactivity
    }
    filterBeingEdited = filter
    loadOptionsByFilter(filter, true)
  }

  function stopEditing() {
    filterBeingEdited = null
  }

  function apply() {
    const clone = filterClones.get(filterBeingEdited)
    if (!isValid(clone)) return
    filterBeingEdited.config = clone.config
    removeClone()
    delete filterBeingEdited.isNew
    loadOptionsByFilter(filterBeingEdited, false)
    filters = filters // Reactivity
    stopEditing()
    fireChangedIfValid()
    // Assume they want to add another filter. Another assumption we could've made:
    // assume they want to edit the filter they just added and focus the button for that.
    addFilterDropdown.focusButton()
  }

  function isValid(clone) {
    const meta = filterTypes[clone.type]
    return meta?.validate(clone.config, filters, meta) ?? true
  }

  // When a user explicitly clicks Cancel
  function cancel() {
    if (filterBeingEdited?.isNew) {
      remove(false)
      addFilterOpen = true
    } else {
      removeClone()
    }
    stopEditing()
  }

  // Similar to cancel(), but when a user just clicks outside
  function closeDropdown(wrappedFilter) {
    // If the user opens a filter while a different filter is open,
    // filterBeingEdited will have already changed, so don't unset it.
    if (wrappedFilter.filter !== filterBeingEdited) return
    if (filterBeingEdited?.isNew) {
      remove(false)
    } else {
      stopEditing()
      filters = filters // Reactivity for validation
    }
  }

  function removeClone() {
    filterClones.delete(filterBeingEdited)
    filterClones = filterClones // Reactivity
  }

  function remove(shouldFireChanged) {
    removeClone()
    filters = filters.filter(f => f !== filterBeingEdited)
    stopEditing()
    if (shouldFireChanged) fireChangedIfValid()
  }

  function cannotBeDeleted(filter) {
    return excludedFilterTypes.includes(filter.type) || _interceptors[filter.type]?.canRemove === false
  }

  function clearFilters(fireEvents = true) {
    // don't clear excludedFilterTypes -- let the calling code handle that
    filters = filters.filter(cannotBeDeleted)
    for (const f of filterClones.keys()) {
      if (cannotBeDeleted(f)) continue
      filterClones.delete(f)
    }
    filterClones = filterClones // Reactivity
    if (fireEvents) {
      onCleared()
      fireChangedIfValid()
    }
  }

  function fireChangedIfValid() {
    setTimeout(() => {
      if ($validationSummaryItems.length) return
      onChanged(filters)
    })
  }

  async function loadOptionsByFilter(filter, opening) {
    const filterType = filter.type
    const meta = filterTypes[filterType]
    if (meta?.optionsEndpoint && api[filterOptionsController]?.[meta.optionsEndpoint] != null) {
      // lazy load filters on a per <Filters> instance basis client-side (only for filters that are loading ALL options in--not custom pickers which only load the selected options)
      // later on, we should cache filter options server-side too, though most are pretty quick already, so not a big deal
      if (filterOptionsByFilterType[filterType]) return
      filterOptionsByFilterType[filterType] = await api[filterOptionsController][meta.optionsEndpoint](api.noMonitor)
    } else if (meta?.getSelected && !opening) {
      // no need to load if opening--custom pickers load selected options themselves upon render
      let selectedValues = filter.config[meta.filterProp] ?? []

      // get simulation value too, if any
      if (simulation?.configValue && !selectedValues.includes(simulation.configValue)) selectedValues = selectedValues.concat(simulation.configValue)

      const hasNull = selectedValues.includes(null)
      const nonNullValues = hasNull ? selectedValues.filter(v => v != null) : selectedValues
      // don't bother getting if no non-null selected values
      if (nonNullValues.length === 0) {
        filterOptionsByFilterType[filterType] = []
        return
      }
      let selectedOptions = await meta.getSelected(nonNullValues)
      if (hasNull) selectedOptions = [null, ...selectedOptions]
      filterOptionsByFilterType[filterType] = selectedOptions
    }
    const interceptor = interceptors[filterType]
    if (interceptor?.shouldIncludeFilterOption && filterOptionsByFilterType[filterType]) {
      filterOptionsByFilterType[filterType] = filterOptionsByFilterType[filterType].filter(interceptor.shouldIncludeFilterOption)
    }
  }

  function clearAndLoadAppliedFilterOptions() {
    // if impersonation has stopped, no need to load filter options.
    if ($persona.personaType === PersonaType.CN) return

    filterOptionsByFilterType = {}
    for (const filter of filters) loadOptionsByFilter(filter, false)
  }

  function handleKeydown(e) {
    const pressedEscape = (e.which || e.keyCode) === Key.Escape
    if (!pressedEscape) return
    e.stopImmediatePropagation()
    addFilterOpen = false
    stopEditing()
  }
</script>

<style>
  .scrollable-50 {
    max-height: 50vh;
    overflow-y: auto;
  }
</style>
