{#if stepSubmission.completeToDate && stepIsNotDone}
  {#if !stepSubmission.canBeCompleteNow}
    {getStepCompletionWhenExplanation(
      step.completeFrom,
      step.completeFromAddDays,
      step.completeTo,
      step.completeToAddDays,
      null,
      stepSubmission.completeFromDate,
      stepSubmission.completeToDate
    )}
  {:else}
    <span class={dueInDays < 3 ? 'text-danger' : dueInDays < 4 ? 'text-warning' : ''}>
      {#if dueInDays === 0}
        today
      {:else}
        by {dueDateFriendly}
        {#if dueInDays >= 0}
          (in {pluralCount('day', dueInDaysAbs)})
        {:else}
          ({pluralCount('day', dueInDaysAbs)} ago)
        {/if}
      {/if}
    </span>
  {/if}
{/if}

<script>
  import { StepSubmissionStatus } from 'config/enums.js'
  import { getStepCompletionWhenExplanation } from 'services/step-completion-when-helpers'
  import { pluralCount } from 'services/string-utils.js'
  export let stepSubmission

  // we really should do https://trello.com/c/TaIi35LI/555-handle-dates-utc-better. Our seeds have been generated in various timezones at this point, and our users are now spread throughout even more timezones and should be able to work with each other and see their own local times.
  // completeToDate could be in some person's timezone (start/end, for instance), whereas datesubmitted is in UTC. So I parse utc now formatted to get the dueInDays for now, even though it's not really accurate everytime.
  $: dueDateFriendly = dayjs(stepSubmission.completeToDate).format('M/D/YYYY')
  $: dueInDays = stepSubmission.completeToDate ? dayjs(stepSubmission.completeToDate).diff(dayjs(dayjs().utc().format('M/D/YYYY')), 'days') : null
  $: dueInDaysAbs = dueInDays != null ? Math.abs(dueInDays) : null
  $: stepIsNotDone = [StepSubmissionStatus.Incomplete, StepSubmissionStatus.AwaitingVerification, StepSubmissionStatus.Rejected].includes(
    stepSubmission.status
  )
  $: step = stepSubmission.step
</script>
