import hexToRgba from 'hex-to-rgba'

const colors = ['red', 'orange', 'yellow', 'green', 'blue', 'purple', 'black', 'pink', 'brown', 'gold', 'lime', 'sky', 'light-purple', 'gray']

// The main thing we want is a even distribution, and small changes in the string
// make huge changes in the output, aka an avalanche effect.
// https://stackoverflow.com/a/52171480/221867
function cyrb53(str, seed = 0) {
  let h1 = 0xdeadbeef ^ seed
  let h2 = 0x41c6ce57 ^ seed
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i)
    h1 = Math.imul(h1 ^ ch, 2654435761)
    h2 = Math.imul(h2 ^ ch, 1597334677)
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909)
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909)
  return 4294967296 * (2097151 & h2) + (h1 >>> 0)
}

function getHueAmplitude(uniqueVal) {
  const val = uniqueVal ?? ''
  // Probably a better way to tweak the algorithm to get more avalanche effect,
  // but this seemed to work better than just calling it once in my testing.
  const fwd = cyrb53(val)
  const rev = cyrb53(val.split('').reverse().join(''))
  return fwd * rev
}

function randomFrom(array) {
  return array[Math.floor(Math.random() * array.length)]
}

class ColorService {
  constructor() {
    this.themeColors = {
      primary: '#00A89D',
      success: '#53b953',
      info: '#2b9ac9',
      warning: '#f3aa41',
      danger: '#ef5350',
      purple: '#7e57c2',
      pink: '#ec407a',
      gray: '#555',
    }
  }

  getAllColors() {
    return colors
  }

  getThemeColor(name) {
    return this.themeColors[name] || '#cccccc'
  }

  hexToRgba(hex, alpha) {
    return hexToRgba(hex, alpha)
  }

  getHue(uniqueVal) {
    return getHueAmplitude(uniqueVal) % 360
  }

  // Cut out 30 degrees of the hue wheel to avoid reddish colors so the user
  // does not think a dynamically-colored UI element is in an error/attention-needing state.
  getNonErrorHue(uniqueVal) {
    return 15 + (getHueAmplitude(uniqueVal) % 330)
  }

  getBestAvailableColor(alreadyUsedColors) {
    // Pick the least used color randomly, preferring those that spark joy first.
    const joylessColors = ['gold', 'brown', 'gray', 'lime', 'black']
    const colorMetas = colors.map(color => ({ color, count: 0, joyless: joylessColors.includes(color) }))
    const colorMetasByColor = {}
    for (const meta of colorMetas) {
      colorMetasByColor[meta.color] = meta
    }
    for (const usedColor of alreadyUsedColors) {
      colorMetasByColor[usedColor].count++
    }

    const orderedMetas = _.orderBy(colorMetas, ['count', 'joyless'])
    const { count, joyless } = orderedMetas[0]
    const metasInGroup = orderedMetas.filter(meta => meta.count === count && meta.joyless === joyless)
    return randomFrom(metasInGroup).color
  }

  humanizeColor(color) {
    return _.capitalize(color).replace(/-/g, ' ')
  }
}

export default new ColorService()
