<div class="input-tags form-control" class:active={focused} on:click={focusNewTagInput} data-test={name} id={name}>
  {#if value != null}
    {#each value as v, i}
      <span class="btn btn-sm btn-{tagClass}" class:bounce={duplicate === v}>
        {v}
        <button type="button" class="btn-reset" on:mouseup={() => removeTag(i)} use:tip={'Remove'} tabindex="-1">
          <Icon name="delete" />
        </button>
      </span>
    {/each}
  {/if}
  <input
    bind:this={newTagInput}
    autocomplete="off"
    class="new-tag-input"
    bind:value={newTag}
    on:focus={focusNewTagInput}
    on:blur={blurNewTagInput}
    on:keydown={keydownNewTagInput}
    placeholder={value == null || value.length === 0 ? placeholder : null}
    style="width:{newTagInputWidth}px;{value && value.length > 0 ? 'margin-left:-.1rem;' : ''}"
  />

  {#if focused && filteredOptionsLimited.length > 0}
    <div class="select-dropdown" out:blur|local>
      <div class="select-dropdown-items-container">
        <div class="select-dropdown-items-container-wrapper">
          {#each filteredOptionsLimited as option, i}
            <!--
        changed classes to use as close to default bootstrap classes as possible so we can theme high level bootstrap classes and everything should stay looking consistent
        d-flex / etc is so you can have a badge off to the right... we might use a badge to indicate # of usages or something later so I left them here for now
        -->
            <div on:click={() => addTag(option)} class="item" class:viewing={currentIndex === i}>{option}</div>
          {/each}
        </div>
      </div>
    </div>
  {/if}
</div>

<div class="small text-right text-gray" class:opacity-0={newTagIsEmpty} style="margin-top: 2px">
  {#if currentIndex > -1}
    <Keyboard key={Key.Tab} /> or <Keyboard key={Key.Enter} /> to add selected
  {:else}
    <Keyboard key={Key.Tab} />, <Keyboard key={Key.Enter} />, or <Keyboard key={Key.Comma} /> to add
  {/if}
</div>

{#if error}
  <Alert type="warning">{error}</Alert>
{/if}

<script>
  import { blur } from 'svelte/transition'
  import { tick } from 'svelte'
  import Alert from 'components/bootstrap/Alert.svelte'
  import Icon from 'components/Icon.svelte'
  import Keyboard from 'components/Keyboard.svelte'
  import Key from 'config/key.js'
  import tip from 'decorators/tip.js'
  import validator from 'services/validator.js'

  export let options = []
  export let value = []
  export let placeholder = ''
  export let tagClass = 'purple'
  export let name = 'tags'

  let newTag = ''
  let currentIndex = -1
  let duplicate = null
  let duplicateStop = null
  let focused = false
  let newTagInput = null
  let error = null

  $: newTagInputWidth = (() => {
    //grow the box as they type
    const charCount = newTag.length === 0 && (value == null || value.length == 0) ? placeholder.length : newTag.length // if no values, show the placeholder and make the box large enough to show the placeholder
    const characterWidth = 7 //approximate
    const width = characterWidth * charCount
    return width + 20 //add a bit for margin/padding
  })()

  $: filteredOptions = (() => {
    if (newTagIsEmpty) return [] // don't show until they start typing
    return value == null
      ? options ?? []
      : options?.filter(o => !value.some(v => v.toLowerCase() === o.toLowerCase()) && o.toLowerCase().includes(newTag.toLowerCase())) ?? []
  })()

  $: filteredOptionsLimited = filteredOptions.slice(0, 15)

  $: {
    const min = -1
    const max = filteredOptions.length - 1
    if (currentIndex < min) currentIndex = min
    if (currentIndex > max) currentIndex = max
  }

  $: newTagIsEmpty = newTag == null || validator.empty(newTag)

  function addTag(newVal = null) {
    if (newTagIsEmpty) return false
    let newTagVal =
      newVal != null
        ? newVal
        : currentIndex > -1
        ? filteredOptions[currentIndex] //they pressed enter after up/down through autocomplete
        : newTag
    newTagVal = newTagVal.toLowerCase().trim()
    const exists = value?.some(v => v.toLowerCase() === newTagVal)
    if (exists) {
      error = `You already added '${newTagVal}'`
      duplicate = newTagVal
      clearTimeout(duplicateStop)
      duplicateStop = setTimeout(() => (duplicate = null), 1000)
      return
    }
    value = value ? [...value, newTagVal] : [newTagVal]
    newTag = ''
    focusNewTagInput()
  }

  function removeTag(index) {
    value = value?.filter((v, i) => i !== index)
    focusNewTagInput()
  }

  function focusNewTagInput() {
    error = null
    focused = true
    if (newTagInput) newTagInput.focus()
  }

  async function blurNewTagInput() {
    if (filteredOptions.length === 0) addTag() // only add on blur if what they typed isn't an option. Feels more natural for mobile.
    await tick() // await tick so click events can complete
    focused = false
  }

  function keydownNewTagInput(e) {
    error = null
    const key = e.which || e.keyCode

    switch (key) {
      // up/down traverses autocomplete
      case Key.Down:
        if (currentIndex < filteredOptions.length - 1) currentIndex++
        e.preventDefault()
        return

      case Key.Up:
        if (currentIndex > -1) currentIndex--
        e.preventDefault()
        return

      // tab, enter, and comma all add whatever's typed or the selected autocomplete if there is one
      // or they add whatever's typed
      case Key.Tab:
      case Key.Enter:
      case Key.Comma:
        if (key === Key.Comma && currentIndex > -1) return
        if (!newTagIsEmpty) {
          addTag()
          e.preventDefault()
          return
        }
        break

      // backspace should delete last value if input box is empty
      case Key.Backspace:
        if (newTagIsEmpty) {
          value = value?.filter((v, i) => i !== value.length - 1) // removing the last item...
          e.preventDefault()
          return
        }
        break
    }
  }
</script>

<style lang="scss">
  @import '../../../css/helpers';

  .input-tags {
    padding: 2px 1px;

    cursor: text;
    border: 1px solid #eee;
    border-radius: 6px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    position: relative; // The container must be positioned relative for the autocomplete items

    &.active {
      border-color: #66afe9;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }

    .btn {
      cursor: default;
      margin: 2px 0 2px 2px;
      animation-duration: 1s;
      border-radius: 4px;
    }

    .new-tag-input {
      border: none;
      outline: 0;
      padding: 0;
      margin: 6px;
    }

    .btn-reset {
      color: $light;
    }
  }
</style>
