<div class="activity-container" {style}>
  <h3>Activity <Badge lg count={activity} /></h3>

  {#if match.canComment}
    <AddComment
      {onAddComment}
      {mentionableUsers}
      commentBoxHelpText="Comments are visible to all parties on the rotation — students, coordinators, &amp; preceptors."
    />
  {/if}

  {#if loading}
    <Loading message="Loading activity" />
  {:else}
    {#if !activity.length}
      <HelpBlock>No activity</HelpBlock>
    {/if}
    <div class="activity">
      {#each activity as a}
        {@const userDeleted = a.user?.deletedDateTime != null}
        <div class="activity-item">
          <ProfilePic
            src={a.user?.name ? a.user.profilePicture : '/images/logo-no-words.png'}
            name={a.user?.name}
            initials={a.user?.initials}
            id={a.user?.userId}
            href={a.user?.url}
            medium
            class="mr1"
            pseudoDisabled={userDeleted}
            title={userDeleted ? 'Inactive user' : null}
          />
          <div class="text">
            <InactiveUserIcon show={userDeleted} />
            <strong class:text-gray={userDeleted}>{a.user?.name ?? 'System'}</strong>
            <span class="small em nowrap">
              {#if a.messageHtml}
                commented {dateService.calendarTimeLower(a.dateCreated)}
              {:else}
                {dateService.calendarTime(a.dateCreated)}
              {/if}
            </span>
            {#if a.messageHtml}
              <DisplayComment messageHtml={a.messageHtml} />
              {#if a.user?.userId == $user.userId}
                <a class="edit-btn" use:tip={'Edit comment'} on:click={() => editComment(a.matchCommentId, a.message, mentionableUsers)} href={null}>
                  <Icon name="edit" class="text-warning" />
                </a>
              {/if}
              {#if a.editHistory?.length}
                <a data-test="edit-history-btn" on:click={() => openEditHistory(a.user, a.editHistory)} href={null} class="small em"> edited </a>
              {/if}
            {:else}
              <div>
                {#if a.status != null}
                  {@const matchStatus = matchStatusHelper.get(a.status)}
                  <Icon name={matchStatus.icon} class="text-{matchStatus.color}" title={matchStatus.label} />
                {/if}
                &nbsp;{a.changeSummary}
                {#if a.matchChangeDetailId != null}
                  <a use:tip={'View change details'} on:click={() => loadChangeDetail(a.matchChangeDetailId)} href={null}>
                    <Icon name="eye" />
                    {#if matchChangeDetailLoading}
                      <Spinner />
                    {/if}
                  </a>
                {/if}
              </div>
            {/if}
          </div>
        </div>
      {/each}
    </div>
  {/if}
</div>

{#if editingComment}
  <Modal on:close={() => (editingComment = false)} title="Edit comment">
    <Form on:submit={() => editCommentSubmit(editingMatchCommentId, editingMatchCommentNew)}>
      <div class="p2">
        <FormGroup valid={validator.required(editingMatchCommentNew)}>
          <MessageBox bind:message={editingMatchCommentNew} {mentionableUsers} name="edit-comment" />
        </FormGroup>
      </div>

      <div class="modal-footer">
        <Btn
          type="submit"
          class="btn-{editingMatchCommentNew !== editingMatchCommentOriginal ? 'primary' : 'disabled'}"
          disabled={editingMatchCommentNew === editingMatchCommentOriginal}
          dataTest="edit-comment-submit-btn"
          icon="save"
        >
          Save changes
        </Btn>
        <Btn on:click={() => (editingComment = false)}>Cancel</Btn>
      </div>
    </Form>
  </Modal>
{/if}

{#if editHistoryOpen}
  <Modal on:close={() => (editHistoryOpen = false)} title="Comment edit history">
    <div class="p2">
      <div class="activity">
        <!-- consider using diff-merger to show differences, like we do for proposed match changes -->
        {#each editHistory as h}
          <div class="activity-item mb2">
            {#if editHistoryUser?.profilePicture}
              <!-- ProfilePic instead here? don't bother? -->
              <img src={editHistoryUser.profilePicture} alt="" />
            {/if}
            <div class="text">
              <strong>{editHistoryUser.name}</strong>
              <span class="small em nowrap">{dateService.calendarTime(h.date)}</span>
              <div class="message">{h.message}</div>
            </div>
          </div>
        {/each}
      </div>
    </div>
  </Modal>
{/if}

{#if matchChangeDetail}
  <MatchChangeModal match={matchChangeDetailToCompareTo} {onMatchChanged} change={matchChangeDetail} onClose={() => (matchChangeDetail = null)} />
{/if}

<script>
  import { changeFromPrev } from 'services/match-merger.js'
  import { onDestroy } from 'svelte'
  import { parseUserIdMentionsToUserNames } from 'services/message-parser.js'
  import AddComment from './AddComment.svelte'
  import api from 'services/api.js'
  import Badge from 'components/Badge.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import dateService from 'services/date-service.js'
  import DisplayComment from './DisplayComment.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import getMatchController from 'services/match-controller.js'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Icon from 'components/Icon.svelte'
  import InactiveUserIcon from 'components/InactiveUserIcon.svelte'
  import Loading from 'components/Loading.svelte'
  import MatchChangeModal from 'components/MatchChangeModal.svelte'
  import matchStatusHelper from 'services/match-status-helper.js'
  import MessageBox from 'components/fields/MessageBox.svelte'
  import Modal from 'components/Modal.svelte'
  import ProfilePic from './ProfilePic.svelte'
  import Spinner from './Spinner.svelte'
  import tip from 'decorators/tip.js'
  import toaster from 'services/toaster.js'
  import user from 'stores/user.js'
  import validator from 'services/validator.js'

  export let match
  export let onMatchChanged
  export let style

  const matchChangeDetailLoading = false
  const loadingDetail = {}
  let activity = []
  let loading = false
  let editingComment = false
  let editingMatchCommentId = null
  let editingMatchCommentOriginal = null
  let editingMatchCommentNew = null
  let editHistoryOpen = false
  let editHistory = null
  let editHistoryUser = null
  let matchChangeDetail = null
  let matchChangeDetailToCompareTo = null
  let activityTask = null

  $: mentionableUsers = match.notifiableUsers?.filter(u => u.deletedDateTime == null) ?? []

  onDestroy(() => activityTask?.abort())

  export async function load() {
    loading = true
    // (TODO: server-side should actually abort the query like kanban query does at dapper level)
    if (activityTask) activityTask.abort()
    activityTask = getMatchController().listActivity({ matchId: match.matchId }, api.noMonitor)
    activity = await activityTask.finally(() => (loading = false))
    activityTask = null
  }

  async function onAddComment(message) {
    await getMatchController().addComment({ matchId: match.matchId }, { message }, api.noMonitor)
    load() // don't do this asynchronously (3-9-2022, why though?  i don't remember)
  }

  function editComment(matchCommentId, message, mentionableUsers) {
    editingComment = true
    editingMatchCommentId = matchCommentId
    message = parseUserIdMentionsToUserNames(message, mentionableUsers)
    editingMatchCommentOriginal = message
    editingMatchCommentNew = message
  }

  async function editCommentSubmit(matchCommentId, message) {
    await getMatchController()
      .editComment({ matchCommentId, matchId: match.matchId }, { message }, api.noMonitor)
      .then(async () => {
        await load()
        toaster.toast({ message: 'Comment updated', type: 'success', icon: 'comment-empty' })
        editingComment = false
      })
      .catch(() => (editingComment = false))
  }

  function openEditHistory(user, edit_history) {
    editHistoryOpen = true
    editHistory = edit_history
    editHistoryUser = user
  }

  async function loadChangeDetail(matchChangeDetailId) {
    // if we already have it loaded, just use that. else get from api
    const _matchChangeDetail = match.proposedChanges.find(c => c.matchChangeDetailId === matchChangeDetailId)
    if (_matchChangeDetail != null) {
      setMatchChangeDetail(_matchChangeDetail)
    } else {
      const loadKey = `detail${matchChangeDetailId}`
      loadingDetail[loadKey] = true
      try {
        const _matchChangeDetail = await getMatchController().getChangeDetail({ matchChangeDetailId, matchId: match.matchId }, api.noMonitor)
        setMatchChangeDetail(_matchChangeDetail)
      } finally {
        loadingDetail[loadKey] = false
      }
    }
  }

  function setMatchChangeDetail(change) {
    // if it's already merged in or rejected, compare it to its previous state. otherwise, it's a proposed change that should be compared to the latest
    const proposed = !change.merged && change.rejectedDate == null
    matchChangeDetailToCompareTo = proposed ? match : changeFromPrev(match, change.changes.previousState)
    matchChangeDetail = change
  }
</script>

<style lang="scss">
  @import '../../css/helpers';

  .activity-container {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    padding-left: 25px;
    padding-right: 20px;
    background-color: #fff;
    width: 25%;
    max-height: 100vh;

    .activity {
      max-height: calc(100vh - 370px);
      overflow-y: auto;

      .activity-item {
        display: flex;
        margin-bottom: 10px;

        .text {
          display: inline-block;
          padding-right: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .activity-container {
      position: relative;
      width: 100%;
      z-index: unset;
      padding-top: 0;
      right: unset;
      top: unset;
      bottom: unset;
    }

    .activity {
      max-height: none;
    }
  }
</style>
