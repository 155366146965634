<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="select{className ? ` ${className}` : ''}"
  class:inline
  class:disabled
  class:text-style={textStyle}
  bind:this={container}
  data-test={name}
  id={name}
>
  <div
    class={textStyle ? 'input-base form-control' : `btn btn-${color} g05`}
    class:btn-sm={sm}
    class:disabled
    data-test="{name}-btn"
    class:open={isOpen}
    tabindex={disabled ? null : '0'}
    use:tip={{ content: title, options: { placement: tooltipPlacement ?? 'top', trigger: 'mouseenter' } }}
    on:click={open}
    on:focus={() => (keyFrom = 'button')}
    on:keydown={keyListener}
    bind:this={btnElem}
  >
    <div class="input-select-content{contentClass ? ` ${contentClass}` : ''}">
      <slot name="prefix" />

      {#if !validator.empty(prefixLabel) && (!_selectedOptions.length || (!multiple && optionValue(_selectedOptions[0])))}{prefixLabel}{/if}

      {#if loading}
        <div class="text-center">
          <Spinner sm />
        </div>
      {/if}

      <FriendlyList
        items={_selectedOptions}
        totalCount={totalSelectedCount}
        let:item={option}
        {punctuation}
        {or}
        toggleable={false}
        {max}
        {showOthersTip}
        {othersTipClass}
        othersTipOptions={{ theme: 'light-gray-scrollable' }}
      >
        <span class="select-input-text{selectInputTextClass ? ` ${selectInputTextClass}` : ''}">
          <slot {option} name="label">
            <slot {option}><SafeHtml value={optionLabel(option)} /></slot>
          </slot>
        </span>

        <div slot="other">
          <slot {option} name="labelOther">
            <slot {option} name="label">
              <slot {option}>
                <SafeHtml value={optionLabel(option)} />
              </slot>
            </slot>
          </slot>
        </div></FriendlyList
      >

      {#if !_selectedOptions?.length}<span class="select-input-text{selectInputTextClass ? ` ${selectInputTextClass}` : ''}"
          ><slot name="placeholder">{placeholder ?? ''}</slot></span
        >{/if}
    </div>

    <span class="dropdown-icon" class:ml05={!contentClass?.includes('flex')}>
      <Icon name="caret-down" />
    </span>
  </div>

  {#if isOpen && !disabled}
    <div
      class="select-dropdown{selectDropdownClass ? ` ${selectDropdownClass}` : ''}"
      bind:this={dropdownElem}
      use:popper={{ reference: btnElem }}
      data-test={name ? `${name}-items` : null}
    >
      {#if multiple}
        <div class="flex-row flex-justify-center">
          <ShowAllSelectedUnselectedPicker bind:value={showFilter} class="m05" on:tab-off-end={closeIfNotFilterable} on:change={onShowFilterChange} />
        </div>
      {/if}

      {#if filterable}
        <div class="filter">
          <div class="input-group">
            <InputText
              bind:value={filter}
              name="{name}-filter"
              placeholder={filterPlaceholder}
              autofocus
              on:focus={() => (keyFrom = 'filter')}
              on:keydown={keyListener}
              on:input={() => loadPageIfServerPaginatingDebounced(0, filter)}
            />
            <a class="input-group-addon" on:click={clearFilter} href={null} tabindex="-1" data-test={name ? `${name}-clear-btn` : ''}>
              <Icon name="close" fw />
            </a>
          </div>
        </div>
      {/if}

      {#if !optionsRendered?.length}
        {#if filter?.length > 0}
          <Alert type="warning">No {showFilter ? 'selected' : showFilter === false ? 'unselected' : ''} options match “{filter}”</Alert>
        {:else if showFilter != null}
          <Alert type="warning">No {showFilter ? 'selected' : showFilter === false ? 'unselected' : ''} options</Alert>
        {:else if loading}
          <!-- Made to match <InfiniteScroll>'s spinner in size, but not margin (as there will be no results) -->
          <div class="text-center">
            <Spinner x3 class="m1" />
          </div>
        {/if}
      {:else}
        <InfiniteScroll
          bind:this={infiniteScroller}
          {currentCount}
          totalCount={_totalCount}
          paused={showFilter}
          loadPage={offset => {
            if (showFilter) return
            return loadPage(offset, filter, showFilter)
          }}
          class="select-dropdown-items-container"
          {distanceToLoadPage}
        >
          <div class="select-dropdown-items-container-wrapper">
            {#each optionsRendered as option, index (optionKey(option))}
              <div
                bind:this={optionElems[index]}
                class="item{option.class ? ` ${option.class}` : ''}"
                class:selected={valueSet.has(optionValue(option))}
                class:viewing={viewIndex == index}
                class:disabled={optionDisabled(option)}
                style={option.style}
                on:click={() => toggleIfEnabled(option, index)}
              >
                <slot {option}>
                  <SafeHtml value={optionLabel(option)} />
                </slot>
              </div>
            {/each}
          </div>
        </InfiniteScroll>
      {/if}
    </div>
  {/if}
</div>

<script>
  import { tick, getContext, createEventDispatcher } from 'svelte'
  import { toLowerSpaceCollapsed } from 'services/string-utils.js'
  import Alert from 'components/bootstrap/Alert.svelte'
  import FriendlyList from 'components/FriendlyList.svelte'
  import Icon from 'components/Icon.svelte'
  import InfiniteScroll from 'components/InfiniteScroll.svelte'
  import InputText from 'components/fields/InputText.svelte'
  import Key from 'config/key.js'
  import optionBuilder from 'services/option-builder.js'
  import popper from 'decorators/popper.js'
  import SafeHtml from 'components/SafeHtml.svelte'
  import ShowAllSelectedUnselectedPicker from 'components/fields/ShowAllSelectedUnselectedPicker.svelte'
  import Spinner from 'components/Spinner.svelte'
  import tip from 'decorators/tip.js'
  import validator from 'services/validator.js'

  const dispatch = createEventDispatcher()

  export let name = null
  export let multiple = false
  export let prefixLabel = ''
  export let textStyle = false
  export let autofocus = false
  export let punctuation = false
  export let or = false
  export let max = 3 // max number of items to show in "placeholder" when multiple values selected
  export let showOthersTip = false
  export let othersTipClass = null
  export let placeholder = '' // placeholder for the main input
  export let title = null
  export let tooltipPlacement = null
  export let options = null
  export let valueSelector = optionBuilder.defaultValueSelector
  export let keySelector = valueSelector
  export let labelSelector = optionBuilder.defaultLabelSelector
  export let disabledSelector = optionBuilder.defaultDisabledSelector
  export let filterStringSelector = optionBuilder.defaultLabelSelector
  $: nValueSelectors = optionBuilder.normalizeSelectors(valueSelector)
  $: nKeySelectors = optionBuilder.normalizeSelectors(keySelector)
  $: nLabelSelectors = optionBuilder.normalizeSelectors(labelSelector)
  $: nDisabledSelectors = optionBuilder.normalizeSelectors(disabledSelector)
  $: nFilterStringSelectors = optionBuilder.normalizeSelectors(filterStringSelector)
  const optionValue = o => optionBuilder.selectFromOption(o, nValueSelectors, value => value != null, null)
  const optionKey = o => optionBuilder.selectFromOption(o, nKeySelectors, value => value != null, null)
  const optionLabel = o => optionBuilder.selectFromOption(o, nLabelSelectors)
  const optionDisabled = o => (_.isObject(o) ? optionBuilder.selectFromOption(o, nDisabledSelectors, value => value != null, false) : false)
  // For this, instead of getting the first non-null value, get all values because
  // we want to search them to see if an option matches the filter.
  const optionFilterStrings = o => (_.isString(o) ? [o] : nFilterStringSelectors.map(selector => selector(o)))

  // pass either a simple value, or an array of values if multiple
  export let value = multiple ? [] : null

  // for binding up to parent the selected
  export let selected

  export let isOpen = false // programmatically open if you want
  export let disabled = false
  export let resetDirtyWhenDisabled = false

  export let color = 'default'
  let className = ''
  export { className as class } // class will get added to the form-control, for if you want to do form-control-lg, form-control-sm, etc to match the rest of your form
  export let contentClass = punctuation ? null : 'flex-row flex-align-center g05'
  export let selectInputTextClass = punctuation ? null : 'flex-row flex-align-center g05'
  export let selectDropdownClass = 'max-width-500'
  export let inline = false // whether to display it inline
  export let sm = false
  export let filterable = false
  export let filter = '' // you can also pass filter to preemptively filter options
  export let filterPlaceholder = 'Search' // placeholder for the filter input
  export let showFilter = null // Show all = null, selected = true, unselected = false
  export let selectedOptions = [] // server-side infinite-scroll
  export let pageSize = 20
  export let totalCount = null
  export let loading = false

  let endIndex = pageSize
  export let loadPage = (offset /*, search*/) => {
    endIndex = offset + pageSize
  }
  export let distanceToLoadPage = 300

  const loadPageIfServerPaginatingDebounced = _.debounce((offset, search) => {
    if (!isServerPaginating) return // only debounce search when server-paginating...
    if (showFilter) return // No reason to get a page if we're showing selected (but later on, consider paginating selected too if calling code is only passing a subset of the selectedOptions)
    return loadPage(offset, search, showFilter)
  }, 300)

  $: if (autofocus && btnElem) focus()

  export function focus() {
    btnElem?.focus?.()
  }

  const optionElems = []
  const initialValue = value
  const markDirty = getContext('markDirty')

  let optionsRendered
  let previousKey = null
  let container = null
  let btnElem = null
  let dropdownElem = null
  let infiniteScroller = null
  let wasOpen = isOpen
  let wasClosedAfterOpen = false

  $: wasOpen ||= isOpen
  $: wasClosedAfterOpen ||= !isOpen && wasOpen
  $: if (disabled && resetDirtyWhenDisabled) {
    wasOpen = false
    wasClosedAfterOpen = false
    markDirty(false)
  }
  $: if (markDirty != null && (wasClosedAfterOpen || (value != null && !validator.equals(value, initialValue)))) markDirty()

  $: valueSet = new Set(multiple && Array.isArray(value) ? value : [value]) // note that [null] could be valid if an option value is null
  $: isServerPaginating = totalCount != null
  // Unlike Input(Checkbox|Radio)Group, we do not need to wrap this in a function to prevent infinite reactivity.
  // If we add a bind:this={inputs[i]} like those two, this issue will apply: https://github.com/sveltejs/svelte/issues/5719
  $: searchFilteredOptions = isServerPaginating || !filterable ? options ?? [] : filterOptions(options, filter)
  $: filteredOptions =
    showFilter == null
      ? searchFilteredOptions
      : showFilter
        ? searchFilteredOptions.filter(o => valueSet.has(optionValue(o)))
        : searchFilteredOptions.filter(o => !valueSet.has(optionValue(o)))
  let viewIndex = -1 // option we're currently viewing w/keyboard navigation
  // keep viewIndex within filteredOptions length
  $: showFilter, (viewIndex = -1)
  $: {
    if (viewIndex > filteredOptions.length - 1) viewIndex = filteredOptions.length - 1
    if (viewIndex < -1) viewIndex = -1
  }
  // when loading from server, `options` might not contain all selected options, so calling code must pass them in initially
  $: currentCount = isServerPaginating ? _.size(options) : endIndex
  $: _totalCount = isServerPaginating ? totalCount : filteredOptions?.length ?? 0
  $: _selectedOptions = isServerPaginating ? selectedOptions ?? [] : (options ?? []).filter(option => valueSet.has(optionValue(option)))
  $: _selectedOptions, multiple, updateSelected()
  $: allSelectedOptionsAreLoaded = multiple ? valueSet.size === _selectedOptions.length : true
  $: totalSelectedCount = !allSelectedOptionsAreLoaded && isServerPaginating && multiple ? valueSet.size : null // calling code might only pass a subset of the selectedOptions, but we still need to show how many total are selected, so use the `value` length
  // if we're rendering only the selected options, we have to render _all_ selected if it's server-side paginated
  $: optionsRendered =
    (showFilter ? filterOptions(_selectedOptions, filter) : isServerPaginating ? filteredOptions : filteredOptions.slice(0, endIndex)) ?? []

  function filterOptions(options, filter) {
    if (options == null) return []
    const normalizedFilter = toLowerSpaceCollapsed(filter)
    if (!normalizedFilter) return options
    return options.filter(o => {
      const toSearch = optionFilterStrings(o)
      return toSearch.some(str => (toLowerSpaceCollapsed(str) ?? '').includes(normalizedFilter))
    })
  }

  function toggleIfEnabled(option, setViewIndex) {
    if (optionDisabled(option)) return
    toggle(option, setViewIndex)
  }

  function toggle(option, setViewIndex) {
    const oValue = optionValue(option)
    if (multiple) {
      // Keep selected options in same order regardless of when toggled by user.
      // Using == instead of === to be consistent with optionBuilder's `selected` logic.
      const isSelected = valueSet.has(oValue)
      const valueArray = value == null ? [] : Array.isArray(value) ? value : [value]
      value = isSelected ? valueArray.filter(v => v != oValue) : valueArray.concat(oValue)
      dispatch('toggle', { option, isSelected: !isSelected }) // so calling code can know _which_ value was _just_ selected or de-selected and potentially modify the selection further for instance

      // if user clicked an option in multi-select, refocus the fakeField
      focusField()
    } else {
      value = oValue
      close(true)
    }
    if (setViewIndex != null) viewIndex = setViewIndex
    dispatch('change', value)
  }

  export async function open() {
    if (disabled || isOpen) return
    isOpen = true
    const selected = multiple ? (value?.length ? value[0] : null) : value
    // TODO: probably just reset it--awkward for big lists. Could scroll to it if not server-side paginating too.
    viewIndex = filteredOptions.findIndex(o => optionValue(o) === selected)
    document.addEventListener('mousedown', clickListener)
    document.addEventListener('touchstart', clickListener)
    dispatch('open')
    await tick()
    if (isOpen && !filterable) focusField()
  }

  function close(shouldFocus = false) {
    if (!isOpen) return
    // focus the non-field so tabbing/shift-tabbing works after closing by selecting an option
    if (shouldFocus) focusField()
    isOpen = false
    previousKey = null
    document.removeEventListener('mousedown', clickListener)
    document.removeEventListener('touchstart', clickListener)
    dispatch('close')
  }

  function closeIfNotFilterable() {
    if (!filterable) close()
  }

  let keyFrom = null
  function keyListener(e) {
    const key = e.which || e.keyCode

    // if tabbing out in either direction, close and let them out
    if (key === Key.Tab) {
      if ((e.shiftKey && keyFrom === 'button') || (!e.shiftKey && (keyFrom === 'filter' || (!filterable && !multiple)))) {
        close()
        return
      }
    }

    // otherwise, if we're not open, almost any key should open
    if (!isOpen) {
      // except shift, so shift-tab doesn't open before closing immediately anyway
      // and up/escape, cuz they feel weird
      if (key === Key.Shift || key === Key.Up || key === Key.Escape) return
      e.preventDefault() // Do this first to avoid `await tick()` accidentally completing the event handlers.
      open()
      return
    }

    // otherwise, handle a few keys for navigating options and toggling them
    switch (key) {
      case Key.Escape:
        e.stopImmediatePropagation()
        close(true)
        break
      case Key.Space:
      case Key.Enter:
        // the user might actually intend to type a space character in the filter box instead of select/deselect an item, so handle that
        const userIntendsToToggle = !filterable || [Key.Up, Key.Down, Key.Space, Key.Enter].includes(previousKey)
        if (userIntendsToToggle && viewIndex != null && filteredOptions[viewIndex] != null && !filteredOptions[viewIndex].disabled) {
          toggle(filteredOptions[viewIndex])
          e.preventDefault()
        }
        break
      case Key.Up:
        e.preventDefault() // Do this first to avoid `await tick()` accidentally completing the event handlers.
        handleUpKey()
        break
      case Key.Down:
        handleDownKey(e)
        break
    }
    previousKey = key
  }

  function handleUpKey() {
    viewIndex--
    let option
    while ((option = filteredOptions[viewIndex])) {
      if (!option.disabled) break
      viewIndex--
    }
    // If we couldn't find an (enabled) option, interpret the intent as wanting to close the dropdown.
    if ((filterable && viewIndex <= -2) || (!filterable && viewIndex <= -1)) {
      close(true)
    } else {
      optionElems[viewIndex]?.scrollIntoView({ behavior: 'auto' })
    }
  }

  function handleDownKey(e) {
    if (isOpen && viewIndex < filteredOptions.length - 1) {
      e.preventDefault()
      const currentViewIndex = viewIndex
      viewIndex++
      let option
      while ((option = filteredOptions[viewIndex])) {
        if (!option.disabled) break
        viewIndex++
      }
      // If we couldn't find an enabled option, just ignore the input.
      if (viewIndex >= filteredOptions.length) viewIndex = currentViewIndex
      optionElems[viewIndex]?.scrollIntoView({ behavior: 'auto' })
    }
  }

  function clickListener(e) {
    if (e.target.closest == null || (e.target.closest('.select') !== container && e.target.closest('.select-dropdown') !== dropdownElem)) {
      close()
    }
  }

  function focusField() {
    btnElem?.focus?.()
  }

  function clearFilter() {
    if (filter === '' || filter == null) return // Prevent redundant binding updates/XHRs
    filter = ''
    if (isServerPaginating) loadPage(0, filter, showFilter)
  }

  function onShowFilterChange() {
    if (!allSelectedOptionsAreLoaded && isServerPaginating && multiple) {
      infiniteScroller?.scrollTo(0)
      loadPage(0, filter, showFilter)
    }
    // dispatch(showFilter === null ? 'show-all' : showFilter ? 'show-selected' : 'show-unselected')
  }

  function updateSelected() {
    selected = multiple ? _selectedOptions : _selectedOptions.length ? _selectedOptions[0] : null
  }
</script>
