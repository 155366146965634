{#if stepSubmission}
  <Modal showClose={false}>
    <span slot="title">
      {#if otherCount !== 0}
        <h4 data-test="other-step-submissions">
          Would you like to
          <span class="text-{actionColor}">{actionDesc}</span>
          “{stepSubmission.step.name}” on
          {#if loading}
            other rotations
          {:else if multipleOthers}
            these other {otherCount} rotations
          {:else}
            this other rotation
          {/if}
          too?
        </h4>
      {/if}
    </span>

    <div class="modal-body">
      {#if loading}
        <Spinner />
        <span class="help-block-inline">
          Looking for other active <Icon lg autoColor name="rotation" /> rotations where “{stepSubmission.step.name}”
          <StepCompleterExplanation {stepSubmission} lowercase />…
        </span>
      {:else if otherCount === 0}
        <HelpBlock>
          No other active <Icon lg autoColor name="rotation" /> rotations where “{stepSubmission.step.name}”
          <StepCompleterExplanation {stepSubmission} lowercase />
        </HelpBlock>
      {:else}
        {#if multipleOthers}
          <div class="mb1">
            {#if allSelected}
              <Btn class="btn-sm" disabled={applying} on:click={deselectAll}>Deselect all</Btn>
            {:else}
              <Btn class="btn-sm btn-primary" disabled={applying} on:click={selectAll}>Select all</Btn>
            {/if}
          </div>
        {/if}
        {#each otherSubmissions as ss (ss.submissionKeyGuaranteedUnique)}
          <StepSubmissionRowWithMatchInfo
            bind:checked={selectedSubmissions[ss.submissionKey]}
            {multipleOthers}
            stepSubmission={ss}
            {matchModalMatchId}
            {onActionCompleted}
            disabled={applying}
          />
        {/each}
      {/if}
    </div>

    <div class="modal-footer">
      {#if otherSubmissions != null && otherCount > 0}
        <Btn
          on:click={applyToSelected}
          loading={applying}
          disabled={selectedSubmissionsCount === 0 || loading}
          class="btn-primary"
          dataTest="step-action-apply"
        >
          Yes,
          {actionDesc}
          {#if loading}
            them
          {:else if multipleOthers}
            {#if allSelected}all{:else}selected {selectedSubmissionsCount}/{otherCount}{/if}
          {:else}it{/if}
        </Btn>
      {/if}

      <Btn on:click={onClose} disabled={applying} dataTest="step-action-close">
        {#if loading}
          Leave other submissions how they are
        {:else if otherCount === 0}
          Close
        {:else}
          No, just this rotation
        {/if}
      </Btn>
    </div>
  </Modal>
{/if}

<script>
  import { pluralCount } from 'services/string-utils.js'
  import { StepAction } from 'config/enums.js'
  import { toServerModel } from 'services/step-submission-service.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Icon from 'components/Icon.svelte'
  import Modal from 'components/Modal.svelte'
  import Spinner from 'components/Spinner.svelte'
  import StepCompleterExplanation from 'components/StepCompleterExplanation.svelte'
  import StepSubmissionRowWithMatchInfo from 'components/StepSubmissionRowWithMatchInfo.svelte'
  import toaster from 'services/toaster.js'

  export let stepAction
  export let matchModalMatchId
  export let onClose
  export let onActionCompleted

  let applying = false
  let selectedSubmissions = {}

  $: loading = stepAction.loading
  $: action = stepAction.action
  $: stepSubmission = stepAction.stepSubmission // TODO: prod says this can be null sometimes. determine why and make it not be null...for now, just check null.
  $: otherSubmissions = stepAction.otherSubmissions
  $: otherCount = otherSubmissions?.length ?? 0
  $: multipleOthers = otherCount > 1
  $: actionDescs = getActionDescriptions(action.type)
  $: actionDesc = actionDescs[0]
  $: actionDescPastTense = actionDescs[1]
  $: actionColor = getActionColor(action.type)
  $: if (otherCount != null) selectAll() // select all when initially open up
  $: selectedSubmissionsCount = Object.keys(selectedSubmissions).filter(k => selectedSubmissions[k]).length
  $: allSelected = selectedSubmissionsCount === otherSubmissions?.length

  // Returns future and past tense descriptions for the given action type.
  function getActionDescriptions(type) {
    switch (type) {
      case StepAction.AddFile:
        return ['add this file to', 'added this file to']
      case StepAction.DeleteFile:
        return ['remove this file from', 'removed this file from']
      case StepAction.SaveForm:
        return ['apply these changes to', 'applied these changes to']
      case StepAction.Reject:
        return ['reject', 'rejected']
      case StepAction.Verify:
        return ['verify', 'verified']
      case StepAction.Waive:
        return ['waive', 'waived']
      case StepAction.UnWaive:
        return ['unwaive', 'unwaived']
      case StepAction.Skip:
        return ['skip', 'skipped']
      case StepAction.Unskip:
        return ['unskip', 'unskipped']
      case StepAction.SetDueDate:
        return ['change the due date for', 'changed the due date for']
      default:
        return ['apply this change to', 'applied this change to']
    }
  }

  function getActionColor(type) {
    switch (type) {
      case StepAction.Reject:
        return 'danger'
      case StepAction.Verify:
        return 'success'
      case StepAction.SetDueDate:
      case StepAction.Waive:
      case StepAction.UnWaive:
        return 'warning'
      case StepAction.Skip:
      case StepAction.Unskip:
        return 'info'
      default:
        return null
    }
  }

  async function applyToSelected() {
    const submissions = otherSubmissions.filter(sub => selectedSubmissions[sub.submissionKey])
    const actionPastTense = actionDescPastTense
    applying = true
    try {
      await action.invoke(submissions.map(toServerModel), true)
      toaster.toast({
        message: `Successfully ${actionPastTense} “${stepSubmission.step.name}” on ${pluralCount('other rotation', submissions)}`,
        type: 'success',
        icon: 'check',
      })
      onClose()
    } finally {
      applying = false
    }
  }

  function selectAll() {
    if (applying) return
    selectedSubmissions = {}
    otherSubmissions.forEach(sub => (selectedSubmissions[sub.submissionKey] = true))
  }

  function deselectAll() {
    if (applying) return
    selectedSubmissions = {}
  }
</script>
