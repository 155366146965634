import validator from 'services/validator.js'

// @id => @user-name
export function parseUserIdMentionsToUserNames(content, mentionableUsers) {
  if (validator.empty(content) || mentionableUsers.length === 0) return content
  const userIdMentionRegex = /@([A-Z0-9]{8}-(?:[A-Z0-9]{4}-){3}[A-Z0-9]{12})/gi
  return content.replace(userIdMentionRegex, (match, userId) => {
    const user = mentionableUsers.find(u => u.userId === userId)
    if (user == null) return match
    return `@${user.userName}`
  })
}
