<!-- TODO: show diffs maybe in a <slot>, so calling code explains how to show the diff -->
{#if conflict != null}
  <div in:slide class="mb1">
    <Alert type="warning">
      <div class="text-center">{conflict.description}</div>
      <div class="text-center m2">
        <Btn on:click={() => (showDiffs = !showDiffs)} class="btn-primary btn-sm" dataTest="toggle-diff">
          {#if showDiffs}Hide{:else}Show{/if}
          differences
        </Btn>
        <Btn
          on:click={() => {
            local = remote
            conflict = undefined
            dispatch('discard')
          }}
          class="btn-warning btn-sm"
          confirm={{
            title: 'Discard unsaved changes',
            message: `Are you sure you’d like to discard your unsaved changes to <strong>${conflict.keyUIName}</strong>?`,
            confirmLabel: 'Discard changes',
            confirmClass: 'btn-danger',
          }}
          dataTest="discard-mine"
        >
          Discard my unsaved changes
        </Btn>
        <Btn
          on:click={() => (conflict = undefined)}
          class="btn-sm"
          confirm={{
            title: 'Keep unsaved changes',
            message: `If you keep your changes to <strong>${conflict.keyUIName}</strong> and save your changes, you may overwrite changes someone else made.<br /><br />Are you sure?`,
            confirmLabel: 'Keep changes',
            confirmClass: 'btn-warning',
          }}
          dataTest="keep-mine"
        >
          Keep my unsaved changes
        </Btn>
      </div>
      {#if showDiffs}
        <div transition:slide|local>
          <slot>
            <DiffMerger newVersion={remote != null ? remote.toString() : ''} oldVersion={local != null ? local.toString() : ''} conflicted />
          </slot>
        </div>
      {/if}
    </Alert>
  </div>
{/if}

<script>
  import Alert from 'components/bootstrap/Alert.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import DiffMerger from 'components/fields/DiffMerger.svelte'
  import { createEventDispatcher } from 'svelte'
  import { slide } from 'svelte/transition'

  const dispatch = createEventDispatcher()

  export let remote = null
  export let local = null
  export let conflict = null
  let showDiffs = false
</script>
