<ButtonModalPicker
  bind:this={buttonModalPicker}
  bind:open
  lg
  {disabled}
  {multiple}
  {hideSelectAll}
  valueSelector={o => o.orgId}
  labelSelector={o => o.name}
  options={orgs}
  optionsForLabel={orgsForLabel}
  bind:value
  bind:valueTemp
  {modalTitle}
  {placeholder}
  class={className}
  {dataTest}
  modalClass="overflow-visible"
  modalContentClass="overflow-visible"
>
  <svelte:fragment slot="buttonLabel">
    {#if selectedOrg}
      <OrgProfilePictureAndName
        name={selectedOrg.name}
        profilePicture={selectedOrg.profilePicture}
        relativeName={selectedOrg.relativeName}
        class="flex-row flex-align-center nowrap flex-grow overflow-hidden"
        {useOverflowEllipsis}
      />
    {:else}
      <span class="button-modal-picker-text">
        {placeholder}
      </span>
    {/if}
    <Icon name="caret-down" />
  </svelte:fragment>

  <!-- We don't want the contents of the picker to appear invalid, so wrap it in an always-valid <FormGroup> -->
  <FormGroup valid class="full-height flex-column g1">
    <OrgFilters fullWidth {excludedFilterTypes} bind:filters {interceptors} {featureTypes} {capacityOwnerOrgId}>
      <svelte:fragment slot="after-keyword-search">
        <OrgPickerShowDropdown {featureTypes} />
      </svelte:fragment>
    </OrgFilters>
    <slot name="additional-filter" />

    {#if loading && currentXhrBody.offset === 0}
      <div class="text-center mt1">
        <Spinner x3 class="m2" />
      </div>
    {:else}
      <InfiniteScroll
        currentCount={orgs?.length}
        {totalCount}
        distanceToLoadPage={100}
        {loadPage}
        class="scrollable-lg flex-grow flex-column {showMinimal ? 'g05' : 'g1'}"
        style="padding-right: 15px"
        dataTest={dataTest + '-items'}
      >
        <!-- TODO(OrgRelationship): Fix the icon and icon color here. -->
        <EmptyPickerSlot
          bind:valueTemp
          {allowSelectNull}
          text={emptyPickerText}
          {multiple}
          {buttonModalPicker}
          iconClass="color-text-steel"
          icon={emptyPickerIcon}
          {dataTest}
          lg={!showMinimal}
        />
        {#if orgs?.length}
          {#each orgs as r, i (r.orgId)}
            <PickerSlot
              {i}
              disabled={r.disabled}
              dataTest={dataTest + '-item'}
              {multiple}
              value={r.orgId}
              {buttonModalPicker}
              lg={!showMinimal}
              {disabledMessage}
              bind:valueTemp
              let:isSelected
              let:isHovered
            >
              <slot name="header" org={r} slot="header">
                <OrgProfilePictureAndName
                  name={r.name}
                  profilePicture={r.profilePicture}
                  relativeName={r.relativeName}
                  class="flex-row g1 flex-align-center"
                />
              </slot>

              <slot org={r} {isSelected} {isHovered}>
                <OrgPickerSlot org={r} {isHovered} {isSelected} {featureTypes} />
              </slot>
            </PickerSlot>
          {/each}
        {:else if orgs == null}
          <!-- This should never happen, but just in case... -->
          Failed to load organizations. <a href={null} on:click={() => loadPage(0)}>Retry?</a>
        {:else}
          <h4 class="p3 text-center">No organizations found.</h4>
        {/if}
      </InfiniteScroll>
    {/if}
  </FormGroup>
</ButtonModalPicker>

<script>
  import { FilterType, ToManyComparison } from 'config/enums.js'
  import api from 'services/api.js'
  import ButtonModalPicker from 'components/fields/ButtonModalPicker.svelte'
  import EmptyPickerSlot from 'components/EmptyPickerSlot.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Icon from 'components/Icon.svelte'
  import InfiniteScroll from 'components/InfiniteScroll.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import OrgFilters from 'components/OrgFilters.svelte'
  import OrgPickerShowDropdown from 'components/OrgPickerShowDropdown.svelte'
  import OrgPickerSlot from 'components/OrgPicker.Slot.svelte'
  import personaFilters from 'stores/persona-filters.js'
  import PickerSlot from 'components/PickerSlot.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'
  import Spinner from 'components/Spinner.svelte'
  import validator from 'services/validator.js'

  // Common picker exports
  export let value
  export let filters = []
  export let hiddenFilters = []
  export let excludedFilterTypes = []

  export let multiple = false
  export let modalTitle = multiple ? 'Select the organizations' : 'Select the organization'
  export let disabled = false
  export let allowSelectNull = false
  export let placeholder = 'None selected'
  export let emptyPickerText = 'No organization'
  export let emptyPickerIcon = null
  let className = ''
  export { className as class }
  export let dataTest = 'org-picker'
  export let interceptors = {}
  export let open = false
  export let featureTypes = null
  export let excludeHierarchyOrgIds = null
  export let excludeOrgIds = null
  export let targetOrgIds = null
  export let includeAgreementCount = false
  export let includeAddresses = false
  export let includeActiveCapacityCount = false
  export let includeStudentCount = false
  export let includeTags = false
  export let includeContactInfo = false
  export let includeDescription = false
  export let includeAncestorOrgIds = false
  export let useOverflowEllipsis = false
  export let hideSelectAll = false
  export let optionMapper = null
  export let disabledMessage = null
  export let options = null
  export let sortProperty = null

  // Specific picker exports
  export let orgsCount = null
  export let selectedOrg = null
  export let autoSelectIfOnlyOrg = false
  export let capacityOwnerOrgId = null
  export let hasEverBeenFiltered = false

  const pageSize = 15
  let buttonModalPicker = null
  let totalCount = null
  let orgs = null
  let orgsForLabel = []
  let valueTemp = null
  let loading = false
  let currentXhrBody = null

  $: personaFiltersOrgId = $personaFilters.orgId
  $: show = $showDropdowns.orgPicker
  // TODO: These should be options you can toggle on/off in the picker.
  $: show.activeCapacityCount = includeActiveCapacityCount
  $: show.studentCount = includeStudentCount

  $: showMinimal = !Object.keys(show)
    .map(k => show[k])
    .some(Boolean)

  $: allFilters = _.cloneDeep(filters ?? []).concat(_.cloneDeep(hiddenFilters ?? []))
  $: if (filters.length) hasEverBeenFiltered = true
  $: personaFiltersOrgId, featureTypes, excludeOrgIds, allFilters, loadPage(0)
  $: open, loadFirstPageIfFiltersChanged()
  $: allOrgs = orgs && orgsForLabel ? [...orgs, ...orgsForLabel] : []
  $: selectedOrg = value && allOrgs?.length ? allOrgs.find(a => a.orgId === value) : null
  $: if (autoSelectIfOnlyOrg && orgs?.length === 1) {
    value = orgs[0].orgId
    selectedOrg = orgs[0]
  }

  function loadFirstPageIfFiltersChanged() {
    if (!open || validator.equals(allFilters, currentXhrBody?.filters ?? [])) return
    if (open) loadPage(0)
  }

  async function loadPage(offset) {
    if (!personaFiltersOrgId) return
    const thisXhrBody = {
      filters: allFilters,
      pageSize,
      offset,
      capacityOwnerOrgId,
      includeAddresses,
      includeAgreementCount,
      includeContactInfo,
      includeDescription,
      includeAncestorOrgIds,
      includeTags,
      includeActiveCapacityCount,
      includeStudentCount,
    }

    if (sortProperty) thisXhrBody.sortProperty = sortProperty

    if (featureTypes?.length)
      thisXhrBody.filters.push({ type: FilterType.FeatureTypes, config: { comparison: ToManyComparison.AllOf, featureTypes } })
    if (excludeHierarchyOrgIds?.length) {
      thisXhrBody.filters.push(
        { type: FilterType.ExcludeOrgs, config: { orgIds: excludeHierarchyOrgIds } },
        { type: FilterType.HasAncestorOrg, config: { exclude: true, ancestorOrgIds: excludeHierarchyOrgIds } },
        { type: FilterType.HasDescendantOrg, config: { exclude: true, descendantOrgIds: excludeHierarchyOrgIds } }
      )
    }
    if (excludeOrgIds?.length) thisXhrBody.filters.push({ type: FilterType.ExcludeOrgs, config: { orgIds: excludeOrgIds } })
    if (targetOrgIds?.length) thisXhrBody.filters.push({ type: FilterType.TargetOrgs, config: { orgIds: targetOrgIds } })

    const selectedOrgIds = (multiple || Array.isArray(value) ? value ?? [] : [value]).filter(
      id => id != null && !orgsForLabel.some(a => a.orgId === id)
    )
    if (selectedOrgIds.length) thisXhrBody.selectedOrgIds = selectedOrgIds

    const ignoreKeys =
      selectedOrg != null &&
      (validator.equals([selectedOrg.orgId], currentXhrBody?.selectedOrgIds) || validator.equals([selectedOrg.orgId], thisXhrBody.selectedOrgIds))
        ? ['selectedOrgIds']
        : []
    if (validator.equals(currentXhrBody, thisXhrBody, ignoreKeys)) return
    loading = true
    currentXhrBody = thisXhrBody

    try {
      const task = api.org.list(thisXhrBody, api.noMonitor)
      const response = await task
      // TODO: Could handle if filters were changed prior to response being received... for now, assume server is fast enough.
      totalCount = response.totalCount
      orgs = offset ? [...orgs, ...response.orgs] : response.orgs
      if (selectedOrg && selectedOrgIds.length === 1 && !orgs.find(o => o.orgId === selectedOrgIds[0])) orgs = [selectedOrg, ...orgs]
      if (selectedOrgIds.length) orgsForLabel = [...orgsForLabel, ...(response.selectedOrgs ?? [selectedOrg])]
      if (offset === 0 && orgsCount == null) orgsCount = totalCount
      options = orgs
    } finally {
      if (validator.equals(currentXhrBody, thisXhrBody)) loading = false
    }

    if (optionMapper) {
      orgs = orgs.map(optionMapper)
    }
  }

  export function clear() {
    value = null
    valueTemp = null
  }

  export function focusAndOpen() {
    buttonModalPicker?.focusAndOpen()
  }
</script>
