<!-- Later on when we have a custom staff picker, combine this into FilterTypeUserListFilter.svelte and use svelte:component accordingly... -->
<FilterTypeListFilter bind:filter {editing} {appliedMeta} {filterOptions} let:option={user} usingCustomPicker labelSelector={o => o.name}>
  <div slot="picker" class="mt1 mb1">
    <StudentPicker bind:value={filter[appliedMeta.filterProp]} multiple or={customPickerShouldUseOr(appliedMeta, filter)} />
  </div>

  {#if component == null}
    <ProfilePic id={user.userId} initials={user.initials} name={user.name} src={user.profilePicture} medium class="mr1" />
    <span>{user.name}</span>
  {/if}

  <div slot="selectedItem" class="inline-flex-row flex-align-center g05" let:item={selectedUser}>
    {#if selectedUser}
      <ProfilePic id={selectedUser.userId} initials={selectedUser.initials} name={selectedUser.name} src={selectedUser.profilePicture} small />
      <strong>{selectedUser.name}</strong>
    {/if}
  </div>
</FilterTypeListFilter>

<script context="module">
  import { FilterType } from 'config/enums.js'
  import { meta as baseMeta, customPickerShouldUseOr } from 'components/filter-types/FilterTypeListFilter.svelte'
  import api from 'services/api'

  export const meta = {
    ...baseMeta,
    idKey: 'userId',
    filterProp: 'userIds',
    // allowEmpty: true,
    toMany: true,
    editLabel: null,

    // student picker specific
    type: FilterType.Students,
    label: 'Student',
    labelApplied: 'student',
    icon: 'graduation-cap',
    iconClass: 'color-text-teal',
    getSelected: async selectedStudentUserIds =>
      (await api.student.list({ selectedStudentUserIds, includePageOfData: false, pageSize: null, excludeTotalCount: true }, api.noMonitor))
        .selectedStudents,
    hasDoesntHave: true,
    toManySuffix: 'of the following students',
    allowEmpty: false, // currently all rotations in system will have students. in future that will change. Note that course student filter overrides this to filter to show courses with NO students though.
    filterPropType: 'string',
  }
</script>

<script>
  import ProfilePic from 'components/ProfilePic.svelte'
  import StudentPicker from 'components/fields/StudentPicker.svelte'
  import FilterTypeListFilter from 'components/filter-types/FilterTypeListFilter.svelte'

  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
  export let component = null
</script>
