import api from 'services/api.js'

let pronounSetsById = null
export async function getPronounSetById(id) {
  if (id == null) return null
  if (pronounSetsById == null) {
    const pronounSets = await loadPronounSets()
    pronounSetsById = _.keyBy(pronounSets, p => p.pronounSetId)
  }
  if (pronounSetsById[id] == null) throw new Error(`Pronoun set with id ${id} not found`)
  return pronounSetsById[id]
}

export async function loadPronounSets() {
  const pronounSets = await api.pronounSet.list() // cached client-side
  if (pronounSets?.map == null)
    throw new Error(
      `pronounSets.map is not a function. Look for error from environments.js saying it didn't find hostname maybe? Maybe a browser isn't setting hostname like I'm expecting. PronounSets starts with ${JSON.stringify(
        pronounSets
      ).substring(0, 100)}`
    )
  return pronounSets.map(p => configurePronounSet(p, null))
}

export function configurePronounSet(p, personName) {
  if (p.heShe.toLowerCase() === 'name' && personName != null) {
    const nameOwnerShip = personName.endsWith('s') ? `${personName}’` : `${personName}’s` // May need to handle more English rules later if we refer to them using their pronoun rules
    p.HeShe = personName
    p.HimHer = personName
    p.HisHer = personName
    p.HisHers = nameOwnerShip
    p.HimselfHerself = personName

    p.heShe = personName
    p.himHer = personName
    p.hisHer = personName
    p.hisHers = nameOwnerShip
    p.himselfHerself = personName
  } else {
    p.HeShe = _.capitalize(p.heShe)
    p.HimHer = _.capitalize(p.himHer)
    p.HisHer = _.capitalize(p.hisHer)
    p.HisHers = _.capitalize(p.hisHers)
    p.HimselfHerself = _.capitalize(p.himselfHerself)

    p.heShe = p.HeShe.toLowerCase()
    p.himHer = p.HimHer.toLowerCase()
    p.hisHer = p.HisHer.toLowerCase()
    p.hisHers = p.HisHers.toLowerCase()
    p.himselfHerself = p.HimselfHerself.toLowerCase()
  }
  p.examples = `${p.HeShe} ${p.HeShe === 'They' ? 'are' : 'is'} studying. I studied with ${p.himHer}. The book is ${p.hisHers}.`
  return p
}
