// Could use npm package semver but it's not worth pulling in a large package for this.
// They do have loose functions so you don't have to worry about tree-shaking, but it's not very ergonomic to use.
// For simplicity, let's just handle Major, Minor, and Patch from semver so we have compatibility with System.Version.
// System.Version calls Patch "Build" instead.
// SemVer doesn't have a Revision, MajorRevision, or MinorRevision.

class Version {
  #major = 0
  #minor = 0
  #patch = 1
  #valid = true
  #parsedFrom = ''

  constructor(major, minor, patch, valid, parsedFrom) {
    this.#major = major
    this.#minor = minor
    this.#patch = patch
    this.#valid = valid
    this.#parsedFrom = parsedFrom
  }

  get valid() {
    return this.#valid
  }

  compare(other) {
    if (this.#valid !== other.#valid) return this.#valid - other.#valid
    if (this.#major !== other.#major) return this.#major - other.#major
    if (this.#minor !== other.#minor) return this.#minor - other.#minor
    return this.#patch - other.#patch
  }

  equals(other) {
    return this.compare(other) === 0
  }

  greaterThan(other) {
    return this.compare(other) > 0
  }

  greaterThanOrEqual(other) {
    return this.compare(other) >= 0
  }

  lessThan(other) {
    return this.compare(other) < 0
  }

  lessThanOrEqual(other) {
    return this.compare(other) <= 0
  }

  toString(prefix = 'v', verbose = false) {
    if (!this.#valid) throw new Error(`Invalid version: ${this.#parsedFrom}`)
    return verbose || this.#patch > 0
      ? `${prefix}${this.#major}.${this.#minor}.${this.#patch}`
      : this.#minor > 0
        ? `${prefix}${this.#major}.${this.#minor}`
        : `${prefix}${this.#major}`
  }
}

const invalid = formatted => new Version(0, 0, 1, false, formatted)

export default {
  parse: function (formatted, requirePrefix = true) {
    if (typeof formatted !== 'string') return invalid(formatted)
    const match = formatted?.match(requirePrefix ? /^v(\d+)(?:\.(\d+))?(?:\.(\d+))?$/i : /^v?(\d+)(?:\.(\d+))?(?:\.(\d+))?$/i)
    if (match == null) return invalid(formatted)
    const major = parseInt(match[1], 10)
    const minor = parseInt(match[2] ?? 0, 10)
    const patch = parseInt(match[3] ?? 0, 10)
    return new Version(major, minor, patch, true, formatted)
  },
}
