<!--generic filter for a multi-select filter-->
{#if editing}
  {#if filterOptions == null && !usingCustomPicker}
    <div><Spinner /></div>
  {:else}
    <div class="filter-list">
      {#if !usingCustomPicker && options.length === 0}
        <div class="mb1">No filter options</div>
      {:else}
        {#if excludable}
          <IsOrIsNot
            sm
            bind:value={filter.exclude}
            isValue={false}
            isLabel={hasDoesntHave ? 'Has' : null}
            isNotLabel={hasDoesntHave ? 'Doesn’t have' : null}
          >
            {#if hasDoesntHave && appliedMeta.editLabel === null}
              <label class="m0" for="filter-editing">{labelApplied ?? label}</label>
            {/if}
          </IsOrIsNot>
        {:else if toMany}
          <strong>
            {appliedMeta.allowsAnyOrExplicitly ? 'Allows' : 'Has'}
            <ToMany sm bind:value={filter.comparison} class="inline-flex-row g1" />
            {toManySuffix}
          </strong>
        {/if}

        {#if !usingCustomPicker && !hideSearchBox && options.length > filterCountThreshold}
          <Filter bind:text={optionFilter} class="my1" autofocus />
        {/if}

        <slot name="picker">
          <InfiniteScroll
            currentCount={optionsPaged.length}
            totalCount={filteredOptions.length}
            distanceToLoadPage={100}
            {loadPage}
            class="scrollable-md mb1"
          >
            <InputCheckboxGroup
              options={optionsPaged}
              bind:value={filter[filterProp]}
              valueSelector={idKey}
              let:option
              let:previousOption
              let:i
              class={inputCheckboxGroupClass}
              autofocusFirstItem={options.length <= filterCountThreshold}
              {sortNullsFirst}
              {name}
            >
              <slot name="before-input" {option} {previousOption} {i} slot="before-input" />
              <slot {option} {previousOption} {i}>
                <SafeHtml value={labelSelector(option)} />
              </slot>
            </InputCheckboxGroup>
          </InfiniteScroll>
        </slot>
      {/if}

      {#if includeNullCheckboxLabel}
        <InputCheckbox bind:checked={includeNull} name="include-null">
          {includeNullCheckboxLabel}
        </InputCheckbox>
      {/if}

      {#if includeEmptyCheckboxLabel}
        <InputCheckbox bind:checked={filter.includeEmpty} disabled={isNoneOf} name="include-empty">
          {includeEmptyCheckboxLabel}
        </InputCheckbox>
      {/if}
    </div>
  {/if}
{:else if selectedOptions == null}
  <FilterTypeListFilterComputedLabel {filter} {appliedMeta} />
  <Icon name="spinner" spin sm />
{:else if selectedOptions.length}
  <div class="inline-flex-row flex-align-center g05">
    <FilterTypeListFilterComputedLabel {filter} {appliedMeta} />
    <div class={selectedItemsContainerClass}>
      <FriendlyList {or} punctuation boldPunctuation={false} toggleable={false} class={selectedItemClass} items={selectedOptions} let:item>
        <slot name="selectedItem" {item} simulatedValue={filter.simulatedValue}>
          <strong class:text-success={item[idKey] === filter.simulatedValue}>
            <SafeHtml value={partialDesc(labelSelector(item), 25)} />
          </strong>
        </slot>
      </FriendlyList>
    </div>
  </div>
{:else}<FilterTypeListFilterComputedLabel {filter} {appliedMeta} />{#if appliedMeta.validate(filterProp, filter, appliedMeta)}…?{/if}{/if}

<script context="module">
  import { NoArgsRemaining } from 'services/filters/index.js'
  import { ToManyComparison } from 'config/enums.js'
  import Version from 'services/filters/version.js'

  const v1 = Version.parse('v1')
  const v2 = Version.parse('v2')

  export const meta = {
    allowNull: false,
    allowEmpty: false,
    excludable: false,
    toMany: false,
    toManySuffix: '',
    or: true,

    encode(writer, appliedMeta, config) {
      // If we don't have to differentiate between v1 or v2 because of how the filter
      // is currently configured, let's save some output by not writing the version.
      // Currently only need to output v2 if includeEmpty is true or the filter prop contains null.
      if ((appliedMeta.allowEmpty && config.includeEmpty) || config[appliedMeta.filterProp]?.includes(null)) writer.writeArg(v2)
      if (appliedMeta.toMany) writer.writeArg(config.comparison)
      if (appliedMeta.excludable) writer.writeArg(config.exclude)
      writer.writeArg(config[appliedMeta.filterProp] ?? [])
      // We can omit includeEmpty if it's false, because that's the default value and this is the last data element.
      // Then the decoder can peek to see if there's another value to read.
      if (appliedMeta.allowEmpty && config.includeEmpty) writer.writeArg(config.includeEmpty)
    },

    decode(reader, appliedMeta) {
      const config = {}
      const version = reader.readMaybeVersion() ?? v1

      if (appliedMeta.excludable) {
        // Handle case where filter could've been toMany: true and is now excludable: true instead.
        const maybeExclude = reader.readIntOrBool()
        if (maybeExclude === true || maybeExclude === ToManyComparison.NoneOf) {
          config.exclude = true
        } else if (maybeExclude === false || maybeExclude === ToManyComparison.AnyOf || maybeExclude === ToManyComparison.AllOf) {
          // AllOf isn't a valid comparison for excludable filters, but we'll treat it as AnyOf as it's probably more accurate than
          // treating it as NoneOf and the UI should be updated to say "Thing is X, Y, or Z" instead of "Thing accepts X, Y, and Z"
          // when the filter is excludable.
          config.exclude = false
        } else {
          // Else it's some other integer that can't be interpreted here.
          // Would try to safely handle this but readIntOrBool would throw on other cases anyway and we haven't
          // seen any cases of this happening with any frequency in production -- people don't muck with the URL much.
          throw new Error(`Invalid value for excludable filter: ${maybeExclude}`)
        }
      } else if (appliedMeta.toMany) {
        const maybeComparison = reader.readIntOrBool()
        if (maybeComparison === true) {
          // Treat as if filter used to be configured as excludable: true, exclude: true
          config.comparison = ToManyComparison.NoneOf
        } else if (maybeComparison === false) {
          // Treat as if filter used to be configured as excludable: true, exclude: false
          config.comparison = ToManyComparison.AnyOf
        } else {
          config.comparison = maybeComparison
        }
      }
      const readMethod = appliedMeta.filterPropType === 'string' ? 'readArray' : 'readIntArray'
      if (version.lessThanOrEqual(v2)) {
        // Prior to v2, null inside the array was interpreted as includeEmpty: true
        // meta.allowNull was renamed to meta.allowEmpty so we should use allowEmpty when reading this filter
        config[appliedMeta.filterProp] = reader[readMethod](false, false, appliedMeta.allowEmpty, false)
        const index = config[appliedMeta.filterProp].indexOf(null)
        if (appliedMeta.allowEmpty) {
          config.includeEmpty = index > -1
          if (config.includeEmpty) config[appliedMeta.filterProp].splice(index, 1)
        }
      } else {
        // Whereas in v2+, null inside the array is interpreted as a null value (such as the Open (any school) CapacityGuest)
        config[appliedMeta.filterProp] = reader[readMethod](false, false, appliedMeta.allowNull, false)
        if (appliedMeta.allowEmpty) {
          const includeEmptyMaybe = reader.peekArgSafe()
          if (includeEmptyMaybe === NoArgsRemaining) config.includeEmpty = false
          else config.includeEmpty = reader.readBool()
        }
      }
      return config
    },

    create(appliedMeta) {
      const config = {
        [appliedMeta.filterProp]: [],
      }
      if (appliedMeta.excludable) config.exclude = false
      if (appliedMeta.toMany) config.comparison = ToManyComparison.AnyOf
      if (appliedMeta.allowEmpty) config.includeEmpty = false
      return config
    },

    validate(filter, filters, appliedMeta) {
      if (appliedMeta.toMany && filter.comparison === ToManyComparison.NoneOf) return true
      if (appliedMeta.allowEmpty && filter.includeEmpty) return true
      return filter[appliedMeta.filterProp]?.length > 0
    },
  }

  export const customPickerShouldUseOr = (appliedMeta, filter) =>
    appliedMeta.excludable || (appliedMeta.toMany && filter.comparison !== ToManyComparison.AllOf)
</script>

<script>
  import _filter from 'services/filter.js'
  import { partialDesc } from 'services/string-utils.js'
  import { sort } from 'services/array-utils.js'
  import Filter from 'components/Filter.svelte'
  import FilterTypeListFilterComputedLabel from 'components/filter-types/FilterTypeListFilter.ComputedLabel.svelte'
  import FriendlyList from 'components/FriendlyList.svelte'
  import Icon from 'components/Icon.svelte'
  import InfiniteScroll from 'components/InfiniteScroll.svelte'
  import InputCheckbox from 'components/fields/InputCheckbox.svelte'
  import InputCheckboxGroup from 'components/fields/InputCheckboxGroup.svelte'
  import IsOrIsNot from 'components/IsOrIsNot.svelte'
  import SafeHtml from 'components/SafeHtml.svelte'
  import Spinner from 'components/Spinner.svelte'
  import ToMany from 'components/ToMany.svelte'
  import validator from 'services/validator.js'

  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
  export let selectedItemsContainerClass = 'flex-row flex-align-center g05'
  export let selectedItemClass = null
  export let inputCheckboxGroupClass = null
  export let sortNullsFirst = true
  export let hideSearchBox = false
  export let usingCustomPicker = false
  export let labelSelector = o => o.optionLabel
  export let name = ''

  // The text filter shows up after this many options.
  // Using a variable because it affects which component gets autofocused
  // and it would be a bug if both conditions didn't agree on the same value.
  const filterCountThreshold = 5
  const pageSize = 40
  let optionsPaged = []
  let optionFilter = ''

  let includeNull = filter.comparison === ToManyComparison.NoneOf || (filter[appliedMeta.filterProp]?.includes(null) ?? false)
  let selectedOptions

  $: filterProp = appliedMeta.filterProp
  $: idKey = appliedMeta.idKey
  $: excludable = appliedMeta.excludable
  $: allowNull = !!appliedMeta.allowNull
  $: allowEmpty = !!appliedMeta.allowEmpty
  $: toMany = appliedMeta.toMany
  $: toManySuffix = appliedMeta.toManySuffix
  $: includeNullCheckboxLabel = allowNull
    ? appliedMeta.excludeNullCheckboxLabel && filter.comparison === ToManyComparison.NoneOf
      ? appliedMeta.excludeNullCheckboxLabel
      : appliedMeta.includeNullCheckboxLabel
    : null
  $: includeEmptyCheckboxLabel = allowEmpty ? appliedMeta.includeEmptyCheckboxLabel : null

  $: or =
    excludable ||
    (toMany && (filter.comparison == null || filter.comparison === ToManyComparison.AnyOf || filter.comparison === ToManyComparison.NoneOf)) ||
    (!excludable && !toMany)
  $: label = appliedMeta.label ?? null
  $: labelApplied = appliedMeta.labelApplied ?? null
  $: hasDoesntHave = appliedMeta.hasDoesntHave ?? false
  $: comparison = filter.comparison
  $: isNoneOf = comparison === ToManyComparison.NoneOf
  $: includeNull, updateFilterProp()
  $: comparison, includeEmptyIfNecessary()
  $: options = filterOptions && allowNull ? [{ [idKey]: null }, ...filterOptions] : filterOptions ?? []
  $: selectedValues = filter[filterProp] ?? []
  $: editing, selectedValues, options, setSelectedOptions()
  $: filteredOptions = validator.empty(optionFilter) ? options : _filter(options, optionFilter)
  $: filteredOptions, initPagination()

  function initPagination() {
    if (!editing) return
    optionsPaged = filteredOptions.slice(0, pageSize)
  }

  function loadPage(offset) {
    const nextPage = filteredOptions.slice(offset, offset + pageSize)
    optionsPaged = optionsPaged.concat(nextPage)
  }

  function updateFilterProp() {
    if (!toMany) return
    const withoutNull = filter[filterProp]?.filter(value => value != null) ?? []
    filter[filterProp] = includeNull ? [null, ...withoutNull] : withoutNull
    filter = filter // Reactivity
  }

  function includeEmptyIfNecessary() {
    if (allowEmpty && isNoneOf) filter.includeEmpty = true
  }

  async function setSelectedOptions() {
    if (editing) return
    if (selectedValues.length === 0) selectedOptions = []
    // There may be duplicate options when simulating a filter change, so let's map these from the values to the options
    // instead of filtering the options based on whether or not they match the values.
    // If necessary, we may want the <FiltersWrappedFilter> component to de-dupe instead. But, not worth it for now.
    selectedOptions = selectedValues?.map(v => options.find(o => o?.[idKey] === v)).filter(o => allowNull || o != null)
    selectedOptions = sort(selectedOptions, labelSelector, sortNullsFirst)
  }
</script>

<style>
  .filter-list {
    min-width: 330px;
    max-width: 600px;
  }
</style>
