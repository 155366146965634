<div use:tip={tooltip} class="flex-row card flex-align-center org-tooltip p1 card-left-thick-{guest.color} ">
  <div>
    <CapacityGuestProfilePictureAndName capacityGuest={guest} nameClass="strong text-lg" useOverflowEllipsis />
    {#if guest.agreement}
      <div class="flex-row flex-align-center g05 mt05">
        <span class="small text-gray em">via</span>
        <Icon name="agreement" autoColor />
        <span>{guest.agreement.name}</span>
        <AgreementStatusLabel status={guest.agreement.status} class={null} />
      </div>
    {:else}
      <NoAgreementSpecified />
    {/if}
  </div>
</div>

<div class="hide">
  <div bind:this={tooltipElem} class="text-left p0">
    <CapacityGuestCardDetailed isTooltip {capacity} {guest} />
  </div>
</div>

<script>
  import Icon from 'components/Icon.svelte'
  import NoAgreementSpecified from 'components/NoAgreementSpecified.svelte'
  import CapacityGuestProfilePictureAndName from 'components/CapacityGuestProfilePictureAndName.svelte'
  import CapacityGuestCardDetailed from './CapacityGuestCardDetailed.svelte'
  import AgreementStatusLabel from 'components/AgreementStatusLabel.svelte'
  import tip from 'decorators/tip.js'

  export let guest
  export let capacity

  let tooltipElem

  $: tooltip = tooltipElem
    ? {
        content: tooltipElem,
        options: {
          theme: 'light-gray-scrollable',
          interactive: true,
          placement: 'left',
        },
      }
    : null
</script>

<style>
  .opportunity-information-grid {
    padding: 0 50px;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-gap: 1rem;
  }
</style>
