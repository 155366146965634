<Modal title="Use a different opportunity" on:close={onClose}>
  <Form on:submit={onSubmit}>
    <div class="modal-body">
      {#if hasMetaData}
        <FormGroup>
          <label for="information-provided">Information provided</label>
          <div id="information-provided" class="well">
            <table data-test="api-metadata">
              {#each apiMetaDataKeys as k}
                <tr><th>{formatEnumKey(k)}</th><td><div class="ml1">{match.apiMetaData[k]}</div></td> </tr>{/each}
            </table>
          </div>
        </FormGroup>
      {/if}
      <!-- choose service/capacity -->
      <FormGroup valid={capacityId > 0}>
        <label for="capacity-picker">Which opportunity should this rotation apply to?</label>
        <CapacityPicker bind:value={capacityId} switchCapacity />
      </FormGroup>

      {#if hasMetaData && capacityId}
        <FormGroup>
          <InputCheckbox bind:checked={autoCategorize} name="autoCategorize"
            >Automatically update other rotations with the same information provided that aren’t active yet to use this opportunity too</InputCheckbox
          >
        </FormGroup>
      {/if}
    </div>
    <div class="modal-footer">
      <SubmitBtn dataTest="btn-switch-capacity" icon="save" loading={submitting} disabled={shouldLoadDefaultsAndMatchIds && matchIds == null}
        >Save</SubmitBtn
      >
      <Btn on:click={onClose}>Cancel</Btn>
    </div>
  </Form>
</Modal>

<script>
  import api from 'services/api.js'
  import { formatEnumKey } from 'services/formatters.js'
  import toaster from 'services/toaster.js'
  import Btn from './bootstrap/Btn.svelte'
  import FormGroup from './bootstrap/FormGroup.svelte'
  import SubmitBtn from './bootstrap/SubmitBtn.svelte'
  import CapacityPicker from './fields/CapacityPicker.svelte'
  import InputCheckbox from './fields/InputCheckbox.svelte'
  import Form from './Form.svelte'
  import Modal from './Modal.svelte'

  export let match
  export let onMatchChanged
  export let onClose

  let capacityId = 0
  let autoCategorize = false
  let matchIds = []
  let submitting = false

  $: apiMetaDataKeys = match.apiMetaData ? Object.keys(match.apiMetaData) : null
  $: hasMetaData = apiMetaDataKeys?.length
  $: shouldLoadDefaultsAndMatchIds = hasMetaData
  $: if (shouldLoadDefaultsAndMatchIds) setDefaultsAndMatchIds()

  async function setDefaultsAndMatchIds() {
    const capacityToApiMetaDataJson = await api.capacityToApiMetaDataJson.getCapacityToApiMetaDataJson(
      { capacityId: match.capacityId },
      match.apiMetaData,
      api.noMonitor
    )
    if (capacityToApiMetaDataJson) {
      capacityId = capacityToApiMetaDataJson.capacityId
      autoCategorize = capacityToApiMetaDataJson.autoCategorize
    }
    matchIds = await api.capacityToApiMetaDataJson.getMatchIdsByCapacityIdAndApiMetaData({ matchId: match.matchId }, api.noMonitor)
  }

  function onSubmit() {
    if (hasMetaData && autoCategorize) changeMatchCapacity(matchIds)
    else changeMatchCapacity([match.matchId])
  }

  async function changeMatchCapacity(matchIds) {
    submitting = true
    try {
      await api.match.switchCapacity({ capacityId, matchIds, autoCategorize }, api.noMonitor)
      await onMatchChanged({ details: true, activity: true })
      toaster.toast({
        message: `Saved successfully.${matchIds.length > 1 ? ` Affects ${matchIds.length} rotations` : ''}`,
        type: 'success',
        icon: 'check',
      })
      onClose()
    } finally {
      submitting = false
    }
  }
</script>
