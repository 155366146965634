const fetchOptions = {
  mode: 'cors',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
}

const environments = {
  localhost: {
    name: 'dev',
    title: 'CN Local',
    linkApi: `https://localhost:${determineLocalApiPort()}`,
    fetchOptions,
  },
  'demo.cliniciannexus.com': {
    name: 'demo',
    title: 'CN Demo',
    // TODO (LinkAPI): when we make demo use separate api and app server, this should be "demo.api.cliniciannexus.com" or whatever
    linkApi: 'https://demo.cliniciannexus.com',
    fetchOptions,
  },
  'preview.cliniciannexus.com': {
    name: 'preview',
    title: 'CN Preview',
    // TODO (LinkAPI): when we make preview use separate api and app server, this should be "preview.api.cliniciannexus.com" or whatever
    linkApi: 'https://preview.cliniciannexus.com',
    fetchOptions,
  },
  'cliniciannexusweb-stage.azurewebsites.net': {
    name: 'stage',
    title: 'CN Stage',
    // TODO (LinkAPI): when we make stage use separate api and app server, this should be "stage.api.cliniciannexus.com" or whatever
    linkApi: 'https://cliniciannexusweb-stage.azurewebsites.net',
    fetchOptions,
  },
  'app.cliniciannexus.com': {
    name: 'prod',
    title: 'Clinician Nexus',
    // TODO (LinkAPI): when we make prod use separate api and app server, this should be "api.cliniciannexus.com" or whatever
    linkApi: 'https://app.cliniciannexus.com',
    fetchOptions,
  },
}

const environment = environments[window.location.hostname]
if (environment == null) throw new Error(`"${window.location.hostname}" was not found in environments.js. Update it.`)

export const isDevEnvironment = environment.name === 'dev'
export default environment

function determineLocalApiPort() {
  const port = parseInt(window.location.port)

  // TODO (LinkAPI): use the commented out `localhostApiPort` instead when prod/demo/stage are using separate api and app servers
  //                 and update appsettings.json files' `LinkApi` accordingly too...
  // This isn't great, but good nuff for now. Basically:
  // If we're hitting app at 5001, means we're running manually locally and our api is at 8080.
  // TODO: ideally, we'd do this at build time rather than runtime. But for tests, each instance of the app would need its own environment.js file, which would be a bit of a pain to setup.

  // const localhostApiPort = 8079 + Math.abs(5000 - port) // so if 5001, then 8080, if 5002, then 8081, etc. (see cypress-ci.js)
  const localhostApiPort = port === 5001 ? 8080 : port
  return localhostApiPort
}
