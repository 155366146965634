<Filters
  bind:this={filtersComponent}
  bind:filters
  interceptors={_interceptors}
  {includedFilterTypes}
  excludedFilterTypes={_excludedFilterTypes}
  {metaMapFuncs}
  {onChanged}
  class={null}
  filterOptionsController="stepSubmissionFilterOptions"
  {label}
  name="add-step-submission-filter-btn"
  {showSearchBox}
  keywordSearchPlaceholder="Search by step name, step organization, or completer name"
/>

<script context="module">
  import { buildFilterTypesArray, buildIgnoredFilterTypesArray } from 'components/Filters.svelte'
  import { FilterType } from 'config/enums.js'

  const notForStudentPersonas = [
    FilterType.SchoolFaculty,
    FilterType.Students,
    FilterType.StudentGraduationDate,
    FilterType.ICanVerify,
    FilterType.StudentCustomTags,
  ]

  const includedFilterTypes = [
    FilterType.CompleteFromDate,
    FilterType.DueDate,
    FilterType.DateModified,
    FilterType.StepSubmissionStatus,
    // these are all handled by StepSubmissionStatus...
    // FilterType.IsCompleted,
    // FilterType.IsVerified,
    // FilterType.IsRejected,
    // FilterType.IsWaived,
    // FilterType.IsSkipped,
    FilterType.ICanComplete,
    FilterType.VerificationExpirationDate,
    FilterType.Steps,
    FilterType.OwnerOrgs,
    FilterType.StepTypes,

    // not for students
    ...notForStudentPersonas,
  ]

  const metaMapFuncs = {
    [FilterType.Students]: meta => {
      meta.allowEmpty = true
      meta.excludable = true
      meta.toMany = false
      meta.or = true
      return meta
    },
    [FilterType.SchoolFaculty]: meta => {
      meta.allowEmpty = false
      meta.excludable = true
      meta.toMany = false
      meta.or = true
      return meta
    },
    [FilterType.Steps]: meta => {
      meta.hasDoesntHave = false
      meta.toMany = false
      return meta
    },
  }

  export function buildFilterTypes(excludedFilterTypes = []) {
    const array = buildFilterTypesArray(metaMapFuncs, includedFilterTypes, excludedFilterTypes)
    const filterTypes = {}
    for (const meta of array) {
      filterTypes[meta.type] = meta
    }
    return filterTypes
  }

  export function buildIgnoredFilterTypes(excludedFilterTypes = []) {
    return buildIgnoredFilterTypesArray(includedFilterTypes, excludedFilterTypes)
  }
</script>

<script>
  import Filters from 'components/Filters.svelte'
  import persona from 'stores/persona.js'

  export let filtersComponent = null
  export let filters = []
  export let onChanged = _.noop
  export let excludedFilterTypes = []
  export let interceptors = {}
  export let label = null
  export let showSearchBox = true

  $: _interceptors = {
    ...interceptors,
  }

  $: _excludedFilterTypes = $persona.isStudent ? excludedFilterTypes.concat(notForStudentPersonas) : excludedFilterTypes
</script>
