{#if guest}
  <div class="m0 p1 {isTooltip ? '' : `card card-left-thick-${guest.color}`}" style="width: fit-content">
    {#if !isTooltip}
      <CapacityGuestProfilePictureAndName capacityGuest={guest} nameClass="strong text-lg" useOverflowEllipsis />

      {#if guest.agreement}
        <div class="flex-row flex-align-center g05 mt05" data-test="capacity-guest-card-agreement-name">
          <span class="small text-gray em">via</span>
          <Icon name="agreement" autoColor />
          <span>{guest.agreement.name}</span>
          <AgreementStatusLabel status={guest.agreement.status} class={null} />
        </div>
      {:else if guest.guestOrgId}
        <NoAgreementSpecified />
      {:else}
        <div />
      {/if}
    {/if}
    <div class="{columnView ? 'flex-column flex-align-start' : 'flex-row'} g2">
      <div>
        <p class="mb05 mt05"><strong>General</strong></p>
        {#if guest.allowsAddingPlaceholderStudents}
          <div class="text-grid">
            <div style="grid-area: icon;">
              <Icon name="check" />
            </div>
            <div class="text-left" style="grid-area: text;">
              <div>Allows placeholder students</div>
              <em>Does not require real students before start</em>
            </div>
          </div>
        {:else}
          <div class="text-grid">
            <div style="grid-area: icon;">
              <Icon name="cancel" />
            </div>
            <div style="grid-area: text;">
              <div>
                <strong>DOESN’T</strong> allow placeholder students
              </div>
              <em>Requires real students before start</em>
            </div>
          </div>
        {/if}
        <div class={columnView ? 'flex-column flex-align-start' : 'flex-row'}>
          <div class="text-grid flex-align-start">
            <div style="grid-area: icon;">
              <CapacityGroupSizeIcon {capacity} />
            </div>
            <div style="grid-area: text;">
              {#if capacity.maxMatches}
                {#if guest.guaranteedMatchCountGranted}
                  <div>
                    {readableNumber(guest.matchCount)} / {guest.guaranteedMatchCountGranted}
                    guaranteed rotations
                  </div>
                  {#if guest.autoReleaseUnscheduledMatchesWhen === AutoReleaseUnscheduledMatchesWhen.Never}
                    <em>Never release rotations</em>
                  {:else if guest.autoReleaseUnscheduledMatchesWhen === AutoReleaseUnscheduledMatchesWhen.Confirmed}
                    <em>Allow manual release</em>
                  {:else if guest.autoReleaseUnscheduledMatchesWhen === AutoReleaseUnscheduledMatchesWhen.PastConfirmDesiredMatchCountByDate}
                    <em>Auto-release after confirmation deadline</em>
                  {:else if guest.autoReleaseUnscheduledMatchesWhen === AutoReleaseUnscheduledMatchesWhen.PastSchedulingEndDate}
                    <em>Auto-release after request end date</em>
                  {/if}
                {:else if isHealth}
                  <div>No guaranteed rotations</div>
                {/if}
              {/if}
              <div class="flex-row flex-align-center g05">
                {readableNumber(guest.matchCount)} / <Unlimited value={guest.maxMatches} />
                max rotations
              </div>
              {#if capacity.allowGroups}
                <div class="flex-row flex-align-center g05">
                  {readableNumber(guest.studentCount)} / <Unlimited value={guest.maxStudents} />
                  max students
                </div>
              {/if}
            </div>
          </div>
        </div>

        <p class="mb05"><strong>Disciplines</strong></p>
        <div class="mb05">
          <CapacityDisciplines {capacity} isDetailsView />
        </div>
        <p class="mb05"><strong>Dates</strong></p>
        <StartAndEndDate startDate={guest.schedulingStartDate} endDate={guest.schedulingEndDate} />
        <div>
          <Icon name="calendar" class="color-text-blue" />
          {#if guest.confirmDesiredMatchCountByDate}
            Confirmed by {guest.confirmDesiredMatchCountByDate}
          {:else}
            No confirmation necessary
          {/if}
        </div>
      </div>
      <div>
        {#if capacity.shifts?.length}
          <p class=" flex-row flex-align-center g05 mb05">
            <strong>Windows</strong><Badge color="blackText" xs count={guestShifts?.length} />
          </p>
          {#if isTooltip}
            <ShiftCards {capacity} shifts={guestShifts} isDetailsView />
          {:else}
            <div style="overflow-y:auto; max-height:300px">
              <ShiftCards {capacity} shifts={guestShifts} isDetailsView />
            </div>
          {/if}
        {/if}
      </div>
    </div>
  </div>
{/if}

<script>
  import { AutoReleaseUnscheduledMatchesWhen, PersonaType } from 'config/enums.js'
  import { getGuestShifts } from 'services/capacity-usage.js'
  import { readableNumber } from 'services/string-utils.js'
  import AgreementStatusLabel from 'components/AgreementStatusLabel.svelte'
  import Badge from 'components/Badge.svelte'
  import CapacityDisciplines from 'components/CapacityDisciplines.svelte'
  import CapacityGroupSizeIcon from 'components/CapacityGroupSizeIcon.svelte'
  import CapacityGuestProfilePictureAndName from 'components/CapacityGuestProfilePictureAndName.svelte'
  import Icon from 'components/Icon.svelte'
  import NoAgreementSpecified from 'components/NoAgreementSpecified.svelte'
  import persona from 'stores/persona.js'
  import ShiftCards from 'components/ShiftCards.svelte'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import Unlimited from 'components/Unlimited.svelte'

  export let capacity
  export let guest
  export let isTooltip = false

  $: isHealth = $persona.personaType === PersonaType.ProviderStaff
  $: columnView = guestShifts?.length > 1 && isTooltip
  $: guestShifts = getGuestShifts(capacity.shifts, guest)
</script>

<style>
  .opportunity-information-grid {
    padding: 0 50px;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-gap: 1rem;
  }

  .text-grid {
    display: grid;
    grid-gap: 5px;
    margin-bottom: 5px;
    grid-template-areas: 'icon  text';
  }
</style>
