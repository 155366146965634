<InputSelect bind:value options={months} sm {disabled} placeholder="Month" />

<script context="module">
  const months = [
    { value: 1, name: 'January', nameShort: 'Jan', days: computeNumDays(1) },
    { value: 2, name: 'February', nameShort: 'Feb', days: computeNumDays(2) },
    { value: 3, name: 'March', nameShort: 'Mar', days: computeNumDays(3) },
    { value: 4, name: 'April', nameShort: 'Apr', days: computeNumDays(4) },
    { value: 5, name: 'May', nameShort: 'May', days: computeNumDays(5) },
    { value: 6, name: 'June', nameShort: 'Jun', days: computeNumDays(6) },
    { value: 7, name: 'July', nameShort: 'Jul', days: computeNumDays(7) },
    { value: 8, name: 'August', nameShort: 'Aug', days: computeNumDays(8) },
    { value: 9, name: 'September', nameShort: 'Sep', days: computeNumDays(9) },
    { value: 10, name: 'October', nameShort: 'Oct', days: computeNumDays(10) },
    { value: 11, name: 'November', nameShort: 'Nov', days: computeNumDays(11) },
    { value: 12, name: 'December', nameShort: 'Dec', days: computeNumDays(12) },
  ]

  function computeNumDays(month) {
    return month === 2 ? 28 : month === 4 || month === 6 || month === 9 || month === 11 ? 30 : 31
  }

  export const monthsByValue = months.reduce((acc, month) => {
    acc[month.value] = month
    return acc
  }, {})
</script>

<script>
  import InputSelect from './InputSelect.svelte'

  export let value
  export let disabled = false
</script>
