<!-- alternate start / end dates - only student can propose -->
{#if match.isEditable && match.allowChanges}
  <div class="mb2">
    {#if match.alternateDates.length < maxAlternateDates}
      <a on:click={addAlternateDates} href={null}>
        <Icon name="plus" />
        Add alternate dates
      </a>
    {/if}
    {#if match.alternateDates?.length}
      <div class="well">
        {#each match.alternateDates as ad, i (ad)}
          <FormGroup valid={validator.date(ad.startDate) && validator.date(ad.endDate)} validationMessage="Please enter valid dates.">
            <a
              class="pull-right ml1"
              on:click={() => useAlternateDates(ad)}
              href={null}
              use:tip={'Use alternate date option ' + (i + 1) + ' instead'}
            >
              Use
              <Icon name="play" />
            </a>
            <a class="pull-right" on:click={() => removeAlternateDates(ad)} href={null} use:tip={'Remove alternate date option ' + (i + 1)}>
              <Icon name="delete" class="text-danger" />
            </a>
            <label for="alternate-dates{i}-start">Alternate option {i + 1}</label>
            <MatchDatePicker
              isExisting
              bind:startDate={ad.startDate}
              bind:endDate={ad.endDate}
              bind:matchDays={match.matchDays}
              capacity={match.capacity}
              {match}
              {submitted}
              {matchInitial}
            />
          </FormGroup>
        {/each}
      </div>
    {/if}
  </div>
{:else}
  {#each match.alternateDates as ad, i (ad)}
    <FormGroup>
      <label>Alternate option {i + 1}</label>
      <div>
        <strong>{dateService.datestamp(ad.startDate)}</strong>
        to
        <strong>{dateService.datestamp(ad.endDate)}</strong>
        {#if match.isEditable}
          <a on:click={() => useAlternateDates(ad)} href={null}>
            Use
            <Icon name="play" />
          </a>
        {/if}
      </div>
    </FormGroup>
  {/each}
{/if}

<script>
  import Icon from 'components/Icon.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import dateService from 'services/date-service.js'
  import tip from 'decorators/tip.js'
  import validator from 'services/validator.js'
  import MatchDatePicker from 'components/MatchDatePicker.svelte'

  export let match
  export let matchInitial
  export let submitted

  const maxAlternateDates = 2

  function addAlternateDates() {
    const alternateDates = match != null ? match.alternateDates : null
    const capacity = match != null ? match.capacity : null
    if (capacity == null) return false
    const startDate = getMinStartDate(capacity.startDate).utc().format('M/D/YYYY')
    const endDateUnformatted = capacity.endDate ? capacity.endDate : capacity.startDate
    const endDate = getMinStartDate(endDateUnformatted).utc().format('M/D/YYYY')
    match.alternateDates = match.alternateDates.concat({
      startDate,
      endDate,
      orderNumber: alternateDates.length,
    })
  }

  function getMinStartDate(capacityStartDate) {
    // default startdate to greater of tomorrow or capacity startdate
    const tomorrow = dayjs().add(1, 'days')
    const startDate = dayjs(capacityStartDate, 'M/D/YYYY')
    return startDate.isBefore(tomorrow) ? tomorrow : startDate
  }

  function removeAlternateDates(ad) {
    match.alternateDates = getOthers(ad)
  }

  function useAlternateDates(ad) {
    match.alternateDates = [
      ...getOthers(ad),
      {
        startDate: match.startDate,
        endDate: match.endDate,
      },
    ]
    match.startDate = ad.startDate
    match.endDate = ad.endDate
  }

  function getOthers(ad) {
    return match.alternateDates.filter(a => !(a.startDate === ad.startDate && a.endDate === ad.endDate))
  }
</script>
