{#if !hideTotalCount && paginatorTotalCount > 0}
  <!--
    ideally probably show count/total if it's a filtered count and pass in a totalUnfilteredCount. but they can simply clear filters to see the total for now.
    much better than showing nothing at all and all grids
    -->
  <HelpBlock>
    {paginatorTotalCount}
    {paginatorTotalCount === 1 ? pagesOfLabelSingular : pagesOfLabel}
  </HelpBlock>
{/if}
<Paginator
  yieldPage
  items={visibleRows}
  totalCount={paginatorTotalCount}
  controls={paginatorControls}
  class={paginatorClass}
  topControlsClass={paginatorTopControlsClass}
  bottomControlsClass={paginatorBottomControlsClass}
  {name}
  bind:currentPage
  bind:pageSize
  {useDefaultPageSizeOptions}
  {pageSizeOptions}
  {pagesOfLabel}
  let:pageItems={_rows}
  itemKeySelector={rowChildrenSelector ? rowKeySelector : null}
  countContributions={rowChildrenSelector ? paginatorCountContributions : null}
>
  <div class="datagrid-container" class:loading data-test={name}>
    <table
      class="table datagrid{className ? ` ${className}` : ''}"
      class:clickable-rows={clickable}
      class:table-hover={clickable}
      class:maybe-selectable={selectedRows}
      class:has-search={hasSearch}
      class:empty={!loading && !_rows?.length}
      class:may-have-subrows={subRowsSelector}
    >
      <thead>
        {#if _showSelectedRows}
          <tr>
            <th class="row-checkbox">
              <InputCheckbox
                bind:checked={allSelected}
                on:click={allCheckboxClicked}
                name="{name}-all-checkbox"
                title={allCheckboxTitle}
                disabled={!_rows?.every(rowIsSelectable)}
              />
            </th>
            <td colspan={columnCount - 1}>
              <slot name="bulk-actions" />
            </td>
          </tr>
        {/if}
        <tr>
          {#if showRowNumber}
            <th class="row-number">#</th>
          {:else if _showSelectedRows || enableSingleRowSelection}
            <th />
          {/if}
          {#if _showHrefButton}
            <th />
          {/if}
          <slot />
        </tr>
      </thead>

      <tbody>
        {#if _rows != null}
          {#if !_rows.length}
            <tr class="empty">
              <td colspan={columnCount}>
                {#if !loading && _emptyMessage}
                  <span data-test="empty-message" class="em small">{_emptyMessage}</span>
                {/if}
              </td>
            </tr>
          {:else}
            {#each _rows as row, i (rowKey(row))}
              {@const _subRows = subRows(row)}
              {@const hasSubRows = _subRows.length && row !== _subRows}
              {@const __rows = hasSubRows ? (_subRows.length ? _subRows : [null]) : [row]}
              {@const rowspan = hasSubRows ? getCount(__rows) : null}

              <!--
                eslint reports no-unused-vars when I use keyFunc in the keyed-#each below.
                When the bug is fixed, we can make the #each more readable by not having a ternary-function-returning-expression
                and instead use a variable name like originally intended.
                https://github.com/sveltejs/svelte/issues/8109
                {@const keyFunc = hasSubRows ? subRowKey : rowKey}
              -->
              {#each __rows as subRow, j ((hasSubRows ? subRowKey : rowKey)(subRow))}
                <tr
                  use:onInteract={_showHrefButton ? null : onRowClick || href ? e => rowClicked(e, row, subRow) : null}
                  use:onMayInteract={e => (e.focusIn || e.mouseIn ? onRowMouseOver(row) : onRowMouseOut(row))}
                  use:tip={getRowTipConfig?.(row)}
                  class={cssClassify(rowClasses[rowKey(row)])}
                  class:descendant-matches-search={descendantMatchesSearchRowClasses[rowKey(row)]}
                  class:matches-search={matchesSearchRowClasses[rowKey(row)]}
                  class:on-interact={_showHrefButton ? null : href}
                  class:row-selected={selectedRow && selectedRow === rowKey(row)}
                  class:stripe={subRowsSelector ? (i + 1) % 2 : false}
                  data-test={buildRowDataTest(row)}
                >
                  {#if showRowNumber && j === 0}
                    <td {rowspan} class={_cellClassSelector(row, 'row-number')}>{1 + i + offset}</td>
                  {/if}
                  {#if _showSelectedRows && j === 0}
                    <td {rowspan} class={_cellClassSelector(row, 'row-checkbox')}>
                      <InputCheckbox
                        bind:checked={selectedRows[rowKey(row)]}
                        name="{name}-checkbox-{rowKey(row)}"
                        stopPropagation
                        disabled={!rowIsSelectable(row)}
                      />
                    </td>
                  {:else if enableSingleRowSelection && j === 0}
                    <td {rowspan} class={_cellClassSelector(row, 'row-radio')}>
                      <InputRadioGroup
                        name="{name}-radio-{rowKey(row)}"
                        bind:value={selectedRow}
                        options={[{ value: rowKey(row), label: '' }]}
                        disabled={!rowIsSelectable(row)}
                      />
                    </td>
                  {/if}
                  {#if _showHrefButton && j === 0}
                    <td {rowspan} class={_cellClassSelector(row, 'row-href-button')}>
                      <a href={fromTemplate(href, row)} use:tip={tipForRow(row)}>
                        <Icon name={hrefButtonIconSelector(row)} />
                      </a>
                    </td>
                  {/if}
                  <slot {row} subRow={hasSubRows ? subRow : null} rowspan={j ? -1 : rowspan} />
                </tr>
              {/each}
            {/each}
          {/if}
        {/if}
      </tbody>
      <slot name="footer">
        {#if showFooter}
          <tfoot>
            <tr>
              <td class="row-checkbox">
                <InputCheckbox
                  bind:checked={allSelected}
                  on:click={allCheckboxClicked}
                  name="{name}-all-checkbox-footer"
                  title={allCheckboxTitle}
                  disabled={!_rows?.every(rowIsSelectable)}
                />
              </td>
              <td colspan={columnCount}>
                <slot name="bulk-actions-footer" />
              </td>
            </tr>
          </tfoot>
        {/if}
      </slot>
    </table>

    <div class="spinner-container g2">
      <Spinner x3 />
      <div>Loading…</div>
    </div>
  </div>

  <div slot="top-controls">
    {#if _showSelectedRows && visibleSelectedRowCount !== selectedRowCount}
      <Alert type="warning" title="" class="alert-inline">
        Showing <strong>{visibleSelectedRowCount}</strong> of <strong>{selectedRowCount}</strong> selected {selectedRowCount === 1
          ? pagesOfLabelSingular
          : pagesOfLabel}
      </Alert>
      <!-- TODO: Some enhancement like this... the problem is the <Paginator> hides this section entirely if there's no reason to paginate and it probably makes sense to always show this kind of messaging.
               So, should probably make this entire condition a <GridAlert> component that handles combinations regarding search results AND selected-rows.
               Then inject that component into TWO different slots for the <Paginator> -- one for the top controls (when they show) and one for just above the grid even if the pagination controls are missing.

      {:else if hasSearch}
      <Alert type="warning" title="" class="alert-inline">
        {#if visibleRowCount === rows.length}
          All <strong>{rows.length}</strong> {rows.length === 1 ? pagesOfLabelSingular : pagesOfLabel} match <strong>{search}</strong>
        {:else}
          <strong>{visibleRowCount}</strong> of <strong>{rows.length}</strong> {rows.length === 1 ? pagesOfLabelSingular : pagesOfLabel} {visibleRowCount === 1 ? 'matches' : 'match'} <strong>{search}</strong>
        {/if}
      </Alert> -->
    {/if}
  </div>
</Paginator>

<script>
  import { buildOffsetFetch } from 'services/offset-fetch.js'
  import { createEventDispatcher } from 'svelte'
  import { cssClassify } from 'services/css.js'
  import { fromTemplate, getCount, pluralize } from 'services/string-utils.js'
  import { setContext } from 'svelte'
  import Alert from 'components/bootstrap/Alert.svelte'
  import Icon from 'components/Icon.svelte'
  import InputCheckbox from 'components/fields/InputCheckbox.svelte'
  import InputRadioGroup from './fields/InputRadioGroup.svelte'
  import onInteract from 'decorators/on-interact.js'
  import optionBuilder from 'services/option-builder.js'
  import Paginator from 'components/Paginator.svelte'
  import Spinner from 'components/Spinner.svelte'
  import tip from 'decorators/tip.js'
  import unsavedForms from 'stores/unsaved-forms.js'
  import validator from 'services/validator.js'
  import onMayInteract from 'decorators/on-may-interact.js'
  import HelpBlock from './fields/HelpBlock.svelte'

  const dispatch = createEventDispatcher()

  export let rows = []
  let className = ''
  export { className as class }
  export let thClass = null
  export let paginatorClass = null
  export let paginatorBottomControlsClass = null
  export let paginatorTopControlsClass = null

  export let href = null // /bacon/taco/[foo]/[bar] - [foo] will be replaced by row.foo; [bar] will be replaced by row.bar
  export let showHrefButton = null
  export let hrefButtonIconSelector = () => 'edit'
  export let onRowClick = null
  export let onRowMouseOver = _.noop
  export let onRowMouseOut = _.noop

  export let search = null
  export let searchPredicate = _.stubTrue // (row, upperCaseSearch, search) => bool
  $: hasSearch = searchPredicate !== _.stubTrue && !validator.empty(search)
  $: upperCaseSearch = hasSearch ? search.toUpperCase() : null // We'll compute this once so it doesn't need to be computed in each call of the searchPredicate

  let descendantMatchesSearchRowClasses = {}
  let matchesSearchRowClasses = {}

  export let loading = false

  export let showRowNumber = false
  export let showFooter = false

  // If you'd like checkboxes for each row, use `bind:selectedRows`
  // and make sure to set `rowKeySelector` to the name of the property you'd like to use as a uniqueifier.
  // For example: <Grid bind:selectedRows rowKeySelector="userId" ...>
  // You can also pass a lambda to make a composite key. e.g. rowKeySelector={row => `${row.userId}_${row.orgId}`}
  // Similarly, this is needed for tree mode.
  export let rowKeySelector = null
  export let selectedRows = null // { [rowKey]: Boolean, ... }
  export let selectedRow = null // Single row selection (InputRadio instead of InputCheckbox)
  export let rowClasses = {} // { [rowKey]: 'class1 class2', ... }
  export let rowIsSelectableSelector = _.stubTrue
  export let rowHasChildrenSelector = null
  export let rowChildrenSelector = null
  export let subRowsSelector = null
  export let subRowKeySelector = null
  export let enableSingleRowSelection = false
  export let expandedRows = {} // { [rowKey]: Boolean, ... }
  // If you have a fully-loaded hierarchy, you can have it automatically expand for the user
  // so they don't have to do it manually, so long as it won't result in more rows than this value:
  export let autoExpandUpToCount = null
  // This is used to tell the <Paginator> whether or not to count a row
  // when determining which rows fall on the page.
  const paginatorCountContributions = {} // { [rowKey]: 0|1, ... }
  // Since you have to use `bind:selectedRows={prop}` or `bind:selectedRows={prop[foo]}`,
  // and there's no way to do `bind:selectedRows={condition ? variable : null}`,
  // you can hide them by binding to this parameter instead.
  export let showSelectedRows = true
  $: _showSelectedRows = selectedRows && showSelectedRows // Simplify usage in a few spots
  $: _showHrefButton = showHrefButton && href != null

  $: nRowKeySelectors = optionBuilder.normalizeSelectors(rowKeySelector ?? _.identity)
  $: nRowIsSelectableSelectors = optionBuilder.normalizeSelectors(rowIsSelectableSelector)
  $: nRowHasChildrenSelectors = optionBuilder.normalizeSelectors(rowHasChildrenSelector)
  $: nRowChildrenSelectors = optionBuilder.normalizeSelectors(rowChildrenSelector)
  $: nSubRowsSelectors = optionBuilder.normalizeSelectors(subRowsSelector)
  $: nSubRowKeySelectors = optionBuilder.normalizeSelectors(subRowKeySelector)
  $: rowKey = row => optionBuilder.selectFromOption(row, nRowKeySelectors)
  $: rowIsSelectable = row => optionBuilder.selectFromOption(row, nRowIsSelectableSelectors)
  $: rowHasChildren = row => optionBuilder.selectFromOption(row, nRowHasChildrenSelectors)
  $: rowChildren = row => optionBuilder.selectFromOption(row, nRowChildrenSelectors)
  $: subRows = row => optionBuilder.selectFromOption(row, nSubRowsSelectors)
  $: subRowKey = row => optionBuilder.selectFromOption(row, nSubRowKeySelectors)

  // TODO: the values in this should use the row key selector so it doesn't just give back a list of string values when the value is a non-string type, like int (see `shiftIdsToApplyTo` usages in `CN.Web\Client\js\components\ShiftDayForm.svelte`--it parses them as ints to check equality)
  //       related: selectedRows I think should just be an array of objects, not a dictionary of key/bool values. Feels strange and not very easily useful.
  // For these parameters, send in uninitialized variables with `bind:...`
  // and they'll update automatically; this way we don't have to do the same work
  // external to this component. Could change this to be an event, but nah.
  export let selectedRowKeys
  export let selectedRowCount
  export let visibleSelectedRowCount

  // This might scale like crap, creating a brand new array every time a record is added.
  $: selectedRowKeys = selectedRows
    ? Object.entries(selectedRows)
        .filter(e => e[1]) // selected or not
        .map(e => e[0]) // rowKey
    : []
  $: selectedRowCount = selectedRowKeys.length
  $: visibleSelectedRowCount = selectedRows && rowKeySelector ? rows.filter(row => selectedRows[rowKey(row)]).length : 0

  export let hideTotalCount = false

  // paginator
  export let paginatorTotalCount = null
  export let currentPage = 1
  export let pageSize = 10
  // If you don't want to be forced to pass custom `pageSizeOptions`,
  // just set `useDefaultPageSizeOptions` and the default options will be used.
  // Also consider passing `scalablePageSizeOptions` from `default-page-size-options.js`
  export let name
  export let useDefaultPageSizeOptions = false
  export let pageSizeOptions = null

  // These are used as `Show all ${pagesOfLabel}`, `${pageSize} ${pagesOfLabel} per page`,
  // and in a warning `Showing ${n} of ${m} selected ${pagesOfLabel}`
  // and in a tooltip `View this ${pagesOfLabel}` or `Edit this ${pagesOfLabel}`
  export let pagesOfLabelSingular = 'row'
  $: pagesOfLabel = pluralize(pagesOfLabelSingular)
  export let emptyMessage = null
  $: _emptyMessage = emptyMessage ?? (pagesOfLabel === 'rows' ? 'No data' : `No ${pagesOfLabel}`)

  export let paginatorControls = 'both'

  export let cellClassSelector = null
  const _cellClassSelector = (row, additionalClassNames = '') => {
    const classNames = cellClassSelector?.(row) ?? ''
    return `${classNames ?? ''}${classNames && additionalClassNames ? ' ' : ''}${additionalClassNames ?? ''}`
  }

  export let getRowTipConfig = null
  let rowsWhenOffsetCalculated
  let offset
  // If we update offset immediately upon pagination,
  // the current page's row numbers will awkardly change.
  // So wait till the rows have been swapped out.
  $: if (rows !== rowsWhenOffsetCalculated) {
    rowsWhenOffsetCalculated = rows
    ;({ offset } = buildOffsetFetch(currentPage, pageSize))
  }

  $: clickable = !_showHrefButton && (href != null || !!onRowClick)

  // Small optimization so we don't have to keep checking if we should be generating this
  // attribute in the template or not.
  $: buildRowDataTest = rowKeySelector && name ? row => `${name}-row-${rowKey(row)}` : () => null

  let allCheckboxTitle
  // The `rows.length` guard is to make the checkbox unchecked when there are 0 rows;
  // otherwise, it would awkwardly visually toggle back to unchecked when rows are loaded.
  $: allSelected = selectedRows && rowKeySelector && rows.length ? rows.every(row => selectedRows[rowKey(row)]) : false
  $: {
    const count = rows?.length ?? 0
    if (count) {
      const verb = allSelected ? 'Unselect' : 'Select'
      const allOrThe = count === 1 ? 'the' : 'all'
      const label = count === 1 ? pagesOfLabelSingular : pagesOfLabel
      const extra = pageSize === Infinity ? '' : ` on page ${currentPage}`
      allCheckboxTitle = `${verb} ${allOrThe} ${count} ${label}${extra}`
    } else {
      allCheckboxTitle = null
    }
  }

  // Use an event to kick this off so there's no infinite recursion issue.
  function allCheckboxClicked() {
    const newValue = !allSelected // Svelte hasn't updated bound value yet
    for (const row of rows) {
      selectedRows[rowKey(row)] = rowIsSelectable(row) ? newValue : false
    }
  }

  // Technically either the name or the header
  let columns = []
  let firstColumnName = null
  $: columnCount = columns.length + showRowNumber + !!_showSelectedRows + !!_showHrefButton
  $: name, rowKeySelector, rowKey, updateContext()

  function updateContext() {
    setContext('grid', {
      name,
      rowKeySelector,
      rowKey,

      addColumn: (name, key) => {
        columns.push({ name: name, key })
        if (columns.length === 1) firstColumnName = name
        columns = columns
      },

      removeColumn: key => {
        const index = columns.findIndex(c => c.key === key)
        if (index >= 0) columns.splice(index, 1)
        columns = columns
        if (index === 0) firstColumnName = columns[0]?.name
      },

      toggleExpanded: row => {
        const key = rowKey(row)
        const isExpanded = !expandedRows[key]
        expandedRows[key] = isExpanded
        if (isExpanded) dispatch('expand', row)
      },

      getCellMetadata: (columnName, row) => ({
        noExpandableRows,
        isFirstColumn: columnName === firstColumnName,
        indent: rowChildrenSelector ? indentsByRowKey[rowKey(row)] : null,
        isExpanded: rowChildrenSelector ? !!expandedRows[rowKey(row)] : null,
        hasChildren: rowChildrenSelector ? rowChildren(row)?.length ?? rowHasChildren(row) ?? false : null,
        isSelected: selectedRows ? !!selectedRows[rowKey(row)] : null,
      }),

      cellClassSelector: _cellClassSelector,
      thClass,
    })
  }

  function tipForRow(row) {
    if (pagesOfLabelSingular === 'row') return null
    const icon = hrefButtonIconSelector(row)
    switch (icon) {
      case 'edit':
        return `Edit this ${pagesOfLabelSingular}`
      case 'eye':
        return `View this ${pagesOfLabelSingular}`
      default:
        return null
    }
  }

  let hasAttemptedAutoExpand = false
  $: rows, autoExpandUpToCount, rowChildren, autoExpand()

  function autoExpand() {
    // Attempt to auto expand once we get rows. After that, it's up to the user to maintain.
    if (!autoExpandUpToCount || !rowChildrenSelector || !rows?.length || hasAttemptedAutoExpand) return
    hasAttemptedAutoExpand = true
    const state = { count: 0, rowsToAutoExpand: {} }
    const canAutoExpand = simulateAutoExpand(rows, state)
    if (canAutoExpand) expandedRows = { ...state.rowsToAutoExpand }
  }

  // Recurses the tree, tracking total row counts.
  // Returns true if the total row count is <= autoExpandUpToCount, otherwise false.
  // Implementation short-circuits so we don't have to recurse the entire tree.
  function simulateAutoExpand(items, state) {
    state.count += items.length
    if (state.count > autoExpandUpToCount) return false
    for (const item of items) {
      const children = rowChildren(item)
      if (children?.length) {
        state.rowsToAutoExpand[rowKey(item)] = true
        const canAutoExpand = simulateAutoExpand(children, state)
        if (!canAutoExpand) return false
      }
    }
    return true
  }

  const indentsByRowKey = {}
  let noExpandableRows = true
  let visibleRows
  $: rows, expandedRows, search, searchPredicate, (visibleRows = buildVisibleRows())

  function buildVisibleRows() {
    // Reset state
    descendantMatchesSearchRowClasses = {}
    matchesSearchRowClasses = {}
    noExpandableRows = true

    const visible = []
    if (rowChildrenSelector) {
      const parentRowsByChildKey = new Map()
      addVisibleTreeRows(visible, rows, 0, expandedRows, parentRowsByChildKey, null)
      return hasSearch
        ? visible.filter(row => {
            const key = rowKey(row)
            return matchesSearchRowClasses[key] || descendantMatchesSearchRowClasses[key]
          })
        : visible
    } else if (hasSearch) {
      for (const row of rows) {
        const matches = searchPredicate(row, upperCaseSearch, search)
        if (matches) visible.push(row)
        matchesSearchRowClasses[rowKey(row)] = matches
      }
      return visible
    }

    return rows
  }

  function addVisibleTreeRows(visible, rows, indent, expandedRows, parentRowsByChildKey, parentRow, ancestorsExpanded = true) {
    for (const row of rows) {
      const key = rowKey(row)
      parentRowsByChildKey.set(key, parentRow)
      indentsByRowKey[key] = indent
      paginatorCountContributions[key] = +(indent === 0) // Whether or not to count a row in <Paginator>
      if (ancestorsExpanded) visible.push(row)
      if (hasSearch && searchPredicate(row, upperCaseSearch, search)) handleTreeRowMatchesSearch(row, parentRowsByChildKey)
      const children = rowChildren(row)
      if (children?.length) {
        noExpandableRows = false
        addVisibleTreeRows(visible, children, indent + 1, expandedRows, parentRowsByChildKey, row, ancestorsExpanded && !!expandedRows[key])
      }
    }
  }

  function handleTreeRowMatchesSearch(row, parentRowsByChildKey) {
    const key = rowKey(row)
    matchesSearchRowClasses[key] = true
    let ancestor = parentRowsByChildKey.get(key)
    while (ancestor) {
      const ancestorKey = rowKey(ancestor)
      descendantMatchesSearchRowClasses[ancestorKey] = true
      ancestor = parentRowsByChildKey.get(ancestorKey)
    }
  }

  function rowClicked(e, row, subRow) {
    // if they clicked an anchor or a button within the row, let the anchor handle. but client-code really should be handling this with stopPropagation.
    const nearestAnchor = e.target?.closest('a, .btn:not(.btn-no-hover)')
    if (nearestAnchor != null) return

    if (href != null) {
      const url = fromTemplate(href, subRow ? { ...row, ...subRow } : row)
      unsavedForms.navigateSafe(url)
    } else {
      if (enableSingleRowSelection) selectedRow = rowKey(row)
      if (onRowClick) onRowClick(row)
    }
  }
</script>
