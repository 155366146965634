import {
  buildFilterTypes as buildStepSubmissionFilterTypes,
  buildIgnoredFilterTypes as buildStepSubmissionIgnoredFilterTypes,
} from 'components/StepSubmissionFilters.svelte'
import { FilterDecoder, FilterEncoder } from 'services/filters/index.js'
import { buildFilterTypes as buildMatchFilterTypes, buildIgnoredFilterTypes as buildMatchIgnoredFilterTypes } from 'components/MatchFilters.svelte'
import { CriteriaFilterType } from 'config/enums.js'

export default function (criteriaFilterType, excludedFilterTypes = []) {
  if (!criteriaFilterType) return {}
  switch (criteriaFilterType) {
    case CriteriaFilterType.StepSubmissionFilter:
      const stepSubmissionFilterTypes = buildStepSubmissionFilterTypes(excludedFilterTypes)
      const stepSubmissionIgnoredFilterTypes = buildStepSubmissionIgnoredFilterTypes(excludedFilterTypes)
      return {
        filterDecoder: new FilterDecoder(stepSubmissionFilterTypes, stepSubmissionIgnoredFilterTypes),
        filterEncoder: new FilterEncoder(stepSubmissionFilterTypes, stepSubmissionIgnoredFilterTypes),
      }
    case CriteriaFilterType.MatchFilter:
      const matchFilterTypes = buildMatchFilterTypes(excludedFilterTypes)
      const matchIgnoredFilterTypes = buildMatchIgnoredFilterTypes(excludedFilterTypes)
      return {
        filterDecoder: new FilterDecoder(matchFilterTypes, matchIgnoredFilterTypes),
        filterEncoder: new FilterEncoder(matchFilterTypes, matchIgnoredFilterTypes),
      }
    default:
      return {}
  }
}
