{#if multiple}
  <InputCheckboxGroup bind:value {options} {autofocusFirstItem} {disabled} />
{:else}
  <InputRadioGroup bind:value {options} {autofocusFirstItem} {disabled} firstOptionClass="mt0" lastOptionClass="mb0" />
{/if}

<script context="module">
  import { CloseReason } from 'config/enums.js'
  import enumToOptions from 'services/enum-to-options.js'

  const systemReasons = [
    CloseReason.OnboardingNotCompletedInTime,
    CloseReason.ApplicationVerificationNotCompletedInTime,
    CloseReason.ClinicalSiteDidntAcceptInTime,
    CloseReason.StudentOrSchoolDidntAcceptInTime,
    CloseReason.ClinicalSiteDidntAcceptFromWaitlistInTime,
  ]

  // so we don't need to change underyling enum values to change order willy-nilly
  const desiredOrder = [
    CloseReason.NoSchoolAgreement,
    CloseReason.RequirementsNotMet,
    CloseReason.DatesDoNotWork,
    CloseReason.NotEligible,

    // system
    CloseReason.OnboardingNotCompletedInTime,
    CloseReason.ApplicationVerificationNotCompletedInTime,
    CloseReason.ClinicalSiteDidntAcceptInTime,
    CloseReason.StudentOrSchoolDidntAcceptInTime,
    CloseReason.ClinicalSiteDidntAcceptFromWaitlistInTime,

    // other always last
    CloseReason.Other,
  ]

  const allOptions = enumToOptions(CloseReason)
  const closeReasons = desiredOrder.map(r => allOptions.find(o => o.optionValue === r))
</script>

<script>
  import InputCheckboxGroup from 'components/fields/InputCheckboxGroup.svelte'
  import InputRadioGroup from 'components/fields/InputRadioGroup.svelte'

  export let value
  export let multiple = false
  export let includeSystem = false
  export let autofocusFirstItem = false
  export let disabled = false

  $: options = includeSystem ? closeReasons : closeReasons.filter(r => !systemReasons.includes(r.optionValue))
</script>
