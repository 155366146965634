<MainContent>
  {#if !capacity || loading}
    <h1>Loading...</h1>
  {:else}
    <div class="mb2">
      <div class="flex-row flex-justify-between">
        <div>
          <h1 class="m0" style="font-weight: 300">
            <CapacityGroupSizeIcon {capacity} iconProps={{ sm: true }} />
            <span data-test="capacity-details-header">{capacity.name}</span>
          </h1>

          <p style="font-style: italic" class="pr2">{capacity.description ?? ''}</p>
        </div>

        <div class="flex-column g1" class:invisible={activityPanelOpen}>
          <div class="hide">
            <div bind:this={activityTooltipElem}>
              {#if unreadActivityCount}
                <div>{pluralCount('unread change', unreadActivityCount)}</div>
              {/if}
              {#if unreadCommentCount}
                <div>{pluralCount('unread comment', unreadCommentCount)}</div>
              {/if}
              {#if dateTimeLastViewed}
                <div>Since <FromNow date={dateTimeLastViewed} /></div>
              {/if}
            </div>
          </div>

          <Btn
            class="flex-row flex-align-center g05"
            on:click={() => (activityPanelOpen = true)}
            iconProps={{ lg: true }}
            icon="comment"
            dataTest="comments-and-activity-btn"
            title={activityTooltipElem && (unreadActivityCount || unreadCommentCount)
              ? { content: activityTooltipElem, options: { placement: 'left' } }
              : null}
          >
            <div class="flex-row flex-align-center g1">
              <span>Comments & activity</span>
              <Badge count={unreadActivityCount + unreadCommentCount} color="danger" class="m0 inset-0" />
            </div>
          </Btn>
          {#if isHealth && personaService.canEditCapacity(capacity)}
            <Btn
              href="{baseHref}/{capacity.capacityId}/edit"
              class="btn btn-primary btn-low-contrast-white flex-row flex-align-center g05"
              dataTest="edit-capacity-btn"
            >
              <Icon name="edit" />
              Edit opportunity
            </Btn>
          {/if}
          {#if hasSchedulingPermission && capacityGuestOrgIds?.length}
            <!--
              If they only have access to a single guest, link to that specifically.
              This won't change anything for them, but if they share the link with someone
              who has more access, they'll have the same UX, instead of the root user
              having to pick a guest/school.
            -->
            {@const maybeOnlyCapacityGuest = capacity.guests.length === 1 ? capacity.guests[0] : null}
            {@const hrefEnd = maybeOnlyCapacityGuest == null ? '' : `/${maybeOnlyCapacityGuest.guestOrgId || 'open'}`}
            <Btn
              href="/opportunities/details/{capacity.capacityId}/schedule-students{hrefEnd}"
              dataTest="schedule-students-to-capacity-btn"
              {...getScheduleStudentsBtnProps(capacity, maybeOnlyCapacityGuest)}
            >
              <Icon name="plus" />
              Schedule students
            </Btn>
          {/if}
        </div>
      </div>
    </div>

    <div class="mb2 flex-row">
      <div>
        <CapacityStatusIcon {capacity} detailsView />
      </div>
      {#if canViewMatches}
        <div class="rotation-status p2" style="border-left: 15px solid #f3aa41">
          <div class="flex-column flex-align-center flex-justify-center">
            <div class="strongish">
              {matchCount}
              /
              <Unlimited value={matchCountLimit} />
              <Icon name="users" class="" />
              Rotations filled
            </div>
            <div on:click={handleClickPendingRequests} class="text-warning-dark m0">
              <Icon name="alert-triangle" />
              {pendingMatchCount} Pending rotations
            </div>
          </div>
        </div>
      {/if}
    </div>
    <br />
    <DetailsInformation {capacity} {isHealth} />
    {#if canViewMatches}
      <div class="mb2">
        <Collapsible open={false}>
          <div slot="label" class="flex-row">
            {#if capacity.postToSearchPage && capacity.staff?.length}
              <div class="mr2">
                <span class="small em">{capacity.staff.length} staff</span>
                {#if capacity.staff.length}
                  <div class="flex-row g05">
                    {#each capacity.staff as staff}
                      <ProfilePic id={staff.userId} name={staff.name} src={staff.profilePicture} title={staff.name} medium />
                    {/each}
                  </div>
                {/if}
              </div>
            {/if}
            <div class="mr2">
              <span class="small em">{uniqueHealthStaff.length} clinic staff</span>
              {#if uniqueHealthStaff.length}
                <div class="flex-row g05">
                  {#each uniqueHealthStaff as staff}
                    <ProfilePic id={staff.userId} name={staff.name} src={staff.profilePicture} title={staff.name} medium />
                  {/each}
                </div>
              {/if}
            </div>
            <div>
              <span class="small em">{uniqueSchoolStaff.length} school staff</span>
              {#if uniqueSchoolStaff.length}
                <div class="flex-row g05">
                  {#each uniqueSchoolStaff as staff}
                    <ProfilePic id={staff.userId} name={staff.name} src={staff.profilePicture} title={staff.name} medium />
                  {/each}
                </div>
              {/if}
            </div>
          </div>
          <div>
            {#if capacity.postToSearchPage && capacity.staff?.length}
              <Collapsible open noContent={capacity.staff.length > 0 ? false : true}>
                <div slot="label" class="flex-row g05 flex-align-center text-center">
                  <Icon name="eye" />
                  <h3 class="m0">Staff visible to applicants</h3>
                  <Badge xs count={capacity.staff.length} />
                </div>
                <div class="flex-row flex-wrap g05">
                  {#each capacity.staff as staff}
                    <StaffCard {staff} {capacity} />
                  {/each}
                </div>
              </Collapsible>
            {/if}
            <Collapsible open noContent={schoolStaff.length ? false : true}>
              <div slot="label" class="flex-row g05 flex-align-center text-center">
                <Icon name="school" />
                <h3 class="m0">School staff (from rotations)</h3>
                <Badge xs count={uniqueSchoolStaff.length} />
              </div>
              <div>
                <div class="flex-row flex-wrap">
                  {#each schoolStaffByUserId as schoolStaffUser}
                    {@const staff = schoolStaffUser[0]}
                    {@const matchRoles = schoolStaffUser.map(s => formatEnumValue(MatchRole, s.matchRole)).join(', ')}
                    <UserProfilePicAndName
                      headerClass="strong m0"
                      profilePicLarge
                      user={staff}
                      class="card card-medium"
                      linkToProfile
                      roundLeftBorder
                    >
                      <div class="flex-column" slot="after-name-and-pronouns">
                        <span>{matchRoles}</span>
                        <IconTextLink icon="email" text={staff.email} href="mailto:{staff.email}" />
                      </div>
                    </UserProfilePicAndName>
                  {/each}
                </div>
              </div>
              <div class="flex-row flex-wrap g05" />
            </Collapsible>
          </div>
        </Collapsible>
      </div>
    {/if}
    {#if isHealth}
      <CapacityGuestDetail {capacity} matches={matches.filter(m => m.status !== MatchStatus.Unsubmitted && m.status !== MatchStatus.Closed)} />
    {/if}

    {#if canViewMatches}
      <div class="mb2" id="matches">
        <Collapsible open>
          <h3 slot="label" class="mb0">Rotations</h3>
          <DetailsRotationList
            {capacity}
            href={`/opportunities/${capacity.capacityId}`}
            {onFiltersChanged}
            bind:filters
            loading={loadingMatches}
            {totalCount}
            {matches}
          />
        </Collapsible>
      </div>
    {/if}
    <div class="mb2">
      <Collapsible open>
        <h3 slot="label" class="mb0">Opportunity calendar</h3>
        {#if capacity}
          <div class="flex-grow"><CalendarControls bind:start bind:end /></div>
          <Calendar
            startDate={start}
            endDate={end}
            let:day
            min={capacity.startDate}
            max={capacity.endDate}
            fullWeekdayLabel
            weekdayLabelStyle="font-size: 16px"
            dateStyle="min-height: 120px"
            dayLabelStyle="font-size: 14px"
            {dayClasses}
          >
            <!-- Changing something in here? Consider making a similar change to CapacityShiftsForm.svelte. -->
            <!-- TODO(phase2-nursing): Create a component ShiftCalendarDay to combine this and CapacityShiftsForm calendars -->
            <div slot="icons">
              {@const dayShifts = shiftsByDate[day.formatted] ?? []}
              {@const dayShiftCount = dayShifts.length}
              {@const anyShifts = dayShiftCount > 0}
              {@const allShifts = dayShiftCount === capacity.shifts.length}
              {@const anyInfiniteMatchCountPerDay = dayShifts.some(shift => !shift.maxMatchCountPerDay)}
              {@const peopleCount = anyInfiniteMatchCountPerDay ? 0 : _.sumBy(dayShifts, shift => shift.maxMatchCountPerDay)}
              <Badge label={allShifts ? 'All' : anyShifts ? dayShiftCount : 'None'} icon={anyShifts ? 'eye' : 'hide'} xs color="pureBlack" />
              <Badge
                label={anyInfiniteMatchCountPerDay ? '' : peopleCount}
                icon={anyInfiniteMatchCountPerDay ? 'infinity' : null}
                iconRight="users"
                xs
                color="pureBlack"
              />
            </div>
            {@const dayShifts = shiftsByDate[day.formatted] ?? []}
            {#if formattedDateExceptions.includes(day.formatted)}
              <div class="flex flex-column flex-align-center">Exception</div>
            {:else if !day.disabled && dayShifts?.length > 0}
              {#each dayShifts as shift}
                <div data-test="shift" class="shift color-bg-{shift.color} mt1" style="border-radius: 5px;">
                  <div class="flex-column flex-align-center g05">
                    <ShiftTimeDisplay {capacity} {shift} dayjsDay={day.day} />
                    <div class="flex-grow">{shift.name}</div>
                    <Badge
                      label={shift.maxMatchCountPerDay > 0 ? shift.maxMatchCountPerDay : ''}
                      icon={shift.maxMatchCountPerDay > 0 ? null : 'infinity'}
                      iconRight="users"
                      xs
                    />
                  </div>
                </div>
              {/each}
            {/if}
          </Calendar>
        {/if}
      </Collapsible>
    </div>

    <CapacityActivity
      {saving}
      capacityId={capacity.capacityId}
      headerStyle="drawer"
      bind:isOpen={activityPanelOpen}
      bind:dateTimeLastViewed
      bind:unreadActivityCount
      bind:unreadCommentCount
    />
  {/if}
</MainContent>

<Router>
  <Route path="/matches/:matchId/*" let:params>
    <MatchModal matchId={params.matchId} on:close={onModalClosed} />
  </Route>

  <Route path="schedule-students/:guestOrgId/*" let:params>
    {@const props = buildScheduleStudentsModalProps(capacity, params)}
    <ScheduleStudentsModal {...props} {routingConfig} />
  </Route>

  <Route path="schedule-students/*" let:params>
    {@const props = buildScheduleStudentsModalProps(capacity, params)}
    <ScheduleStudentsModal {...props} {routingConfig} />
  </Route>
</Router>

<script>
  import { formatEnumValue } from 'services/formatters.js'
  import { getScheduleStudentsBtnProps } from 'services/capacity-usage.js'
  import { MatchListSortProperty, FilterType, MatchRole, MatchStatus, Permission, PersonaType } from 'config/enums'
  import { onDestroy } from 'svelte'
  import { pluralCount } from 'services/string-utils'
  import { Router, Route } from 'svelte-routing'
  import { shiftOccursOnDate } from 'services/calendar-service.js'
  import ScheduleStudentsModal, { buildScheduleStudentsModalProps } from 'components/ScheduleStudentsModal.svelte'
  import api from 'services/api.js'
  import Badge from 'components/Badge.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Calendar from 'components/Calendar.svelte'
  import CalendarControls from 'components/CalendarControls.svelte'
  import CapacityActivity from 'components/CapacityActivity.svelte'
  import CapacityGroupSizeIcon from 'components/CapacityGroupSizeIcon.svelte'
  import CapacityStatusIcon from 'components/CapacityStatusIcon.svelte'
  import Collapsible from 'components/Collapsible.svelte'
  import dateService from 'services/date-service.js'
  import dayjs from 'dayjs'
  import DetailsInformation from './Opportunities.Details.Information.svelte'
  import DetailsRotationList from './Opportunities.Details.RotationList.svelte'
  import FromNow from 'components/FromNow.svelte'
  import getMatchController from 'services/match-controller.js'
  import Icon from 'components/Icon.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import MainContent from 'components/MainContent.svelte'
  import MatchModal from 'components/MatchModal.svelte'
  import CapacityGuestDetail from './Opportunities.CapacityGuestDetail.svelte'
  import persona from 'stores/persona'
  import personaService from 'services/persona-service.js'
  import ProfilePic from 'components/ProfilePic.svelte'
  import ShiftTimeDisplay from 'components/ShiftTimeDisplay.svelte'
  import sockets from 'services/sockets.js'
  import StaffCard from 'components/StaffCard.svelte'
  import Unlimited from 'components/Unlimited.svelte'
  import unsavedForms from 'stores/unsaved-forms'
  import UserProfilePicAndName from 'components/UserProfilePicAndName.svelte'
  import validator from 'services/validator'

  export let pageSize
  export let currentPage = 1
  export let totalCount = 0
  export let loadCapacity
  export let loading = false
  export let capacity
  export let capacityId
  export const defaultSortProperty = MatchListSortProperty.StartDate
  export const defaultSortAscending = false
  export let sortProperty = defaultSortProperty
  export let sortAscending = defaultSortAscending
  export let baseHref
  export let saving = false
  export let selectedCapacityIds

  const routingConfig = {
    close: onModalClosed,
  }

  let currentXhr = null
  let currentXhrBody = null
  let loadingMatches = false
  let loadingStaff = false
  let matches = []
  let capacityMatchesStaffCount = 0
  let capacityMatchesStaff = []
  let filters = []

  // Activity-related:
  let unreadActivityCount
  let unreadCommentCount
  let dateTimeLastViewed
  let activityTooltipElem = null

  let start
  let end
  let shiftsByDate = {}
  let activityPanelOpen = false

  $: if (capacityId) selectedCapacityIds = [capacityId]
  $: _baseHref = baseHref + `/${capacityId}`
  $: capacityGuestOrgIds = capacity?.guests.map(g => g.guestOrgId)
  $: isHealth = $persona.personaType === PersonaType.ProviderStaff
  $: healthStaff = capacityMatchesStaff.filter(s => [MatchRole.ClinicCoordinator, MatchRole.Preceptor].includes(s.matchRole))
  $: uniqueHealthStaff = _.uniqBy(healthStaff, cms => cms.userId)
  $: schoolStaff = capacityMatchesStaff.filter(s => [MatchRole.SchoolCoordinator, MatchRole.SchoolFaculty].includes(s.matchRole))
  $: uniqueSchoolStaff = _.uniqBy(schoolStaff, cms => cms.userId)
  $: schoolStaffByUserId = _.values(_.groupBy(schoolStaff, o => o.userId))
  $: pendingMatchCount = matches.filter(
    m => m.status === MatchStatus.PendingApplicationVerification || m.status === MatchStatus.PendingClinicalSite
  ).length
  $: hiddenFilters = [{ type: FilterType.MatchCapacity, config: { capacityIds: [capacity?.capacityId] } }]
  // TODO(phase2-nursing): Validate if the user has access to this opportunity
  $: canViewMatches =
    personaService.hasAnyPermissionAnywhere(Permission.ViewMatches, Permission.CoordinateMatches) ||
    (isHealth && personaService.hasPermissionAnywhere(Permission.PreceptMatches))
  $: hasSchedulingPermission = personaService.hasPermissionAnywhere(Permission.AddMatchesApi)
  $: startDate = capacity?.startDate
  $: endDate = capacity?.endDate
  $: capacityShifts = capacity?.shifts ? _.orderBy(capacity.shifts, [s => (s.startTime == null ? 0 : parseInt(s.startTime.replace(':', '')))]) : []
  $: dateExceptions = capacity?.dateExceptions ?? []
  $: formattedDateExceptions = dateExceptions.map(de => dateService.datestamp(de))
  $: dayClasses = Object.fromEntries(formattedDateExceptions.map(date => [date, 'excluded']))
  $: startDate, endDate, setInitialStartEnd()
  $: capacityShifts, start, end, setShiftsByDate()
  $: matchCount = capacity?.guests?.length ? _.sumBy(capacity.guests, 'matchCount') : 0
  $: matchCountLimit = capacity?.guests?.length
    ? isHealth
      ? capacity.maxMatches
      : capacity.guests.some(cg => !cg.maxMatches)
        ? null
        : _.sumBy(capacity.guests, 'maxMatches')
    : 0

  const unsubscribe = sockets.on('UI_Capacity', ui => {
    if (ui?.capacityId != capacity?.capacityId) return
    if (saving) return
  })
  onDestroy(unsubscribe)

  function setShiftsByDate() {
    if (!capacity) return
    shiftsByDate = {}
    let currentDate = dayjs(start)
    while (currentDate.isSameOrBefore(end)) {
      const formattedDate = currentDate.format('M/D/YYYY')
      shiftsByDate[formattedDate] = capacity.shifts.filter(shift => shiftOccursOnDate(capacity, shift, currentDate))
      currentDate = currentDate.add(1, 'day')
    }
  }

  function setInitialStartEnd() {
    if (capacity?.startDate) start = dayjs(capacity?.startDate)
    else if (start == null) start = dayjs()
    start = start.startOf('month')
    end = start.endOf('month')
  }

  function onModalClosed() {
    unsavedForms.navigateSafe(_baseHref)
  }

  function handleClickPendingRequests(event) {
    event.preventDefault()
    filters = [
      {
        type: FilterType.MatchStatuses,
        config: { statuses: [MatchStatus.PendingApplicationVerification, MatchStatus.PendingClinicalSite] },
      },
    ]

    loadMatches()
    const element = document.getElementById('matches')
    element.scrollIntoView({ behavior: 'smooth' })
  }

  async function loadMatches() {
    if (!canViewMatches) return
    loadingMatches = true
    let thisXhr = null
    try {
      thisXhr = getGridData()
      const response = await thisXhr
      if (thisXhr === currentXhr) {
        totalCount = response.totalCount
        matches = response.matches
      }
    } finally {
      if (thisXhr === currentXhr) {
        loadingMatches = false
        currentXhr = null
        currentXhrBody = null
      }
    }
  }
  function buildGetDataBodyBase() {
    return {
      filters: [...filters, ...hiddenFilters],
      sortProperty,
      sortAscending,
    }
  }

  function getGridData() {
    const body = {
      ...buildGetDataBodyBase(),
      pageSize,
      offset: pageSize * (currentPage - 1),
      capacityId: capacity.capacityId,
      // includeAgreements: true,
    }

    if (currentXhr && validator.equals(currentXhrBody, body)) {
      return currentXhr
    }

    currentXhr = getMatchController().getListPage(body, api.noMonitor)
    currentXhrBody = body
    return currentXhr
  }

  function onFiltersChanged(_filters) {
    filters = _filters
    loadMatches()
  }

  async function loadStaff() {
    if (!canViewMatches) return
    loadingStaff = true
    try {
      const response = await api.capacity.listCapacityStaff({ capacityId: capacity.capacityId })
      if (response) {
        capacityMatchesStaffCount = response.length
        capacityMatchesStaff = response.capacityStaff
      }
    } finally {
      loadingStaff = false
    }
  }

  $: if (!capacity) loadCapacity()
  $: if (capacity?.capacityId) {
    loadMatches()
    loadStaff()
  }
</script>

<style>
  .rotation-status {
    background-color: #f9eac3;
    border-radius: 4px;
    align-self: stretch;
  }
</style>
