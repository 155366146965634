<Form on:submit={changeName}>
  <div class="modal-body mr2 ml2">
    {#if !$user.legalFirstName && !$user.legalLastName}
      <span data-test="name-change-info">
        <h3>What’s in a name?</h3>
        <p>
          We’re making some site-wide changes on how we handle names. In addition to clarifying legal names, we are now allowing people to designate a
          preferred or chosen first name and separate their titles/credentials from their name.
        </p>
        <p>This preferred/chosen name will be used throughout the app, though your legal name may still be shown in some cases where necessary.</p>
        <p>
          {#if $user.nameSuggestion && !validator.equals($user.nameSuggestion, {})}
            We’ve taken our best shot at reformatting your name based on what you had before, but the process isn’t perfect. Please take a moment to
            adjust anything if needed.
          {/if}
          You can also adjust these at any time from your account settings.
        </p>
      </span>
    {/if}

    <div class="grid-container">
      <FormGroup style="grid-column: 1 / 3; grid-row: 1/2; margin-bottom: 0;">
        <label for="name-prefix">Prefix</label>
        <InputNamePrefix bind:value={input.namePrefix} />
      </FormGroup>

      <FormGroup
        style="grid-column: 1 / 4; grid-row: 2 / 3; margin-bottom: 0;"
        valid={validator.required(input.legalFirstName)}
        validationMessage="The first name field is required."
      >
        <label for="first-name">
          Legal first name <RequiredMarker />
        </label>
        <InputText name="first-name" bind:value={input.legalFirstName} required />
      </FormGroup>

      <FormGroup style="grid-column: 4 / 7; grid-row: 2 / 3; margin-bottom: 0;">
        <label for="preferred-name">Preferred/chosen first name</label>
        <InputText name="preferred-name" bind:value={input.preferredName} />
      </FormGroup>

      <FormGroup style="grid-column: 1 / 7; grid-row: 3 / 4; margin-bottom: 0;">
        <label for="middle-name"> Middle name </label>
        <InputText name="middle-name" bind:value={input.middleName} />
      </FormGroup>

      <FormGroup
        style="grid-column: 1 / 5; grid-row: 4 / 5; margin-bottom: 0;"
        valid={validator.required(input.legalLastName)}
        validationMessage="The last name field is required."
      >
        <label for="last-name">
          Legal last name <RequiredMarker />
        </label>
        <InputText name="last-name" bind:value={input.legalLastName} required />
      </FormGroup>

      <FormGroup style="grid-column: 5 / 7; grid-row: 4 / 5; margin-bottom: 0;">
        <label for="name-suffix">Suffix</label>
        <InputNameSuffix bind:value={input.nameSuffix} />
      </FormGroup>

      <FormGroup style="grid-column: 1 / 7; grid-row: 5 / 6; margin-bottom: 0;">
        <label for="credentials" class="flex-row flex-align-center g05">
          Earned titles/credentials
          <Help tipOptions={{ maxWidth: 300 }}>
            These are titles or credentials you have already earned. They will be publicly visible in some places, appended after or below your name.
          </Help>
        </label>
        <InputText name="credentials" bind:value={input.credentials} placeholder="MD, PhD, RN, LPN, etc." />
      </FormGroup>
    </div>

    <div class="mt2">
      <label for="name-preview" class="flex-row flex-align-center g05 m0">
        Name preview <Help tipOptions={{ maxWidth: 300 }}>This is how your name will appear to others.</Help>
      </label>
      <h2 class="form-control-static m0 p0" name="name-preview" data-test="name-preview">
        {input.namePrefix ?? ''}
        {(validator.empty(input.preferredName) ? input.legalFirstName : input.preferredName) ?? ''}
        {input.legalLastName ?? ''}
        {input.nameSuffix ?? ''}
      </h2>
    </div>

    <div class="mt1">
      <label for="name-preview-full" class="m0">Full name with credentials preview</label>
      <h2 class="form-control-static m0 p0" name="name-preview-full" data-test="name-preview-full">
        {input.namePrefix ?? ''}
        {(validator.empty(input.preferredName) ? input.legalFirstName : input.preferredName) ?? ''}
        {input.middleName ?? ''}
        {input.legalLastName ?? ''}
        {input.nameSuffix ?? ''}
        {input.credentials ?? ''}
      </h2>
    </div>
  </div>

  <div class="modal-footer">
    <button type="submit" class="btn btn-{!loading ? 'primary' : 'disabled disabled'}" disabled={loading} data-test="submit-name-btn">
      {loading ? 'Updating…' : 'Update name'}
    </button>
    {#if $user.legalFirstName && $user.legalLastName}
      <button type="button" class="btn btn-default" on:click={() => dispatch('close')}>Cancel</button>
    {/if}
  </div>
</Form>

<script>
  import { createEventDispatcher } from 'svelte'
  import api from 'services/api.js'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Help from 'components/Help.svelte'
  import InputNamePrefix from 'components/InputNamePrefix.svelte'
  import InputNameSuffix from 'components/InputNameSuffix.svelte'
  import InputText from 'components/fields/InputText.svelte'
  import RequiredMarker from './fields/RequiredMarker.svelte'
  import toaster from 'services/toaster.js'
  import user from 'stores/user.js'
  import validator from 'services/validator.js'

  const dispatch = createEventDispatcher()

  let loading = false
  const input = {
    legalFirstName: null,
    legalLastName: null,
    preferredName: null,
    namePrefix: null,
    nameSuffix: null,
    middleName: null,
    credentials: null,
  }

  const setInput = () => {
    if (!validator.empty($user.legalFirstName) || !validator.empty($user.legalLastName)) {
      input.legalFirstName = $user.legalFirstName
      input.middleName = $user.middleName
      input.legalLastName = $user.legalLastName
      input.preferredName = $user.preferredName
      input.namePrefix = $user.namePrefix
      input.nameSuffix = $user.nameSuffix
      input.credentials = $user.credentials
    } else if ($user.nameSuggestion) {
      input.legalFirstName = $user.nameSuggestion.legalFirstName
      input.middleName = $user.nameSuggestion.middleName
      input.legalLastName = $user.nameSuggestion.legalLastName
      input.preferredName = $user.nameSuggestion.preferredName
      input.namePrefix = $user.nameSuggestion.namePrefix
      input.nameSuffix = $user.nameSuggestion.nameSuffix
      input.credentials = $user.nameSuggestion.credentials
    }
  }

  $: if ($user != null) setInput()

  function changeName() {
    const data = input
    loading = true
    api.profile
      .changeName(data)
      .then(response => {
        $user = response
        toaster.toast({ message: 'Name updated', type: 'success', icon: 'check' })
        dispatch('close')
      })
      .finally(() => (loading = false))
  }
</script>

<style lang="scss">
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto auto auto auto;
    grid-gap: 10px;
  }
</style>
