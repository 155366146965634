{#if value.length === 0}
  {labelNone}
{:else if value.length === options.length}
  {labelAll}
{:else if value.length === 2 && valueSorted[0] === 1 && valueSorted[1] === 7}
  {labelWeekends}
{:else if value.length === 5 && valueSorted[0] === 2 && valueSorted[4] === 6}
  {labelWeekdays}
{:else}
  <FriendlyList max={Infinity} punctuation items={ranges} let:item>
    {#if item.length === 1}
      {labels[item[0] - 1]}
    {:else}
      {labels[item[0] - 1]}–{labels[item[1] - 1]}
    {/if}
  </FriendlyList>
{/if}

<script context="module">
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const bubbleDaysOfWeek = ['S', 'M', 'T', 'W', 'Th', 'F', 'S']
  const abbreviatedDaysOfWeek = daysOfWeek.map(d => d.substring(0, 3))
  // Server uses 1-based days.
  export const options = daysOfWeek.map((name, i) => ({ name, bubbleName: bubbleDaysOfWeek[i], value: i + 1 }))
</script>

<script>
  import { groupByContiguousFriendly } from 'services/array-utils.js'
  import FriendlyList from 'components/FriendlyList.svelte'

  export let value = []
  export let labelNone = 'no days'
  export let labelAll = 'all days'
  export let labelWeekdays = 'weekdays'
  export let labelWeekends = 'weekends'

  $: valueSorted = value.toSorted((a, b) => a - b)
  $: ranges = groupByContiguousFriendly(valueSorted)
  $: countRangeWords = _.sum(ranges.map(r => r.length))
  $: labels = countRangeWords <= 3 ? daysOfWeek : abbreviatedDaysOfWeek
</script>
