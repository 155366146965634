{#if item != null}
  <ModalConfirm
    show={item}
    autoClose={false}
    {mustType}
    disabled={loading || isDeleting || mustType == null}
    confirmIcon="delete"
    confirmLabel={isDeleting ? 'Deleting…' : 'Delete'}
    lg
    confirmClass="btn-danger"
    on:confirm={onConfirm}
    on:cancel={onCancel}
  >
    <h4 slot="title">Delete {typeLowerCase}</h4>

    <slot />

    {#if loading}
      <Loading message="Checking {typeLowerCase} usage" />
    {:else if hasReasons}
      <Alert type="danger" dataTest="cant-delete-reason">
        The {typeLowerCase}
        {namePrefix}
        <Icon name={typeIcon} autoColor={useAutoColor} class={typeIconClass} />
        {#if useNameSlot}
          <slot name="name" />
        {:else}
          <strong>{name}</strong>
        {/if}
        {#if canForceDelete}
          should be deleted with caution
        {:else}
          cannot be deleted
        {/if}
        because {itHasPrefixForReasons ? 'it has' : ''}
        <DynamicFragments fragments={reasons} />.

        {#if hasWarnings}
          You may also not want to delete it because {itHasPrefixForWarnings ? 'it has' : ''} <DynamicFragments fragments={warnings} />.
        {/if}
      </Alert>
    {:else if hasWarnings}
      <Alert type="warning" dataTest="cant-delete-warning">
        You may not want to delete the {typeLowerCase}
        {namePrefix}
        <Icon name={typeIcon} autoColor={useAutoColor} class={typeIconClass} />
        {#if useNameSlot}
          <slot name="name" />
        {:else}
          <strong>{name}</strong>
        {/if}
        because {itHasPrefixForWarnings ? 'it has' : ''}
        <DynamicFragments fragments={warnings} />.

        <p class="mt1">Are you sure you want to delete it?</p>
      </Alert>
    {:else}
      <p>
        The {typeLowerCase}
        {namePrefix}
        <Icon name={typeIcon} autoColor={useAutoColor} class={typeIconClass} />
        {#if useNameSlot}
          <slot name="name" />
        {:else}
          <strong>{name}</strong>
        {/if}
        {canBeDeletedText}. Are you sure you want to delete it?
      </p>
    {/if}
  </ModalConfirm>
{/if}

<script>
  import {
    buildFilterTypes as buildAgreementFilterTypes,
    buildIgnoredFilterTypes as buildAgreementIgnoredFilterTypes,
  } from 'components/AgreementFilters.svelte'
  import {
    buildFilterTypes as buildServiceFilterTypes,
    buildIgnoredFilterTypes as buildServiceIgnoredFilterTypes,
  } from 'components/ServiceFilters.svelte'
  import { FeatureType } from 'config/enums.js'
  import { FilterEncoder } from 'services/filters/index.js'
  import { pluralCount, strip } from 'services/string-utils.js'
  import {
    buildFilterTypes as buildCapacityFilterTypes,
    buildIgnoredFilterTypes as buildCapacityIgnoredFilterTypes,
  } from 'components/CapacityFilters.svelte'
  import Alert from 'components/bootstrap/Alert.svelte'
  import DynamicFragments, { builder } from 'components/DynamicFragments.svelte'
  import Icon from 'components/Icon.svelte'
  import Loading from 'components/Loading.svelte'
  import matchStatusHelper from 'services/match-status-helper.js'
  import ModalConfirm from 'components/ModalConfirm.svelte'
  import personaService from 'services/persona-service'
  import toaster from 'services/toaster.js'

  export let type
  export let namePrefix = ''
  export let typeIcon
  export let typeIconClass
  export let item
  export let deleteItem
  export let canForceDelete = false
  export let canDeleteItem
  export let itemWasDeleted
  export let buildReasons = _.noop
  export let buildWarnings = _.noop
  export let onDelete
  export let onCancel
  export let nameSelector = item => item?.name
  export let canBeDeletedText = 'is not being used'
  export let useNameSlot = false
  export let useAutoColor = false

  $: typeLowerCase = type.toLowerCase()
  $: name = item ? nameSelector(item) : null

  let loading = true

  let reasons = null
  let warnings = null
  let itHasPrefixForReasons = true
  let itHasPrefixForWarnings = true
  let hasReasons = false
  let hasWarnings = false

  const hasHealthFeature = personaService.canUseAnyFeatureType(FeatureType.HealthInstitution)
  const orgClass = hasHealthFeature ? 'color-text-orange' : 'color-text-blue'

  $: mustType = loading || (hasReasons && !canForceDelete) ? null : 'delete'
  $: if (item) loadCanDelete()

  async function loadCanDelete() {
    loading = true
    const result = await canDeleteItem()
    setFromResult(result)
    loading = false
  }

  function setFromResult(result) {
    // Assume the consumer isn't going to call endOfItHas()
    itHasPrefixForReasons = true
    itHasPrefixForWarnings = true
    const reasonItems = buildResultItems(result, buildReasons, count => {
      itHasPrefixForReasons = count > 0
    })
    const warningItems = buildResultItems(result, buildWarnings, count => {
      itHasPrefixForWarnings = count > 0
    })
    hasReasons = reasonItems.length > 0
    hasWarnings = warningItems.length > 0
    reasons = friendlyList(reasonItems)
    warnings = friendlyList(warningItems)
  }

  function friendlyList(items) {
    return builder.friendlyList({ punctuation: true, max: Infinity, items })
  }

  function buildResultItems(result, buildFn, setItHas) {
    const items = []
    const wrappedBuilder = {
      addMatchStatusCountItems: matchStatusCounts => addMatchStatusCountItems(items, matchStatusCounts),
      addStepTypeCountItems: stepTypeCounts => addStepTypeCountItems(items, stepTypeCounts),
      addStaffCount: count => addStaffCount(items, count),
      addPendingStaffCount: count => addPendingStaffCount(items, count),
      addStudentCount: count => addStudentCount(items, count),
      addDescendantLocationCount: count => addDescendantLocationCount(items, count),
      addAgreementCount: (count, filters = null) => addAgreementCount(items, count, filters),
      addServiceCount: (count, filters = null) => addServiceCount(items, count, filters),
      addActiveCapacityCount: (count, filters = null) => addActiveCapacityCount(items, count, filters),
      endOfItHas: () => setItHas(items.length),
      addSimple: text => addSimple(items, text),
    }
    buildFn(wrappedBuilder, result)
    return items
  }

  function buildChildFragments(count, icon, label, iconProps = {}) {
    return [count, ' ', builder.icon('times', { sm: true }), ' ', builder.icon(icon, iconProps), ' ', pluralCount(label, count, 'omitNumber')]
  }

  function buildAnchor(childFragments, href, className = 'text-black') {
    return builder.anchor(childFragments, { href, class: `inline-flex-row flex-align-center g05${className ? ` ${className}` : ''}` })
  }

  function addMatchStatusCountItems(array, matchStatusCounts) {
    if (!matchStatusCounts?.length) return
    for (const msc of matchStatusCounts) {
      const helper = matchStatusHelper.get(msc.status)
      const childFragments = buildChildFragments(msc.count, helper.icon, `${helper.label} rotation`)
      // TODO: Would be nice to link to a pre-filtered version of a rotation list.
      array.push(buildAnchor(childFragments, `/dashboard`, `text-${helper.color}`))
    }
  }

  function addStepTypeCountItems(array, stepTypeCounts) {
    if (!stepTypeCounts?.length) return
    for (const stc of stepTypeCounts) {
      const helper = matchStatusHelper.getByStepType(stc.stepType)
      const childFragments = buildChildFragments(stc.count, helper.icon, `${helper.stepLabel} step`)
      // TODO: Steps should get quick filters, too. And be able to link to the page with an encoded filter query string.
      array.push(buildAnchor(childFragments, helper.stepHref, `text-${helper.color}`))
    }
  }

  function addStaffCount(array, count) {
    if (!count) return
    const childFragments = buildChildFragments(count, 'staff-settings', 'staff member')
    array.push(buildAnchor(childFragments, '/settings/staff'))
  }

  function addPendingStaffCount(array, count) {
    if (!count) return
    const childFragments = buildChildFragments(count, 'shift', 'pending staff member', { class: `swap-opacity ${orgClass}` })
    array.push(childFragments)
  }

  function addStudentCount(array, count) {
    if (!count) return
    const childFragments = buildChildFragments(count, 'graduation-cap', 'active student')
    array.push(buildAnchor(childFragments, '/students', 'color-text-teal'))
  }

  function addDescendantLocationCount(array, count) {
    if (!count) return
    const childFragments = buildChildFragments(count, 'map-marker', 'descendant location')
    array.push(builder.strong(childFragments))
  }

  function addServiceCount(array, count, filters) {
    if (!count) return
    const encodedFilters = buildFilters(filters, buildServiceFilterTypes, buildServiceIgnoredFilterTypes)
    const childFragments = buildChildFragments(count, 'shapes', 'service')
    array.push(buildAnchor(childFragments, `/services${encodedFilters}`, 'color-text-purple'))
  }

  function addAgreementCount(array, count, filters) {
    if (!count) return
    const encodedFilters = buildFilters(filters, buildAgreementFilterTypes, buildAgreementIgnoredFilterTypes)
    const childFragments = buildChildFragments(count, 'agreement', 'agreement', { lg: true, autoColor: true })
    array.push(buildAnchor(childFragments, `/settings/agreements${encodedFilters}`))
  }

  function addActiveCapacityCount(array, count, filters) {
    if (!count) return
    const encodedFilters = buildFilters(filters, buildCapacityFilterTypes, buildCapacityIgnoredFilterTypes)
    const childFragments = buildChildFragments(count, 'list', 'active opportunity')
    array.push(buildAnchor(childFragments, `/opportunities${encodedFilters}`, 'color-text-purple'))
  }

  function buildFilters(filters, filterTypeBuilder, ignoredFilterTypeBuilder) {
    if (!filters) return ''
    const builtFilterTypes = filterTypeBuilder()
    const buildIgnoredFilterTypes = ignoredFilterTypeBuilder()
    const filterEncoder = new FilterEncoder(builtFilterTypes, buildIgnoredFilterTypes)
    const encodedFilters = filterEncoder.encode(filters)
    return `?filters=${encodedFilters}`
  }

  function addSimple(array, text) {
    array.push(builder.strong(text))
  }

  let isDeleting
  async function onConfirm() {
    if (isDeleting) return
    isDeleting = true
    try {
      name = strip(name)
      const result = await deleteItem()

      if (itemWasDeleted(result)) {
        const message = `Deleted ${typeLowerCase}${namePrefix ? ` ${namePrefix}` : ''} <strong>${name}</strong>!`
        toaster.toast({ html: true, message, type: 'success', icon: 'check' })
        onDelete()
        return
      }

      setFromResult(result)
      if (hasReasons) {
        toaster.toast({
          html: true,
          message: `${type}${namePrefix ? ` ${namePrefix}` : ''} <strong>${name}</strong> could not be deleted.`,
          type: 'warning',
          icon: 'alert-triangle',
        })
        return
      } else {
        toaster.toast({
          html: true,
          message: `${type}${namePrefix ? ` ${namePrefix}` : ''} <strong>${name}</strong> was already deleted.`,
          type: 'warning',
          icon: 'alert-triangle',
        })
        onDelete()
      }
    } finally {
      isDeleting = false
    }
  }
</script>
