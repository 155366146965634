import { DashboardViewCategory, PersonaType, RotationListViewType, SavedSearchType } from 'config/enums.js'

import ActiveStepsReport from './dashboard-view-types/active-step-report.js'
import ApplicationStepsReport from './dashboard-view-types/application-step-report.js'
import EvaluationStepsReport from './dashboard-view-types/evaluation-step-report.js'
import MyStepsReport from './dashboard-view-types/my-steps-report.js'
import OffboardingStepsReport from './dashboard-view-types/offboarding-step-report.js'
import OnboardingStepsReport from './dashboard-view-types/onboarding-step-report.js'
import PerformanceAndUtilizationReport from './dashboard-view-types/performance-and-utilization-report.js'
import SchoolComplianceStepsReport from './dashboard-view-types/school-compliance-step-report.js'
import capacityCalendar from './dashboard-view-types/capacity-calendar.js'
import cnOverview from './dashboard-view-types/cn-overview.js'
import consortiumRelations from './dashboard-view-types/consortium-relations.js'
import consortiumRotations from './dashboard-view-types/consortium-rotations.js'
import evaluationsReport from './dashboard-view-types/evaluations-report.js'
import hourLogReport from './dashboard-view-types/hour-log-report.js'
import personaService from 'services/persona-service.js'
import rotationBreakdownReport from './dashboard-view-types/rotation-breakdown-report.js'
import rotationList from './dashboard-view-types/rotation-list.js'
import rotationStatusHistoryReport from './dashboard-view-types/rotation-status-history-report.js'
import studentReport from './dashboard-view-types/student-report.js'

// not ready for demoing yet
// import listStudents from './dashboard-view-types/list-students.js'

const viewTypes = {
  [SavedSearchType.RotationList]: rotationList,
  [SavedSearchType.EvaluationsReport]: evaluationsReport,
  [SavedSearchType.ConsortiumRelations]: consortiumRelations,
  [SavedSearchType.ConsortiumRotations]: consortiumRotations,
  [SavedSearchType.RotationBreakdownReport]: rotationBreakdownReport,
  [SavedSearchType.RotationStatusHistoryReport]: rotationStatusHistoryReport,
  [SavedSearchType.CapacityCalendar]: capacityCalendar,
  [SavedSearchType.StudentReport]: studentReport,
  [SavedSearchType.HourLogReport]: hourLogReport,
  [SavedSearchType.SchoolComplianceStepsReport]: SchoolComplianceStepsReport,
  [SavedSearchType.ApplicationStepsReport]: ApplicationStepsReport,
  [SavedSearchType.OnboardingStepsReport]: OnboardingStepsReport,
  [SavedSearchType.ActiveStepsReport]: ActiveStepsReport,
  [SavedSearchType.MyStepsReport]: MyStepsReport,
  [SavedSearchType.EvaluationStepsReport]: EvaluationStepsReport,
  [SavedSearchType.OffboardingStepsReport]: OffboardingStepsReport,
  [SavedSearchType.PerformanceAndUtilizationReport]: PerformanceAndUtilizationReport,
  [SavedSearchType.CNOverview]: cnOverview,
}

const invertedDashboardViewCategory = _.invert(DashboardViewCategory)
const categoryColors = {
  [DashboardViewCategory.Views]: 'green',
  [DashboardViewCategory.Reports]: 'blue',
  [DashboardViewCategory.MySteps]: 'green',
}
Object.keys(viewTypes).forEach(k => {
  const vt = viewTypes[k]
  vt.savedSearchType = parseInt(k)
  vt.categoryNameSingular = invertedDashboardViewCategory[vt.category].toLowerCase().replace(/s$/, '')
  vt.color = vt.color || categoryColors[vt.category]
  vt.slug = _.kebabCase(vt.name)
  vt.templates = vt.templates || []

  vt.preparedTemplates = [
    prepareTemplate(vt, {}), // one for the base view type
    ...vt.templates.map(t => prepareTemplate(vt, t)), // one for each template merged with the base view type
  ]
})

function prepareTemplate(viewType, template) {
  let filters = []
  if (viewType.criteria && viewType.criteria.filters) filters = filters.concat(viewType.criteria.filters)
  if (template.criteria && template.criteria.filters) filters = filters.concat(template.criteria.filters)

  const prepared = {
    savedSearchType: viewType.savedSearchType,
    slug: `${_.kebabCase(viewType.name)}${template.name ? `/${_.kebabCase(template.name)}` : ''}`,
    name: viewType.name,
    description: viewType.description,
    color: viewType.color,
    icon: viewType.icon,
    category: viewType.category,

    ...template,

    criteria: {
      ...viewType.criteria,
      ...template.criteria,
      filters,
    },
  }
  return prepared
}

export function prepareCriteriaForSave(criteria) {
  const result = {}
  if (!criteria) return JSON.stringify(result)
  const keys = Object.keys(criteria)
    .filter(k => criteria[k] != null)
    .sort()
  keys.forEach(key => {
    result[key] = criteria[key]
  })
  return JSON.stringify(result)
}

export const orderedViews = [
  SavedSearchType.RotationList,
  SavedSearchType.CapacityCalendar,
  SavedSearchType.MyStepsReport,
  // [SavedSearchType.StudentList]: studentList,

  // consortium only
  SavedSearchType.ConsortiumRelations,
  SavedSearchType.ConsortiumRotations,

  // old report types
  SavedSearchType.EvaluationsReport,
  SavedSearchType.RotationBreakdownReport,
  SavedSearchType.RotationStatusHistoryReport,
  SavedSearchType.StudentReport,
  SavedSearchType.HourLogReport,
  SavedSearchType.SchoolComplianceStepsReport,
  SavedSearchType.ApplicationStepsReport,
  SavedSearchType.OnboardingStepsReport,
  SavedSearchType.ActiveStepsReport,
  SavedSearchType.EvaluationStepsReport,
  SavedSearchType.OffboardingStepsReport,
  SavedSearchType.PerformanceAndUtilizationReport,

  // cn only - may add more here
  SavedSearchType.CNOverview,
].map(key => viewTypes[key])

export function filterViewTypesToPersona(persona) {
  return orderedViews.filter(
    viewType =>
      !viewType.disabled &&
      (((viewType.permission == null || personaService.hasPermissionAnywhere(viewType.permission)) &&
        (viewType.featureTypes == null || personaService.canUseAnyFeatureType(...viewType.featureTypes))) ||
        (persona.personaType === PersonaType.Student && viewType.availableToStudents))
  )
}

export function hydrateSavedViews(savedViewsModel) {
  const views = savedViewsModel.views
  return views?.map(v => {
    const viewType = viewTypes[v.savedSearchType]
    return {
      ...v,
      viewType,
      icon: getIcon(v, viewType),
      color: v.color ?? viewType.color,
    }
  })
}

// maybe implement a standard viewType.getIcon method if we decide this is worth doing
function getIcon(view, viewType) {
  if (view.icon) return view.icon

  if (view.savedSearchType == SavedSearchType.RotationList) {
    const rotationListViewType = JSON.parse(view.search).rotationListViewType
    if (rotationListViewType === RotationListViewType.Calendar) return 'calendar'
  }

  return viewType.icon
}

export default viewTypes
