{#if conflicts}
  <MergeConflictsMatch bind:local={match} remote={matchInitial} bind:conflict={conflicts.matchUsers} />
{/if}
<Collapsible label="people" name="match-modal-people" remember={peopleCount <= 5}>
  <div slot="label">
    <div class="profile-pics">
      <div>
        <div class="title">{pluralCount('Student', match?.placeholderStudentCount + students.length)}</div>
        <div class="flex-row flex-wrap flex-align-center">
          {#if match.placeholderStudentCount}
            <PlaceholderStudentProfilePic md value={match.placeholderStudentCount} />
          {/if}
          <UserCondensedList users={students} let:user>
            <UserCondensed {user} school={match.school} showStudentInfo />
          </UserCondensedList>
        </div>
      </div>
      {#if schoolStaff.length}
        <div>
          <div class="title">School staff</div>
          <div class="flex-row flex-wrap flex-align-center">
            <UserCondensedList users={schoolStaff} let:user>
              <UserCondensed {user} school={match.school} roles={user.roleNames} />
            </UserCondensedList>
          </div>
        </div>
      {/if}

      {#if healthStaff.length}
        <div>
          <div class="title">Clinic staff</div>
          <div class="flex-row flex-wrap flex-align-center">
            <UserCondensedList users={healthStaff} let:user>
              <UserCondensed {user} roles={user.roleNames} />
            </UserCondensedList>
          </div>
        </div>
      {/if}
    </div>
  </div>
  <div class="flex-column g05">
    {#if match.capacityGuest?.allowsAddingPlaceholderStudents}
      <PlaceholderStudentsModal bind:matchDayConfigs shifts={match?.shifts} bind:value={match.placeholderStudentCount} includeLabel />
    {:else if match.placeholderStudentCount}
      <Alert type="warning">
        {#if isHealth}
          The opportunity associated with this rotation currently doesn’t allow placeholder students. You can still replace placeholder students, but
          will need to change the opportunity configuration if you would like to add more.
        {:else if isSchool}
          The opportunity associated with this rotation currently doesn’t allow placeholder students. You can still replace placeholder students, but
          <strong class="strongish">{match.org.name}</strong> will need to change the configuration if you would like to add more.
        {/if}
      </Alert>
    {/if}

    <Students bind:match {students} {userNameFriendly} {deleteMatchUser} />
    <Staff bind:match {userNameFriendly} {deleteMatchUser} {removeFacultyExtra} staff={schoolStaff} isSchoolStaff />
    <Staff bind:match {userNameFriendly} {deleteMatchUser} {removeFacultyExtra} staff={healthStaff} />
  </div></Collapsible
>

<script>
  import { PersonaType } from 'config/enums.js'
  import { pluralCount, toFriendlyList } from 'services/string-utils.js'
  import Alert from 'components/bootstrap/Alert.svelte'
  import Collapsible from 'components/Collapsible.svelte'
  import MergeConflictsMatch from 'components/MergeConflictsMatch.svelte'
  import PlaceholderStudentProfilePic from 'components/PlaceholderStudentProfilePic.svelte'
  import PlaceholderStudentsModal from 'components/PlaceholderStudentsModal.svelte'
  import Staff from 'components/MatchModal.Staff.svelte'
  import Students from 'components/MatchModal.Students.svelte'
  import user from 'stores/user.js'
  import UserCondensed from 'components/UserCondensed.svelte'
  import UserCondensedList from 'components/UserCondensedList.svelte'
  import persona from 'stores/persona'

  export let match
  export let students
  export let healthStaff = []
  export let schoolStaff = []
  export let conflicts
  export let matchInitial

  // even if they explicitly opened the people section, let's close it if there are a lot of people
  // otherwise, students don't see where they need to complete steps
  $: peopleCount = _.size(students) + _.size(schoolStaff) + _.size(healthStaff)
  $: matchDayConfigs = match?.matchDayConfigs
  $: isHealth = $persona.personaType === PersonaType.ProviderStaff
  $: isSchool = $persona.personaType === PersonaType.SchoolStaff

  function userNameFriendly(u) {
    if (u == null) return
    const loggedInUserId = $user != null ? $user.userId : null
    return loggedInUserId === u.userId ? 'yourself' : u.name
  }

  function deleteMatchUser(role) {
    return user => {
      match.matchUsers = match.matchUsers.filter(mu => !(mu.userId === user.userId && mu.matchRole === role))
      match.matchDays = match.matchDays.filter(md => md.userId !== user.userId)
    }
  }

  function removeFacultyExtra(person, matchRole) {
    if (person.roles == null || person.roles.length === 1) return ''
    const youOrThem = $user.userId === person.userId ? 'you' : 'them'
    const otherRoles = toFriendlyList(otherRoleNames(person.roles, matchRole))
    return person.roles.length > 1 ? `This will not remove ${youOrThem} as ${otherRoles} on this rotation.` : ''
  }

  function otherRoleNames(roles, matchRole) {
    return roles == null ? [] : roles.filter(r => r.value !== matchRole).map(r => r.name)
  }
</script>

<style>
  .profile-pics {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .profile-pics > div:not(:last-child, :first-child) {
    border-right: 1px solid #eee;
  }
  .profile-pics > div {
    margin-right: 20px;
    padding-right: 20px;
    margin-bottom: 15px;
  }
  .profile-pics .title {
    font-size: 12px;
    color: #999;
    font-weight: 400;
    margin-bottom: 5px;
  }
</style>
