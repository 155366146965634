<!-- Shows a tooltip of its slot if its slot gets truncated with an ellipsis -->
{#if wrapper == null}
  <!-- Effectively disable, content will likely wrap instead of overflowing -->
  <slot />
{:else if wrapper === 'span'}
  <span class={_className} bind:this={span} use:tip={tooltip}><slot /></span>
  {#if !disabled}
    <span class="hide">
      <span class={className} bind:this={spanTooltip}><slot /></span>
    </span>
  {/if}
{:else if wrapper === 'div'}
  <div class={_className} bind:this={div} use:tip={tooltip}><slot /></div>
  {#if !disabled}
    <div class="hide">
      <div class={className} bind:this={divTooltip}><slot /></div>
    </div>
  {/if}
{/if}

<script>
  import tip from 'decorators/tip.js'

  export let wrapper = 'span'
  export let disabled = false
  export { className as class }
  export let tooltipOptions = {}

  let className = null
  $: _className = `text-overflow-ellipsis${className ? ` ${className}` : ''}`

  let span = null
  let spanTooltip = null
  let div = null
  let divTooltip = null

  $: elem = span ?? div

  let needsTooltip = false
  $: if (elem) setTooltip()
  // Default to a light gray theme because most slot content will be displayed
  // on a light background anyway, so will most likely look best with a light gray tooltip.
  $: tooltip =
    needsTooltip && !disabled ? { content: spanTooltip ?? divTooltip, options: { theme: 'light-gray-scrollable', ...tooltipOptions } } : null

  // TODO(services): Handle window resize & content resize
  function setTooltip() {
    needsTooltip = elemNeedsTooltip()
  }

  function elemNeedsTooltip() {
    if (elem.clientWidth < elem.scrollWidth) return true
    for (const child of elem.children) {
      if (child.clientWidth < child.scrollWidth) return true
    }
    return false
  }
</script>
