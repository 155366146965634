{#if options}
  <InputSelect {name} multiple {placeholder} {options} {punctuation} {or} bind:value filterable={options.length > 5} {disabled} />
{/if}

<script>
  import InputSelect from 'components/fields/InputSelect.svelte'
  import api from 'services/api.js'

  export let name = 'rotation-types-picker'
  export let value = null
  export let placeholder = 'Experience types'
  export let punctuation = false
  export let or = false
  export let disabled = false

  let rotationTypes = null
  let options = null

  if (options == null) {
    if (!rotationTypes) {
      api.tag.listRotationTypes(api.noMonitor).then(data => {
        rotationTypes = data
        options = data
      })
    } else {
      options = rotationTypes
    }
  }
</script>
