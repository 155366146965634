import { StepAction, StepSubmissionStatus } from 'config/enums.js'

export function toServerModel(submission) {
  return {
    stepSubmissionId: submission.stepSubmissionId,
    matchId: submission.matchId,
    stepId: submission.stepId,
    userId: submission.userId,
    targetUserId: submission.targetUserId,
  }
}

export function canTakeAction(submission, stepAction) {
  if (stepAction === StepAction.Verify || stepAction === StepAction.Reject) return submission.canVerify
  if (stepAction === StepAction.Waive) return submission.canWaive
  return submission.canCompleteNow
}

export function isSynced(a, b) {
  if (a.completed !== b.completed) return false
  if (a.formSubmissionId !== b.formSubmissionId) return false
  if (comparableFiles(a) !== comparableFiles(b)) return false
  return true
}

export function isSyncedExact(a, b) {
  if (a.status !== b.status) return false
  return isSynced(a, b)
}

export function comparableFiles(submission) {
  // name is a unique name with guid, as opposed to `nameOriginal`
  return submission.stepSubmissionFiles ? JSON.stringify(submission.stepSubmissionFiles.map(f => f.name).sort()) : null
}

export function getStepStatusIcon(stepSubmissionStatus) {
  switch (stepSubmissionStatus) {
    case StepSubmissionStatus.Completed:
      return 'check'
    case StepSubmissionStatus.Waived:
      return 'waived'
    case StepSubmissionStatus.Skipped:
      return 'skipped'
    case StepSubmissionStatus.Verified:
      return 'check'
    case StepSubmissionStatus.Rejected:
      return 'alert-triangle'
    case StepSubmissionStatus.Incomplete:
    case StepSubmissionStatus.AwaitingVerification:
    default:
      return 'time'
  }
}

export function getStepStatusColor(stepSubmissionStatus) {
  switch (stepSubmissionStatus) {
    case StepSubmissionStatus.Completed:
    case StepSubmissionStatus.Waived:
    case StepSubmissionStatus.Skipped:
    case StepSubmissionStatus.Verified:
      return 'success'
    case StepSubmissionStatus.AwaitingVerification:
      return 'purple'
    case StepSubmissionStatus.Rejected:
      return 'danger'
    case StepSubmissionStatus.Incomplete:
    default:
      return 'warning'
  }
}
