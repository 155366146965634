<div bind:this={pageElem} class="relative">
  <div class:invisible={enabled}>
    <slot />
  </div>
  <div bind:this={scrollOffsetElem} style="position: absolute; top: {-offsetTop - 20}px" />
</div>

{#if enabled}
  <div
    bind:this={spotlightElem}
    use:portal={'#spotlights'}
    class="m0 absolute{spotlightClass ? ` ${spotlightClass}` : ''}"
    style="max-width: 100vw; max-height: 100vh; overflow: auto; top: {Math.max(0, rect.top - offset.top)}px; left: {Math.max(
      0,
      rect.left - offset.left
    )}px; {spotlightStyle ? ` ${spotlightStyle}` : ''}"
  >
    <slot name="before" />
    <div bind:this={defaultSlotElem}>
      <slot />
    </div>
    <slot name="after" />
  </div>
{/if}

<script>
  import { portal } from 'svelte-portal'
  import { tick } from 'svelte'

  export let enabled = false
  export let spotlightClass = null
  export let spotlightStyle = null

  const scrollOptions = { behavior: 'smooth', block: 'start' }
  let rect = { top: 0, left: 0 }
  let pageElem
  let scrollOffsetElem
  let spotlightElem
  let defaultSlotElem

  $: if (pageElem) updateRect()
  $: spotlightElemStyles = spotlightElem ? getComputedStyle(spotlightElem) : null
  $: containerOffsetTop = spotlightElemStyles ? parseFloat(spotlightElemStyles.borderTopWidth) : 0
  $: containerOffsetLeft = spotlightElemStyles ? parseFloat(spotlightElemStyles.borderLeftWidth) : 0
  $: offset = {
    top: containerOffsetTop + defaultSlotElem?.offsetTop || 0,
    left: containerOffsetLeft + defaultSlotElem?.offsetLeft || 0,
  }
  $: offsetTop = offset.top
  $: offsetTop, scrollOffsetElem, spotlightElem, scrollIntoView(enabled)

  function updateRect() {
    if (!pageElem) return
    rect = pageElem.getBoundingClientRect()
    requestAnimationFrame(updateRect)
  }

  async function scrollIntoView(enabled) {
    await tick()
    if (!enabled) return
    scrollOffsetElem?.scrollIntoView(scrollOptions)
  }
</script>
