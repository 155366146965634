import '../css/global.scss'
import 'core-js' // polyfills all features, including early-stage proposals: https://github.com/zloirock/core-js#commonjs-api
import 'regenerator-runtime/runtime.js'
import 'services/polyfills.js'

import App from './App.svelte'
import { handleErrors } from 'services/errors.js'

if (window.browserIsSupported) {
  handleErrors()
  new App({ target: document.getElementById('app') })
  document.getElementById('initial-loading').remove()
}
