<!--
-----------------------
When a user changes a form input value, warn them when they try:
-----------------------
- closing the tab/window
- clicking the browser back button
- manually typing in a url into the browser
- clicking an anchor (so the url doesn't change when they click a normal url and mess up history/back-button)
- closing the modal the form is in
  - though the calling component needs to listen for this and explicitly call ConfirmNavAwayInstance.show('Form name')

-----------------------
To use in your form:
-----------------------
import unsavedForms from 'stores/unsaved-forms.js'
import validator from 'services/validator.js'
const form = 'settings stuff'
$: hasChanges = !validator.equals(inputInitial, input)
$: hasChanges ? unsavedForms.add(form) : unsavedForms.del(form)
// when input is set, set inputInitial like: inputInitial = _.cloneDeep(input)
// upon successful saving or form-reset, reset inputInitial to equal input or explicitly call `unsavedForms.del(form)`
// if you need to warn explicitly due to a non-route-change event (like closing a modal that contains your form), use `navigateSafe`
-->

<div bind:this={containerEl}>
  <slot />
</div>

{#if show}
  <Modal on:close={cancelNavigateAway} title="Leave?" dataTest="confirm-navigate-away-modal">
    <div class="modal-body flex-column g1">
      <div class="text-center" data-test="confirm-navigate-away-message">
        {message}
      </div>

      {#if isDevEnvironment}
        <UnsavedFormDebug />
      {:else if $user.isImpersonating || $persona.personaType === PersonaType.CN}
        <Collapsible label="What changed? (Only Clinician Nexus staff can see this)">
          <UnsavedFormDebug />
        </Collapsible>
      {/if}
    </div>
    <div class="modal-footer text-center">
      <Btn class="btn-default" on:click={cancelNavigateAway} dataTest="stay">Stay</Btn>
      <Btn class="btn-primary" on:click={abandonChangesAndNavigate} dataTest="leave">Leave</Btn>
    </div>
  </Modal>
{/if}

<script>
  import { isDevEnvironment } from 'services/environment.js'
  import { navigate } from 'svelte-routing'
  import { PersonaType } from 'config/enums.js'
  import { shouldNavigate } from 'svelte-routing/src/utils.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Collapsible from 'components/Collapsible.svelte'
  import UnsavedFormDebug from 'components/UnsavedFormDebug.svelte'
  import Modal from 'components/Modal.svelte'
  import persona from 'stores/persona.js'
  import unsavedForms from 'stores/unsaved-forms.js'
  import user from 'stores/user.js'

  const message = 'Unsaved changes will be lost. Are you sure you want to leave?'
  let goingTo
  let containerEl
  let listening = false
  let attemptingBackbutton = false

  // backbutton handling
  // register this popstate listener before any `Router`
  // instances do, so we can stopImmediatePropagation and
  // prevent the router from swapping out the active component
  window.addEventListener('popstate', onPopState)

  $: show = $unsavedForms.warnFor != null
  $: shouldPromptOnNav = $unsavedForms.forms.length > 0
  $: if ($unsavedForms.forms.length > 0) addListeners()
  else removeListeners()

  function addListeners() {
    if (listening) return
    listening = true

    // closing window/tab
    window.onbeforeunload = beforeunload
    window.addEventListener('beforeunload', beforeunload)

    // clicking anchor
    if (containerEl) containerEl.addEventListener('click', onClick)

    // console.log('listening for nav away: ' + $unsavedForms.forms.join(', '))
  }

  function removeListeners() {
    window.onbeforeunload = null
    window.removeEventListener('beforeunload', beforeunload)
    if (containerEl) containerEl.removeEventListener('click', onClick)
    listening = false
    // console.log('not listening for nav away')
  }

  // back button
  function onPopState(e) {
    if (shouldPromptOnNav) {
      // prevent other popstate handlers from being called (i.e. the ones that svelte-routing adds)
      e.stopImmediatePropagation()
      attemptingBackbutton = true
      // put us back where we were (can't cancel popstate: https://stackoverflow.com/questions/32432296/is-it-possible-to-e-preventdefault-in-window-onpopstate)
      history.go(1)
      unsavedForms.warn()
    }
  }

  // closing tab or window
  function beforeunload(e) {
    // disable for client-side tests, so browser doesn't hang open when running tests from command line: https://github.com/cypress-io/cypress/issues/1235
    // chrome successfully ignores with a console warning, but electron browser doesn't. UPDATE: this is fixed in electron browser as of 11/08/2019: https://github.com/cypress-io/cypress/issues/2118
    // also disable when livereload is enabled during dev. else it pops up endlessly. not sure why really, but whatever
    if (window.Cypress != null || window.LiveReload != null) {
      return
    }

    // note the custom message usually isn't actually shown in browsers' implementation of beforeunload, but their default messaging is fine
    e.returnValue = message
    return message
  }

  // clicking an anchor
  function onClick(event) {
    const anchor = findClosest('A', event.target)
    goingTo = anchor?.pathname
    if (
      anchor?.target === '' &&
      anchor.host === window.location.host &&
      shouldNavigate(event) &&
      !anchor.hasAttribute('noroute') && // we may want to do opposite too
      !unsavedForms.pathIsSafe(goingTo)
    ) {
      event.preventDefault()
      event.stopPropagation()
      unsavedForms.warn()
    }
  }
  function findClosest(tagName, el) {
    while (el && el.tagName !== tagName) el = el.parentNode
    return el
  }

  function abandonChangesAndNavigate() {
    const onNavAway = $unsavedForms.warnFor.onNavAway
    if (onNavAway != null) {
      onNavAway()
    } else if (attemptingBackbutton) {
      // finish the back button action that we had programmatically reverted in popstate handler
      history.go(-1)
      attemptingBackbutton = false
    } else if (goingTo != null) {
      const isLoggingOut = goingTo.indexOf('/logout') > -1
      isLoggingOut
        ? (window.location = goingTo) //if they're logging out, we need the browser to hit the logout server endpoint
        : navigate(goingTo)
      goingTo = null
    }
    unsavedForms.navAway()
  }

  function cancelNavigateAway() {
    goingTo = null
    show = false
    unsavedForms.cancelNavAway()
  }
</script>
