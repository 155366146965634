{#if alwaysShow || !user.emailConfirmed}
  <Btn
    {clearBtnStyling}
    icon="email"
    loading={sending}
    class="{className}{clearBtnStyling ? ' specific-underline' : className ? '' : ' btn-default'}"
    {autofocus}
    {confirm}
    on:click={resendInvite}
    dataTest="resend-invite-btn"
  >
    {#if clearBtnStyling}
      <span class="underlineable">{label}</span>
    {:else}
      {label}
    {/if}
  </Btn>
{/if}

<script>
  import { createEventDispatcher } from 'svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import api from 'services/api.js'
  import toaster from 'services/toaster.js'
  import { buildConfirm, buildUserDescription } from 'services/user-description.js'

  const dispatch = createEventDispatcher()

  export let user
  export let clearBtnStyling = true
  export let alwaysShow = false
  export let shouldConfirm = true
  let className = null
  export { className as class }
  export let autofocus = false
  export let schoolId = null
  // In the future, might want to take in an optional `orgId` to use api.org.resendInvite

  const confirmOptions = {
    title: 'Resend invitation email',
    confirmLabel: 'Resend',
    confirmIcon: 'email',
  }
  $: confirm = shouldConfirm ? buildConfirm(confirmOptions, 'Are you sure you’d like to resend an invitation email to ', user) : null

  let sending = false
  $: label = sending ? 'Sending…' : 'Resend invitation'

  async function resendInvite() {
    if (sending) return
    // Capture state before API call in case `user` object changes before `await` returns
    const message = `Invitation sent to ${buildUserDescription(user)}!`
    const event = { message, shouldToast: true }
    sending = true
    try {
      if (schoolId) await api.school.resendInvite({ orgId: schoolId, studentId: user.userId })
      else await api.account.resendInvite(user.userId)
      dispatch('sent', event)
      if (event.shouldToast) {
        toaster.toast({ message, html: true, type: 'success', icon: 'check' })
      }
    } finally {
      sending = false
    }
  }
</script>
