{#if !validator.empty(content)}
  <Icon name="help" {sm} class="help-tip text-info" {focusable} {title} />
{/if}
<div class="hide">
  <div bind:this={content}>
    <slot />
  </div>
</div>

<script>
  import Icon from 'components/Icon.svelte'
  import validator from 'services/validator.js'

  export let sm = false
  export let focusable = true
  export let tipOptions = {}

  let content = null
  $: title = {
    content,
    options: {
      theme: 'help',
      interactive: true,
      maxWidth: 250,
      ...tipOptions,
    },
  }
</script>
