<Card name={staff.name} id={staff.userId} image={staff.profilePicture} class="card-medium {className ? ` ${className}` : ''}">
  <div class="flex-row flex-justify-between" style="width: 300px;">
    <div>
      <h3 class="break-spaces">
        {staff.name}
        {#if !isFaculty}
          {#if capacityAccessibilityTooltip}
            <Icon lg name="hide" class="text-danger ml05" title={capacityAccessibilityTooltip} />
          {:else}
            <Icon lg name="eye" class="text-success ml05" />
          {/if}
        {/if}
      </h3>
      {#if staff.orgTitle}
        <LongDesc text={staff.orgTitle} max={65} class="mb0" />
      {/if}
      {#if staff.titles?.length}
        <LongDesc text={staff.titles.join(', ')} max={65} class="mb0" />
      {/if}
      {#if staff.email}
        <IconTextLink icon="email" text={staff.email} href="mailto:{staff.email}" />
      {/if}
    </div>
    {#if editable}
      <div class="flex-row g05">
        {#if canManageStaff}
          <Btn
            clearBtnStyling
            on:click={() => (showUserRolesModal = true)}
            title="View this user’s titles, external ID, and roles"
            icon="edit"
            iconProps={{ class: 'outline-gray', lg: true }}
          />
        {/if}
        <div class="text-right mb05">
          <StaffConfirmDeleteModal staff={deletingStaff} {isCapacityStaff} onDelete={onDeleted} onClose={() => (deletingStaff = null)} />
          <Btn clearBtnStyling on:click={onDeleteClicked} title="Remove staff" icon="delete" iconProps={{ class: 'text-danger', lg: true }} />
        </div>
      </div>
    {/if}
  </div>
  {#if staffShiftsCount}
    <span style="text-align-center">
      Visible on
      {staffShiftsCount}
      <Icon xs name="times" />
      <Icon name="shift" class="color-text-steel" />
      Availability {pluralCount('window', staffShiftsCount, 'omitNumber')}
    </span>
  {/if}
</Card>

{#if showUserRolesModal}
  <CapacityVisibleStaffModal
    {capacity}
    {orgs}
    {teams}
    staffId={staff.staffId}
    onClose={onCloseStaffModal}
    {capacityAccessibilityTooltip}
    on:reloadStaff={reloadStaffOrTitles}
    onRemoveStaff={onDelete}
    bind:selectedTitles
  />
{/if}

<script>
  import { removeDuplicatesAndSort } from 'services/array-utils.js'
  import { pluralCount } from 'services/string-utils.js'
  import api from 'services/api.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Card from './Card.svelte'
  import Icon from 'components/Icon.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import CapacityVisibleStaffModal from 'components/CapacityVisibleStaffModal.svelte'
  import LongDesc from 'components/LongDesc.svelte'
  import roleService from 'services/role-service'
  import StaffConfirmDeleteModal from 'components/Staff.ConfirmDeleteModal.svelte'
  import unsavedForms from 'stores/unsaved-forms.js'

  export let staff
  export let capacity
  export let editable
  export let canManageStaff
  let className = ''
  export { className as class }
  export let orgs
  export let teams
  export let isCapacityStaff
  export let onDelete = _.noop
  export let isFaculty = false

  let showUserRolesModal = false
  let selectedTitles = []
  let deletingStaff

  $: capacityAccessibilityTooltip = roleService.getCapacityAccessibilityTooltip(capacity, staff.orgStaffRoles)
  $: staffShiftsCount = capacity.shifts?.filter(sh => sh.staff?.some(s => s.staffId === staff.staffId)).length || 0

  function onDeleteClicked() {
    deletingStaff = staff
  }

  function onDeleted() {
    deletingStaff = null
    onDelete(staff.userId)
  }

  function onCloseStaffModal(hasChanges) {
    if (hasChanges) {
      unsavedForms.doActionSafe(hideUserRolesModal)
    } else {
      hideUserRolesModal()
    }
  }

  function hideUserRolesModal() {
    showUserRolesModal = false
  }

  async function reloadStaffOrTitles(e) {
    if (e.detail?.updateOnlyTitle) {
      staff.titles = removeDuplicatesAndSort(selectedTitles)
      return
    }
    const body = {
      orgId: staff.staffOrgId,
      staffId: staff.staffId,
      includeEmail: true,
    }

    const response = await api.staff.list(body, api.noMonitor)
    const orgStaff = response.staff?.find(s => s.staffId == staff.staffId)
    if (orgStaff) {
      const { title, externalId, user, org, orgStaffRoles } = orgStaff
      staff.name = user.name
      staff.orgStaffRoles = orgStaffRoles
      staff.orgTitle = title
      staff.staffOrgId = org.orgId
      staff.profilePicture = user.profilePicture
      staff.externalId = externalId
      staff.titles = removeDuplicatesAndSort(selectedTitles)
    }
  }
</script>
