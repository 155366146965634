{#if value != null}
  {#if readable}
    {readableNumber(value)}
  {:else}
    {value}
  {/if}
{:else if size === 'lg'}
  <Icon title={label} lg name="infinity" {size} class="text-info" />
{:else}
  <Icon title={label} name="infinity" {size} class="text-info" />
{/if}

<script>
  import Icon from 'components/Icon.svelte'
  import { readableNumber } from 'services/string-utils'

  export let label = 'Unlimited'
  export let value = null
  export let readable = false
  export let size = 'sm'
</script>
