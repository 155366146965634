import toOrdinalNumber from './to-ordinal-number.js'
import { DisplaySymbol } from 'config/enums.js'

export function toSimpleDate(date) {
  return dayjs(date).format('M/D/YYYY')
}

export function toStudentYear(year, postfix = '') {
  if (year === 10) return `Postgraduate${postfix}`
  return year ? `${toOrdinalNumber(year)} year${postfix}` : ''
}

export function formatEnumKey(input) {
  if (input == null || input.trim() === '') return ''

  // attempt to un-capitalize cases where "A" is not in an acronym
  // could alternatively just handle these cases manually (RequireStaffToChooseFromAList)
  input = input.replace(/([a-z])A([A-Z])/g, (_, before, after) => {
    return `${before} a ${after.toLowerCase()}`
  })

  // capitalize
  input = input[0].toUpperCase() + input.slice(1)

  let first = true
  input = input
    .replace(/([A-Z][a-z])/g, (_, capitalLetterFollowedByLowercaseLetter) => {
      // only first match is capitalized
      const val = first ? ` ${capitalLetterFollowedByLowercaseLetter}` : ` ${capitalLetterFollowedByLowercaseLetter.toLowerCase()}`
      first = false
      return val
    })
    .trim()

  // put spaces before numbers too
  input = input.replace(/([0-9]+)/g, ' $1').trim()

  return input
}

export function formatEnumValue(enumObject, value) {
  const displays = enumObject[DisplaySymbol] ?? {}
  if (displays[value]) return displays[value]
  const inverted = _.invert(enumObject)
  return formatEnumKey(inverted[value])
}

export function slugifyEnumValue(enumObject, value) {
  const inverted = _.invert(enumObject)
  return _.kebabCase(inverted[value])
}
