<div class="mb2">
  <Form on:submit={() => addComment(value)} bind:this={commentForm}>
    <FormGroup class="mb0">
      <MessageBox
        bind:this={messageBoxComponent}
        placeholder="Add a comment…"
        bind:message={value}
        {mentionableUsers}
        name="new-comment"
        {disabled}
        maxlength={5000}
      />
      <HelpBlock>{commentBoxHelpText}</HelpBlock>
    </FormGroup>
    {#if hasChanges}
      <FormGroup>
        <Btn
          type="submit"
          class="btn-{!addingComment ? 'primary' : 'disabled'}"
          disabled={addingComment}
          dataTest="add-comment-btn"
          icon="paper-plane"
        >
          {addingComment ? 'Adding comment…' : 'Add comment'}
        </Btn>
      </FormGroup>
    {/if}
  </Form>
</div>

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import MessageBox from 'components/fields/MessageBox.svelte'
  import toaster from 'services/toaster.js'
  import unsavedForms from 'stores/unsaved-forms.js'
  import validator from 'services/validator.js'

  export let onAddComment
  export let mentionableUsers
  export let commentBoxHelpText
  export let disabled = false

  const form = 'MatchActivity'
  let commentForm
  let addingComment = false
  let messageBoxComponent
  let value = ''

  $: hasChanges = !validator.empty(value)
  $: hasChanges ? unsavedForms.add(form) : unsavedForms.del(form)

  async function addComment() {
    if (addingComment) return
    addingComment = true
    try {
      await onAddComment(value)
      value = ''
      messageBoxComponent?.focus?.()
      toaster.toast({ message: 'Comment saved', type: 'success', icon: 'comment-empty' })
    } finally {
      addingComment = false
    }
  }
</script>
