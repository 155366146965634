<ButtonModalPicker
  on:changed
  bind:this={buttonModalPicker}
  bind:open
  lg
  {disabled}
  {multiple}
  valueSelector={s => s.userId}
  labelSelector={s => s.name}
  options={staffMembers}
  bind:value
  bind:valueTemp
  {modalTitle}
  {placeholder}
  {dataTest}
  modalClass="overflow-visible"
  modalContentClass="overflow-visible"
>
  <svelte:fragment slot="modal-link">
    <IconTextLink text="Add {personaTypeisHealth ? 'staff' : 'faculty'}" onClick={() => (open = true)} id="add-faculty-btn" />
  </svelte:fragment>

  <slot slot="top-modal-body" name="top-modal-body" tempSelectedCount={valueTemp?.length ?? 0} />

  <FormGroup valid class="full-height flex-column g1">
    <InfiniteScroll
      currentCount={staffMembers?.length}
      {totalCount}
      distanceToLoadPage={100}
      {loadPage}
      class="scrollable-lg flex-grow flex-column {showMinimal ? 'g05' : 'g1'}"
      style="padding-right: 15px"
    >
      <EmptyPickerSlot bind:valueTemp {allowSelectNull} text="No staff member" {multiple} {buttonModalPicker} {dataTest} lg={!showMinimal} />
      {#if staffMembers?.length}
        {#each staffMembers as s, i (s.userId)}
          <PickerSlot {i} {dataTest} {multiple} value={s.userId} {buttonModalPicker} lg={!showMinimal} bind:valueTemp let:isSelected let:isHovered>
            <slot name="header" staff={s} slot="header">
              <UserProfilePicAndName
                user={s}
                profilePicSmall={showMinimal}
                tagName={showMinimal ? 'h4' : 'h3'}
                headerClass="leading-none m0 normal"
                pronounSetStyle={showMinimal ? 'font-size: 14px; display: inline' : 'font-size: 16px'}
                pronounsBelow={!showMinimal}
              />
            </slot>

            <!-- TODO(phase2-nursing): Look at how CapacityPicker does this and implement a slot that includes more details. -->
            <!--
              <slot staff={s} {isSelected} {isHovered}>
                {#if !showMinimal}
                <UserProfilePicAndName
                user={s}
                profilePicSmall={showMinimal}
                tagName={showMinimal ? 'h4' : 'h3'}
                headerClass="leading-none m0 normal"
                pronounSetStyle={showMinimal ? 'font-size: 14px; display: inline' : 'font-size: 16px'}
                pronounsBelow={!showMinimal}
                />
                {/if}
              </slot>
            -->
          </PickerSlot>
        {/each}
      {:else if staffMembers == null}
        <!-- This should never happen, but just in case... -->
        Failed to load staff members. <a href={null} on:click={() => loadPage(0)}>Retry?</a>
      {:else}
        <h4 class="p3 text-center">No staff members found.</h4>
      {/if}
    </InfiniteScroll>
  </FormGroup>
</ButtonModalPicker>

<script>
  import { FeatureType } from 'config/enums.js'
  import api from 'services/api.js'
  import ButtonModalPicker from 'components/fields/ButtonModalPicker.svelte'
  import EmptyPickerSlot from 'components/EmptyPickerSlot.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import InfiniteScroll from 'components/InfiniteScroll.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import persona from 'stores/persona.js'
  import personaService from 'services/persona-service.js'
  import PickerSlot from 'components/PickerSlot.svelte'
  // import showDropdowns from 'stores/show-dropdowns.js'
  import UserProfilePicAndName from 'components/UserProfilePicAndName.svelte'

  // Common picker exports
  export let value
  export let placeholder = 'None selected'
  export let multiple = false
  export let modalTitle = multiple ? 'Select the staff members' : 'Select the staff member'
  export let disabled = false
  export let allowSelectNull = false
  export let dataTest = 'staff-picker'
  export let open = false

  // Specific picker exports
  export let selected = null
  export let orgId
  export let allStaff = null
  export let personaTypeisHealth = false
  export let excludeUserIds = []

  let buttonModalPicker = null
  let staffMembers = []
  let valueTemp = null
  let totalCount = null

  // TODO(phase2-nursing): Should probably add a Show... dropdown and wire this up.
  $: show = {} //$showDropdowns.staffPicker
  $: showMinimal = !Object.keys(show)
    .map(k => show[k])
    .some(Boolean)
  $: value, staffMembers, setSelected()
  $: hasCoreSchedulingFeature = personaService.canUseAnyFeatureType(FeatureType.CoreScheduling)
  $: personaOrgId = $persona.orgId
  $: personaOrgId, hasCoreSchedulingFeature, loadPage(0)
  $: allStaff = staffMembers
  $: excludeUserIds, (staffMembers = staffMembers?.filter(s => !excludeUserIds.some(es => es === s.userId)))

  async function loadPage() {
    if (!$persona.orgId || !hasCoreSchedulingFeature) return
    staffMembers = personaTypeisHealth
      ? await api.staff.listPreceptors({ orgId }, api.noMonitor)
      : await api.staff.listFaculty({ orgId }, api.noMonitor)
    totalCount = staffMembers.length
    return staffMembers
  }

  function setSelected() {
    selected =
      value == null
        ? null
        : multiple
          ? staffMembers.filter(s => value.includes(s.user ? s.user.userId : s.userId))
          : staffMembers.find(s => (s.user ? s.user.userId == value : s.userId == value))
  }

  export function clear() {
    value = null
    valueTemp = null
  }

  export function focusAndOpen() {
    buttonModalPicker?.focusAndOpen()
  }
</script>
