<div
  use:tip={disabled ? { content: disabledMessage, options: { theme: 'help' } } : null}
  class="picker-slot flex-column g1 m0{className ? ` ${className}` : ''}"
  class:selected={isSelected}
  class:hovered={isHovered}
  class:lg
  class:disabled
  on:mouseenter={() => (isHovered = true)}
  on:mouseleave={() => (isHovered = false)}
  on:click={onClick}
  data-test={dataTestRoot}
>
  <div class="flex-row flex-align-center g05{showHeader ? '' : ' hide'}" data-test={dataTestHeader}>
    <slot name="header" />
    {#if !hideSelectedLabel && isSelected}
      <div class="flex-grow" />
      <Icon name="check" />
      <span data-test={dataTestSelected} class:small={!lg}>Selected</span>
    {/if}
  </div>
  {#if showCheckbox}
    <div class="flex-row flex-align-baseline g1">
      <InputCheckbox bind:checked={isSelected} on:focus={() => (isHovered = true)} on:blur={() => (isHovered = false)} />
      <slot {isSelected} {isHovered} />
    </div>
  {:else}
    <slot {isSelected} {isHovered} />
  {/if}
</div>

<script>
  import Icon from 'components/Icon.svelte'
  import InputCheckbox from 'components/fields/InputCheckbox.svelte'
  import tip from 'decorators/tip.js'

  export let i = null
  export let dataTest = null
  export let multiple
  export let valueTemp
  export let value
  export let buttonModalPicker
  export { className as class }
  export let lg = true
  export let hideSelectedLabel = false
  export let showCheckbox = false
  export let showHeader = true
  export let disabled = false
  export let disabledMessage = ''

  let className = null
  let isHovered = false

  $: isSelected = multiple ? valueTemp?.includes(value) ?? false : value === valueTemp
  $: dataTestRoot = dataTest ? `${dataTest}-${i}` : null
  $: dataTestHeader = dataTest ? `${dataTest}-header-${i}` : null
  $: dataTestSelected = dataTest ? `${dataTest}-selected-${i}` : null

  function onClick() {
    if (disabled) return

    if (multiple) {
      if (isSelected) valueTemp = valueTemp.filter(v => v !== value)
      else valueTemp = valueTemp == null ? [value] : [...valueTemp, value]
    } else {
      valueTemp = value
      setTimeout(() => {
        // buttonModalPicker may be destroyed by the time this runs
        // For example, the picker could be in an {#if} block. In that case,
        // buttonModalPicker will be non-null, but focusAndClose will be undefined.
        // Not entirely sure why it works out that way, but it does.
        buttonModalPicker.focusAndClose?.()
      })
    }
  }
</script>

<style lang="scss">
  @use 'sass:color';
  @import '../../css/helpers';

  .picker-slot {
    width: calc(100% - 10px);
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #f5f5f5;
    cursor: pointer;
    padding: 10px;

    &.lg {
      padding: 15px;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.hovered {
      border-color: color.scale($primary, $lightness: 5%);
      background: color.scale($primary, $saturation: -80%, $lightness: 80%);

      &.disabled {
        opacity: 0.5;
        border-color: #ddd;
        background: #f5f5f5;
      }
    }

    &.selected {
      border-color: $primary;
      border-width: 2px;
      padding: 9px;
      background: color.scale($primary, $saturation: -85%, $lightness: 85%);

      &.lg {
        padding: 14px;
      }

      &.hovered {
        border-color: darken($primary, 5%);
        background: color.scale($primary, $saturation: -75%, $lightness: 75%);
      }
    }
  }
</style>
