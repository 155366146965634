<Grid
  let:row
  rows={capacity.shifts}
  onRowClick={onClickShift}
  class="middle"
  bind:selectedRows={selectedShifts}
  bind:selectedRowKeys={selectedShiftIds}
  bind:selectedRowCount={selectedShiftsCount}
  showSelectedRows={selectable}
  rowKeySelector={row => row.shiftId}
>
  <slot {row} />

  <GridCol {row} header="Availability window" class="shrink" thClass="nowrap">
    <div class="flex-column g05">
      <div>
        <span class="badge color-bg-{row.color}">{row.name}</span>
      </div>
    </div>
  </GridCol>

  <GridCol {row} header={matchesPerDayHeader} class="shrink" thClass="nowrap">
    {#if row.maxMatchCountPerDay}
      <div class="text-right">{readableNumber(row.maxMatchCountPerDay)}</div>
    {:else}
      <Icon lg name="infinity" class="text-info" />
      Unlimited
    {/if}
  </GridCol>
  <GridCol {row} header="Locations">
    {#if row.locationId && orgs}
      <div>
        <Icon name="map-marker" fw />
        {orgs[row.locationId]?.name}
      </div>
    {/if}
  </GridCol>

  <GridCol {row} header="Schedules">
    {#if row.shiftDays?.length}
      {#each row.shiftDays as shiftDay}
        <ShiftDayView {capacity} shift={row} {shiftDay} />
      {/each}
    {:else}
      <ShiftDayView {capacity} shift={row} shiftDay={null} sameAsShift />
    {/if}
  </GridCol>
</Grid>

<script>
  import { readableNumber } from 'services/string-utils.js'
  import Grid from 'components/Grid.svelte'
  import GridCol from 'components/GridCol.svelte'
  import Icon from 'components/Icon.svelte'
  import ShiftDayView from 'components/ShiftDayView.svelte'

  export let capacity
  export let orgs
  export let onClickShift
  export let selectable
  export let selectedShiftIds

  let selectedShifts = []
  let selectedShiftsCount = 0

  $: matchesPerDayHeader = `${capacity.allowGroups ? 'Groups' : 'Students/learners'} per day`
</script>
