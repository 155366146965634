import { hasDifferences, isEmpty } from 'services/object-utils.js'
import { maskFormat } from 'components/fields/InputTextMasked.svelte'
import { toDomainName } from 'services/string-utils.js'
import domainRegex from 'services/domain-regex.js'

const defaultFormat = 'M/D/YYYY'

const maskChars = new RegExp(maskFormat.map(mf => _.escapeRegExp(mf.str)).join('|'), 'g')
const maskReplacements = {}
for (const mask of maskFormat) {
  maskReplacements[mask.str] = mask.regexp.source
}

const maskRegexCache = {}
function getRegexFromMask(mask) {
  const cached = maskRegexCache[mask]
  if (cached) return cached
  // Depending on what's added to maskFormat, this may be brittle.
  // Currently, this should work, supporting `0`, `*`, and `a`
  const escaped = _.escapeRegExp(mask)
  const pattern = escaped.replace(maskChars, char => maskReplacements[char])
  const regexp = new RegExp(`^${pattern}$`)
  maskRegexCache[mask] = regexp
  return regexp
}

const patternRegexCache = {}
function getRegexFromPattern(pattern) {
  const cached = patternRegexCache[pattern]
  if (cached) return cached
  const regexp = new RegExp(pattern)
  patternRegexCache[pattern] = regexp
  return regexp
}

const luhnDoubles = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]

class Validator {
  constructor() {
    // using same regex as CN.Infrastructure\Attributes\EmailAttribute.cs. TODO: send to client-side so it's DRY
    // See https://data.iana.org/TLD/tlds-alpha-by-domain.txt for a list of TLDs.
    // Note that the longest TLD is a 26-character punycode and the shortest is 2 characters.
    // Use 30 characters here to not let the regex run away, but also allow for new TLDs.
    this.emailRegex = /^[^.]([^@<>\s]+)?[^.]@[^@<>\s-][^@<>\s]+\.[a-zA-Z]{2,30}$/
    this.doublePeriodRegex = /\.\./
    this.nameWithSpaceRegex = /[^\s]+\s+[^\s]+/
    this.phoneRegex = /^([+][0-9][-\s.])?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
  }

  // validates that a date string is in the given format
  date(s, format = defaultFormat) {
    return dayjs(s, format).format(format) === s
  }

  nonStrictCheckDate(s) {
    return dayjs(s).isValid()
  }

  dateBefore(s1, s2, format = defaultFormat) {
    return dayjs(s1, format).isBefore(dayjs(s2, format))
  }

  dateSameOrBefore(s1, s2, format = defaultFormat) {
    return dayjs(s1, format).isSameOrBefore(dayjs(s2, format))
  }

  dateAfter(s1, s2, format = defaultFormat) {
    return dayjs(s1, format).isAfter(dayjs(s2, format))
  }

  inFuture(date, format = defaultFormat) {
    return this.dateAfter(dayjs(date, format), dayjs())
  }

  dateBetween(s1, start, end, format = defaultFormat) {
    if (!dayjs(s1, format).isValid() || !dayjs(start, format).isValid()) return false
    if (dayjs.utc(s1, format).isBefore(dayjs(start, format), 'days')) return false
    if (end != null && dayjs(end, format).isValid() && dayjs.utc(s1, format).isAfter(dayjs(end, format), 'days')) return false
    return true
  }

  email(s) {
    return !this.empty(s) && this.emailRegex.test(s) && !this.doublePeriodRegex.test(s)
  }

  /**
   * checks if any changes exist between a and b
   * @param {any} a
   * @param {any} b
   * @param {array<string>} ignoreKeys which object keys to ignore (foreign keys, primary keys, and other things the user won't be modding)
   */

  equals(a, b, ignoreKeys = null) {
    ignoreKeys = (ignoreKeys || []).concat('id')
    return !hasDifferences(a, b, ignoreKeys)
  }

  empty(s) {
    return isEmpty(s)
  }

  int(s) {
    return s != null && !isNaN(parseInt(s)) && isFinite(s) && parseInt(s).toString() == s.toString()
  }

  min(s, min) {
    return this.intRange(s, min, Infinity)
  }

  intRange(s, min, max) {
    const n = parseInt(s)
    return n == s && n >= min && n <= max
  }

  year(s) {
    return this.intRange(s, 1900, dayjs().add(100, 'year'))
  }

  length(s, min) {
    return !this.empty(s) && s.length >= min
  }

  name(s) {
    return this.required(s) && this.length(s, 3)
  }

  nameWithSpace(s) {
    // name must be at least: non-whitespace + whitespace + non-whitespace
    return this.required(s) && this.nameWithSpaceRegex.test(s)
  }

  numeric(s) {
    return !isNaN(parseFloat(s)) && isFinite(s)
  }

  phone(s) {
    return this.phoneRegex.test(s)
  }

  regex(pattern, s) {
    const regexp = getRegexFromPattern(pattern)
    return regexp.test(s)
  }

  mask(mask, s) {
    const regexp = getRegexFromMask(mask)
    return regexp.test(s)
  }

  luhn(s) {
    let sum = 0
    for (let i = 0; i < s.length; i++) {
      const digit = s[s.length - 1 - i] - '0'
      sum += i % 2 != 0 ? luhnDoubles[digit] : digit
    }
    return sum % 10 == 0
  }
  // If you modify this, also modify the server-side version in StringExtensions.cs - IsValidNationalProviderId
  nationalProviderId(s) {
    return s.length === 10 && this.luhn(`80840${s}`)
  }

  required(s) {
    return !this.empty(s)
  }

  checked(b) {
    return b == true
  }

  dynamicField(field, value, dateFormat) {
    if (this.empty(value)) return !field.required
    switch (field.type) {
      case 'text':
      case 'textarea':
        if (field.maxLength != null && value?.length > field.maxLength) return false
        if (field.regex) return this.regex(field.regex, value)
        else if (field.mask) return this.mask(field.mask, value)
        else return this.required(value)
      case 'select':
      case 'radiogroup':
        return this.required(value)
      case 'phone':
        return this.phone(value)
      case 'email':
        return this.email(value)
      case 'yesno':
        return value === true || value === 'true' || value === false || value === 'false'
      case 'checkbox':
        return !field.required || value === true || value === 'true'
      case 'checkboxgroup':
        return value.length
      case 'numeric':
        if (!this.numeric(value)) return false
        if (field.maxValue != null && value > field.maxValue) return false
        if (field.minValue != null && value < field.minValue) return false
        return true
      case 'date':
        return this.date(value, dateFormat)
      case 'rating':
        return this.int(value) && this.intRange(value, 1, 5)
      case 'file':
        return value?.length > 0
    }
    // console.warn('invalid field type: ', type)
    return false
  }

  containsDomain(s) {
    return toDomainName(s) != null
  }

  domain(s) {
    return domainRegex.test(s) && !this.doublePeriodRegex.test(s)
  }

  // A "formSection" is a group of form groups in a conditionally rendered DOM element.
  // When that DOM element isn't rendered, its form groups are not validated.
  // This is used to ensure that the form is valid before submitting in those cases.
  getInvalidFormSections(validationMessagesByFormGroupByFormSection) {
    const invalidFormSections = []
    for (const formSection in validationMessagesByFormGroupByFormSection) {
      const validationMessagesByFormGroup = validationMessagesByFormGroupByFormSection[formSection]
      for (const formGroup in validationMessagesByFormGroup) {
        const validationMessage = validationMessagesByFormGroup[formGroup]
        if (!this.empty(validationMessage)) {
          invalidFormSections.push(formSection)
          break
        }
      }
    }
    return invalidFormSections
  }

  isValidHttpUrl(string) {
    let url
    try {
      url = new URL(string)
    } catch (_) {
      return false
    }
    return url.protocol === 'http:' || url.protocol === 'https:'
  }
}

const validator = new Validator()

export default validator
