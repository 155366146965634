<Modal on:close={closeModal} title={modalTitle} xl={change?.changes?.matchDays != null}>
  <div slot="title">
    <h3>{modalTitle}</h3>
    {#if change.dueDate != null}
      <HelpBlock>
        {#if !validator.inFuture(change.dueDate)}
          <Icon name="alert-triangle" class="text-danger mr1" />
        {/if} due {dateService.fromnow(change.dueDate)}
      </HelpBlock>
    {/if}
  </div>

  <div class="modal-body">
    <MatchChange proposing={!change.merged && change.rejectedDate == null} {match} change={change.changes} bind:showNewSchedule />
  </div>

  {#if canAct || canRemove}
    <div class="modal-footer">
      {#if canAct}
        <Btn class="btn-primary" on:click={() => accept(change)}>Accept proposed change</Btn>
        <Btn class="btn-danger" on:click={() => (rejecting = true)}>Reject proposed change</Btn>
      {/if}
      {#if canRemove}
        <!--only creator of proposed change can delete it-->
        <Btn
          class="btn-danger"
          confirm={{
            title: 'Remove proposed change',
            message: 'Are you sure you want to remove this proposed change?',
            confirmLabel: 'Remove',
            confirmClass: 'btn-danger',
          }}
          on:click={() => deleteChange(change)}
        >
          Remove proposed change
        </Btn>
      {/if}
    </div>
  {/if}
</Modal>

{#if rejecting}
  <Modal title="Reject" on:close={() => (rejecting = false)}>
    <div class="modal-body">
      <FormGroup>
        <label for="reject-reason">Reject reason</label>
        <InputTextarea bind:value={rejectReason} placeholder="Reject reason" autofocus name="reject-reason" />
      </FormGroup>
      <Btn dataTest="confirm-reject" class="btn-danger" on:click={() => reject(change)}>Reject proposed change</Btn>
    </div>
  </Modal>
{/if}

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import dateService from 'services/date-service.js'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import getMatchController from 'services/match-controller.js'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Icon from 'components/Icon.svelte'
  import InputTextarea from 'components/fields/InputTextarea.svelte'
  import MatchChange from 'components/MatchChange.svelte'
  import Modal from 'components/Modal.svelte'
  import persona from 'stores/persona.js'
  import toaster from 'services/toaster.js'
  import user from 'stores/user.js'
  import validator from 'services/validator.js'

  export let match
  export let onMatchChanged
  export let change
  export let onClose

  let rejecting = false
  let rejectReason = ''
  let showNewSchedule = true

  $: canAct = change.canAct && change.changeSource != $persona.personaType
  $: canRemove = $user.userId === change.userId && !change.merged && change.rejectedDate == null

  $: modalTitle = (() => {
    let title

    // type of change
    if (change.merged) {
      if (change.acceptedDate) title = 'Accepted proposed change'
      else title = 'Change'
    } else {
      if (change.rejectedDate) title = 'Rejected proposed change'
      else {
        title = 'Proposed change'
      }
    }

    // from
    title += ` from ${change.proposerName}`

    return title
  })()

  async function accept(change) {
    //TODO: consider using automapper to make it so api spits out object and consumes an object, rather than json string...
    await getMatchController().acceptChangeMatch({ matchId: match.matchId }, change)
    toaster.toast({ message: 'Change accepted', type: 'success', icon: 'edit' })
    await onMatchChanged({ details: true, activity: true })
    closeModal()
  }

  async function reject(change) {
    const data = {
      matchChangeDetailId: change.matchChangeDetailId,
      rejectReason: rejectReason,
    }
    await getMatchController().rejectChangeMatch({ matchId: match.matchId }, data)
    toaster.toast({ message: 'Change rejected', type: 'danger', icon: 'edit' })
    await onMatchChanged({ details: true, activity: true })
    closeModal()
    rejecting = false
    rejectReason = ''
  }

  async function deleteChange(change) {
    await getMatchController().deleteChangeMatch({ matchId: match.matchId, matchChangeDetailId: change.matchChangeDetailId })
    toaster.toast({ message: 'Change deleted', type: 'danger', icon: 'edit' })
    await onMatchChanged({ details: true, activity: true })
    closeModal()
  }

  function closeModal() {
    onClose()
    showNewSchedule = true
  }
</script>
