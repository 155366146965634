<div class="mt2 submission-row" class:submission-row-skinny={insideOtherStepSubmissionsModal} data-test="match-step-{stepSubmission.step.name}">
  <div class="step-name-desc">
    {#if !insideOtherStepSubmissionsModal}
      <h4 class="mb0">
        {#if showStepType}
          <Icon
            name={iconByStepType[stepSubmission.step.stepType]}
            class="text-{matchStatusHelper.getByStepType(stepSubmission.step.stepType).color} mr05"
          />
        {/if}
        {#if hasViewerPermission}
          <Btn
            href="/settings/{slugify(stepTypeFriendly)}-steps/{stepSubmission.stepId}"
            title={hasEditorPermission ? 'Configure step settings' : 'View step settings'}
            clearBtnStyling
          >
            {stepSubmission.step.name}
          </Btn>
        {:else}
          {stepSubmission.step.name}
        {/if}
      </h4>
      <HelpBlock class="mt0 mb0">
        {#if showStepType}{stepTypeFriendly} requirement{:else}Requirement{/if} of {formatRelativeName(stepSubmission.step.orgRelativeName)}
        {#if stepSubmission.step.teamName}({stepSubmission.step.teamName}){/if}
      </HelpBlock>
      <MatchStepsStepInfo step={stepSubmission.step} />
    {/if}

    <div class="em mt0 small">
      <StepCompleterExplanation {stepSubmission} {startDateOverride} {endDateOverride} onAction={startAction} />
    </div>
  </div>

  <div class="small flex-grow">
    <StepSubmissionStatusInfo {stepSubmission} />
  </div>

  <div>
    <StepActions {stepSubmission} {matchModalMatchId} {startDateOverride} {endDateOverride} onAction={startAction} btnSm {disabled} />
  </div>
</div>

{#if $stepActions[actionKey]?.showOtherSubmissions}
  <!--
    When performing a step action (completing, verifying, rejecting, waiving, etc),
    prompt all submissions for shared steps in most scenarios,
    so the user can explicitly determine what to do with them on each rotation that they apply to.
  -->
  <OtherStepSubmissions
    stepAction={$stepActions[actionKey]}
    {matchModalMatchId}
    onClose={resetActionAndFireActionCompleted}
    onActionCompleted={loadAllSubmissions}
  />
{/if}

<script>
  import { formatEnumValue } from 'services/formatters.js'
  import { formatRelativeName } from 'services/orgs-service'
  import { Permission, StepSubmissionStatus, StepType, StepAction } from 'config/enums.js'
  import { slugify } from 'services/string-utils.js'
  import { toServerModel, canTakeAction, isSynced, isSyncedExact } from 'services/step-submission-service'
  import api from 'services/api.js'
  import Btn from './bootstrap/Btn.svelte'
  import getMatchController from 'services/match-controller.js'
  import HelpBlock from './fields/HelpBlock.svelte'
  import Icon from 'components/Icon.svelte'
  import matchStatusHelper from 'services/match-status-helper'
  import MatchStepsStepInfo from 'components/MatchSteps.StepInfo.svelte'
  import OtherStepSubmissions from './OtherStepSubmissions.svelte'
  import personaService from 'services/persona-service.js'
  import StepActions from './StepActions.svelte'
  import stepActions from 'stores/step-submission-row-actions.js'
  import StepCompleterExplanation from './StepCompleterExplanation.svelte'
  import StepSubmissionStatusInfo from './StepSubmissionStatusInfo.svelte'

  export let stepSubmission
  export let matchModalMatchId = null
  export let startDateOverride = null
  export let endDateOverride = null
  export let onActionCompleted = null
  export let disabled = false

  // this list is re-used to display the submission's other submissions for shared step/user combos
  // when we're looking at the submission's other submissions, we display them differently and act directly on them (i.e. don't infinitely recurse other submissions of other submissions...)
  export let insideOtherStepSubmissionsModal = false
  export let showStepType = false

  const actionKey = `${stepSubmission.matchId}-${stepSubmission.userId}-${stepSubmission.targetUserId}-${stepSubmission.stepId}`
  const iconByStepType = _.invert(StepType)

  $: hasViewerPermission = personaService.hasStaffPermission(Permission.ViewSteps, stepSubmission.step.orgId, stepSubmission.step.teamId)
  $: hasEditorPermission = personaService.hasStaffPermission(Permission.ManageSteps, stepSubmission.step.orgId, stepSubmission.step.teamId)
  $: stepTypeFriendly = formatEnumValue(StepType, stepSubmission.step.stepType)

  async function startAction(action) {
    // if another action on this submission is running, wait for it before continuing with this action (e.g. saving a form, then verifying it immediately--need to wait til the form is saved)
    if ($stepActions[actionKey]?.runningTask) {
      try {
        await $stepActions[actionKey].runningTask
      } catch (e) {
        // if the task failed, the UI will have popped open the error modal, so we can just return--we just want to let any task that might be running (successful or not) finish
      }
    }

    if ($stepActions[actionKey] == null) {
      $stepActions[actionKey] = {
        action,
        stepSubmission,
        loading: false,
      }
    }
    $stepActions[actionKey].runningTask = performAction(action)
    await $stepActions[actionKey].runningTask
  }

  async function performAction(action) {
    if (action.reset == null) action.reset = () => null

    // if shared step, determine if we need to prompt user with other instances of this user/step combo
    let shouldShowOthersAfterward = false
    if (!stepSubmission.step.perMatch && !insideOtherStepSubmissionsModal) {
      await loadAllSubmissions()
      shouldShowOthersAfterward = shouldShowOtherSubmissions()
    }

    // if no need to prompt, we can perform the action on all submissions
    // else, just perform the action on this rotation and allow the user to determine what to do on other rotations explicitly
    const submissions = (
      !insideOtherStepSubmissionsModal && !shouldShowOthersAfterward && $stepActions[actionKey].allSubmissions?.length > 0
        ? $stepActions[actionKey].allSubmissions
        : [stepSubmission]
    ).map(toServerModel)

    await action.invoke(submissions, false)

    // don't prompt until it's ready to be looked at. if they nav away, no big deal--they'll see the other matches soon enough.
    if (shouldShowOthersAfterward) {
      // Took action on this rotation and need to present the other rotatation submissions.
      // Need to reload others to re-compute whether they're synced with this submission or not.
      await loadAllSubmissions()
      $stepActions[actionKey].showOtherSubmissions = true
    } else resetActionAndFireActionCompleted()
  }

  function shouldShowOtherSubmissions() {
    // no need to prompt if user navigated away
    if ($stepActions[actionKey]?.action == null) return false
    // no need to prompt if no others
    if ($stepActions[actionKey].otherSubmissions.length === 0) return false
    // Some step actions always should prompt user about what to do with others (if there are any others) (verify|waive|reject).
    if ($stepActions[actionKey].action.alwaysShowOthers) return true
    // if some others are verified or waived or skipped, always prompt
    if ($stepActions[actionKey].otherSubmissions.some(sub => sub.status === StepSubmissionStatus.Verified || sub.waived || sub.skipped)) return true
    // if form submission and others have different submission, prompt to avoid overwriting
    if ($stepActions[actionKey].action.type === StepAction.SaveForm && $stepActions[actionKey].otherSubmissions.some(sub => !sub.synced)) return true
    return false
  }

  async function loadAllSubmissions() {
    const key = stepSubmission.submissionKey
    // massage data like `MatchSteps` does, but no need to do it's grouping/sorting
    // note we don't bother showing steps that the user can't act
    if ($stepActions[actionKey] == null) return
    $stepActions[actionKey].loading = true
    $stepActions[actionKey].allSubmissions = []
    $stepActions[actionKey].otherSubmissions = []
    let stepSubmissions
    try {
      stepSubmissions = await getMatchController().listAllSubmissionsByStepAndUser(toServerModel(stepSubmission), api.noMonitor)
    } finally {
      if ($stepActions[actionKey]) $stepActions[actionKey].loading = false
    }
    if (stepSubmissions == null) return
    const { submissions, ...step } = stepSubmissions
    if (submissions == null || $stepActions[actionKey] == null) return // if user closed modal before other submissions were loaded
    const isThisSubmission = sub => sub.submissionKey === key
    const updatedStepSubmission = submissions.find(isThisSubmission)
    const submissionsMapped = submissions.map(submission => ({
      ...submission,
      step,
      // TODO: not sure why updatedStepSubmission would ever be null, but this pops up in production.
      //       maybe could happen if someone deleted this submission while this person was modifying it, so let's just consider it not-synced.
      synced: updatedStepSubmission == null ? false : isSynced(updatedStepSubmission, submission),
      syncedExact: updatedStepSubmission == null ? false : isSyncedExact(updatedStepSubmission, submission),
    }))
    $stepActions[actionKey].allSubmissions = _.orderBy(
      submissionsMapped.filter(submission => canTakeAction(submission, $stepActions[actionKey].action?.type)),
      s => (s.match ? new Date(s.match.startDate) : null)
    )
    $stepActions[actionKey].otherSubmissions = $stepActions[actionKey].allSubmissions.filter(sub => !isThisSubmission(sub))
    $stepActions[actionKey].stepSubmission = submissionsMapped.find(isThisSubmission)
  }

  function resetActionAndFireActionCompleted() {
    $stepActions[actionKey]?.action?.reset?.()
    onActionCompleted?.()
    $stepActions[actionKey] = null
    delete $stepActions[actionKey]
  }
</script>

<style>
  .submission-row {
    display: flex;
  }

  .step-name-desc {
    width: 350px;
  }

  .submission-row-skinny {
    display: block;
  }
  .submission-row-skinny > div:not(:last-child) {
    padding-right: 10px;
  }

  @media only screen and (max-width: 700px) {
    .submission-row {
      display: block;
    }

    .submission-row > div:not(:last-child) {
      padding-right: 10px;
    }
  }
</style>
