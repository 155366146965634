<!--
  given 2 strings, show a diff.
  we don't show a merged result, since we only ever use one or the other (if you need a merged result, check file history on this and find where we used to use "patch" method).
  the user gets ability to modify the final result though (so basically a manual merge). Giving a merged result might be confusing for an average user.
-->

{#if hasChanges}
  <div>
    <div class="btn-group pull-right">
      <Btn class="btn-sm btn-{show === 'new' ? 'primary' : 'default'}" on:click={() => (show = 'new')}>
        {#if proposing}Proposed changes{:else if conflicted}Current changes{:else}Change{/if}
      </Btn>
      <Btn class="btn-sm btn-{show === 'old' ? 'primary' : 'default'}" on:click={() => (show = 'old')}>
        {#if proposing}Current{:else if conflicted}Your unsaved{:else}Previous{/if}
      </Btn>
      <Btn class="btn-sm btn-{show === 'diff' ? 'primary' : 'default'}" on:click={() => (show = 'diff')}>Differences</Btn>
    </div>
    {#if title}
      <h3>{title} changed</h3>
    {/if}
  </div>

  <div class="clearfix" />
  <div class="well">
    {#if show === 'diff'}
      <SafeHtml value={diff} />
    {:else}
      <!-- TODO(scheduling): when content overflows textarea, it isn't scrollable, do something better here -->
      <!--just using textarea to display. no editing from here currently-->
      <InputTextarea disabled autofocus value={show === 'new' ? newVersionString : oldVersionString} name="version-box" />
    {/if}
  </div>
{/if}

<script>
  import SafeHtml from 'components/SafeHtml.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import InputTextarea from 'components/fields/InputTextarea.svelte'
  import validator from 'services/validator.js'
  import diffMatchPatch from 'diff-match-patch'

  export let proposing = false
  export let conflicted = false
  export let title = null
  export let oldVersion = ''
  export let newVersion = ''

  const dmp = new diffMatchPatch.diff_match_patch()
  let show = 'diff'

  $: oldVersionString = oldVersion != null ? oldVersion.toString() : ''
  $: newVersionString = newVersion != null ? newVersion.toString() : ''

  $: hasChanges = (() => {
    if (validator.empty(newVersion) && validator.empty(oldVersionString)) return false // both either null or empty
    return oldVersionString !== newVersionString
  })()

  $: diff = (() => {
    if (oldVersionString == null || newVersionString == null) return ''
    const diff = dmp.diff_main(oldVersionString, newVersionString)
    const diffPretty = dmp.diff_prettyHtml(diff)
    return diffPretty
  })()
</script>
