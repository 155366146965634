{#if value.length === 0}
  {labelNone}
{:else if isAllDays}
  {labelAll}
{:else}
  the
  <FriendlyList max={Infinity} punctuation items={labelItems} let:item>
    {#if Array.isArray(item)}
      {#if item.length === 1}
        {#if item[0] === 32}
          {#if labelItems.length > 1}
            the
          {/if}
          last day of the month
        {:else}
          <OrdinalNumber n={item[0]} />
        {/if}
      {:else}
        <OrdinalNumber n={item[0]} />–<OrdinalNumber n={item[1]} />
      {/if}
    {:else}
      {item}
    {/if}
  </FriendlyList>
{/if}

<script context="module">
  export const options = _.range(1, 31)
</script>

<script>
  import { groupByContiguousFriendly } from 'services/array-utils.js'
  import { pluralCount } from 'services/string-utils.js'
  import FriendlyList from 'components/FriendlyList.svelte'
  import OrdinalNumber from 'components/OrdinalNumber.svelte'

  export let value = []
  export let labelNone = 'no days'
  export let labelAll = 'all days'
  export let maxWordCount = 3

  let labelItems = []
  $: valueSorted = value.toSorted((a, b) => a - b)
  $: value, updateLabel()
  $: secondToLastValueSorted = valueSorted.length > 1 ? valueSorted[valueSorted.length - 2] : null
  $: lastValueSorted = valueSorted.length ? valueSorted[valueSorted.length - 1] : null
  $: isAllDays = valueSorted.length === 32 || (valueSorted.length === 31 && secondToLastValueSorted === 30 && [31, 32].includes(lastValueSorted))

  function updateLabel() {
    const leastRedundantDaysOfMonth = getLeastRedundantRepeatDaysOfMonth(valueSorted)
    const ranges = groupByContiguousFriendly(leastRedundantDaysOfMonth)
    let countWords = 0
    let countOthers = 0
    const items = []
    for (let i = 0; i < ranges.length; i++) {
      const range = ranges[i]
      if (countWords <= maxWordCount || (countOthers === 0 && i === ranges.length - 1)) {
        items.push(range)
        countWords += range.length
      } else if (range.length === 1) {
        countOthers++
      } else {
        countOthers += 1 + range[1] - range[0]
      }
    }
    if (countOthers) items.push(pluralCount('other day', countOthers))
    labelItems = items
  }

  // If you change this, consider also changing DateService.GetLeastRedundantRepeatDaysOfMonth.
  // If the value contains the 28th through 31st, there's no reason to say "last day of the month"
  // However, if the value contains the 31st and we're gonna say "the last day of the month", we should remove the 31st
  // because the 31st would always be the last day of the month.
  // Assumes value is sorted.
  function getLeastRedundantRepeatDaysOfMonth(daysOfMonth) {
    const len = daysOfMonth.length
    if (len < 2) return daysOfMonth
    const hasBoth31stAndLast = daysOfMonth[len - 1] === 32 && daysOfMonth[len - 2] === 31
    if (!hasBoth31stAndLast) return daysOfMonth
    if (len < 5) return [...daysOfMonth.toSpliced(len - 2, 2), 32] // Remove the 31st
    const has28 = daysOfMonth[len - 5] == 28
    const has29 = daysOfMonth[len - 4] == 29
    const has30 = daysOfMonth[len - 3] == 30
    if (!has28 || !has29 || !has30) return [...daysOfMonth.toSpliced(len - 2, 2), 32] // Remove the 31st
    return daysOfMonth.toSpliced(len - 1, 1) // Remove "the last day of the month"
  }
</script>
