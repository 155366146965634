import { ArchiveActiveFilter, FilterType } from 'config/enums.js'
import FilterTypeAgreement, { meta as FilterTypeAgreementMeta } from 'components/filter-types/FilterTypeAgreement.svelte'
import FilterTypeAgreementStatusNow, { meta as FilterTypeAgreementStatusNowMeta } from 'components/filter-types/FilterTypeAgreementStatusNow.svelte'
import FilterTypeAgreementStatusPast, {
  meta as FilterTypeAgreementStatusPastMeta,
} from 'components/filter-types/FilterTypeAgreementStatusPast.svelte'
import FilterTypeCanManage, { meta as FilterTypeCanManageMeta } from 'components/filter-types/FilterTypeCanManage.svelte'
import FilterTypeCapacity, { meta as FilterTypeCapacityMeta } from 'components/filter-types/FilterTypeCapacity.svelte'
import FilterTypeCapacityAllowCoordinatorScheduling, {
  meta as FilterTypeCapacityAllowCoordinatorSchedulingMeta,
} from 'components/filter-types/FilterTypeCapacityAllowCoordinatorScheduling.svelte'
import FilterTypeCapacityAllowPlaceholderStudents, {
  meta as FilterTypeCapacityAllowPlaceholderStudentsMeta,
} from 'components/filter-types/FilterTypeCapacityAllowPlaceholderStudents.svelte'
import FilterTypeCapacityAllowGroups, {
  meta as FilterTypeCapacityAllowGroupsMeta,
} from 'components/filter-types/FilterTypeCapacityAllowGroups.svelte'
import FilterTypeCapacityEndDate, { meta as FilterTypeCapacityEndDateMeta } from 'components/filter-types/FilterTypeCapacityEndDate.svelte'
import FilterTypeCapacityLocation, { meta as FilterTypeCapacityLocationMeta } from 'components/filter-types/FilterTypeCapacityLocation.svelte'
import FilterTypeCapacityMatchConfirmation, {
  meta as FilterTypeCapacityMatchConfirmationMeta,
} from 'components/filter-types/FilterTypeCapacityMatchConfirmation.svelte'
import FilterTypeCapacityMatchCounts, {
  meta as FilterTypeCapacityMatchCountsMeta,
} from 'components/filter-types/FilterTypeCapacityMatchCounts.svelte'
import FilterTypeCapacityOpenForScheduling, {
  meta as FilterTypeCapacityOpenForSchedulingMeta,
} from 'components/filter-types/FilterTypeCapacityOpenForScheduling.svelte'
import FilterTypeCapacityPostToSearchPage, {
  meta as FilterTypeCapacityPostToSearchPageMeta,
} from 'components/filter-types/FilterTypeCapacityPostToSearchPage.svelte'
import FilterTypeCapacityStartDate, { meta as FilterTypeCapacityStartDateMeta } from 'components/filter-types/FilterTypeCapacityStartDate.svelte'
import FilterTypeCapacityStudentCount, {
  meta as FilterTypeCapacityStudentCountMeta,
} from 'components/filter-types/FilterTypeCapacityStudentCount.svelte'
import FilterTypeCloseReason, { meta as FilterTypeCloseReasonMeta } from 'components/filter-types/FilterTypeCloseReason.svelte'
import FilterTypeClosedDate, { meta as FilterTypeClosedDateMeta } from 'components/filter-types/FilterTypeClosedDate.svelte'
import FilterTypeCompleteFromDate, { meta as FilterTypeCompleteFromDateMeta } from 'components/filter-types/FilterTypeCompleteFromDate.svelte'
import FilterTypeCoordinator, { meta as FilterTypeCoordinatorMeta } from 'components/filter-types/FilterTypeCoordinator.svelte'
import FilterTypeCourse, { meta as FilterTypeCourseMeta } from 'components/filter-types/FilterTypeCourse.svelte'
import FilterTypeCourseEndDate, { meta as FilterTypeCourseEndDateMeta } from 'components/filter-types/FilterTypeCourseEndDate.svelte'
import FilterTypeCourseStartDate, { meta as FilterTypeCourseStartDateMeta } from 'components/filter-types/FilterTypeCourseStartDate.svelte'
import FilterTypeCustomTagMatch, { meta as FilterTypeCustomTagMatchMeta } from 'components/filter-types/FilterTypeCustomTagMatch.svelte'
import FilterTypeCustomTagStudent, { meta as FilterTypeCustomTagStudentMeta } from 'components/filter-types/FilterTypeCustomTagStudent.svelte'
import FilterTypeDateModified, { meta as FilterTypeDateModifiedMeta } from 'components/filter-types/FilterTypeDateModified.svelte'
import FilterTypeDiscipline, { meta as FilterTypeDisciplineMeta } from 'components/filter-types/FilterTypeDiscipline.svelte'
import FilterTypeDueDate, { meta as FilterTypeDueDateMeta } from 'components/filter-types/FilterTypeDueDate.svelte'
import FilterTypeGuestSchedulingEndDate, {
  meta as FilterTypeGuestSchedulingEndDateMeta,
} from 'components/filter-types/FilterTypeGuestSchedulingEndDate.svelte'
import FilterTypeGuestSchedulingStartDate, {
  meta as FilterTypeGuestSchedulingStartDateMeta,
} from 'components/filter-types/FilterTypeGuestSchedulingStartDate.svelte'
import FilterTypeHasIncompleteSteps, { meta as FilterTypeHasIncompleteStepsMeta } from 'components/filter-types/FilterTypeHasIncompleteSteps.svelte'
import FilterTypeHasIssues, { meta as FilterTypeHasIssuesMeta } from 'components/filter-types/FilterTypeHasIssues.svelte'
import FilterTypeHasProposedChanges, { meta as FilterTypeHasProposedChangesMeta } from 'components/filter-types/FilterTypeHasProposedChanges.svelte'
import FilterTypeHasRotations, { meta as FilterTypeHasRotationsMeta } from 'components/filter-types/FilterTypeHasRotations.svelte'
import FilterTypeRotationRequestRestrictionEndDate, {
  meta as FilterTypeRotationRequestRestrictionEndDateMeta,
} from 'components/filter-types/FilterTypeRotationRequestRestrictionEndDate.svelte'
import FilterTypeRotationRequestRestrictionStartDate, {
  meta as FilterTypeRotationRequestRestrictionStartDateMeta,
} from 'components/filter-types/FilterTypeRotationRequestRestrictionStartDate.svelte'
import FilterTypeHasStepsDueSoon, { meta as FilterTypeHasStepsDueSoonMeta } from 'components/filter-types/FilterTypeHasStepsDueSoon.svelte'
import FilterTypeHasStepsICanComplete, {
  meta as FilterTypeHasStepsICanCompleteMeta,
} from 'components/filter-types/FilterTypeHasStepsICanComplete.svelte'
import FilterTypeHasStepsICanVerify, { meta as FilterTypeHasStepsICanVerifyMeta } from 'components/filter-types/FilterTypeHasStepsICanVerify.svelte'
import FilterTypeHostTeam, { meta as FilterTypeHostTeamMeta } from 'components/filter-types/FilterTypeHostTeam.svelte'
import FilterTypeICanComplete, { meta as FilterTypeICanCompleteMeta } from 'components/filter-types/FilterTypeICanComplete.svelte'
import FilterTypeICanVerify, { meta as FilterTypeICanVerifyMeta } from 'components/filter-types/FilterTypeICanVerify.svelte'
import FilterTypeIsCompleted, { meta as FilterTypeIsCompletedMeta } from 'components/filter-types/FilterTypeIsCompleted.svelte'
import FilterTypeIsRejected, { meta as FilterTypeIsRejectedMeta } from 'components/filter-types/FilterTypeIsRejected.svelte'
import FilterTypeIsSkipped, { meta as FilterTypeIsSkippedMeta } from 'components/filter-types/FilterTypeIsSkipped.svelte'
import FilterTypeIsVerified, { meta as FilterTypeIsVerifiedMeta } from 'components/filter-types/FilterTypeIsVerified.svelte'
import FilterTypeIsWaived, { meta as FilterTypeIsWaivedMeta } from 'components/filter-types/FilterTypeIsWaived.svelte'
import FilterTypeKeyword, { meta as FilterTypeKeywordMeta } from 'components/filter-types/FilterTypeKeyword.svelte'
import FilterTypeMatchCreatedDate, { meta as FilterTypeMatchCreatedDateMeta } from 'components/filter-types/FilterTypeCreatedDate.svelte'
import FilterTypeMatchEndDate, { meta as FilterTypeMatchEndDateMeta } from 'components/filter-types/FilterTypeMatchEndDate.svelte'
import FilterTypeMatchRequestType, { meta as FilterTypeMatchRequestTypeMeta } from 'components/filter-types/FilterTypeMatchRequestType.svelte'
import FilterTypeMatchHasPlaceholderStudents, {
  meta as FilterTypeMatchHasPlaceholderStudentsMeta,
} from 'components/filter-types/FilterTypeMatchHasPlaceholderStudents.svelte'
import FilterTypeMatchStartDate, { meta as FilterTypeMatchStartDateMeta } from 'components/filter-types/FilterTypeMatchStartDate.svelte'
import FilterTypeMatchStatusNow, { meta as FilterTypeMatchStatusNowMeta } from 'components/filter-types/FilterTypeMatchStatusNow.svelte'
import FilterTypeMatchStatusPast, { meta as FilterTypeMatchStatusPastMeta } from 'components/filter-types/FilterTypeMatchStatusPast.svelte'
import FilterTypeMatchSubmittedDate, { meta as FilterTypeMatchSubmittedDateMeta } from 'components/filter-types/FilterTypeSubmittedDate.svelte'
import FilterTypeNameSuggestionFieldComparison, {
  meta as FilterTypeNameSuggestionFieldComparisonMeta,
} from 'components/filter-types/FilterTypeNameSuggestionFieldComparison.svelte'
import FilterTypeNameSuggestionStatus, {
  meta as FilterTypeNameSuggestionStatusMeta,
} from 'components/filter-types/FilterTypeNameSuggestionStatus.svelte'
import FilterTypeOrgAddress, { meta as FilterTypeOrgAddressMeta } from 'components/filter-types/FilterTypeOrgAddress.svelte'
import FilterTypeOrgHealth, { meta as FilterTypeOrgHealthMeta } from 'components/filter-types/FilterTypeOrgHealth.svelte'
import FilterTypeOrgHealthAddress, { meta as FilterTypeOrgHealthAddressMeta } from 'components/filter-types/FilterTypeOrgHealthAddress.svelte'
import FilterTypeOrgOwner, { meta as FilterTypeOrgOwnerMeta } from 'components/filter-types/FilterTypeOrgOwner.svelte'
import FilterTypeOrgSchool, { meta as FilterTypeOrgSchoolMeta } from 'components/filter-types/FilterTypeOrgSchool.svelte'
import FilterTypeOrgSchoolAddress, { meta as FilterTypeOrgSchoolAddressMeta } from 'components/filter-types/FilterTypeOrgSchoolAddress.svelte'
import FilterTypeOrgTag, { meta as FilterTypeOrgTagMeta } from 'components/filter-types/FilterTypeOrgTag.svelte'
import FilterTypeOrgTarget, { meta as FilterTypeOrgTargetMeta } from 'components/filter-types/FilterTypeOrgTarget.svelte'
import FilterTypeOwnerUser, { meta as FilterTypeOwnerUserMeta } from 'components/filter-types/FilterTypeOwnerUser.svelte'
import FilterTypePreceptor, { meta as FilterTypePreceptorMeta } from 'components/filter-types/FilterTypePreceptor.svelte'
import FilterTypeRotationType, { meta as FilterTypeRotationTypeMeta } from 'components/filter-types/FilterTypeRotationType.svelte'
import FilterTypeSchoolCoordinator, { meta as FilterTypeSchoolCoordinatorMeta } from 'components/filter-types/FilterTypeSchoolCoordinator.svelte'
import FilterTypeSchoolFaculty, { meta as FilterTypeSchoolFacultyMeta } from 'components/filter-types/FilterTypeSchoolFaculty.svelte'
import FilterTypeService, { meta as FilterTypeServiceMeta } from 'components/filter-types/FilterTypeService.svelte'
import FilterTypeSpecialty, { meta as FilterTypeSpecialtyMeta } from 'components/filter-types/FilterTypeSpecialty.svelte'
import FilterTypeStaffRole, { meta as FilterTypeStaffRoleMeta } from 'components/filter-types/FilterTypeStaffRole.svelte'
import FilterTypeStep, { meta as FilterTypeStepMeta } from 'components/filter-types/FilterTypeStep.svelte'
import FilterTypeStepSubmissionStatus, {
  meta as FilterTypeStepSubmissionStatusMeta,
} from 'components/filter-types/FilterTypeStepSubmissionStatus.svelte'
import FilterTypeStepSubmissionTargetUser, {
  meta as FilterTypeStepSubmissionTargetUserMeta,
} from 'components/filter-types/FilterTypeStepSubmissionTargetUser.svelte'
import FilterTypeStepType, { meta as FilterTypeStepTypeMeta } from 'components/filter-types/FilterTypeStepType.svelte'
import FilterTypeStudent, { meta as FilterTypeStudentMeta } from 'components/filter-types/FilterTypeStudent.svelte'
import FilterTypeStudentGraduationDate, {
  meta as FilterTypeStudentGraduationDateMeta,
} from 'components/filter-types/FilterTypeStudentGraduationDate.svelte'
import FilterTypeStudentYears, { meta as FilterTypeStudentYearsMeta } from 'components/filter-types/FilterTypeStudentYears.svelte'
import FilterTypeVerificationExpirationDate, {
  meta as FilterTypeVerificationExpirationDateMeta,
} from 'components/filter-types/FilterTypeVerificationExpirationDate.svelte'
import FilterTypeWaitlistedBy, { meta as FilterTypeWaitlistedByMeta } from 'components/filter-types/FilterTypeWaitlistedBy.svelte'

FilterTypeAgreementMeta.component = FilterTypeAgreement
FilterTypeAgreementStatusNowMeta.component = FilterTypeAgreementStatusNow
FilterTypeAgreementStatusPastMeta.component = FilterTypeAgreementStatusPast
FilterTypeCanManageMeta.component = FilterTypeCanManage
FilterTypeCapacityAllowCoordinatorSchedulingMeta.component = FilterTypeCapacityAllowCoordinatorScheduling
FilterTypeCapacityAllowPlaceholderStudentsMeta.component = FilterTypeCapacityAllowPlaceholderStudents
FilterTypeCapacityAllowGroupsMeta.component = FilterTypeCapacityAllowGroups
FilterTypeCapacityEndDateMeta.component = FilterTypeCapacityEndDate
FilterTypeGuestSchedulingStartDateMeta.component = FilterTypeGuestSchedulingStartDate
FilterTypeGuestSchedulingEndDateMeta.component = FilterTypeGuestSchedulingEndDate
FilterTypeCapacityLocationMeta.component = FilterTypeCapacityLocation
FilterTypeCapacityMeta.component = FilterTypeCapacity
FilterTypeCapacityOpenForSchedulingMeta.component = FilterTypeCapacityOpenForScheduling
FilterTypeCapacityPostToSearchPageMeta.component = FilterTypeCapacityPostToSearchPage
FilterTypeCapacityMatchConfirmationMeta.component = FilterTypeCapacityMatchConfirmation
FilterTypeCapacityMatchCountsMeta.component = FilterTypeCapacityMatchCounts
FilterTypeCapacityStudentCountMeta.component = FilterTypeCapacityStudentCount
FilterTypeCapacityStartDateMeta.component = FilterTypeCapacityStartDate
FilterTypeClosedDateMeta.component = FilterTypeClosedDate
FilterTypeCloseReasonMeta.component = FilterTypeCloseReason
FilterTypeCoordinatorMeta.component = FilterTypeCoordinator
FilterTypeCustomTagMatchMeta.component = FilterTypeCustomTagMatch
FilterTypeCustomTagStudentMeta.component = FilterTypeCustomTagStudent
FilterTypeDisciplineMeta.component = FilterTypeDiscipline
FilterTypeHasIncompleteStepsMeta.component = FilterTypeHasIncompleteSteps
FilterTypeHasStepsDueSoonMeta.component = FilterTypeHasStepsDueSoon
FilterTypeHasProposedChangesMeta.component = FilterTypeHasProposedChanges
FilterTypeHasRotationsMeta.component = FilterTypeHasRotations
FilterTypeRotationRequestRestrictionEndDateMeta.component = FilterTypeRotationRequestRestrictionEndDate
FilterTypeRotationRequestRestrictionStartDateMeta.component = FilterTypeRotationRequestRestrictionStartDate
FilterTypeHasStepsICanCompleteMeta.component = FilterTypeHasStepsICanComplete
FilterTypeICanCompleteMeta.component = FilterTypeICanComplete
FilterTypeICanVerifyMeta.component = FilterTypeICanVerify
FilterTypeIsCompletedMeta.component = FilterTypeIsCompleted
FilterTypeIsVerifiedMeta.component = FilterTypeIsVerified
FilterTypeIsRejectedMeta.component = FilterTypeIsRejected
FilterTypeIsWaivedMeta.component = FilterTypeIsWaived
FilterTypeIsSkippedMeta.component = FilterTypeIsSkipped
FilterTypeHasStepsICanVerifyMeta.component = FilterTypeHasStepsICanVerify
FilterTypeHostTeamMeta.component = FilterTypeHostTeam
FilterTypeKeywordMeta.component = FilterTypeKeyword
FilterTypeMatchCreatedDateMeta.component = FilterTypeMatchCreatedDate
FilterTypeMatchEndDateMeta.component = FilterTypeMatchEndDate
FilterTypeMatchRequestTypeMeta.component = FilterTypeMatchRequestType
FilterTypeMatchHasPlaceholderStudentsMeta.component = FilterTypeMatchHasPlaceholderStudents
FilterTypeMatchStartDateMeta.component = FilterTypeMatchStartDate
FilterTypeCourseStartDateMeta.component = FilterTypeCourseStartDate
FilterTypeCourseEndDateMeta.component = FilterTypeCourseEndDate
FilterTypeDateModifiedMeta.component = FilterTypeDateModified
FilterTypeDueDateMeta.component = FilterTypeDueDate
FilterTypeCompleteFromDateMeta.component = FilterTypeCompleteFromDate
FilterTypeVerificationExpirationDateMeta.component = FilterTypeVerificationExpirationDate
FilterTypeMatchStatusNowMeta.component = FilterTypeMatchStatusNow
FilterTypeMatchStatusPastMeta.component = FilterTypeMatchStatusPast
FilterTypeMatchSubmittedDateMeta.component = FilterTypeMatchSubmittedDate
FilterTypeStepSubmissionStatusMeta.component = FilterTypeStepSubmissionStatus
FilterTypeOrgHealthAddressMeta.component = FilterTypeOrgHealthAddress
FilterTypeOrgAddressMeta.component = FilterTypeOrgAddress
FilterTypeOrgHealthMeta.component = FilterTypeOrgHealth
FilterTypeOrgOwnerMeta.component = FilterTypeOrgOwner
FilterTypeOrgSchoolAddressMeta.component = FilterTypeOrgSchoolAddress
FilterTypeOrgSchoolMeta.component = FilterTypeOrgSchool
FilterTypeOrgTargetMeta.component = FilterTypeOrgTarget
FilterTypeOwnerUserMeta.component = FilterTypeOwnerUser
FilterTypePreceptorMeta.component = FilterTypePreceptor
FilterTypeRotationTypeMeta.component = FilterTypeRotationType
FilterTypeOrgTagMeta.component = FilterTypeOrgTag
FilterTypeSchoolCoordinatorMeta.component = FilterTypeSchoolCoordinator
FilterTypeSchoolFacultyMeta.component = FilterTypeSchoolFaculty
FilterTypeServiceMeta.component = FilterTypeService
FilterTypeSpecialtyMeta.component = FilterTypeSpecialty
FilterTypeStaffRoleMeta.component = FilterTypeStaffRole
FilterTypeStepMeta.component = FilterTypeStep
FilterTypeStepTypeMeta.component = FilterTypeStepType
FilterTypeCourseMeta.component = FilterTypeCourse
FilterTypeStepSubmissionTargetUserMeta.component = FilterTypeStepSubmissionTargetUser
FilterTypeStudentGraduationDateMeta.component = FilterTypeStudentGraduationDate
FilterTypeStudentMeta.component = FilterTypeStudent
FilterTypeStudentYearsMeta.component = FilterTypeStudentYears
FilterTypeWaitlistedByMeta.component = FilterTypeWaitlistedBy
FilterTypeNameSuggestionStatusMeta.component = FilterTypeNameSuggestionStatus
FilterTypeHasIssuesMeta.component = FilterTypeHasIssues
FilterTypeNameSuggestionFieldComparisonMeta.component = FilterTypeNameSuggestionFieldComparison

export const allFilterTypes = [
  FilterTypeKeywordMeta,
  FilterTypeMatchStatusNowMeta,
  FilterTypeMatchStatusPastMeta,
  FilterTypeStepSubmissionStatusMeta,
  FilterTypeAgreementStatusNowMeta,
  FilterTypeAgreementStatusPastMeta,
  FilterTypeStepMeta,
  FilterTypeStaffRoleMeta,
  FilterTypeStepTypeMeta,
  FilterTypeHasStepsDueSoonMeta,
  FilterTypeHasIncompleteStepsMeta,
  FilterTypeHasStepsICanCompleteMeta,
  FilterTypeHasStepsICanVerifyMeta,
  FilterTypeIsCompletedMeta,
  FilterTypeIsVerifiedMeta,
  FilterTypeIsRejectedMeta,
  FilterTypeIsWaivedMeta,
  FilterTypeIsSkippedMeta,
  FilterTypeStepSubmissionTargetUserMeta,
  FilterTypeWaitlistedByMeta,
  FilterTypeHasProposedChangesMeta,
  FilterTypeHasRotationsMeta,
  FilterTypeRotationRequestRestrictionStartDateMeta,
  FilterTypeRotationRequestRestrictionEndDateMeta,
  FilterTypeMatchCreatedDateMeta,
  FilterTypeMatchSubmittedDateMeta,
  FilterTypeMatchStartDateMeta,
  FilterTypeDueDateMeta,
  FilterTypeCompleteFromDateMeta,
  FilterTypeDateModifiedMeta,
  FilterTypeVerificationExpirationDateMeta,
  FilterTypeICanCompleteMeta,
  FilterTypeICanVerifyMeta,
  FilterTypeMatchEndDateMeta,
  FilterTypeCourseStartDateMeta,
  FilterTypeCourseEndDateMeta,
  FilterTypeClosedDateMeta,
  FilterTypeCloseReasonMeta,
  FilterTypeRotationTypeMeta,
  FilterTypeOrgTagMeta,
  FilterTypeSpecialtyMeta,
  FilterTypeStudentMeta,
  FilterTypeDisciplineMeta,
  FilterTypeStudentYearsMeta,
  FilterTypeStudentGraduationDateMeta,
  FilterTypeOrgSchoolMeta,
  FilterTypeCourseMeta,
  FilterTypeOrgSchoolAddressMeta,
  FilterTypeSchoolCoordinatorMeta,
  FilterTypeSchoolFacultyMeta,
  FilterTypeAgreementMeta,
  FilterTypeOrgHealthMeta,
  FilterTypeOrgHealthAddressMeta,
  FilterTypeOrgAddressMeta,
  FilterTypeOrgOwnerMeta,
  FilterTypeOrgTargetMeta,
  FilterTypeOwnerUserMeta,
  FilterTypeCapacityLocationMeta,
  FilterTypeHostTeamMeta,
  FilterTypePreceptorMeta,
  FilterTypeCoordinatorMeta,
  FilterTypeServiceMeta,
  FilterTypeCapacityMeta,
  FilterTypeMatchRequestTypeMeta,
  FilterTypeMatchHasPlaceholderStudentsMeta,
  FilterTypeCustomTagMatchMeta,
  FilterTypeCustomTagStudentMeta,
  FilterTypeCapacityMatchConfirmationMeta,
  FilterTypeCapacityMatchCountsMeta,
  FilterTypeCapacityStudentCountMeta,
  FilterTypeCapacityStartDateMeta,
  FilterTypeCapacityEndDateMeta,
  FilterTypeGuestSchedulingStartDateMeta,
  FilterTypeGuestSchedulingEndDateMeta,
  FilterTypeCapacityOpenForSchedulingMeta,
  FilterTypeCapacityPostToSearchPageMeta,
  FilterTypeCapacityAllowCoordinatorSchedulingMeta,
  FilterTypeCapacityAllowPlaceholderStudentsMeta,
  FilterTypeCapacityAllowGroupsMeta,
  FilterTypeNameSuggestionStatusMeta,
  FilterTypeHasIssuesMeta,
  FilterTypeNameSuggestionFieldComparisonMeta,
  FilterTypeCanManageMeta,
  {
    type: FilterType.ExcludeInherited,
    create: null,
  },
  {
    type: FilterType.MatchStatusCategory,

    // eslint-disable-next-line no-unused-vars
    encode(writer, appliedMeta, config) {
      writer.writeArg(config.statusCategory)
    },

    // eslint-disable-next-line no-unused-vars
    decode(reader, appliedMeta) {
      const config = {}
      config.statusCategory = reader.readInt()
      return config
    },

    create() {
      return {
        statusCategory: ArchiveActiveFilter.Active,
      }
    },
  },
]
