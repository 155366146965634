<!--
  TODO(phase2-nursing): This has a lot of overlap with FilterTypeCapacityMatchCounts.svelte.
                        We should be able to pull that into this component and make it more generic.
                        Perhaps we could even have Simple and Advanced be part of the generic component.
                        If you support Simple options, you'd provide the min, max, icon, iconClass, and label.
                        You could also leverage `<InputRange>.excludeOptions` to optionally omit the min: Infinity, max: Infinity radio option.
 -->
 {#if editing}
  <div class="p2" style="min-width: 300px">
    <FormGroup validationMessageIsHtml valid={!validationMessages.length} validationMessage={validationMessages.join(' ')}>
      <label class="mb05" for={appliedMeta.name}>{appliedMeta.label}</label>
      <InputRange
        name={appliedMeta.name}
        bind:valueMin={filter.unitsMin}
        bind:valueMax={filter.unitsMax}
        {blankValue}
        {infinityValue}
        defaultFiniteMin={1}
        defaultFiniteMax={50}
        firstOptionClass="mt0"
        min={1}
      >
        <svelte:fragment slot="infinity-label">
          <Icon lg name="infinity" class="text-info" />
          <span>Unlimited</span>
        </svelte:fragment>
      </InputRange>
    </FormGroup>
  </div>
{:else}
  <Icon lg name={appliedMeta.icon} class={appliedMeta.iconClass} />
  Has
  {#if filter.unitsMin !== filter.unitsMax}<strong>{filter.unitsMin}</strong>–{/if}{#if filter.unitsMax === null}<Icon
      lg
      name="infinity"
      class="text-info"
    />
    <strong>unlimited</strong>
  {:else}<strong>{filter.unitsMax}</strong>{/if}
  {singularOrPlural(amountDifference, optionLabel)}
{/if}

<script context="module">
  const blankValue = NaN
  const infinityValue = null

  function getValidationMessages(filter) {
    const messages = []

    // Values will be NaN if they selected a radio with an <InputNumber> and either leave it blank
    // or paste invalid characters.

    if (Number.isNaN(filter.unitsMin))
      messages.push(Number.isNaN(filter.unitsMax) ? 'Please select a minimum (at least 1) and maximum.' : 'Please select a minimum (at least 1).')
    else if (Number.isNaN(filter.unitsMax)) messages.push('Please select a maximum.')
    if (messages.length) return messages
    // TODO(phase2-nursing): InputRange sets the max to NaN if it's the minimum is greater than the maximum, so we can not tell if the maximum is empty or if is greater than the minimun.
    //                       In both cases we will display the message 'Please select a maximum'. Leave this comment for phase 2 in case we want to change this behavior.
    // if (filter.unitsMin != infinityValue && filter.unitsMax != infinityValue && filter.unitsMin > filter.unitsMax)
    //   messages.push('Minimum cannot be greater than maximum.')

    return messages
  }

  export const meta = {
    // eslint-disable-next-line no-unused-vars
    encode(writer, appliedMeta, config) {
      writer.writeArg(config.unitsMin)
      writer.writeArg(config.unitsMax)
    },

    // eslint-disable-next-line no-unused-vars
    decode(reader, appliedMeta) {
      const config = {}
      config.unitsMin = reader.readInt(true)
      config.unitsMax = reader.readInt(true)
      return config
    },

    create() {
      return {
        unitsMin: 1,
        unitsMax: null,
      }
    },

    validate(filter) {
      const messages = getValidationMessages(filter)
      return !messages.length
    },
  }
</script>

<script>
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Icon from 'components/Icon.svelte'
  import InputRange from 'components/InputRange.svelte'
  import { singularOrPlural } from 'services/string-utils'

  export let filter
  export let editing = false
  export let appliedMeta
  export let optionLabel = ''

  $: validationMessages = getValidationMessages(filter)
  $: amountDifference = calculateDifference()

  function calculateDifference() {
    if (filter.unitsMax === null) return Infinity
    if (!Number.isNaN(filter.unitsMin) && !Number.isNaN(filter.unitsMax)) {
      if (filter.unitsMin < filter.unitsMax) {
        return filter.unitsMax - filter.unitsMin
      }
    }
    return 1
  }
</script>
