<!-- svelte wrapper for InfiniteScroll service -->
<div bind:this={containerElem} class={className} {style} data-test="infinite-scroll" on:scroll={onContainerScroll}>
  <slot />
  {#if loading}
    <div class="text-center mt1">
      <Spinner x3 class="m2" />
    </div>
  {/if}
</div>

<svelte:window on:resize={onWindowResizeDebounced} />

<script>
  import { createEventDispatcher, tick } from 'svelte'
  import Spinner from 'components/Spinner.svelte'
  import { trackErrorWithoutThrow } from 'services/errors.js'

  export let currentCount = 0
  export let totalCount = Infinity
  export let loadPage
  export let paused = false
  export let distanceToLoadPage = null
  let className = 'scrollable-xl'
  export { className as class }
  export let style = null

  const dispatch = createEventDispatcher()
  let checking = false
  let noNewResultsBeingFound = false
  let loading = false
  let containerElem
  let failures = 0

  $: _distanceToLoadPage = distanceToLoadPage ?? 300
  $: isFullyLoaded = currentCount >= totalCount
  $: containerElem, currentCount, totalCount, checking, loadPageIfNecessary()

  function onContainerScroll(e) {
    loadPageIfNecessary()
    // If they're slamming the scroll wheel, and we start loading the next page,
    // chances are they actually want to see the next page or they'd move their mouse
    // somewhere else that's obviously not scrollable so let's prevent the _page_ (or the container) from scrolling.
    if (loading) e.stopImmediatePropagation()
  }

  let lastWindowHeight = window.innerHeight
  function onWindowResize() {
    if (lastWindowHeight >= window.innerHeight) return
    lastWindowHeight = window.innerHeight
    loadPageIfNecessary()
  }
  const onWindowResizeDebounced = _.debounce(onWindowResize, 600)

  async function loadPageIfNecessary() {
    // console.log('loadPageIfNecessary', { containerElem, currentCount, totalCount, loading, paused, failures, noNewResultsBeingFound, checking })
    if (!containerElem) {
      return
    }
    // Only ever have 1 of these in the air at a time
    if (checking) {
      return
    }
    // Stop trying if we're not finding new results
    if (noNewResultsBeingFound) {
      return
    }
    checking = true
    if (isFullyLoaded || loading || paused) {
      checking = false
      return
    }
    if (failures >= 5) {
      throw new Error('Either your endpoint is down, pageSize is not set well, or the container element is not fixed size and is continuing to grow.')
    }
    const scrollHeight = containerElem.scrollHeight
    const containerHeight = containerElem.clientHeight
    if (scrollHeight === 0 && containerHeight === 0) {
      checking = false
      return // We're hidden
    }
    const scrollY = containerElem.scrollTop
    const distanceFromBottom = scrollHeight - containerHeight - scrollY
    const isInReloadDistance = distanceFromBottom <= _distanceToLoadPage
    if (!isInReloadDistance) {
      checking = false
      return
    }

    const prevCount = currentCount
    loading = true
    try {
      await loadPage(currentCount)
      await tick() // so parent can update infinite scroller component's exported properties
    } catch (e) {
      trackErrorWithoutThrow(e)
      failures++
    } finally {
      loading = false
    }
    if (prevCount === currentCount) {
      // If no items got loaded after we tried to load, new results may be on a previous page.
      // E.g., an item at the top of the list was removed by another user after we had loaded the page and started scrolling.
      noNewResultsBeingFound = true
      checking = false
      return
    }
    failures = 0
    dispatch('reset')
    checking = false
  }

  export function scrollTo(y) {
    containerElem?.scrollTo(0, y)
  }
</script>
