<svelte:window on:focus={checkIfLoggedIn} />

{#if loginResult != null && loginResult.verifyTwoFactor && !loginResult.confirmEmail}
  <TwoFactor bind:loginResult on:verified={completeSignIn} />
{:else}
  <UnauthorizedLayout>
    {#if loginResult?.confirmEmail}
      <h3>You need to confirm your email before proceeding.</h3>
      <Alert type="success">
        You should receive an email at
        {$savedEmail}
        with a link to confirm your email soon. If you don’t see it, be sure to check your spam.
      </Alert>
      <Btn on:click={resendEmailConfirmation} class="btn-primary btn-lg btn-block ml1" disabled={loggingIn}>
        {loggingIn ? 'Sending…' : 'Send another email'}
      </Btn>
    {:else}
      {#if errors?.length > 0}
        {#each errors as e}
          <Alert type="danger">{e}</Alert>
        {/each}
      {/if}
      {#if !next}
        <Form on:submit={goNext}>
          <h2 class="login-form-title">
            {#if !validator.empty($savedEmail)}Welcome back!{:else}Welcome!{/if}
          </h2>
          <p class="sign-up-link">Don’t have an account? <a href="/join">Sign up for free.</a></p>

          <FormGroup valid={validator.email($savedEmail)} validationMessage="Please enter a valid email address" class="mb1">
            <label for="email" class="block text-left">Email</label>

            <InputText bind:this={emailEl} bind:value={$savedEmail} placeholder="your-name@example.com" name="email" autofocus autocomplete="email" />
            <input aria-hidden="true" type="password" style="display:none;" autocomplete="current-password" />
            <!--hack so browser still autocompletes the email box-->
          </FormGroup>

          <!-- <a aria-hidden="true" class="forgot-password-link reserved-space" href="/forgot-password">Forgot password?</a> -->

          <FormGroup class="mb1">
            <Btn
              type="submit"
              class="btn-block btn-primary btn-x1 btn-login btn-low-contrast-white uppercase"
              dataTest="login-next"
              loading={goingNext}>Continue</Btn
            >
          </FormGroup>
        </Form>
      {:else if ssoProviders?.length > 0}
        <SsoProviderButtons {ssoProviders} />
      {:else}
        <Form on:submit={login}>
          <!-- <a class="email-link" on:click={() => (next = false)} href={null} data-test="change-email" use:tip={'Change email'}>
            <Icon class="non-hover color-text-teal" x2 name="back" />
            <Icon class="hover color-text-teal" x2 name="circle-arrow-left" />
          </a> -->
          <h2 class="login-form-title">
            {#if !validator.empty($savedEmail)}Welcome back!{:else}Welcome!{/if}
          </h2>
          <div class="email-link" on:click={() => (next = false)} use:tip={'Change email'} data-test="change-email">
            <IconTextLink icon="back" text={$savedEmail} href={'/login'} />
          </div>

          <FormGroup valid={validator.required(password)} validationMessage="Please enter your password" class="mb1">
            <label for="password" class="block text-left">Password</label>

            <InputPassword bind:this={pwEl} bind:value={password} placeholder="Password" name="password" autofocus autocomplete="current-password" />
          </FormGroup>
          <a class="forgot-password-link" href="/forgot-password">Forgot password?</a>

          <FormGroup class="mb1">
            <Btn
              type="submit"
              class="btn-block uppercase btn-{!loggingIn ? 'primary btn-low-contrast-white' : 'disabled disabled'} btn-x1 btn-login"
              disabled={loggingIn || success}
              dataTest="login"
            >
              {#if loggingIn}Loading…{:else if success}Logging in…{:else}Log in{/if}
            </Btn>
          </FormGroup>
        </Form>
      {/if}
    {/if}
  </UnauthorizedLayout>
{/if}

<script>
  import { getRedirectUrl } from 'services/location-service.js'
  import { savedEmail, getProvidersByEmail, startSso } from 'services/sso-service.js'
  import Alert from 'components/bootstrap/Alert.svelte'
  import api from 'services/api.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import environment from 'services/environment.js'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import globalLoading from 'stores/loading.js'
  import IconTextLink from 'components/IconTextLink.svelte'
  import initial from 'stores/initial.js'
  import InputPassword from 'components/fields/InputPassword.svelte'
  import InputText from 'components/fields/InputText.svelte'
  import SsoProviderButtons from 'pages/SsoProviderButtons.svelte'
  import tip from 'decorators/tip.js'
  import TwoFactor from 'components/TwoFactor.svelte'
  import UnauthorizedLayout from 'components/UnauthorizedLayout.svelte'
  import unsavedForms from 'stores/unsaved-forms.js'
  import validator from 'services/validator.js'

  export let captchaInstance

  let pwEl, emailEl
  let errors = []
  let goingNext = false
  let loggingIn = false
  let next = false
  let success = false
  let password = ''
  let ssoProviders = null
  let loginResult = {}

  $: $savedEmail, pwEl, emailEl, focusInput()

  if ($savedEmail) loadSsoProviders()
  handleSsoSignin()

  function focusInput() {
    if (validator.email($savedEmail) && pwEl != null) pwEl.focus()
    else if (emailEl != null) emailEl.focus()
  }

  async function loadSsoProviders() {
    ssoProviders = await getProvidersByEmail($savedEmail)
  }

  async function goNext() {
    $savedEmail = $savedEmail.trim()
    if (!validateEmail()) return
    goingNext = true
    let startingSso = false
    try {
      await loadSsoProviders()
      // if they click next and have exactly 1 external idp option, take them straight there
      if (ssoProviders?.length === 1) {
        startSso(ssoProviders[0].ssoSchemeName)
        startingSso = true
      } else {
        next = true
      }
      return false
    } finally {
      if (!startingSso) goingNext = false // keep the next button showing loading while we nav them to their sso idp
    }
  }

  function validateEmail() {
    if (!validator.required($savedEmail)) {
      errors = ['Email is required']
      return false
    }
    if (!validator.email($savedEmail)) {
      errors = ['Invalid email']
      return false
    }
    errors = null
    return true
  }

  async function login() {
    loggingIn = true
    const twoFactorEnabled = await api.account.twoFactorIsEnabled({ email: $savedEmail })
    let captchaResponse
    try {
      // skip captcha if twofactor is enabled, since some users struggle to complete captchas
      captchaResponse = twoFactorEnabled ? null : await captchaInstance.execute()
    } catch {
      // user canceled captcha modal
      loggingIn = false
      return
    }
    try {
      const result = await api.account.login({
        email: $savedEmail,
        password,
        captchaResponse,
      })
      handleLoginResult(result)
    } catch (errorRes) {
      handleLoginError(errorRes)
    } finally {
      loggingIn = false
    }
  }

  function resendEmailConfirmation() {
    api.account.resendEmailConfirmationAnonymous($savedEmail)
    return false
  }

  async function handleLoginError(errorRes) {
    // if response is empty 400, they may already be logged in (happens when validateantiforgerytoken fails), just trigger reload
    if (errorRes.response?.status === 400 && validator.empty(errorRes.content)) {
      window.location.reload()
      return
    }
    captchaInstance.resetCaptcha()
  }

  function handleSsoSignin() {
    if ($initial.loginResult == null) return
    handleLoginResult($initial.loginResult)
  }

  function handleLoginResult(loginRslt) {
    loginResult = loginRslt || {}
    if (!validator.empty(loginResult.resetPassword)) {
      const model = window.encodeURIComponent(JSON.stringify(loginResult.resetPassword))
      unsavedForms.navigateSafe(`/reset-password?resetPasswordModel=${model}`)
    } else if (!loginResult.verifyTwoFactor) {
      success = true
      completeSignIn()
    } else {
      loggingIn = false
    }
  }

  function completeSignIn() {
    loggingIn = true
    $globalLoading = true
    const redirect = getRedirect()
    window.location = redirect
  }

  function getRedirect() {
    // This logic had been in the router component, so if they visited other public pages and then logged in, they'd be taken back to where they left off,
    // but most of the time they'd probably just log right back in and then be taken right back to where they were.
    // Additionally, if they clicked a link to an authenticated route from a public page while not logged in, they should be taken to the authenticated route, not
    // the route they initially came in on. e.g. browing api docs not logged in, click "mange api users", login, should then see manage api users page.
    // If they came to a public page directly, don't redirect them back to that public page upon login.
    // e.g. they go to resetpassword. upon login, they shouldn't go _back_ to reset password.
    // TODO: handle multiple personas better: i.e., don't allow people to visit pages that their persona doesn't have access to.
    //       Shouldn't happen as long as they don't touch localstorage or login as a different user though, so probably not a big deal.
    const noRedirect = /(?:resetPassword)|(?:reset-password)|(?:join)|(?:forgot-password)|(?:api-docs)/i.test(window.location.pathname)
    if (noRedirect) return '/'
    return getRedirectUrl()
  }

  async function checkIfLoggedIn() {
    // When user comes back to this tab after they've been auto-logged out, reload in case user logged in from a different tab.
    // Using fetch directly so our error-handling doesn't send us to the login page when it gets 401 when not logged in
    // Not sending a websocket, since we don't create websocket connection til they're logged in and we also don't want to send a "loggedin" event publically anyway.
    // consider sending "Logout" websocket though, so when user logs out in one place it logs out in all other places. But ideally, we'd allow them to control that, like facebook does. They might want to log out on one device, but not another.
    const res = await window.fetch(`${environment.linkApi}/api/profile`, environment.fetchOptions)
    if (res.status === 200) {
      // url is already set to where they had been, so just reload
      window.location.reload()
    }
  }
</script>

<style lang="scss">
  .login-form-title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 10px;
    text-align: center;
  }

  .sign-up-link {
    text-align: center;
    margin-bottom: 20px;
  }
  .email-link {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;
  }
  .forgot-password-link {
    display: block;
    width: 100%;
    text-align: right;
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 450px) {
    .sign-up-link {
      margin-bottom: 60px;
    }
    .email-link {
      margin-bottom: 60px;
    }
  }
</style>
