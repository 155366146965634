<div class="join-container">
  <div class="join-message">
    <h1>Sign up for free</h1>
    <p>
      <FreeTrialExplanation />
    </p>
    <a class="btn-pricing" href="/pricing">See Pricing</a>
  </div>
  <div class="join-form-container">
    <a class="logo-link" href="/">
      <img src="/images/logo.png?v=2" class="logo" alt="" />
    </a>
    <div class="join-form">
      <Form on:submit={join} bind:submitted>
        <FormGroup valid={!submitted || validator.required(input.legalFirstName)} validationMessage="You must provide a first name">
          <label for="first-name">Legal first name</label>
          <InputText bind:value={input.legalFirstName} autocomplete="off" name="first-name" placeholder="Legal first name" autofocus />
        </FormGroup>
        <FormGroup valid={!submitted || validator.required(input.legalLastName)} validationMessage="You must provide a last name">
          <label for="last-name" class="mt1">Legal last name</label>
          <InputText bind:value={input.legalLastName} autocomplete="off" name="last-name" placeholder="Legal last name" />
        </FormGroup>
        <FormGroup valid={validator.email($savedEmail)} validationMessage="Please enter a valid email address.">
          <label for="email" class="block text-left">Email</label>
          <InputText
            name="email"
            id="email"
            bind:value={$savedEmail}
            bind:this={emailInputEl}
            placeholder="your-name@example.com"
            autocomplete="email"
            on:blur={loadSsoProviders}
          />
        </FormGroup>
        <FormGroup class="mb0" valid={passwordIsValid}>
          <label for="password" class="block text-left">Password</label>
          <InputPassword name="password" id="password" bind:value={input.password} placeholder="Password" autocomplete="new-password" />
          {#if $initial.resetPasswordDetails}
            <PasswordComplexityValidator
              bind:valid={passwordIsValid}
              {submitted}
              password={input.password}
              passwordSettings={$initial.resetPasswordDetails.passwordSettings}
            />
          {/if}
        </FormGroup>
        <FormGroup valid={validator.equals(input.password, input.confirmPassword)} validationMessage="Passwords do not match.">
          <label for="confirm-password" class="block text-left">Confirm password</label>
          <InputPassword
            name="confirm-password"
            id="confirm-password"
            bind:value={input.confirmPassword}
            placeholder="Confirm password"
            autocomplete="new-password"
          />
        </FormGroup>
        <FormGroup>
          <label for="how-did-you-hear-of-us" class="block text-left">How did you hear about us? <span class="optional">(optional)</span></label>
          <InputSelect
            textStyle
            bind:value={input.hearAboutUs}
            name="how-did-you-hear-of-us"
            id="how-did-you-hear-of-us"
            options={[
              { value: 'Referral from a Student', label: 'Referral from a Student' },
              { value: 'Referral from a Teaching Affiliate', label: 'Referral from a Teaching Affiliate' },
              { value: 'Referral from a Clinical Site', label: 'Referral from a Clinical Site' },
              { value: 'Email/Call from Clinician Nexus', label: 'Email/Call from Clinician Nexus' },
              { value: 'Google Search', label: 'Google Search' },
              { value: 'Bing Search', label: 'Bing Search' },
              { value: 'Instagram', label: 'Instagram' },
              { value: 'Twitter', label: 'Twitter' },
              { value: 'Facebook', label: 'Facebook' },
              { value: 'LinkedIn', label: 'LinkedIn' },
              { value: 'Tradeshow', label: 'Tradeshow' },
              { value: otherOption, label: otherOption },
            ]}
          />
        </FormGroup>
        {#if input.hearAboutUs === otherOption}
          <FormGroup>
            <InputText name="hearAboutUsOther" id="hearAboutUsOther" bind:value={input.hearAboutUsOther} maxlength="100" />
          </FormGroup>
        {/if}
        <FormGroup valid={validator.checked(input.acceptEula)} validationMessage="You must accept the end user license agreement to continue.">
          <InputCheckbox name="accept-eula" bind:checked={input.acceptEula}>
            I accept the <a on:click={showEula} href={null}>End User License Agreement</a>.
          </InputCheckbox>
        </FormGroup>
        <FormGroup class="mb1">
          <Btn
            class="btn-{saving ? 'disabled disabled' : 'primary btn-low-contrast-white'} btn-block btn-xl"
            type="submit"
            loading={saving}
            dataTest="signup">Sign up</Btn
          >
        </FormGroup>
      </Form>
      <div class="join-footer">
        <p>
          Already have an account? <a href="/login">Log in.</a>
        </p>
        <p><a href="https://www.cliniciannexus.com">Learn more about our platform</a> | <a href="/support/contact">Help</a></p>
      </div>
    </div>
  </div>
</div>

{#if show}
  <Modal lg title="End User License Agreement" on:close={close}>
    <div class="p2">
      <Eula />
    </div>
    <div class="modal-footer">
      <Btn class="btn btn-primary" on:click={acceptEulaHandler}>I accept</Btn>
      <Btn class="btn btn-default" on:click={close}>Close</Btn>
    </div>
  </Modal>
{/if}

{#if ssoProviders?.length > 0}
  <Modal
    on:close={clearSsoProvidersAndEmail}
    title="You should login via {toFriendlyList(
      ssoProviders.map(p => p.ssoDisplayName),
      4,
      true
    )}"
  >
    <div class="modal-body text-center mt3">
      <h4>You will be redirected back to Clinician Nexus afterward to complete the sign up process.</h4>
    </div>
    <div class="modal-footer text-center">
      <SsoProviderButtons {ssoProviders} />
      <Btn class="btn-default" on:click={clearSsoProvidersAndEmail} autofocus>Use different email</Btn>
    </div>
  </Modal>
{/if}

{#if showUserAlreadyExists}
  <UserAlreadyExistsModal {existingUser} on:close={resetUserAlreadyExists} />
{/if}

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import Eula from 'components/Eula.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import InputCheckbox from 'components/fields/InputCheckbox.svelte'
  import InputPassword from 'components/fields/InputPassword.svelte'
  import InputText from 'components/fields/InputText.svelte'
  import Modal from 'components/Modal.svelte'
  import UserAlreadyExistsModal from 'components/account/UserAlreadyExistsModal.svelte'
  import { toFriendlyList } from 'services/string-utils.js'
  import api from 'services/api.js'
  import { savedEmail, getProvidersByEmail } from 'services/sso-service.js'
  import validator from 'services/validator.js'
  import globalLoading from 'stores/loading.js'
  import InputSelect from 'components/fields/InputSelect.svelte'
  import FreeTrialExplanation from './FreeTrialExplanation.svelte'
  import PasswordComplexityValidator from 'components/PasswordComplexityValidator.svelte'
  import initial from 'stores/initial.js'
  import SsoProviderButtons from './SsoProviderButtons.svelte'

  export let referralId
  export let captchaInstance

  const input = {
    legalFirstName: '',
    legalLastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    acceptEula: false,
    referralId,
    hearAboutUs: '',
    hearAboutUsOther: '',
  }
  const otherOption = 'Some other way (tell us about it)'

  let passwordIsValid
  let submitted = false
  let emailInputEl
  let saving = false
  let show = false
  let showUserAlreadyExists = false
  let existingUser = null
  let ssoProviders = null

  if (referralId) api.referral.clicked({ referralId })

  loadSsoProviders()

  async function join() {
    try {
      saving = true
      if (input.hearAboutUs === otherOption) {
        input.hearAboutUs = input.hearAboutUsOther
      }
      input.captchaResponse = await captchaInstance.execute()
      await api.account.register(
        { ...input, email: $savedEmail },
        {
          monitor: false,
          canHandleRequestError: (response, content) => {
            return !!content?.emailIsTaken
          },
        }
      )
      // registered and redirecting, so now show global loader
      // and leave the button displaying as loading too
      $globalLoading = true
      window.location = '/add-role'
    } catch (ex) {
      captchaInstance.resetCaptcha()
      saving = false
      $globalLoading = false
      if (ex?.content?.emailIsTaken) {
        existingUser = ex.content.existingUser
        showUserAlreadyExists = true
      }
    }
  }

  function showEula() {
    show = true
  }

  function acceptEulaHandler() {
    input.acceptEula = true
    show = false
  }

  function close() {
    show = false
  }

  function resetUserAlreadyExists() {
    showUserAlreadyExists = false
    existingUser = null
  }

  async function loadSsoProviders() {
    ssoProviders = await getProvidersByEmail($savedEmail)
  }

  function clearSsoProvidersAndEmail() {
    ssoProviders = null
    $savedEmail = null
    emailInputEl?.focus()
  }
</script>

<style lang="scss">
  .join-container {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 5fr 4fr;
    grid-template-rows: auto;
    grid-template-areas: 'message form';
  }
  h1 {
    color: #e3fcf6;
    font-size: 64px;
    font-weight: 300;
  }
  .logo {
    height: 100px;
    margin: 50px 0;
  }
  .logo-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .join-message {
    grid-area: message;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(142.41deg, #44c5b4 0%, #227774 100%);
    color: #e3fcf6;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .join-message > p {
    text-align: center;
    width: 50%;
    font-size: 20px;
    font-weight: 400;
  }

  .join-form-container {
    grid-area: form;
    padding: 0 120px;
    max-height: 100vh;
    overflow-y: auto;
  }
  .btn-pricing {
    text-decoration: none;
    border: 0.5px solid #e3fcf6;
    color: #e3fcf6;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 4px;
    text-align: center;
    padding: 28px 48px;
  }
  .btn-pricing:hover {
    background: #e3fcf6;
    color: #227774;
  }
  .join-footer {
    border-top: 1px solid #cbd5e1;
    width: 100%;
    margin: 30px 0;
    padding-top: 40px;
  }
  .optional {
    opacity: 0.65;
  }

  @media only screen and (max-width: 1200px) {
    .join-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 2fr 5fr;
      grid-template-areas:
        'logo'
        'message'
        'form';
      max-width: 100vw;
      height: fit-content;
      padding-bottom: 30px;
    }
    h1 {
      font-size: 48px;
    }
    .logo-link {
      grid-area: logo;
    }
    .logo {
      height: 90px;
      margin: 0;
    }
    .join-message {
      grid-area: message;
    }
    .join-message > p {
      width: 75%;
      font-size: 20px;
      line-height: 23px;
    }
    .join-form-container {
      display: contents;
    }
    .join-form {
      grid-area: form;
      padding: 40px 90px 0;
    }
    .btn-pricing {
      padding: 18px 32px;
    }
    .join-footer {
      width: 100%;
    }
  }
  @media only screen and (max-width: 450px) {
    .join-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 0.6fr 2fr 6fr;
      grid-template-areas:
        'logo'
        'message'
        'form';
      max-width: 100vw;
      max-height: fit-content;
      padding-bottom: 20px;
    }
    h1 {
      font-size: 36px;
      line-height: 42px;
    }
    .logo-link {
      grid-area: logo;
      margin: 0;
      padding: 0;
    }
    .logo {
      height: 50px;
      margin: 0;
    }
    .join-message {
      grid-area: message;
    }
    .join-message > p {
      width: 85%;
      font-size: 16px;
      line-height: 19px;
    }
    .join-form {
      grid-area: form;
      padding: 10px 10px 0;
      margin: 0;
    }
    .btn-pricing {
      padding: 8px 82px;
    }
  }
</style>
