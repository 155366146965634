import { humanFileSize } from './string-utils.js'
import modelState from 'stores/model-state.js'
import { selectMany } from './object-utils.js'

export function getClientSideBase64Uri(file) {
  return new Promise((resolve, reject) => {
    if (!file) {
      resolve(null)
    }
    if (window.URL) {
      const url = URL.createObjectURL(file) // todo: consider how to revokeObjectURL when done
      resolve(url)
    }
    reject(new Error('This browser is incapable of converting a file to a data url'))
  })
}

export async function downloadBinary(response) {
  const blob = await response.blob() // no need to set blob type--seems browsers are able to determine type by file extension just fine, despite content-type set to generic binary type
  const blobUrl = window.URL.createObjectURL(blob)

  // construct an anchor element to click and cause the browser to download the file
  // note we don't append the link to the body because we want to be able to click it (can't click it if there's a modal open for instance)
  const link = document.createElement('a')
  link.href = blobUrl
  link.download = _getFilenameFromContentDisposition(response.headers.get('content-disposition'))
  link.click()

  // clear the blobUrl on next event loop, as some people on stackoverflow mentioned it could error in some browsers otherwise.
  setTimeout(() => window.URL.revokeObjectURL(blobUrl))
}

// exported so we can unit test it
export function _getFilenameFromContentDisposition(disposition) {
  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/i
  const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i

  if (utf8FilenameRegex.test(disposition)) {
    return decodeURIComponent(utf8FilenameRegex.exec(disposition)[1])
  } else {
    // prevent ReDos attacks by anchoring the ascii regex to string start and
    //  slicing off everything before 'filename='
    const filenameStart = disposition.toLowerCase().indexOf('filename=')
    if (filenameStart >= 0) {
      const partialDisposition = disposition.slice(filenameStart)
      const matches = asciiFilenameRegex.exec(partialDisposition)
      if (matches != null && matches[2]) {
        return matches[2]
      }
    }
  }
  return 'clinician-nexus-file'
}

export function validateFile(file, acceptableFileTypes, maxSizeMegaByte = 100) {
  if (!file) return false
  const mimeIsEmpty = file.type === ''
  const acceptableMimeTypes = selectMany(acceptableFileTypes, f => f.mimeTypes)
  if (!mimeIsEmpty && !acceptableMimeTypes.includes(file.type.toLowerCase())) {
    const list = acceptableFileTypes.map(f => f.description).join(', ')
    modelState.set(`“${file.name}” has an invalid file type. Valid file types are: ${list}`)
    return false
  }

  // check file size - limit to 100MB for now
  // todo make max file size an attribute + validate file size on server
  // todo: if image and too large, resize it dynamically client-side before sending?
  if (file.size > 1024 * 1024 * maxSizeMegaByte) {
    const isImage = isImageFile(file.type)
    const message = `File “${file.name}” is too large (${humanFileSize(file.size)}). Files must be smaller than ${maxSizeMegaByte}MB.${
      isImage ? ' You can resize it to be smaller.' : ''
    }`
    modelState.set(message)
    return false
  }
  return true
}

export const excelTypes = [
  {
    name: 'Excel',
    description: 'Excel',
    mimeTypes: [
      'application/vnd.ms-excel (official)',
      'application/msexcel',
      'application/x-msexcel',
      'application/x-ms-excel',
      'application/x-excel',
      'application/x-dos_ms_excel',
      'application/xls',
      'application/x-xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
  },
]

export function isImageFile(contentType) {
  return contentType?.startsWith('image/')
}

export function isPdfFile(contentType) {
  return contentType === 'application/pdf'
}

export function canPreviewFile(contentType) {
  return isImageFile(contentType) || isPdfFile(contentType)
}
