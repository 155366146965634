<div class="modal-body general-body{generalBodyClass ? ` ${generalBodyClass}` : ''}" class:has-body-top={hasBodyTopSlot}>
  {#if hasBodyTopSlot}
    <div class="body-top">
      <slot name="body-top" />
    </div>
  {/if}

  <div class="body-meta flex-column g1">
    {#if userDocument.fileType}
    <div>
      <label for="document-type" class="m0">Type</label>
      <div class="flex-row flex-align-center g05">
        <Icon lg name={userDocument.fileType.icon} class="text-primary" />
        <span>{userDocument.fileType.name}</span>
      </div>
    </div>
    {/if}

    <div>
      <label for="document-size" class="m0">Size</label>
      <div>{humanFileSize(userDocument.size)}</div>
    </div>

    {#if userDocument.dateCreated}
      <div>
        <label for="date-created" class="m0">Uploaded</label>
        <div><FromNow date={userDocument.dateCreated} showTooltip localize /></div>
      </div>
    {/if}

    {#if userDocumentSharing != null}
      <div>
        <label for="visibility" class="m0">Who has access</label>
        <UserDocumentAccessPeople {userDocument} {userDocumentSharing} />
      </div>
    {/if}

    {#if canCheckerboard}
      <div class="flex-grow" />

      <InputToggle
        containerClass="flex-row flex-align-center g1"
        labelClass="flex-row flex-align-center g05"
        class="m0"
        bind:checked={checkerboard}
        name="show-checkerboard"
        tooltip="Toggle checkerboard background"
      >
        <Icon lg name="game-board" style="color: #a1a1a1" />
        <span>Background</span>
      </InputToggle>
    {/if}
  </div>

  <div
    class="body-preview flex-grow flex-column flex-align-center relative"
    class:preview-available={previewAvailable && canManageDocuments}
    class:is-image={isImage}
    class:explicit-size={canManageDocuments && isPdf && !failedToLoad}
    class:p2={loading || !previewAvailable || canCheckerboard}
    class:checkerboard={loaded && canCheckerboard && checkerboard}
    class:loaded
    class:flex-justify-center={!canManageDocuments || loading || !previewAvailable}
  >
    {#if !canManageDocuments || failedToLoad || !previewAvailable}
      <h3 class="m0" data-test="preview-not-available">Preview not available</h3>
      {#if failedToLoad}
        <span>Failed to load document</span>
      {:else if !canManageDocuments}
        <span>You are <Icon lg name="impersonate" class="text-purple" /> impersonating</span>
      {/if}
    {:else if loading}
      <div class="absolute center">
        <h3 class="m0">Loading preview…</h3>
      </div>
    {/if}

    {#if canManageDocuments && !failedToLoad}
      {#if isImage}
        <!--
        If we set the src of the image to a data URI'd SVG with the same dimensions, the modal won't jump/resize when the image finally loads
        This is due to intrinsic sizing of the image and size containment. In the future, we could probably leverage the upcoming intrinsicsize attribute
        I had also tried the `contain-intrinsic-size` CSS property but it didn't seem to work. There are other things worth trying, too, but I need to move on and this works perfectly.
        See: https://googlechrome.github.io/samples/intrinsic-size
        See: https://developer.mozilla.org/en-US/docs/Web/CSS/contain-intrinsic-size
        See: https://developer.mozilla.org/en-US/docs/Web/CSS/contain-intrinsic-block-size
        See: https://developer.mozilla.org/en-US/docs/Web/CSS/contain-intrinsic-inline-size
      -->
        <img
          class="image"
          alt={userDocument.nameOriginal}
          src={hasDimensions
            ? 'data:image/svg+xml,' +
              encodeURIComponent(`<svg xmlns="http://www.w3.org/2000/svg" width="${userDocument.width}px" height="${userDocument.height}px"></svg>`)
            : url}
          on:load={async e => {
            loadedCount++ // This starts at 0, then goes to 1 when the data URI'd SVG loads (instantly), then 2 when the user document is loaded.
            // Prevent on:load from firing in an infinite loop
            // e.target.src will include the protocol://domain:port portion of the URL whereas url will not
            // So, we need to check the end of the string
            if (!e.target.src.endsWith(url)) e.target.src = url
            else loading = false
          }}
          on:error={() => (failedToLoad = true)}
        />
      {:else if isPdf}
        {#if canUseAdobeEmbed}
          <div bind:this={adobePdfViewerElem} id={adobePdfViewerElemId}></div>
        {:else}
          <object
            data={url}
            type="application/pdf"
            title={userDocument.nameOriginal}
            on:load={() => (loading = false)}
            on:error={() => (failedToLoad = true)}
          >
            <iframe src={url} title={userDocument.nameOriginal} />
          </object>
        {/if}
      {/if}
    {/if}
  </div>
</div>

<script context="module">
  // It's not actually the full window; it's like SIZED_CONTAINER but fills its container
  // and provides a better UX because you can scroll with the mouse instead of having to use
  // the buttons in the toolbar or scrub a horizontal scrollbar
  const previewConfig = {
    embedMode: 'FULL_WINDOW',
    enableFormFilling: false,
    showAnnotationTools: false,
  }
</script>

<script>
  import { humanFileSize } from 'services/string-utils.js'
  import { isDevEnvironment } from 'services/environment.js'
  import { isImageFile, isPdfFile } from 'services/file-service.js'
  import FromNow from 'components/FromNow.svelte'
  import Icon from 'components/Icon.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'
  import user from 'stores/user.js'
  import UserDocumentAccessPeople from 'components/UserDocumentAccessPeople.svelte'
  import adobeEmbed from 'stores/adobe-embed.js'

  export let userDocument
  export let userDocumentSharing
  export let generalBodyClass = null
  export let failedToLoad = false
  export let noMinimumAlphaWidthOrHeight = false // only dbo.UserDocument has these currently. we should add them to the other AbstractFile implementations (stepsubmissionfiles, stepfiles, agreementfiles)...but meh for now.

  const hasBodyTopSlot = !!$$props.$$slots?.['body-top']
  const adobePdfViewerElemId = 'adobe-pdf-viewer'

  let loading = true
  let loadedCount = 0
  let checkerboard = true
  let adobePdfViewerElem = null

  $: url = userDocument?.url
  $: canManageDocuments = !$user.isImpersonating || isDevEnvironment // Impersonated students can only view documents list
  $: loaded = (hasDimensions && loadedCount > 1) || (!hasDimensions && loadedCount > 0)
  $: isImage = isImageFile(userDocument?.contentType)
  $: hasDimensions = isImage && userDocument.width && userDocument.height
  $: canCheckerboard =
    !noMinimumAlphaWidthOrHeight && canManageDocuments && isImage && (userDocument.minimumAlpha == null || userDocument.minimumAlpha < 200)
  $: isPdf = isPdfFile(userDocument?.contentType)
  $: previewAvailable = isImage || isPdf
  $: canUseAdobeEmbed = $adobeEmbed.ready && $adobeEmbed.clientId != null
  $: if (canUseAdobeEmbed && adobePdfViewerElem) initAdobeEmbed()

  function initAdobeEmbed() {
    const adobeDCView = new window.AdobeDC.View({
      clientId: $adobeEmbed.clientId,
      divId: adobePdfViewerElemId,
      downloadWithCredentials: true,
    })
    adobeDCView.registerCallback(window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER, e => {
      if (e.type === 'PDF_VIEWER_OPEN') loading = false
      else if (e.type === 'APP_RENDERING_FAILED') failedToLoad = true
    })
    const file = {
      content: { location: { url } },
      metaData: { fileName: userDocument.nameOriginal },
    }
    adobeDCView.previewFile(file, previewConfig)
  }
</script>

<style lang="scss">
  .general-body {
    display: grid;
    gap: 15px 30px;
    padding: 30px;

    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-template-areas: 'meta preview';

    &.has-body-top {
      grid-template-rows: auto 1fr;
      grid-template-columns: auto 1fr;
      grid-template-areas:
        'top top'
        'meta preview';
    }
  }

  .body-top {
    grid-area: top;
  }

  .body-meta {
    grid-area: meta;
  }

  .body-preview {
    grid-area: preview;
    background: #eee;

    &.preview-available {
      max-height: 70vh;

      &.is-image {
        // Intentionally before .checkerboard
        &.loaded {
          background: transparent;
        }

        &.checkerboard {
          background-image: linear-gradient(45deg, #a1a1a1 25%, transparent 25%), linear-gradient(-45deg, #a1a1a1 25%, transparent 25%),
            linear-gradient(45deg, transparent 75%, #a1a1a1 75%), linear-gradient(-45deg, transparent 75%, #a1a1a1 75%);
          background-size: 50px 50px;
          background-position:
            0 0,
            0 24.6px,
            24.6px -24.6px,
            -24.6px 0px;
        }
      }

      &.explicit-size {
        height: 70vh;
      }
    }

    * {
      max-width: 100%;
      max-height: 100%;
    }

    object,
    iframe {
      border: none;
      width: 100%;
      height: 100%;
    }
  }

  .image {
    object-fit: contain;
  }

  @media (max-width: 600px) {
    .general-body {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      grid-template-areas:
        'meta'
        'preview';
      gap: 10px;

      &.has-body-top {
        grid-template-rows: auto auto auto;
        grid-template-areas:
          'top'
          'meta'
          'preview';
      }
    }

    .body-preview.preview-available {
      max-height: 30vh;

      &.explicit-size {
        height: 30vh;
      }
    }
  }
</style>
