<div class="flex-row flex-align-center{gapClass ? ` ${gapClass}` : ''}{className ? ` ${className}` : ''}" use:tip={tooltip}>
  <ProfilePic
    src={user.profilePicture}
    name={user.name}
    id={user.userId}
    initials={user.initials}
    large={profilePicLarge}
    medium={profilePicMedium}
    small={profilePicSmall}
    xs={profilePicXs}
    {href}
    class={profilePicClass}
    pseudoDisabled={deleted}
    {roundLeftBorder}
  >
    <slot name="profile-pic" />
  </ProfilePic>

  {#if !profilePicOnly}
    <div class="flex-column g05">
      <svelte:element this={linkToProfile ? 'a' : 'div'} {href}>
        <svelte:element this={tagName} class="{headerClass}{pronounsBelow ? '' : ' flex-row flex-align-center g05'}" style={headerStyle}>
          <span class="underlineable">
            {user.name}
            <slot name="after-name" />
          </span>
          <!--
          Intentionally omit name parameter in PronounSet to keep Name pronoun example brief
          (otherwise would potentially repeat a long name in a small amount of space)
        -->
          <PronounSet pronounSetId={user.pronounSetId} style={pronounSetStyle} />
          <slot name="after-pronouns" />
        </svelte:element>
        {#if deleted}
          <div>
            <InactiveUserIcon show title={null} />
            Inactive user
          </div>
        {/if}
      </svelte:element>
      <slot name="after-name-and-pronouns" />
    </div>
  {/if}
</div>

<script>
  import InactiveUserIcon from 'components/InactiveUserIcon.svelte'
  import ProfilePic from 'components/ProfilePic.svelte'
  import PronounSet from 'components/PronounSet.svelte'
  import tip from 'decorators/tip.js'

  export let user
  let className = ''
  export { className as class }
  export let pronounsBelow = false
  export let tagName = 'h4'
  export let headerClass = 'm0 normal'
  export let headerStyle = 'line-height: 16px'
  export let linkToProfile = false
  export let gapClass = linkToProfile ? 'g05' : 'g1'
  export let pronounSetStyle = 'font-size: 12px; color: #777'
  export let profilePicXs = false
  export let profilePicSmall = false
  export let profilePicMedium = false
  export let profilePicLarge = false
  export let profilePicClass = null
  export let profilePicOnly = false
  export let roundLeftBorder = false
  export let tooltip = null

  $: href = linkToProfile ? `/user/${user.userName}` : null
  $: deleted = user.deletedDateTime != null
</script>
